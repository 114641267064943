import { useReactiveVar } from "@apollo/client";
import { FC, useCallback } from "react";
import { useMatch } from "react-router-dom";
import { useNotificationsContext } from "~@/context/notifications-context";
import {
  ChannelType,
  CoreRoomFieldsFragmentDoc,
  CoreUserFieldsFragmentDoc,
  LastMessageContentOutput,
  MessageStatusOutput,
  RoomOutput2,
  RoomStatus,
  UserOutput,
  useUpdateMessageDataMutation,
  useWatchCreateMessageSubscription,
} from "~@/graphql/codegen/generated";
import useInternalUser from "~@/hooks/useInternalUser";
import useStoreMessage from "~@/pages/chat-room/hooks/useStoreMessage";
import { selectedChannelsTypesVar } from "~@/reactive-variables";

export type WatchCreateMessageProps = {
  channelType: ChannelType;
};

const WatchCreateMessage: FC<WatchCreateMessageProps> = ({ channelType }) => {
  const { internalUser } = useInternalUser();

  const { incomingMessage } = useNotificationsContext();

  const [updateMessageData] = useUpdateMessageDataMutation();

  const storeMessage = useStoreMessage();

  useWatchCreateMessageSubscription({
    variables: {
      channelType,
    },
    onData({ data: { data }, client: { cache, readFragment } }) {
      const newMessage = data?.watchCreateMessage;

      if (newMessage instanceof Object) {
        const userFragment = cache.readFragment<UserOutput>({
          id: cache.identify({
            id: newMessage.messageAuthorId,
            __typename: "UserOutput",
          }),
          fragment: CoreUserFieldsFragmentDoc,
        });

        const acceptedRoomFragment = cache.readFragment<RoomOutput2>({
          id: cache.identify({
            id: newMessage.chatRoomId,
            __typename: "RoomOutput2",
          }),
          fragment: CoreRoomFieldsFragmentDoc,
          fragmentName: "CoreRoomFields",
        });

        const isPlayAudio =
          acceptedRoomFragment?.activeOperator?.id === internalUser?.id;

        const isFromBot =
          newMessage.messageAuthorId ===
            process.env.REACT_APP_MESSAGES_BOT_ID ||
          newMessage.messageAuthorId ===
            process.env.REACT_APP_NOTIFICATIONS_BOT_ID;

        const isFromClient = !isFromBot && !userFragment?.roleId;
        cache.modify({
          id: cache.identify({
            id: newMessage.chatRoomId,
            __typename: "RoomOutput2",
          }),
          fields: {
            lastMessageContent(): LastMessageContentOutput {
              return {
                messageText: newMessage.messageText,
                messageContent:
                  newMessage.messageContent &&
                  Array.isArray(JSON.parse(newMessage.messageContent))
                    ? JSON.parse(newMessage.messageContent)
                    : null,
              };
            },
            lastMessageAt(existing: string): string {
              return newMessage.messageCreatedDateTime ?? existing;
            },
            lastClientMessageAt(existing: string | null) {
              return isFromClient
                ? newMessage.messageCreatedDateTime
                : existing;
            },
          },
        });

        storeMessage(newMessage);

        if (isFromClient) {
          if (newMessage?.chatRoomStatus === "accepted" && isPlayAudio) {
            incomingMessage.play();
          }

          updateMessageData({
            variables: {
              input: {
                chatRoomId: newMessage.chatRoomId as string,
                isClient: false,
                messageStatus: MessageStatusOutput.MessageIsRead,
                messagesIds: [newMessage.messageId as string],
              },
            },
          });
        }
      }
    },
  });

  return null;
};

const useWatchCreateMessage = () => {
  const selectedChannelsTypes = useReactiveVar(selectedChannelsTypesVar);

  const watchCreateMessage = useCallback(() => {
    return selectedChannelsTypes.map((selectedChannelType) => {
      return (
        <WatchCreateMessage
          key={selectedChannelType}
          channelType={selectedChannelType}
        />
      );
    });
  }, [selectedChannelsTypes]);

  return watchCreateMessage;
};

export default useWatchCreateMessage;
