import { useCallback } from "react";
import { Reference, useApolloClient } from "@apollo/client";
import {
  GetChatRoomMessagesOutput,
  CoreMessageFieldsFragmentDoc,
  MessageOutput,
} from "~@/graphql/codegen/generated";
import some from "lodash/some";

const useStoreMessage = () => {
  const { cache } = useApolloClient();

  const storeMessage = useCallback(
    (message: MessageOutput) => {
      cache.modify({
        fields: {
          getChatRoomMessages(
            existing: GetChatRoomMessagesOutput | null,
            { readField }
          ) {
            if (existing?.roomId === message.chatRoomId) {
              const chatRoomMessageRef = cache.writeFragment({
                data: message,
                fragment: CoreMessageFieldsFragmentDoc,
                fragmentName: "CoreMessageFields",
              });

              if (
                some(
                  existing?.chatRoomMessages,
                  (ref: Reference) =>
                    readField<string>("messageId", ref) === message.messageId
                )
              ) {
                return existing;
              }

              return {
                ...existing,
                chatRoomMessages: [
                  ...(existing?.chatRoomMessages || []),
                  chatRoomMessageRef,
                ],
              };
            }

            return existing;
          },
        },
      });
    },
    [cache]
  );

  return storeMessage;
};

export default useStoreMessage;
