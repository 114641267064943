import { FC, useCallback } from "react";
import { useMatch } from "react-router-dom";
import { useReactiveVar } from "@apollo/client";
import { useWatchUpdateMessageDataSubscription } from "~@/graphql/codegen/generated";
import type { WatchCreateMessageProps } from "./useWatchCreateMessage";

import { selectedChannelsTypesVar } from "~@/reactive-variables";

const WatchUpdateMessageData: FC<WatchCreateMessageProps> = ({
  channelType,
}) => {
  const match = useMatch("/chats/:chatRoomId");

  useWatchUpdateMessageDataSubscription({
    variables: {
      channelType,
    },
    onData({ data: { data }, client: { cache } }) {
      const newMessageData = data?.watchUpdateMessageData;

      if (
        newMessageData instanceof Object &&
        match?.params.chatRoomId !== newMessageData.chatRoomId
      ) {
        cache.modify({
          id: cache.identify({
            id: newMessageData.chatRoomId,
            __typename: "RoomOutput2",
          }),
          fields: {
            unreadMessagesNumber() {
              return newMessageData.unreadMessagesNumber;
            },
          },
        });
      }
    },
  });

  return null;
};

const useWatchUpdateMessageData = () => {
  const selectedChannelsTypes = useReactiveVar(selectedChannelsTypesVar);

  const watchUpdateMessageData = useCallback(() => {
    return selectedChannelsTypes.map((selectedChannelType) => {
      return (
        <WatchUpdateMessageData
          key={selectedChannelType}
          channelType={selectedChannelType}
        />
      );
    });
  }, [selectedChannelsTypes]);

  return watchUpdateMessageData;
};

export default useWatchUpdateMessageData;
