import { Reference, useApolloClient, useReactiveVar } from "@apollo/client";
import differenceBy from "lodash/differenceBy";
import filter from "lodash/filter";
import groupBy from "lodash/groupBy";
import some from "lodash/some";
import { useCallback } from "react";
import { useMatch } from "react-router-dom";
import { useNotificationsContext } from "~@/context/notifications-context";
import {
  CoreRoomFieldsFragmentDoc,
  GetRooms2Output,
  RoomOutput2,
  RoomStatus,
} from "~@/graphql/codegen/generated";
import useInternalUser from "~@/hooks/useInternalUser";
import { newRoomsIdsVar } from "~@/reactive-variables";

const useStoreChatRooms = () => {
  const newRoomsIds = useReactiveVar(newRoomsIdsVar);
  const match = useMatch("/chats/*");

  const { cache } = useApolloClient();
  const { newRoom } = useNotificationsContext();

  const { internalUser } = useInternalUser();

  const shouldStore = useCallback(
    (room: RoomOutput2) => {
      const onlyForMyTeams = some(
        internalUser?.teams?.map((team) => team?.id),
        (teamId) => {
          return teamId ? room.teamsIds.includes(teamId) : false;
        }
      );

      const onlyForMe =
        room.activeOperator?.id === internalUser?.id ||
        internalUser?.role?.roleTechnicalName === "root";

      return (
        (room.previousStatus === null &&
          (room.status === RoomStatus.NonAccepted ||
            room.status === RoomStatus.Invisible)) ||
        (room.previousStatus === RoomStatus.Invisible &&
          (room.status === RoomStatus.NonAccepted ||
            room.status === RoomStatus.Completed ||
            (room.status === RoomStatus.Accepted && onlyForMe))) ||
        (room.previousStatus === RoomStatus.NonAccepted &&
          ((room.status === RoomStatus.Accepted && onlyForMe) ||
            (room.status === RoomStatus.NonAccepted && onlyForMyTeams) ||
            (room.status === RoomStatus.Completed &&
              some(
                room.operators,
                (operator) => operator?.id === internalUser?.id
              )))) ||
        (room.previousStatus === RoomStatus.Accepted &&
          ((room.status === RoomStatus.NonAccepted && onlyForMyTeams) ||
            (room.status === RoomStatus.Accepted && onlyForMe) ||
            room.status === RoomStatus.Completed)) ||
        (room.previousStatus === RoomStatus.Completed &&
          ((room.status === RoomStatus.NonAccepted &&
            (room.teamsIds.length > 0 ? onlyForMyTeams : true)) ||
            room.status === RoomStatus.Invisible))
      );
    },
    [internalUser]
  );

  const storeChatRooms = useCallback(
    (chatRooms: RoomOutput2[]) => {
      const filteredChatRooms = filter(chatRooms, (room) => {
        return shouldStore(room);
      });

      const groupedRooms = groupBy(filteredChatRooms, (room) => room.status);

      for (const [status, rooms] of Object.entries(groupedRooms)) {
        const roomsIds = rooms.map((roomData) => roomData.id);
        const mergedRoomsIds = [...roomsIds, ...newRoomsIds];

        if (rooms.length > 0) {
          const roomsRefs = rooms.map((room) =>
            cache.writeFragment({
              data: room,
              fragment: CoreRoomFieldsFragmentDoc,
              fragmentName: "CoreRoomFields",
            })
          );

          cache.modify({
            fields: {
              getRooms2(existing: GetRooms2Output | null, { readField }) {
                if (existing?.requestedStatus === status) {
                  const existingRoomsRefs = readField<Reference[]>(
                    "rooms",
                    existing
                  );

                  if (existingRoomsRefs) {
                    if (
                      differenceBy(roomsRefs, existingRoomsRefs, "__ref")
                        .length === 0
                    ) {
                      return existing;
                    }

                    return {
                      ...existing,
                      rooms: [...existingRoomsRefs, ...roomsRefs],
                      pagination: {
                        ...existing.pagination,
                        totalItems:
                          (existing.pagination?.totalItems || 0) +
                          roomsRefs.length,
                      },
                    };
                  }

                  return existing;
                }

                return existing;
              },
            },
          });

          if (status === RoomStatus.NonAccepted) {
            if (!match) {
              newRoomsIdsVar(mergedRoomsIds);
            }

            newRoom.play();
          }
        }
      }
    },
    [cache, newRoom, newRoomsIds, match, shouldStore]
  );

  return storeChatRooms;
};

export default useStoreChatRooms;
