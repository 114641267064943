import twemoji from "twemoji";

type ParseEmoji = (emojiUnicode: string) => string;

const parseEmoji: ParseEmoji = (emojiUnicode) => {
  if (emojiUnicode) {
    return twemoji.parse(emojiUnicode);
  }

  return emojiUnicode;
};

export default parseEmoji;
