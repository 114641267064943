import { FC } from "react";
import BotsDeadTech from "~@/assets/image/non-svg/bots-dead-technical.png";
import { sanitize } from "~@/utils";
import { ArrowRotateLeft14x14Icon } from "~@/icons";
import { useTranslation } from "react-i18next";

const TechnicalError: FC = () => {
  const { t } = useTranslation();

  return (
    <div className="error error--is-fullheight">
      <div className="error__bots">
        <img alt="error-bots" src={BotsDeadTech} />
      </div>
      <p className="error__title error__title--is-500">
        {sanitize({ html: t("error.oops") })}
      </p>
      <p className="error__subtitle">{t("error.we-know")}</p>
      <p className="error__subtitle">{t("error.reload").toLowerCase()}</p>
      <button
        onClick={() => window.location.reload()}
        className="button button--h-48 button--fill-black-0"
      >
        <span className="icon-text">
          <span> {t("action.update-page")}</span>
          <span className="icon icon--is-14x14">
            <ArrowRotateLeft14x14Icon />
          </span>
        </span>
      </button>
    </div>
  );
};

export default TechnicalError;
