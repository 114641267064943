export const enum ROLES {
  Root = "root",
  Administrator = "administrator",
  Operator = "operator",
}

export const enum SCOPES {
  AllowedToViewDashboard = "allowed-to-view-dashboard",
  AllowedToViewChats = "allowed-to-view-view-chats",
  AllowedToViewClients = "allowed-to-view-clients",
  AllowedToViewBotControl = "allowed-to-view-bot-control",
  AllowedToViewAgenda = "allowed-to-view-agenda",
  AllowedToViewSettings = "allowed-to-view-settings",
  AllowedToViewChannelsSettings = "allowed-to-view-channels-settings",
  AllowedToViewTeamsSettings = "allowed-to-view-teams-settings",
  AllowedToViewTemplatesSettings = "allowed-to-view-templates-settings",
  AllowedToViewClientCardSettings = "allowed-to-view-client-card-settings",
  AllowedToViewBotBuilder = "allowed-to-view-bot-builder",
  AllowedToViewAllChats = "allowed-to-view-all-chats",
  AllowedToAssignChatsToOtherTeams = "allowed-to-assign-chats-to-other-teams",
  AllowedToEditPublicSnippets = "allowed-to-edit-public-snippets",
  AllowedToDeletePublicSnippets = "allowed-to-delete-public-snippets",
  AllowedToEditSnippetsCategories = "allowed-to-edit-snippets-categories",
  AllowedToCreateBot = "allowed-to-create-bot",
  AllowedToToggleBot = "allowed-to-toggle-bot",
  AllowedToCreateChannels = "allowed-to-create-channels",
  AllowedToCreateTemplatesForOtherTeams = "allowed-to-create-template-for-other-teams",
  AllowedToCreateTeams = "allowed-to-create-teams",
  AllowedToCreateOperators = "allowed-to-create-operators",
  AllowedToViewGeneralAnalytics = "allowed-to-view-general-analytics",
  AllowedToViewChatsAnalytics = "allowed-to-view-chats-analytics",
  AllowedToViewOperatorsAnalytics = "allowed-to-view-operators-analytics",
  AllowedToViewClientsAnalytics = "allowed-to-view-clients-analytics",
  AllowedToFilterOperatorsAnalyticsByTeam = "allowed-to-filter-operators-analytics-by-team",
  AllowedToFilterOperatorsAnalyticsByOperator = "allowed-to-filter-operators-analytics-by-operator",
  AllowedToDownloadContacts = "allowed-to-download-clients",
  AllowedToBotBuilder = "allowed-to-bot-builder",
  AllowedToBotActions = "allowed-to-bot-actions",
  AllowedToCreateTemplateAssignedToCategory = "allowed-to-create-template-assigned-to-category",
  AllowedToViewInvisibleChats = "allowed-to-view-invisible-chats",
}

export const PERMISSIONS = {
  [ROLES.Operator]: [
    SCOPES.AllowedToViewChats,
    SCOPES.AllowedToViewClients,
    SCOPES.AllowedToViewAgenda,
    SCOPES.AllowedToViewSettings,
    SCOPES.AllowedToViewTemplatesSettings,
    SCOPES.AllowedToViewDashboard,
    SCOPES.AllowedToViewOperatorsAnalytics,
  ],
  [ROLES.Administrator]: [
    SCOPES.AllowedToViewDashboard,
    SCOPES.AllowedToViewChats,
    SCOPES.AllowedToViewClients,
    SCOPES.AllowedToViewBotControl,
    SCOPES.AllowedToViewAgenda,
    SCOPES.AllowedToViewSettings,
    SCOPES.AllowedToViewChannelsSettings,
    SCOPES.AllowedToViewTeamsSettings,
    SCOPES.AllowedToViewTemplatesSettings,
    SCOPES.AllowedToViewClientCardSettings,
    SCOPES.AllowedToViewBotBuilder,
    SCOPES.AllowedToViewAllChats,
    SCOPES.AllowedToAssignChatsToOtherTeams,
    SCOPES.AllowedToEditPublicSnippets,
    SCOPES.AllowedToDeletePublicSnippets,
    SCOPES.AllowedToEditSnippetsCategories,
    SCOPES.AllowedToToggleBot,
    SCOPES.AllowedToCreateTemplatesForOtherTeams,
    SCOPES.AllowedToCreateOperators,
    SCOPES.AllowedToViewGeneralAnalytics,
    SCOPES.AllowedToViewChatsAnalytics,
    SCOPES.AllowedToViewOperatorsAnalytics,
    SCOPES.AllowedToFilterOperatorsAnalyticsByTeam,
    SCOPES.AllowedToFilterOperatorsAnalyticsByOperator,
    SCOPES.AllowedToCreateTemplateAssignedToCategory,
    SCOPES.AllowedToViewInvisibleChats,
    SCOPES.AllowedToViewClientsAnalytics,
  ],
  [ROLES.Root]: [
    SCOPES.AllowedToViewDashboard,
    SCOPES.AllowedToViewChats,
    SCOPES.AllowedToViewClients,
    SCOPES.AllowedToViewBotControl,
    SCOPES.AllowedToViewAgenda,
    SCOPES.AllowedToViewSettings,
    SCOPES.AllowedToViewChannelsSettings,
    SCOPES.AllowedToViewTeamsSettings,
    SCOPES.AllowedToViewTemplatesSettings,
    SCOPES.AllowedToViewClientCardSettings,
    SCOPES.AllowedToViewBotBuilder,
    SCOPES.AllowedToViewAllChats,
    SCOPES.AllowedToAssignChatsToOtherTeams,
    SCOPES.AllowedToEditPublicSnippets,
    SCOPES.AllowedToDeletePublicSnippets,
    SCOPES.AllowedToEditSnippetsCategories,
    SCOPES.AllowedToCreateBot,
    SCOPES.AllowedToToggleBot,
    SCOPES.AllowedToCreateChannels,
    SCOPES.AllowedToCreateTemplatesForOtherTeams,
    SCOPES.AllowedToCreateTeams,
    SCOPES.AllowedToCreateOperators,
    SCOPES.AllowedToViewGeneralAnalytics,
    SCOPES.AllowedToViewChatsAnalytics,
    SCOPES.AllowedToViewOperatorsAnalytics,
    SCOPES.AllowedToFilterOperatorsAnalyticsByTeam,
    SCOPES.AllowedToFilterOperatorsAnalyticsByOperator,
    SCOPES.AllowedToDownloadContacts,
    SCOPES.AllowedToBotBuilder,
    SCOPES.AllowedToBotActions,
    SCOPES.AllowedToCreateTemplateAssignedToCategory,
    SCOPES.AllowedToViewInvisibleChats,
    SCOPES.AllowedToViewClientsAnalytics,
  ],
};
