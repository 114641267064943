type CalculateMediaSize = (args: {
  width: number;
  height: number;
  maxWidth: number;
  maxHeight: number;
}) => { width: number; height: number };

const calculateMediaSize: CalculateMediaSize = ({
  width,
  height,
  maxWidth,
  maxHeight,
}) => {
  const ratio = width / height;

  if (width > maxWidth || height > maxHeight) {
    return {
      width: Math.ceil(Math.min(maxWidth, ratio * maxHeight)),
      height: Math.ceil(Math.min(maxHeight, maxWidth / ratio)),
    };
  }

  return {
    width,
    height,
  };
};

export default calculateMediaSize;
