import axios, { AxiosResponse } from "axios";

type DownloadFile = (blob: Blob | string, fileName: string) => void;

const downloadFile: DownloadFile = (blobOrUrl, fileName) => {
  if (typeof blobOrUrl !== "string") {
    const data = window.URL.createObjectURL(blobOrUrl);

    const link = document.createElement("a");
    link.href = data;
    link.download = fileName;

    // this is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(
      new MouseEvent("click", {
        bubbles: true,
        cancelable: true,
        view: window,
      })
    );

    setTimeout(() => {
      window.URL.revokeObjectURL(data);
      link.remove();
    }, 100);
  } else {
    return axios
      .get(blobOrUrl, {
        responseType: "blob",
      })
      .then((response: AxiosResponse<Blob>) => {
        const blobData = [response.data];
        const blob = new Blob(blobData, { type: "application/octet-stream" });
        const blobURL =
          window.URL && window.URL.createObjectURL
            ? window.URL.createObjectURL(blob)
            : window.webkitURL.createObjectURL(blob);
        const tempLink = document.createElement("a");
        tempLink.style.display = "none";
        tempLink.href = blobURL;
        tempLink.setAttribute("download", fileName);

        if (typeof tempLink.download === "undefined") {
          tempLink.setAttribute("target", "_blank");
        }

        document.body.appendChild(tempLink);
        tempLink.click();

        setTimeout(() => {
          document.body.removeChild(tempLink);
          window.URL.revokeObjectURL(blobURL);
        }, 200);
      });
  }
};

export default downloadFile;
