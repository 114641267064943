import {
  FC,
  ComponentType,
  ComponentProps,
  PropsWithChildren,
  createContext,
  useContext,
} from "react";
import UIfx from "uifx";

import outgoingMessage from "~@/assets/audio/outgoing-message.mp3";
import incomingMessage from "~@/assets/audio/incoming-message.mp3";
import newRoom from "~@/assets/audio/new-room.mp3";

export type NotificationsContext = {
  outgoingMessage: UIfx;
  incomingMessage: UIfx;
  newRoom: UIfx;
};

// eslint-disable-next-line @typescript-eslint/no-redeclare -- companion object
export const NotificationsContext = createContext<
  NotificationsContext | undefined
>(undefined);

export const useNotificationsContext = () => {
  const context = useContext(NotificationsContext);

  if (!context) {
    throw new Error("Missing NotificationsProvider.");
  }

  return context;
};

export const NotificationsProvider: FC<PropsWithChildren> = ({ children }) => {
  return (
    <NotificationsContext.Provider
      value={{
        outgoingMessage: new UIfx(outgoingMessage, {
          volume: 1,
          throttleMs: 500,
        }),
        incomingMessage: new UIfx(incomingMessage, {
          volume: 1,
          throttleMs: 500,
        }),
        newRoom: new UIfx(newRoom, {
          volume: 1,
          throttleMs: 500,
        }),
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};

export const withNotificationsProvider = <P extends object>(
  WrappedComponent: ComponentType<P>
): FC<P> => {
  return function WithNotificationProvider(
    props: ComponentProps<typeof WrappedComponent>
  ) {
    return (
      <NotificationsProvider>
        <WrappedComponent {...props} />
      </NotificationsProvider>
    );
  };
};
