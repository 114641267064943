import { makeVar } from "@apollo/client";
import { ChannelType, RoomStatus } from "~@/graphql/codegen/generated";
import { channelsTypes, chatsTypes, systemZone } from "~@/readonly-variables";

export const selectedChannelsTypesVar = makeVar<ChannelType[]>([
  ...channelsTypes,
]);

export const selectedChatsTypeVar = makeVar<RoomStatus>(chatsTypes[0]);

export const favoritesSnippetsIdsVar = makeVar<string[]>([]);

export const snippetsCategoriesFilterVar = makeVar<string>("");

export const newRoomsIdsVar = makeVar<string[]>([]);

export const isNewFeaturesAllowedVar = makeVar<boolean>(true);

export const currentTimeZoneVar = makeVar<string>(
  localStorage.getItem("currentTimeZone") ?? systemZone.name
);
