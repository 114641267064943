export const from = (device: number): boolean => {
  const mediaQuery = `screen and (min-width: ${device}px)`;

  return window.matchMedia(mediaQuery).matches;
};

export const until = (device: number): boolean => {
  const mediaQuery = `screen and (max-width: ${device - 1}px)`;

  return window.matchMedia(mediaQuery).matches;
};

export const between = (fromArg: number, untilArg: number): boolean => {
  const mediaQuery = `screen and (min-width: ${fromArg}px) and (max-width: ${
    untilArg - 1
  }px)`;

  return window.matchMedia(mediaQuery).matches;
};
