import { PhoneNumberFormat, PhoneNumberUtil } from "google-libphonenumber";

const phoneUtil = PhoneNumberUtil.getInstance();

type GetRegionCode = (phoneNumber: string) => string | undefined;

export const getRegionCode: GetRegionCode = (phoneNumber) => {
  const regionCode = phoneUtil.getRegionCodeForNumber(
    phoneUtil.parse(phoneNumber, "")
  );

  return regionCode;
};

type FormatPhoneNumber = (phoneNumber: string) => string;

export const formatPhoneNumber: FormatPhoneNumber = (phoneNumber) => {
  const regionCode = getRegionCode(phoneNumber);

  if (regionCode) {
    return phoneUtil.format(
      phoneUtil.parse(phoneNumber, regionCode),
      PhoneNumberFormat.INTERNATIONAL
    );
  }

  return phoneNumber;
};

type ValidatePhoneNumber = (phoneNumber: string) => boolean;

export const validatePhoneNumber: ValidatePhoneNumber = (phoneNumber) => {
  const regionCode = getRegionCode(phoneNumber);

  return phoneUtil.isValidNumberForRegion(
    phoneUtil.parse(phoneNumber, regionCode),
    regionCode
  );
};
