import { SystemZone } from "luxon";
import { ReactElement, SVGProps } from "react";
import {
  ChannelType,
  CustomFieldDataType,
  RoomStatus,
} from "~@/graphql/codegen/generated";
import {
  CircleEmail22x22Icon,
  CircleEmail24x24Icon,
  CircleInstagram22x22Icon,
  CircleInstagram24x24Icon,
  CircleMessenger22x22Icon,
  CircleMessenger24x24Icon,
  CircleSMS22x22Icon,
  CircleSMS24x24Icon,
  CircleTelegram22x22Icon,
  CircleTelegram24x24Icon,
  CircleVK22x22Icon,
  CircleVK24x24Icon,
  CircleWhatsapp22x22Icon,
  CircleWhatsapp24x24Icon,
  Email12x12Icon,
  Email14x14Icon,
  Instagram12x12Icon,
  Instagram14x14Icon,
  Messenger12x12Icon,
  Messenger14x14Icon,
  SMS12x12Icon,
  SMS14x14Icon,
  Telegram12x12Icon,
  Telegram14x14Icon,
  Vk12x12Icon,
  Vk14x14Icon,
  Whatsapp12x12Icon,
  Whatsapp14x14Icon,
} from "~@/icons";

export const chatsTypes: [
  RoomStatus.Invisible,
  RoomStatus.NonAccepted,
  RoomStatus.Accepted,
  RoomStatus.Completed
] = [
  RoomStatus.Invisible,
  RoomStatus.NonAccepted,
  RoomStatus.Accepted,
  RoomStatus.Completed,
];

export const channelsTypes = Object.values(ChannelType);

export const customFieldDataTypes = Object.values(CustomFieldDataType);

export const CircleChannel22x22Icon: Readonly<{
  [channelType in ChannelType]: ReactElement<SVGProps<SVGSVGElement>>;
}> = {
  [ChannelType.Instagram]: <CircleInstagram22x22Icon />,
  [ChannelType.FacebookMessenger]: <CircleMessenger22x22Icon />,
  [ChannelType.Telegram]: <CircleTelegram22x22Icon />,
  [ChannelType.Vk]: <CircleVK22x22Icon />,
  [ChannelType.Whatsapp]: <CircleWhatsapp22x22Icon />,
  [ChannelType.Sms]: <CircleSMS22x22Icon />,
  [ChannelType.Email]: <CircleEmail22x22Icon />,
};

export const CircleChannel24x24Icon: Readonly<{
  [channelType in ChannelType]: ReactElement<SVGProps<SVGSVGElement>>;
}> = {
  [ChannelType.Instagram]: <CircleInstagram24x24Icon />,
  [ChannelType.FacebookMessenger]: <CircleMessenger24x24Icon />,
  [ChannelType.Telegram]: <CircleTelegram24x24Icon />,
  [ChannelType.Vk]: <CircleVK24x24Icon />,
  [ChannelType.Whatsapp]: <CircleWhatsapp24x24Icon />,
  [ChannelType.Sms]: <CircleSMS24x24Icon />,
  [ChannelType.Email]: <CircleEmail24x24Icon />,
};

export const Channel14x14Icon: Readonly<{
  [channelType in ChannelType]: ReactElement<SVGProps<SVGSVGElement>>;
}> = {
  [ChannelType.Instagram]: <Instagram14x14Icon />,
  [ChannelType.FacebookMessenger]: <Messenger14x14Icon />,
  [ChannelType.Telegram]: <Telegram14x14Icon />,
  [ChannelType.Vk]: <Vk14x14Icon />,
  [ChannelType.Whatsapp]: <Whatsapp14x14Icon />,
  [ChannelType.Email]: <Email14x14Icon />,
  [ChannelType.Sms]: <SMS14x14Icon />,
};

export const Channel12x12Icon: Readonly<{
  [channelType in ChannelType]: ReactElement<SVGProps<SVGSVGElement>>;
}> = {
  [ChannelType.Instagram]: <Instagram12x12Icon />,
  [ChannelType.FacebookMessenger]: <Messenger12x12Icon />,
  [ChannelType.Telegram]: <Telegram12x12Icon />,
  [ChannelType.Vk]: <Vk12x12Icon />,
  [ChannelType.Whatsapp]: <Whatsapp12x12Icon />,
  [ChannelType.Email]: <Email12x12Icon />,
  [ChannelType.Sms]: <SMS12x12Icon />,
};

export const systemZone = new SystemZone();
