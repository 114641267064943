import Loader from "./components/base/Loader";
import PermissionsGate from "./components/layout/PermissionsGate";
import TechnicalError from "./components/layout/TechnicalError";
import { withCachePersistorProvider } from "./context/cache-persistor-context";
import { withNotificationsProvider } from "./context/notifications-context";
import { PreservedQueryParamsProvider } from "./context/preserved-query-params-context";
import {
  useGetOrganizationsQuery,
  useGetRolesQuery,
} from "./graphql/codegen/generated";
import { withApolloClient } from "./hoc/withApolloClient";
import useInternalUser from "./hooks/useInternalUser";
import { SCOPES } from "./permission-maps";
import { useWatchUsers } from "./subscriptions";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import classNames from "classnames";
import { Settings } from "luxon";
import { FC, Suspense, lazy, useEffect } from "react";
import { withErrorBoundary } from "react-error-boundary";
import { useTranslation } from "react-i18next";
import { Route, Routes, useMatch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import ServerError from "~@/pages/500";
import { isNewFeaturesAllowedVar } from "~@/reactive-variables";

const Home = lazy(() => import("./pages/home"));
const BotBuilder = lazy(() => import("./pages/bot-builder"));

const App: FC = () => {
  const match = useMatch("/bot-builder/*");
  const { i18n } = useTranslation();

  const {
    data: rolesData,
    loading: areRolesLoading,
    error: rolesError,
  } = useGetRolesQuery();

  const { isInternalUserLoading, internalUserError } = useInternalUser({
    skip: rolesData?.getRoles?.length === 0,
  });

  const { loading: isOrganizationsLoading } = useGetOrganizationsQuery({
    onCompleted({ getOrganizations }) {
      const currentOrganization = getOrganizations?.current;

      if (!currentOrganization) return;

      // const localZone = new SystemZone();

      // Settings.defaultZone = currentOrganization.timezone ?? localZone.name;
      // Settings.defaultZone = "America/Los_Angeles" ?? localZone.name;

      if (process.env.NODE_ENV === "production") {
        isNewFeaturesAllowedVar(currentOrganization.country === "US");
      }
    },
  });

  useWatchUsers();

  useEffect(() => {
    Settings.defaultLocale = i18n.language;
  }, [i18n.language]);

  useEffect(() => {
    if (rolesData?.getRoles?.length === 0) {
      window.location.reload();
    }
  }, [rolesData]);

  if (areRolesLoading || isInternalUserLoading || isOrganizationsLoading)
    return <Loader isFullHeight />;
  else if (internalUserError || rolesError) return <ServerError />;
  else
    return (
      <PreservedQueryParamsProvider>
        <Suspense fallback={<Loader isFullHeight />}>
          <Routes>
            <Route path="*" element={<Home />} />
            <Route
              path="bot-builder/:botId?/:flowId?"
              element={
                <PermissionsGate scopes={[SCOPES.AllowedToViewBotBuilder]}>
                  <BotBuilder />
                </PermissionsGate>
              }
            />
          </Routes>
        </Suspense>
        <ToastContainer
          className={classNames({
            "Toastify__toast-container--has-navbar-top":
              match instanceof Object,
          })}
          theme="dark"
          role="alert"
          limit={1}
          position="top-center"
          closeButton={false}
          hideProgressBar
          autoClose={2000}
        />
      </PreservedQueryParamsProvider>
    );
};

export default withAuthenticationRequired(
  withErrorBoundary(
    withCachePersistorProvider(
      withApolloClient(withNotificationsProvider(App))
    ),
    {
      fallbackRender: TechnicalError,
    }
  ),
  {
    onRedirecting() {
      return <Loader isFullHeight />;
    },
  }
);
