import { useGetUserQuery } from "~@/graphql/codegen/generated";

const useInternalUser = (
  baseOptions?: Parameters<typeof useGetUserQuery>[0]
) => {
  const { data, loading, error, startPolling, stopPolling } = useGetUserQuery({
    ...baseOptions,
  });

  return {
    internalUser: data?.getUser,
    isInternalUserLoading: loading,
    internalUserError: error,
    startPolling,
    stopPolling,
  } as const;
};

export default useInternalUser;
