import { useCallback } from "react";
import { Reference, useApolloClient } from "@apollo/client";
import {
  GetChatRoomMessagesOutput,
  DeleteMessageOutput,
} from "~@/graphql/codegen/generated";
import filter from "lodash/filter";

const useRemoveMessage = () => {
  const { cache } = useApolloClient();

  const removeMessage = useCallback(
    (removedMessage: DeleteMessageOutput) => {
      cache.modify({
        fields: {
          getChatRoomMessages(
            existing: GetChatRoomMessagesOutput | null,
            { readField }
          ) {
            if (existing?.roomId === removedMessage.chatRoomId) {
              return {
                ...existing,
                chatRoomMessages: filter(
                  existing?.chatRoomMessages,
                  (ref: Reference) =>
                    readField<string>("messageId", ref) !==
                    removedMessage.messageId
                ),
              };
            }

            return existing;
          },
        },
      });
    },
    [cache]
  );

  return removeMessage;
};

export default useRemoveMessage;
