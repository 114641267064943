import { FC } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

export enum LoaderSize {
  ExtraSmall = "xs",
  Small = "sm",
  Medium = "md",
  Large = "lg",
  ExtraLarge = "xl",
}

export enum LoaderColor {
  Gray0 = "gray-0",
  Gray1 = "gray-1",
  Gray2 = "gray-2",
  Gray3 = "gray-3",
  Gray4 = "gray-4",
  Gray5 = "gray-5",
  Purple3 = "purple-3",
}

type LoaderProps = {
  isFullHeight?: boolean;
  isFixed?: boolean;
  size?: LoaderSize;
  color?: LoaderColor;
};

const Loader: FC<LoaderProps> = ({
  isFullHeight = false,
  isFixed = false,
  size = LoaderSize.Large,
  color = LoaderColor.Gray3,
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={classNames(
        "loader",
        `loader--has-size-${size}`,
        `loader--is-${color}`,
        {
          "loader--is-fullheight": isFullHeight,
          "loader--is-fixed": isFixed,
        }
      )}
    >
      {t("loading")}
    </div>
  );
};

export default Loader;
