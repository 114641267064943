type ParseBytesToSize = (bytes: number) => string;

const parseBytesToSize: ParseBytesToSize = (bytes) => {
  const sizes = ["B", "KB", "MB", "GB", "TB"];

  if (bytes === 0) {
    return "0 B";
  }

  const i = Math.floor(Math.log(bytes) / Math.log(1024));

  return Math.round(bytes / 1024 ** i) + " " + sizes[i];
};

export default parseBytesToSize;
