import { t } from "i18next";

const convertTime = (time: number) => {
  let formattedTime;
  if (!time) return 0;
  if (time >= 3600) {
    formattedTime = `~ ${Math.round(Math.round(time) / 3600)} ${t(
      "dashboard.hours"
    )}${
      Math.round(Number(time)) % 3600 >= 60
        ? ` ${Math.round((Math.round(time) % 3600) / 60)} ${t(
            "dashboard.minutes"
          )}`
        : ""
    }`;
  } else if (time >= 60) {
    formattedTime = `~ ${Math.round(Math.round(time) / 60)} ${t(
      "dashboard.minutes"
    )}${
      time % 60 > 0 ? ` ${Math.round(time % 60)} ${t("dashboard.seconds")}` : ""
    }`;
  } else {
    formattedTime = `~ ${time} ${t("dashboard.seconds")}`;
  }
  return formattedTime;
};

export default convertTime;
