import { ReactElement } from "react";
import ReactHtmlParser from "react-html-parser";
import sanitizeHtml from "sanitize-html";

type Sanitize = (args: {
  html: string;
  options?: sanitizeHtml.IOptions;
}) => ReactElement[];

const sanitize: Sanitize = ({ html, options }) =>
  ReactHtmlParser(sanitizeHtml(html, options));

export default sanitize;
