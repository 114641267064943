import App from "./App";
import "./assets/scss/styles.scss";
import Auth0ProviderWithNavigate from "./auth/auth0-provider-with-navigate";
import Loader from "./components/base/Loader";
import "./i18n";
import queryString from "query-string";
import { Suspense } from "react";
import { createRoot } from "react-dom/client";
import Modal from "react-modal";
import { BrowserRouter } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";

const { parse, stringify } = queryString;

export const rootElement = document.getElementById("root") as HTMLElement;

Modal.setAppElement(rootElement);

const root = createRoot(rootElement);

root.render(
  <BrowserRouter basename="/">
    <QueryParamProvider
      adapter={ReactRouter6Adapter}
      options={{
        searchStringToObject: parse,
        objectToSearchString: stringify,
      }}
    >
      <Auth0ProviderWithNavigate>
        <Suspense fallback={<Loader />}>
          <App />
        </Suspense>
      </Auth0ProviderWithNavigate>
    </QueryParamProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log)
