import { Reference, useApolloClient, useReactiveVar } from "@apollo/client";
import difference from "lodash/difference";
import filter from "lodash/filter";
import groupBy from "lodash/groupBy";
import some from "lodash/some";
import { useCallback } from "react";
import {
  GetRooms2Output,
  RoomOutput2,
  RoomStatus,
} from "~@/graphql/codegen/generated";
import useInternalUser from "~@/hooks/useInternalUser";
import { newRoomsIdsVar } from "~@/reactive-variables";

const useRemoveChatRooms = () => {
  const newRoomsIds = useReactiveVar(newRoomsIdsVar);
  const { cache } = useApolloClient();

  const { internalUser } = useInternalUser();

  const shouldRemove = useCallback(
    (room: RoomOutput2) => {
      const onlyFromOtherTeams = !some(
        internalUser?.teams?.map((team) => team?.id),
        (teamId) => {
          return teamId ? room.teamsIds.includes(teamId) : false;
        }
      );

      return (
        (room.previousStatus === RoomStatus.NonAccepted &&
          (room.status === RoomStatus.Accepted ||
            (room.status === RoomStatus.NonAccepted && onlyFromOtherTeams) ||
            room.status === RoomStatus.Completed)) ||
        (room.previousStatus === RoomStatus.Accepted &&
          (room.status === RoomStatus.NonAccepted ||
            (room.status === RoomStatus.Accepted &&
              room.activeOperator?.id !== internalUser?.id) ||
            room.status === RoomStatus.Completed)) ||
        room.previousStatus === RoomStatus.Completed ||
        room.previousStatus === RoomStatus.Invisible
      );
    },
    [internalUser]
  );

  const removeChatRooms = useCallback(
    (chatRooms: RoomOutput2[]) => {
      const filteredChatRooms = filter(chatRooms, (room) => {
        return shouldRemove(room);
      });

      const groupedRooms = groupBy(
        filteredChatRooms,
        (room) => room.previousStatus
      );

      for (const [previousStatus, rooms] of Object.entries(groupedRooms)) {
        if (rooms.length > 0) {
          const roomsIdsToRemove = rooms.map((room) => room.id);

          cache.modify({
            fields: {
              getRooms2(existing: GetRooms2Output | null, { readField }) {
                if (existing?.requestedStatus === previousStatus) {
                  const existingRoomsRefs = readField<Reference[]>(
                    "rooms",
                    existing
                  );

                  if (existingRoomsRefs) {
                    return {
                      ...existing,
                      rooms: filter(existingRoomsRefs, (ref) => {
                        const roomId = readField<string>("id", ref);

                        return roomId
                          ? !roomsIdsToRemove.includes(roomId)
                          : true;
                      }),
                      pagination:
                        difference(
                          roomsIdsToRemove,
                          existingRoomsRefs.map((ref) =>
                            readField<string>("id", ref)
                          )
                        ).length > 0
                          ? existing.pagination
                          : {
                              ...existing.pagination,
                              totalItems:
                                (existing.pagination?.totalItems ||
                                  roomsIdsToRemove.length) -
                                roomsIdsToRemove.length,
                            },
                    };
                  }

                  return existing;
                }

                return existing;
              },
            },
          });

          if (previousStatus === RoomStatus.NonAccepted) {
            newRoomsIdsVar(
              filter(newRoomsIds, (id) => {
                return !roomsIdsToRemove.includes(id);
              })
            );
          }
        }
      }
    },
    [cache, newRoomsIds, shouldRemove]
  );

  return removeChatRooms;
};

export default useRemoveChatRooms;
