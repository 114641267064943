import {
  FC,
  Dispatch,
  SetStateAction,
  ComponentType,
  ComponentProps,
  PropsWithChildren,
  createContext,
  useState,
  useContext,
  useCallback,
} from "react";
import type { NormalizedCacheObject } from "@apollo/client";
import type { CachePersistor } from "apollo3-cache-persist";

export type CachePersistorContext = readonly [
  CachePersistor<NormalizedCacheObject> | undefined,
  Dispatch<SetStateAction<CachePersistor<NormalizedCacheObject> | undefined>>,
  () => void
];

// eslint-disable-next-line @typescript-eslint/no-redeclare -- companion object
export const CachePersistorContext = createContext<
  CachePersistorContext | undefined
>(undefined);

export const useCachePersistorContext = () => {
  const context = useContext(CachePersistorContext);

  if (!context) {
    throw new Error("Missing CachePersistorProvider.");
  }

  return context;
};

export const CachePersistorProvider: FC<PropsWithChildren> = ({ children }) => {
  const [persistor, setPersistor] = useState<
    CachePersistor<NormalizedCacheObject> | undefined
  >(undefined);

  const clearCache = useCallback(() => {
    if (!persistor) {
      return;
    }

    persistor.purge();
  }, [persistor]);

  return (
    <CachePersistorContext.Provider
      value={[persistor, setPersistor, clearCache]}
    >
      {children}
    </CachePersistorContext.Provider>
  );
};

export const withCachePersistorProvider = <P extends object>(
  WrappedComponent: ComponentType<P>
): FC<P> => {
  return function WithCachePersistorProvider(
    props: ComponentProps<typeof WrappedComponent>
  ) {
    return (
      <CachePersistorProvider>
        <WrappedComponent {...props} />
      </CachePersistorProvider>
    );
  };
};
