import { InMemoryCache, Reference } from "@apollo/client";
import compact from "lodash/compact";
import filter from "lodash/filter";
import orderBy from "lodash/orderBy";
import unionBy from "lodash/unionBy";
import uniq from "lodash/uniq";
import uniqBy from "lodash/uniqBy";
import { DateTime } from "luxon";
import {
  CommonUserFields,
  GetChannels2Document,
  GetChannels2Query,
  GetChatRoomMessagesDocument,
  GetChatRoomMessagesQuery,
  GetCommentsOnClientOutput2,
  GetUsersOutput,
  MessageOutput,
  PresenceStatus,
  QueryGetChannels2Args,
  QueryGetChatRoomMessagesArgs,
  RoomLinkOutput,
  ValidateBot,
} from "~@/graphql/codegen/generated";
import { TypedTypePolicies } from "~@/graphql/codegen/type-policies";
import {
  favoritesSnippetsIdsVar,
  snippetsCategoriesFilterVar,
} from "~@/reactive-variables";

const typePolicies: TypedTypePolicies = {
  RoleOutput: {
    keyFields: ["roleId"],
  },
  TeamOutput: {
    keyFields: ["id"],
    fields: {
      users: {
        read(existing: Reference[] | null, { readField }): Reference[] {
          return uniqBy(compact(existing), (user) => {
            return readField<string>("id", user);
          });
        },
        merge(
          _: Reference[] | null | undefined,
          incoming: Reference[] | null
        ): Reference[] | null {
          return incoming;
        },
      },
    },
  },
  UserCustomFieldOutput: {
    keyFields: false,
  },
  UserOutput: {
    keyFields: ["id"],
    fields: {
      optionalName: {
        read(_, { readField }): string {
          const commonUserFields = readField<CommonUserFields>(
            "common"
          ) as CommonUserFields;

          if (
            compact([commonUserFields.firstName, commonUserFields.lastName])
              .length > 0
          ) {
            return "";
          }

          return (
            commonUserFields.phone ||
            commonUserFields.email ||
            readField<string | null>("whatsappProfile") ||
            readField<string | null>("whatsappUsername") ||
            readField<string | null>("telegramUsername") ||
            readField<string | null>("instagramProfile") ||
            (commonUserFields.autoNickname as string)
          );
        },
      },
      lastTicketAt: {
        read(_, { readField }): string | null {
          const roomLinks = readField<RoomLinkOutput[]>(
            "roomsLinks"
          ) as RoomLinkOutput[];
          if (!roomLinks) return null;

          const newestObject = roomLinks.reduce((prev, current) => {
            const prevDate = prev.lastTicketAt
              ? DateTime.fromISO(prev.lastTicketAt)
              : null;
            const currentDate = current.lastTicketAt
              ? DateTime.fromISO(current.lastTicketAt)
              : null;

            if (!prevDate) {
              return current;
            } else if (!currentDate) {
              return prev;
            }

            return prevDate > currentDate ? prev : current;
          }, {});

          return newestObject.lastTicketAt || null;
        },
      },
      role: {
        read(_, { readField, toReference }): Reference | null {
          const roleId = readField<string | null>("roleId");

          if (!roleId) return null;

          const role = toReference({
            roleId,
            __typename: "RoleOutput",
          });

          return role ?? null;
        },
      },
      teams: {
        read(existing: Reference[] | null, { readField }): Reference[] | null {
          return existing
            ? uniqBy(compact(existing), (team) => readField<string>("id", team))
            : existing;
        },
        merge(
          _: Reference[] | null | undefined,
          incoming: Reference[] | null
        ): Reference[] | null {
          return incoming;
        },
      },
      roomsLinks: {
        merge(
          existing: RoomLinkOutput[] | null | undefined,
          incoming: RoomLinkOutput[] | null
        ): RoomLinkOutput[] {
          return unionBy(existing, incoming, "roomId");
        },
      },
      onlineStatus: {
        merge(
          existing: Reference | null | undefined,
          incoming: Reference | null
        ): Reference | null {
          return existing ? existing : incoming;
        },
      },
      customFields: {
        merge(
          _: Reference[] | null | undefined,
          incoming: Reference[] | null | undefined
        ) {
          return incoming;
        },
      },
    },
  },
  UserPresenceStatusOutput: {
    keyFields: ["userId"],
    fields: {
      presenceStatus: {
        read(existing: PresenceStatus | null): PresenceStatus {
          return !existing ? PresenceStatus.Offline : existing;
        },
      },
    },
  },
  CommonUserFields: {
    fields: {
      firstName: {
        read(existing: string | null | undefined): string {
          return existing ?? "";
        },
      },
      lastName: {
        read(existing: string | null | undefined): string {
          return existing ?? "";
        },
      },
      email: {
        read(existing: string | null | undefined): string {
          return existing ?? "";
        },
      },
      phone: {
        read(existing: string | null | undefined): string {
          return existing ?? "";
        },
      },
    },
  },
  ChannelOutput2: {
    keyFields: ["id"],
  },
  ChatSnippetsOutput: {
    keyFields: ["snippetId"],
    fields: {
      isFavorite: {
        read(_, { readField }): boolean {
          const snippetId = readField<string>("snippetId");

          return snippetId
            ? favoritesSnippetsIdsVar().includes(snippetId)
            : false;
        },
      },
    },
  },
  ChatSnippetsCategoriesOutput: {
    keyFields: ["categoryId"],
  },
  GetOperatorsAnalytics: {
    keyFields: ["input", ["dateStart", "dateEnd"]],
  },
  RoomOutput2: {
    keyFields: ["id"],
    fields: {
      operators: {
        read(existing: Reference[] | null, { readField }): Reference[] {
          return uniqBy(compact(existing), (user) =>
            readField<string>("id", user)
          );
        },
        merge(
          _: Reference[] | null | undefined,
          incoming: Reference[] | null
        ): Reference[] | null {
          return incoming;
        },
      },
      isExpired: {
        read(_, { readField }): boolean {
          const lastClientMessageAt = readField<string | null>(
            "lastClientMessageAt"
          );

          if (!lastClientMessageAt) return false;

          const lastMessageFromClientDT = DateTime.fromISO(
            lastClientMessageAt,
            {
              zone: "utc",
            }
          )
            .toLocal()
            .plus({ day: 1 });

          return DateTime.local() >= lastMessageFromClientDT;
        },
      },
    },
  },
  MessageOutput: {
    keyFields: ["messageId"],
    fields: {
      isFromBot: {
        read(_, { readField }): boolean {
          const messageAuthorId = readField<string | null>("messageAuthorId");

          if (!messageAuthorId) return true;

          return (
            messageAuthorId === process.env.REACT_APP_MESSAGES_BOT_ID ||
            messageAuthorId === process.env.REACT_APP_NOTIFICATIONS_BOT_ID
          );
        },
      },
      isFromClient: {
        read(_, { readField, toReference }): boolean {
          const messageAuthorId = readField<string | null>("messageAuthorId");
          const role = readField<Reference | null>(
            "role",
            toReference({
              id: messageAuthorId,
              __typename: "UserOutput",
            })
          );

          const isBot = readField<boolean>("isFromBot");

          return !isBot && !role;
        },
      },
    },
  },
  DeleteMessageOutput: {
    keyFields: ["messageId"],
  },
  CommentOutput2: {
    keyFields: ["commentId"],
  },
  BotOutput: {
    keyFields: ["id"],
    fields: {
      teamsIds: {
        merge(
          _: string[] | null | undefined,
          incoming: string[] | null
        ): string[] | null {
          return incoming;
        },
      },
      connectedChannels: {
        read(_, { readField, cache }) {
          const botId = readField<string>("id");
          const channelsData = cache.readQuery<
            GetChannels2Query,
            QueryGetChannels2Args
          >({
            query: GetChannels2Document,
          });

          return filter(channelsData?.getChannels2, (channel) => {
            return channel?.bot?.id === botId;
          });
        },
      },
    },
  },
  AiBotUserSettingsOutput: {
    keyFields: ["id"],
  },
  FlowOutput: {
    keyFields: ["id"],
  },
  AppOutput: {
    keyFields: ["id"],
  },
  CalendarOutput: {
    keyFields: ["id"],
  },
  CalendarEventOutput: {
    keyFields: ["id"],
  },
  NodeOutput: {
    keyFields: ["id"],
    fields: {
      buttons: {
        read(existing: Reference[] | null, { readField }): Reference[] {
          return uniqBy(compact(existing), (button) =>
            readField<string>("id", button)
          );
        },
        merge(
          _: Reference[] | null | undefined,
          incoming: Reference[] | null
        ): Reference[] | null {
          return incoming;
        },
      },
      teamsIds: {
        read(existing: string[] | null): string[] {
          return uniq(compact(existing));
        },
        merge(
          _: string[] | null | undefined,
          incoming: string[] | null
        ): string[] | null {
          return incoming;
        },
      },
      isDelete: {
        read(existing: boolean | null | undefined): boolean {
          return existing ?? false;
        },
      },
    },
  },
  EdgeOutput: {
    keyFields: ["id"],
    fields: {
      isDelete: {
        read(existing: boolean | null | undefined): boolean {
          return existing ?? false;
        },
      },
    },
  },
  ButtonOutput: {
    keyFields: ["id"],
    fields: {
      // isDelete: {
      //   read(existing: boolean | null | undefined): boolean {
      //     return existing ?? false;
      //   },
      // },
    },
  },
  CustomFieldOutput: {
    keyFields: ["id"],
  },
  GetChatSnippetsCategories: {
    fields: {
      categories: {
        read(existing: Reference[] | null, { readField }): Reference[] {
          return filter(
            uniqBy(existing, (snippetsCategory) =>
              readField<string>("categoryId", snippetsCategory)
            ),
            (snippetsCategory) => {
              if (snippetsCategoriesFilterVar()) {
                const categoryName = readField<string>(
                  "categoryName",
                  snippetsCategory
                );

                return typeof categoryName === "string"
                  ? categoryName
                      .toLowerCase()
                      .includes(snippetsCategoriesFilterVar().toLowerCase())
                  : false;
              }
              return true;
            }
          );
        },
      },
    },
  },
  GetChatSnippets: {
    fields: {
      snippets: {
        read(
          existing: Reference[] | null,
          { readField, variables }
        ): Reference[] {
          return filter(
            uniqBy(existing, (snippet) =>
              readField<string>("snippetId", snippet)
            ),
            (snippet) => {
              if (
                Object.prototype.hasOwnProperty.call(variables, "categoryId")
              ) {
                return (
                  readField<string | null>("categoryId", snippet) ===
                  variables?.categoryId
                );
              }

              return true;
            }
          );
        },
      },
    },
  },
  GetUsersOutput: {
    fields: {
      users: {
        read(existing: Reference[] | null, { readField }): Reference[] {
          return uniqBy(compact(existing), (user) =>
            readField<string>("id", user)
          );
        },
      },
    },
  },
  GetRooms2Output: {
    fields: {
      rooms: {
        read(existing: Reference[] | null, { readField }): Reference[] {
          return orderBy(
            uniqBy(compact(existing), (room) => readField<string>("id", room)),
            [
              (room) =>
                DateTime.fromISO(
                  readField<string>("lastMessageAt", room) as string,
                  {
                    zone: "utc",
                  }
                ).toMillis(),
            ],
            ["desc"]
          );
        },
        merge(
          existing: Reference[] | null | undefined,
          incoming: Reference[] | null
        ): Reference[] | null {
          if (existing) {
            if (Array.isArray(incoming)) {
              return [...existing, ...incoming];
            }

            return existing;
          }

          return incoming;
        },
      },
    },
  },
  ValidateBot: {
    keyFields: false,
  },
  GetChatRoomMessagesOutput: {
    fields: {
      chatRoomMessages: {
        read(existing: Reference[] | null, { readField }): Reference[] {
          return uniqBy(compact(existing), (chatRoomMessage) =>
            readField<string>("messageId", chatRoomMessage)
          );
        },
        merge(
          existing: Reference[] | null | undefined,
          incoming: Reference[] | null
        ): Reference[] | null {
          if (existing) {
            if (Array.isArray(incoming)) {
              return [...incoming, ...existing];
            }

            return existing;
          }

          return Array.isArray(incoming) ? incoming : null;
        },
      },
    },
  },
  GetCommentsOnClientOutput2: {
    fields: {
      comments: {
        read(existing: Reference[] | null, { readField }): Reference[] {
          return uniqBy(existing, (comment) =>
            readField<string>("commentId", comment)
          );
        },
      },
    },
  },
  Query: {
    fields: {
      getCustomFields: {
        read(
          existing: Reference[] | null | undefined,
          { readField }
        ): Reference[] | null | undefined {
          return existing
            ? uniqBy(compact(existing), (customField) =>
                readField<string>("id", customField)
              )
            : existing;
        },
        merge(
          _: Reference[] | null | undefined,
          incoming: Reference[] | null
        ): Reference[] | null {
          return incoming;
        },
      },
      getCalendar: {
        keyArgs: ["@persist"],
        read(
          existing: Reference[] | null | undefined,
          { readField }
        ): Reference[] | null | undefined {
          return existing
            ? uniqBy(compact(existing), (calendar) =>
                readField<string>("id", calendar)
              )
            : existing;
        },
        merge(
          _: Reference[] | null | undefined,
          incoming: Reference[] | null
        ): Reference[] | null {
          return incoming;
        },
      },
      getCalendarEvents: {
        keyArgs: ["@persist"],
        read(
          existing: Reference[] | null | undefined,
          { readField }
        ): Reference[] | null | undefined {
          return existing
            ? uniqBy(compact(existing), (cEvent) =>
                readField<string>("id", cEvent)
              )
            : existing;
        },
        merge(
          _: Reference[] | null | undefined,
          incoming: Reference[] | null
        ): Reference[] | null {
          return incoming;
        },
      },
      foundMessages: {
        keyArgs: ["chatRoomId"],
        read(_, { cache, variables }): MessageOutput[] {
          if (
            variables instanceof Object &&
            typeof variables.chatRoomId === "string" &&
            typeof variables.search === "string"
          ) {
            const { chatRoomId, search } = variables;

            const existingMessages = cache.readQuery<
              GetChatRoomMessagesQuery,
              QueryGetChatRoomMessagesArgs
            >({
              query: GetChatRoomMessagesDocument,
              variables: {
                chatRoomId,
                fetchSize: 20,
              },
            });

            return filter(
              existingMessages?.getChatRoomMessages?.chatRoomMessages,
              (message): message is MessageOutput =>
                message instanceof Object
                  ? search.length > 0 &&
                    Boolean(
                      message.messageText
                        ?.toLowerCase()
                        .includes(search.toLowerCase())
                    )
                  : false
            );
          }

          return [];
        },
      },
      getRoles: {
        merge(
          _: Reference[] | null | undefined,
          incoming: Reference[] | null
        ): Reference[] | null {
          return incoming;
        },
      },
      getApps: {
        keyArgs: ["input", ["types"]],
        merge(
          _: Reference[] | null | undefined,
          incoming: Reference[] | null
        ): Reference[] | null {
          return incoming;
        },
      },
      getUsers: {
        keyArgs: ["input", ["type", "isActive"]],
        merge(
          _: GetUsersOutput | null | undefined,
          incoming: GetUsersOutput | null
        ): GetUsersOutput | null {
          return incoming;
        },
      },
      getChannels2: {
        keyArgs: ["isActiveOnly"],
        read(
          existing: Reference[] | null | undefined,
          { readField }
        ): Reference[] | null | undefined {
          return existing
            ? uniqBy(compact(existing), (channel) =>
                readField<string>("id", channel)
              )
            : existing;
        },
        merge(
          _: Reference[] | null | undefined,
          incoming: Reference[] | null
        ): Reference[] | null {
          return incoming;
        },
      },
      getTeams: {
        read(
          existing: Reference[] | null | undefined,
          { readField }
        ): Reference[] | null | undefined {
          return existing
            ? uniqBy(compact(existing), (team) => readField<string>("id", team))
            : existing;
        },
        merge(
          _: Reference[] | null | undefined,
          incoming: Reference[] | null
        ): Reference[] | null {
          return incoming;
        },
      },
      getRooms2: {
        keyArgs: ["input", ["channelsTypes", "status", "filter"]],
      },
      getChatRoomMessages: {
        keyArgs: ["chatRoomId"],
      },
      getCommentsOnClient2: {
        keyArgs: ["clientId"],
        merge(
          existing: GetCommentsOnClientOutput2 | null | undefined,
          incoming: GetCommentsOnClientOutput2 | null
        ): GetCommentsOnClientOutput2 | null {
          return incoming;
        },
      },
      getBots: {
        keyArgs: ["status"],
        read(
          existing: Reference[] | null | undefined,
          { readField }
        ): Reference[] | null | undefined {
          return existing
            ? uniqBy(compact(existing), (bot) => readField<string>("id", bot))
            : existing;
        },
        merge(
          _: Reference[] | null | undefined,
          incoming: Reference[] | null
        ): Reference[] | null {
          return incoming;
        },
      },
      // getBot: {
      //   keyArgs: ["id"],
      //   merge(
      //     _: Reference | null | undefined,
      //     incoming: Reference | null
      //   ): Reference | null {
      //     return incoming;
      //   },
      // },
      getFlows: {
        keyArgs: ["botId"],
        read(
          existing: Reference[] | null | undefined,
          { readField }
        ): Reference[] | null | undefined {
          return existing
            ? uniqBy(compact(existing), (flow) => readField<string>("id", flow))
            : existing;
        },
        merge(
          _: Reference[] | null | undefined,
          incoming: Reference[] | null
        ): Reference[] | null {
          return incoming;
        },
      },
      getNodes: {
        keyArgs: ["input", ["botId", "flowId"], "@persist"],
        read(existing: Reference[] | null, { readField }): Reference[] {
          return uniqBy(compact(existing), (node) =>
            readField<string>("id", node)
          );
        },
        merge(
          _: Reference[] | null | undefined,
          incoming: Reference[] | null
        ): Reference[] | null {
          return incoming;
        },
      },
      getEdges: {
        keyArgs: ["input", ["botId", "flowId"], "@persist"],
        read(existing: Reference[] | null, { readField }): Reference[] {
          return uniqBy(compact(existing), (node) =>
            readField<string>("id", node)
          );
        },
        merge(
          _: Reference[] | null | undefined,
          incoming: Reference[] | null
        ): Reference[] | null {
          return incoming;
        },
      },
      validateBot: {
        keyArgs: ["id"],
        merge(
          _: ValidateBot[] | null | undefined,
          incoming: ValidateBot[] | null
        ): ValidateBot[] | null {
          return incoming;
        },
      },
    },
  },
};

const cache = new InMemoryCache({
  typePolicies,
});

export default cache;
