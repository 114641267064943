import {
  ComponentProps,
  ComponentType,
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import type { Options } from "react-select";
import {
  NumberParam,
  StringParam,
  createEnumParam,
  useQueryParams,
  withDefault,
} from "use-query-params";
import type { Option } from "~@/components/base/Select";
import { SortBy, SortOrder } from "~@/graphql/codegen/generated";

const SortByParam = createEnumParam(Object.values(SortBy));

const SortOrderParam = createEnumParam(Object.values(SortOrder));

export enum Language {
  Ru = "ru",
  En = "en",
}

export const perPageOptions: Options<Option> = [
  {
    value: "200",
    label: "200",
  },
  {
    value: "400",
    label: "400",
  },
  {
    value: "600",
    label: "600",
  },
  {
    value: "800",
    label: "800",
  },
  {
    value: "1000",
    label: "1000",
  },
] as const;

export type PreservedQueryParamsContext = {
  perPage: number;
  updatePerPage: (p: number) => void;
  clientsSearch: string;
  updateClientsSearch: (q: string) => void;
  usersSearch: string;
  updateUsersSearch: (q: string) => void;
  sortBy: SortBy;
  updateSortBy: (field: SortBy) => void;
  sortOrder: SortOrder;
  updateSortOrder: (order: SortOrder) => void;
};

// eslint-disable-next-line @typescript-eslint/no-redeclare -- companion object
export const PreservedQueryParamsContext = createContext<
  PreservedQueryParamsContext | undefined
>(undefined);

export const usePreservedQueryParamsContext = () => {
  const context = useContext(PreservedQueryParamsContext);

  if (!context) {
    throw new Error("Missing PreservedQueryParamsProvider.");
  }

  return context;
};

export const PreservedQueryParamsProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const [query, setQuery] = useQueryParams({
    p: withDefault(NumberParam, parseInt(perPageOptions[0].value)),
    q: withDefault(StringParam, ""),
    sb: withDefault(SortByParam, SortBy.CreatedAt),
    so: withDefault(SortOrderParam, SortOrder.Desc),
  });
  const {
    p: perPageQuery,
    q: searchQuery,
    so: sortOrderQuery,
    sb: sortByQuery,
  } = query;
  const [perPage, setPerPage] = useState(perPageQuery);
  const [clientsSearch, setClientsSearch] = useState(searchQuery);
  const [usersSearch, setUsersSearch] = useState(searchQuery);
  const [sortBy, setSortBy] = useState(sortByQuery);
  const [sortOrder, setSortOrder] = useState(sortOrderQuery);
  const updatePerPage = useCallback(
    (value: number) => {
      setQuery({ p: value }, "pushIn");
      setPerPage(value);
    },
    [setQuery]
  );

  const updateSortBy = useCallback(
    (value: SortBy) => {
      setSortBy(value);
      setQuery({ sb: value }, "pushIn");
    },
    [setQuery]
  );

  const updateSortOrder = useCallback(
    (value: SortOrder) => {
      setSortOrder(value);
      setQuery({ so: value }, "pushIn");
    },
    [setQuery]
  );

  const updateClientsSearch = useCallback(
    (value: string) => {
      setQuery({ q: value }, "pushIn");
      setClientsSearch(value);
    },
    [setQuery]
  );

  const updateUsersSearch = useCallback(
    (value: string) => {
      setQuery({ q: value }, "pushIn");
      setUsersSearch(value);
    },
    [setQuery]
  );

  useEffect(() => {
    const perPageOptionsValues = perPageOptions.map((usersPerPageOption) =>
      parseInt(usersPerPageOption.value)
    );

    if (!perPageOptionsValues.includes(perPage)) {
      updatePerPage(perPageOptionsValues[0]);
    }
  }, [perPage, updatePerPage]);

  return (
    <PreservedQueryParamsContext.Provider
      value={{
        perPage,
        updatePerPage,
        clientsSearch,
        updateClientsSearch,
        usersSearch,
        updateUsersSearch,
        sortBy,
        updateSortBy,
        sortOrder,
        updateSortOrder,
      }}
    >
      {children}
    </PreservedQueryParamsContext.Provider>
  );
};

export const withPreservedQueryParamsProvider = <P extends object>(
  WrappedComponent: ComponentType<P>
): FC<P> => {
  return function WithPreservedQueryParamsProvider(
    props: ComponentProps<typeof WrappedComponent>
  ) {
    return (
      <PreservedQueryParamsProvider>
        <WrappedComponent {...props} />
      </PreservedQueryParamsProvider>
    );
  };
};
