import { FC, PropsWithChildren, useMemo } from "react";
import useInternalUser from "~@/hooks/useInternalUser";
import { PERMISSIONS, SCOPES } from "~@/permission-maps";
import some from "lodash/some";

type PermissionsGateProps = {
  scopes?: SCOPES[];
  RenderError?: () => JSX.Element;
};

type HasPermission = (props: {
  permissions: SCOPES[];
  scopes: SCOPES[];
}) => boolean;

const hasPermission: HasPermission = ({ permissions, scopes }) => {
  const scopesMap: Partial<Record<SCOPES, boolean>> = {};

  scopes.forEach((scope) => {
    scopesMap[scope] = true;
  });

  return some(permissions, (permission) => scopesMap[permission]);
};

export const usePermissionsGate = (
  scopes: PermissionsGateProps["scopes"] = []
) => {
  const { internalUser } = useInternalUser();

  const permissionGranted = useMemo(
    () =>
      hasPermission({
        permissions:
          PERMISSIONS[
            internalUser?.role?.roleTechnicalName as
              | "operator"
              | "administrator"
              | "root"
          ],
        scopes,
      }),
    [internalUser, scopes]
  );

  return permissionGranted;
};

const PermissionsGate: FC<PropsWithChildren<PermissionsGateProps>> = ({
  children,
  scopes = [],
  RenderError,
}) => {
  const permissionGranted = usePermissionsGate(scopes);

  if (!permissionGranted) return RenderError ? <RenderError /> : null;

  return <>{children}</>;
};

export default PermissionsGate;
