import { FC } from "react";
import { sanitize } from "~@/utils";
import { useTranslation } from "react-i18next";

import Error500 from "~@/assets/image/non-svg/500.png";
import BotsDead from "~@/assets/image/non-svg/bots-dead.png";
import { ArrowRotateLeft14x14Icon } from "~@/icons";

const ServerError: FC = () => {
  const { t } = useTranslation();
  return (
    <div className="error error--is-fullheight">
      <div className="error__code">
        <img alt="error500" src={Error500} />
      </div>
      <div className="error__bots">
        <img alt="error-bots" src={BotsDead} />
      </div>
      <p className="error__title error__title--is-500">
        {sanitize({ html: t("error.server-error") })}
      </p>
      <p className="error__subtitle">{t("error.reload")}</p>
      <div className="buttons buttons--has-gap-4">
        <button
          onClick={() => window.location.reload()}
          className="button button--h-48 button--fill-black-0"
        >
          <span className="icon-text">
            <span> {t("error.reload-page")}</span>
            <span className="icon icon--is-14x14">
              <ArrowRotateLeft14x14Icon />
            </span>
          </span>
        </button>
      </div>
    </div>
  );
};

export default ServerError;
