import translationEN from "./locales/en-translation.json";
import translationRU from "./locales/ru-translation.json";
import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: ["en", "ru"],
    resources: {
      en: { translation: translationEN },
      ru: { translation: translationRU },
    },
    debug: process.env.NODE_ENV === "development",
    detection: {
      order: ["localStorage", "navigator"],
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18next;
