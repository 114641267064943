import { FC, useCallback } from "react";
import { useReactiveVar } from "@apollo/client";
import useRemoveMessage from "~@/pages/chat-room/hooks/useRemoveMessage";
import {
  GetChatRoomMessagesQuery,
  QueryGetChatRoomMessagesArgs,
  GetChatRoomMessagesDocument,
  LastMessageContentOutput,
  useWatchDeleteMessageSubscription,
} from "~@/graphql/codegen/generated";
import type { WatchCreateMessageProps } from "./useWatchCreateMessage";
import last from "lodash/last";

import { selectedChannelsTypesVar } from "~@/reactive-variables";

const WatchDeleteMessage: FC<WatchCreateMessageProps> = ({ channelType }) => {
  const removeMessage = useRemoveMessage();

  useWatchDeleteMessageSubscription({
    variables: { channelType },
    onData({ data: { data }, client: { cache } }) {
      const removedMessage = data?.watchDeleteMessage;

      if (removedMessage instanceof Object) {
        removeMessage(removedMessage);

        const existingMessagesData = cache.readQuery<
          GetChatRoomMessagesQuery,
          QueryGetChatRoomMessagesArgs
        >({
          query: GetChatRoomMessagesDocument,
          variables: {
            chatRoomId: removedMessage.chatRoomId as string,
            fetchSize: 20,
          },
        });

        const lastMessage = last(
          existingMessagesData?.getChatRoomMessages?.chatRoomMessages
        );

        if (lastMessage) {
          cache.modify({
            id: cache.identify({
              id: lastMessage.chatRoomId,
              __typename: "RoomOutput2",
            }),
            fields: {
              lastMessageContent(): LastMessageContentOutput {
                return {
                  messageText: lastMessage.messageText,
                  messageContent:
                    lastMessage.messageContent &&
                    Array.isArray(JSON.parse(lastMessage.messageContent))
                      ? JSON.parse(lastMessage.messageContent)
                      : null,
                };
              },
              lastMessageAt(existing: string): string {
                return lastMessage.messageCreatedDateTime ?? existing;
              },
            },
          });
        }
      }
    },
  });

  return null;
};

const useWatchDeleteMessage = () => {
  const selectedChannelsTypes = useReactiveVar(selectedChannelsTypesVar);

  const watchDeleteMessage = useCallback(() => {
    return selectedChannelsTypes.map((selectedChannelType) => {
      return (
        <WatchDeleteMessage
          key={selectedChannelType}
          channelType={selectedChannelType}
        />
      );
    });
  }, [selectedChannelsTypes]);

  return watchDeleteMessage;
};

export default useWatchDeleteMessage;
