import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  NormalizedCacheObject,
  split,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from "@apollo/client/utilities";
import { useAuth0 } from "@auth0/auth0-react";
import { CachePersistor, LocalForageWrapper } from "apollo3-cache-persist";
import localForage from "localforage";
import { ComponentProps, ComponentType, FC, useEffect, useState } from "react";
import cache from "~@/cache";
import Loader from "~@/components/base/Loader";
import { useCachePersistorContext } from "~@/context/cache-persistor-context";
import { createPersistLink, persistenceMapper } from "~@/persistence";

export const withApolloClient = <P extends object>(
  WrappedComponent: ComponentType<P>
): FC<P> => {
  return function WithApolloClient(
    props: ComponentProps<typeof WrappedComponent>
  ) {
    const [client, setClient] = useState<
      ApolloClient<NormalizedCacheObject> | undefined
    >(undefined);
    const [, setPersistor] = useCachePersistorContext();

    const { getAccessTokenSilently } = useAuth0();

    useEffect(() => {
      const init = async () => {
        const newPersistor = new CachePersistor({
          cache,
          storage: new LocalForageWrapper(localForage),
          debug: process.env.NODE_ENV === "development",
          trigger: "write",
          persistenceMapper,
        });

        await newPersistor.restore();

        setPersistor(newPersistor);

        const httpLink = new HttpLink({
          uri: `${process.env.REACT_APP_LAMBDAS_ENDPOINT as string}/graphql`,
        });
        const wsLink = new WebSocketLink({
          uri: `${(process.env.REACT_APP_LAMBDAS_ENDPOINT as string).replace(
            "https",
            "wss"
          )}/graphql`,
          options: {
            reconnect: true,
            reconnectionAttempts: 10,
            connectionParams: {
              authToken: await getAccessTokenSilently(),
            },
          },
        });
        const authLink = setContext(async (_, { headers }) => {
          return {
            headers: {
              ...headers,
              Authorization: `Bearer ${await getAccessTokenSilently()}`,
            },
          };
        });
        const persistLink = createPersistLink();

        setClient(
          new ApolloClient({
            link: split(
              ({ query }) => {
                const definition = getMainDefinition(query);
                return (
                  definition.kind === "OperationDefinition" &&
                  definition.operation === "subscription"
                );
              },
              wsLink,
              persistLink.concat(authLink.concat(httpLink))
            ),
            cache,
            connectToDevTools: process.env.NODE_ENV === "development",
          })
        );
      };

      init().catch(console.error);
    }, [setPersistor, getAccessTokenSilently]);

    return client ? (
      <ApolloProvider client={client}>
        <WrappedComponent {...props} />
      </ApolloProvider>
    ) : (
      <Loader isFullHeight />
    );
  };
};
