import { FC, useCallback } from "react";
import {
  RoomOutput2,
  useWatchRooms2Subscription,
} from "~@/graphql/codegen/generated";
import useInternalUser from "~@/hooks/useInternalUser";
import useRemoveChatRooms from "~@/pages/chat-room/hooks/useRemoveChatRooms";
import useStoreChatRooms from "~@/pages/chat-room/hooks/useStoreChatRooms";

type WatchRoomsProps = {
  teamId: string;
};

const WatchRooms: FC<WatchRoomsProps> = ({ teamId }) => {
  const { internalUser } = useInternalUser();

  const removeChatRooms = useRemoveChatRooms();
  const storeChatRooms = useStoreChatRooms();

  useWatchRooms2Subscription({
    skip: !internalUser,
    variables: {
      teamId,
      userId: internalUser?.id as string,
    },
    onData({ data: { data } }) {
      const rooms = data?.watchRooms2?.rooms;

      if (Array.isArray(rooms)) {
        removeChatRooms(rooms as RoomOutput2[]);

        storeChatRooms(rooms as RoomOutput2[]);
      }
    },
  });

  return null;
};

const useWatchRooms = () => {
  const { internalUser } = useInternalUser();

  const watchRooms = useCallback(() => {
    return internalUser?.teams?.map((team) => {
      if (!team) return null;

      return <WatchRooms key={team.id} teamId={team.id} />;
    });
  }, [internalUser]);

  return watchRooms;
};

export default useWatchRooms;
