import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type AiBotTemplateWithFieldsOutput = {
  __typename?: "AiBotTemplateWithFieldsOutput";
  createdAt: Scalars["String"];
  description?: Maybe<LanguageMap>;
  fields: Scalars["String"];
  id: Scalars["ID"];
  isAvailable: Scalars["Boolean"];
  name: LanguageMap;
};

export type AiBotUserSettingsOutput = {
  __typename?: "AiBotUserSettingsOutput";
  botId: Scalars["ID"];
  createdAt: Scalars["String"];
  id: Scalars["ID"];
  templateId: Scalars["ID"];
  updatedAt: Scalars["String"];
  userSettings?: Maybe<Scalars["String"]>;
};

export enum AnalyticsCalendarInterval {
  Day = "DAY",
  Hour = "HOUR",
  Month = "MONTH",
  Quarter = "QUARTER",
  Week = "WEEK",
}

export enum AnalyticsChatsDistributionLabels {
  Bots = "BOTS",
  Operators = "OPERATORS",
}

export enum AnalyticsChatsRepeatedLabels {
  Primary = "PRIMARY",
  Repeated = "REPEATED",
}

export type AnalyticsHeatMapSeriesDataItem = {
  __typename?: "AnalyticsHeatMapSeriesDataItem";
  x: Scalars["String"];
  y: Scalars["Int"];
};

export type AnalyticsHeatMapSeriesItem = {
  __typename?: "AnalyticsHeatMapSeriesItem";
  data: Array<Maybe<AnalyticsHeatMapSeriesDataItem>>;
  name: Scalars["String"];
};

export type AnalyticsOperatorsMetricsTableRow = {
  __typename?: "AnalyticsOperatorsMetricsTableRow";
  avgAcceptedSec: Scalars["Float"];
  avgFirstMessageSec: Scalars["Float"];
  completedChats: Scalars["Int"];
  operatorName: Scalars["String"];
};

export enum AnalyticsOperatorsPerformanceSeriesStatus {
  Accepted = "ACCEPTED",
  Offline = "OFFLINE",
  Online = "ONLINE",
}

export type ApiKey = {
  __typename?: "ApiKey";
  apiKey: Scalars["String"];
  createdAt: Scalars["String"];
  id: Scalars["ID"];
  lastCallAt?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
};

export type ApiKeyId = {
  __typename?: "ApiKeyId";
  id: Scalars["ID"];
};

export type ApiKeyShort = {
  __typename?: "ApiKeyShort";
  apiKey: Scalars["String"];
  id: Scalars["ID"];
};

export type AppField = {
  __typename?: "AppField";
  c?: Maybe<Array<AppFieldDetails>>;
  d?: Maybe<LanguageMap>;
  dt?: Maybe<AppFieldDataTypeType>;
  dts?: Maybe<Array<AppFieldDataTypesType>>;
  mn?: Maybe<Scalars["Int"]>;
  mx?: Maybe<Scalars["Int"]>;
  n: LanguageMap;
  p?: Maybe<LanguageMap>;
  r?: Maybe<Scalars["Boolean"]>;
  t: AppFieldTypeType;
  tg: Scalars["String"];
  v?: Maybe<Scalars["String"]>;
};

export enum AppFieldDataTypeType {
  Number = "number",
  Secret = "secret",
  Select = "select",
  String = "string",
}

export enum AppFieldDataTypesType {
  Node = "node",
  User = "user",
}

export type AppFieldDetails = AppFieldInner | AppFieldsGroupInner;

export type AppFieldInner = {
  __typename?: "AppFieldInner";
  d?: Maybe<LanguageMap>;
  dt?: Maybe<AppFieldDataTypeType>;
  dts?: Maybe<Array<AppFieldDataTypesType>>;
  mn?: Maybe<Scalars["Int"]>;
  mx?: Maybe<Scalars["Int"]>;
  n: LanguageMap;
  p?: Maybe<LanguageMap>;
  r?: Maybe<Scalars["Boolean"]>;
  t: AppFieldTypeType;
  tg: Scalars["String"];
  v?: Maybe<Scalars["String"]>;
};

export enum AppFieldSettingsDataTypeType {
  Node = "node",
  Number = "number",
  Secret = "secret",
  Select = "select",
  String = "string",
  User = "user",
}

export enum AppFieldTypeType {
  AreaMultiselect = "areaMultiselect",
  Input = "input",
  Multiselect = "multiselect",
  Select = "select",
}

export type AppFieldsGroup = {
  __typename?: "AppFieldsGroup";
  c: Array<AppField>;
  g: AppFieldsGroupType;
};

export type AppFieldsGroupInner = {
  __typename?: "AppFieldsGroupInner";
  c: Array<AppFieldInner>;
  g: AppFieldsGroupType;
};

export enum AppFieldsGroupType {
  Action = "action",
  Common = "common",
  Fields = "fields",
}

export type AppGallery = {
  __typename?: "AppGallery";
  alt: LanguageMap;
  height: Scalars["Int"];
  imageUrl?: Maybe<Scalars["String"]>;
  videoUrl?: Maybe<Scalars["String"]>;
  width: Scalars["Int"];
};

export type AppInstallation = {
  __typename?: "AppInstallation";
  createdAt: Scalars["String"];
  id: Scalars["ID"];
  localId?: Maybe<Scalars["String"]>;
  method?: Maybe<MethodType>;
  settings?: Maybe<Array<Maybe<AppSettingsGroup>>>;
};

export type AppOutput = {
  __typename?: "AppOutput";
  createdAt: Scalars["String"];
  description?: Maybe<LanguageMap>;
  gallery?: Maybe<Scalars["String"]>;
  iconUrl: Scalars["String"];
  id: Scalars["ID"];
  instructions?: Maybe<LanguageMap>;
  isAvailable: Scalars["Boolean"];
  isInstalled: Scalars["Boolean"];
  latestVersion: Scalars["String"];
  name: LanguageMap;
  tag: Scalars["String"];
  type: AppType;
  versions: Array<Maybe<AppVersion>>;
};

export type AppSettingsField = {
  __typename?: "AppSettingsField";
  c?: Maybe<Array<AppSettingsFieldDetails>>;
  dt: AppFieldSettingsDataTypeType;
  tg: Scalars["String"];
  v: Scalars["String"];
};

export type AppSettingsFieldDetails =
  | AppSettingsFieldInner
  | AppSettingsGroupInner;

export type AppSettingsFieldInner = {
  __typename?: "AppSettingsFieldInner";
  dt: AppFieldSettingsDataTypeType;
  tg: Scalars["String"];
  v: Scalars["String"];
};

export type AppSettingsGroup = {
  __typename?: "AppSettingsGroup";
  c: Array<AppSettingsField>;
  g: AppFieldsGroupType;
};

export type AppSettingsGroupInner = {
  __typename?: "AppSettingsGroupInner";
  c: Array<AppSettingsFieldInner>;
  g: AppFieldsGroupType;
};

export enum AppType {
  BotNode = "BOT_NODE",
  Webhook = "WEBHOOK",
}

export type AppVersion = {
  __typename?: "AppVersion";
  createdAt: Scalars["String"];
  fields: Array<Maybe<AppFieldsGroup>>;
  id: Scalars["ID"];
  installations: Array<Maybe<AppInstallation>>;
  isInstalled: Scalars["Boolean"];
  version: Scalars["String"];
};

export type BaseCalendarEventOutput = {
  __typename?: "BaseCalendarEventOutput";
  actionType?: Maybe<CalendarEventActionType>;
  event?: Maybe<CalendarEventOutput>;
};

export type BotOutput = {
  __typename?: "BotOutput";
  botType?: Maybe<BotType>;
  connectedChannels?: Maybe<Array<Maybe<ChannelOutput2>>>;
  createdAt?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  isActive?: Maybe<Scalars["Boolean"]>;
  name?: Maybe<Scalars["String"]>;
  publishedAt?: Maybe<Scalars["String"]>;
  sessionsCount?: Maybe<Scalars["Int"]>;
  status?: Maybe<BotStatus>;
  teamsIds?: Maybe<Array<Maybe<Scalars["String"]>>>;
  updatedAt?: Maybe<Scalars["String"]>;
  userSettingsRaw?: Maybe<Scalars["String"]>;
};

export enum BotStatus {
  Deleted = "DELETED",
  Deletion = "DELETION",
  Draft = "DRAFT",
  Published = "PUBLISHED",
}

export enum BotType {
  Ai = "AI",
  Scheme = "SCHEME",
}

export type ButtonInput = {
  botId?: InputMaybe<Scalars["String"]>;
  buttonOrder?: InputMaybe<Scalars["Int"]>;
  flowId?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  localId?: InputMaybe<Scalars["String"]>;
  localNodeId?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  nodeId?: InputMaybe<Scalars["String"]>;
};

export type ButtonOutput = {
  __typename?: "ButtonOutput";
  botId?: Maybe<Scalars["String"]>;
  buttonOrder?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["String"]>;
  localId?: Maybe<Scalars["String"]>;
  localNodeId?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  nodeId?: Maybe<Scalars["String"]>;
};

export enum CalendarEventActionType {
  Created = "CREATED",
  Deleted = "DELETED",
  Updated = "UPDATED",
}

export type CalendarEventInput = {
  description?: InputMaybe<Scalars["String"]>;
  endDateTime?: InputMaybe<Scalars["String"]>;
  location?: InputMaybe<Scalars["String"]>;
  participants?: InputMaybe<Array<InputMaybe<EventParticipantInput>>>;
  startDateTime?: InputMaybe<Scalars["String"]>;
  title: Scalars["String"];
};

export type CalendarEventOutput = {
  __typename?: "CalendarEventOutput";
  accountId?: Maybe<Scalars["String"]>;
  busy?: Maybe<Scalars["Boolean"]>;
  calendarId?: Maybe<Scalars["String"]>;
  customerEventId?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  endDateTime?: Maybe<Scalars["String"]>;
  hideParticipants?: Maybe<Scalars["Boolean"]>;
  icalUid?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  location?: Maybe<Scalars["String"]>;
  messageId?: Maybe<Scalars["String"]>;
  object?: Maybe<Scalars["String"]>;
  organizerEmail?: Maybe<Scalars["String"]>;
  organizerName?: Maybe<Scalars["String"]>;
  owner?: Maybe<Scalars["String"]>;
  participants?: Maybe<Array<Maybe<EventParticipantOutput>>>;
  readOnly?: Maybe<Scalars["Boolean"]>;
  reminders?: Maybe<Scalars["String"]>;
  startDateTime?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["String"]>;
  visibility?: Maybe<Scalars["String"]>;
};

export type CalendarInput = {
  description?: InputMaybe<Scalars["String"]>;
  location?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  timezone?: InputMaybe<Scalars["String"]>;
};

export type CalendarOutput = {
  __typename?: "CalendarOutput";
  accountId?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  isPrimary?: Maybe<Scalars["Boolean"]>;
  location?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  object?: Maybe<Scalars["String"]>;
  readOnly?: Maybe<Scalars["Boolean"]>;
  timezone?: Maybe<Scalars["String"]>;
};

export type ChangeDeletionStatusesInput = {
  usersIds: Array<Scalars["String"]>;
};

export type ChannelOutput2 = {
  __typename?: "ChannelOutput2";
  bot?: Maybe<BotOutput>;
  createdAt: Scalars["String"];
  deletedAt?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  link: Scalars["String"];
  name: Scalars["String"];
  publicId: Scalars["String"];
  status: ChannelStatus;
  type: ChannelType;
  updatedAt: Scalars["String"];
};

export enum ChannelStatus {
  Active = "ACTIVE",
  Error = "ERROR",
  Inactive = "INACTIVE",
  PendingDeactivation = "PENDING_DEACTIVATION",
  PendingDeletion = "PENDING_DELETION",
}

export type ChannelStatusOutput = {
  __typename?: "ChannelStatusOutput";
  statusDescription?: Maybe<Scalars["String"]>;
  statusId?: Maybe<Scalars["String"]>;
  statusName?: Maybe<Scalars["String"]>;
};

export enum ChannelType {
  Email = "EMAIL",
  FacebookMessenger = "FACEBOOK_MESSENGER",
  Instagram = "INSTAGRAM",
  Sms = "SMS",
  Telegram = "TELEGRAM",
  Vk = "VK",
  Whatsapp = "WHATSAPP",
}

export enum ChannelsModes {
  Bot = "BOT",
  Human = "HUMAN",
}

export type ChatSnippetsCategoriesOutput = {
  __typename?: "ChatSnippetsCategoriesOutput";
  categoryId?: Maybe<Scalars["String"]>;
  categoryName?: Maybe<Scalars["String"]>;
  parentId?: Maybe<Scalars["String"]>;
  totalSnippetsCount?: Maybe<Scalars["Int"]>;
  userId?: Maybe<Scalars["String"]>;
};

export type ChatSnippetsFavoritesOutput = {
  __typename?: "ChatSnippetsFavoritesOutput";
  isFavorite: Scalars["Boolean"];
  snippetId?: Maybe<Scalars["String"]>;
  userId?: Maybe<Scalars["String"]>;
};

export type ChatSnippetsOutput = {
  __typename?: "ChatSnippetsOutput";
  categoryId?: Maybe<Scalars["String"]>;
  isFavorite: Scalars["Boolean"];
  snippet?: Maybe<Scalars["String"]>;
  snippetId?: Maybe<Scalars["String"]>;
  teamsIds?: Maybe<Array<Maybe<Scalars["String"]>>>;
  userId?: Maybe<Scalars["String"]>;
};

export type ChatsAnalyticsOutput = {
  __typename?: "ChatsAnalyticsOutput";
  avgAccepted: Scalars["Float"];
  avgInvisible: Scalars["Float"];
  avgNonAccepted: Scalars["Float"];
  chatsByChannels: LineChart;
  chatsDistribution: ChatsDistribution;
  chatsProcessed: Scalars["Int"];
  chatsRepeated: ChatsRepeated;
  chatsUnprocessed: Scalars["Int"];
};

export type ChatsDistribution = {
  __typename?: "ChatsDistribution";
  labels: Array<AnalyticsChatsDistributionLabels>;
  series: Array<Scalars["Int"]>;
};

export type ChatsRepeated = {
  __typename?: "ChatsRepeated";
  labels: Array<AnalyticsChatsRepeatedLabels>;
  series: Array<Scalars["Int"]>;
};

export type CommentOutput2 = {
  __typename?: "CommentOutput2";
  clientId?: Maybe<Scalars["String"]>;
  comment?: Maybe<Scalars["String"]>;
  commentId?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["String"]>;
  operator?: Maybe<UserOutput>;
};

export type CommonUserFields = {
  __typename?: "CommonUserFields";
  autoNickname: Scalars["String"];
  createdAt: Scalars["String"];
  email?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  phone?: Maybe<Scalars["String"]>;
  userpicUrl: Scalars["String"];
};

export type ContactsAnalyticsOutput = {
  __typename?: "ContactsAnalyticsOutput";
  activeChats: DataByRoomStatus;
  contactsByChannels: DataByChannelType;
  newContacts7d: LineChart;
  newContactsToday: Scalars["Int"];
  totalContacts: Scalars["Int"];
};

export type CreateCalendarEventInput = {
  calendarId: Scalars["String"];
  event: CalendarEventInput;
};

export type CreateCalendarInput = {
  calendar: CalendarInput;
};

export type CreateChatRoomMessageInput = {
  chatRoomId: Scalars["String"];
  isClient: Scalars["Boolean"];
  localMessageId?: InputMaybe<Scalars["String"]>;
  messageAuthorId: Scalars["String"];
  messageChannelId: Scalars["String"];
  messageChannelType: ChannelType;
  messageContent?: InputMaybe<Scalars["String"]>;
  messageMetadata?: InputMaybe<Scalars["String"]>;
  messageText?: InputMaybe<Scalars["String"]>;
};

export type CreateCommentOnClientInput = {
  clientId: Scalars["String"];
  comment: Scalars["String"];
  operatorId: Scalars["String"];
};

export type CreateTeamInput = {
  name: Scalars["String"];
  usersIds?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export enum CustomFieldDataType {
  Date = "DATE",
  Email = "EMAIL",
  Multiselect = "MULTISELECT",
  Number = "NUMBER",
  Phone = "PHONE",
  Text = "TEXT",
  Url = "URL",
}

export enum CustomFieldMethodType {
  Create = "CREATE",
  Delete = "DELETE",
  Update = "UPDATE",
}

export type CustomFieldOutput = {
  __typename?: "CustomFieldOutput";
  dataType: CustomFieldDataType;
  description?: Maybe<Scalars["String"]>;
  fieldName: Scalars["String"];
  fieldOrder: Scalars["Int"];
  id: Scalars["String"];
};

export type DataByChannelType = {
  __typename?: "DataByChannelType";
  labels: Array<Maybe<ChannelType>>;
  series: Array<Maybe<Scalars["Int"]>>;
};

export type DataByRoomStatus = {
  __typename?: "DataByRoomStatus";
  labels: Array<Maybe<RoomStatus>>;
  series: Array<Maybe<Scalars["Int"]>>;
};

export type DeleteCalendarInput = {
  eventId: Scalars["String"];
};

export type DeleteMessageOutput = {
  __typename?: "DeleteMessageOutput";
  channelId?: Maybe<Scalars["String"]>;
  channelType?: Maybe<ChannelType>;
  chatRoomId?: Maybe<Scalars["String"]>;
  messageId?: Maybe<Scalars["String"]>;
};

export type DeleteOrganizationsOutput = {
  __typename?: "DeleteOrganizationsOutput";
  deleted?: Maybe<Scalars["Boolean"]>;
  teamsIds?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type DeployOrganizationOutput = {
  __typename?: "DeployOrganizationOutput";
  oid: Scalars["String"];
};

export type EdgeInput = {
  botId?: InputMaybe<Scalars["String"]>;
  childId?: InputMaybe<Scalars["String"]>;
  flowId?: InputMaybe<Scalars["String"]>;
  handleParentId?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  isDelete?: InputMaybe<Scalars["Boolean"]>;
  localChildId?: InputMaybe<Scalars["String"]>;
  localHandleParentId?: InputMaybe<Scalars["String"]>;
  localId?: InputMaybe<Scalars["String"]>;
  localParentId?: InputMaybe<Scalars["String"]>;
  parentId?: InputMaybe<Scalars["String"]>;
};

export type EdgeOutput = {
  __typename?: "EdgeOutput";
  botId?: Maybe<Scalars["String"]>;
  childId?: Maybe<Scalars["String"]>;
  flowId?: Maybe<Scalars["String"]>;
  handleParentId?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  isDelete?: Maybe<Scalars["Boolean"]>;
  localChildId?: Maybe<Scalars["String"]>;
  localHandleParentId?: Maybe<Scalars["String"]>;
  localId?: Maybe<Scalars["String"]>;
  localParentId?: Maybe<Scalars["String"]>;
  parentId?: Maybe<Scalars["String"]>;
};

export type EventParticipantInput = {
  comment?: InputMaybe<Scalars["String"]>;
  email: Scalars["String"];
  name?: InputMaybe<Scalars["String"]>;
  phoneNumber?: InputMaybe<Scalars["String"]>;
};

export type EventParticipantOutput = {
  __typename?: "EventParticipantOutput";
  comment?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  phoneNumber?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
};

export type FacebookPagesCategoryListOutput = {
  __typename?: "FacebookPagesCategoryListOutput";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

export type FacebookPagesCursorsOutput = {
  __typename?: "FacebookPagesCursorsOutput";
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
};

export type FacebookPagesDataOutput = {
  __typename?: "FacebookPagesDataOutput";
  access_token?: Maybe<Scalars["String"]>;
  category?: Maybe<Scalars["String"]>;
  category_list?: Maybe<Array<Maybe<FacebookPagesCategoryListOutput>>>;
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  tasks?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type FacebookPagesPagingOutput = {
  __typename?: "FacebookPagesPagingOutput";
  cursors?: Maybe<FacebookPagesCursorsOutput>;
};

export type FileItemOutput = {
  __typename?: "FileItemOutput";
  createdAt?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  status?: Maybe<FileStatus>;
  url?: Maybe<Scalars["String"]>;
  userId?: Maybe<Scalars["String"]>;
};

export enum FileStatus {
  Decline = "DECLINE",
  Done = "DONE",
  Error = "ERROR",
  InProgress = "IN_PROGRESS",
}

export type FlowOutput = {
  __typename?: "FlowOutput";
  botId?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  isMain?: Maybe<Scalars["Boolean"]>;
  name?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["String"]>;
};

export type GeneralAnalyticsOutput = {
  __typename?: "GeneralAnalyticsOutput";
  activeChats: Scalars["Int"];
  chatsByChannels: DataByChannelType;
  newChats7d: LineChart;
  operatorsPerformance: OperatorsPerformance;
  totalChats: Scalars["Int"];
  totalContacts: Scalars["Int"];
};

export type GetChatRoomMessagesOutput = {
  __typename?: "GetChatRoomMessagesOutput";
  chatRoomMessages?: Maybe<Array<Maybe<MessageOutput>>>;
  pagingState?: Maybe<Scalars["String"]>;
  roomId?: Maybe<Scalars["String"]>;
};

export type GetChatSnippets = {
  __typename?: "GetChatSnippets";
  snippets?: Maybe<Array<Maybe<ChatSnippetsOutput>>>;
  totalNumberOfSnippets?: Maybe<Scalars["Int"]>;
};

export type GetChatSnippetsCategories = {
  __typename?: "GetChatSnippetsCategories";
  categories?: Maybe<Array<Maybe<ChatSnippetsCategoriesOutput>>>;
  totalNumberOfCategories?: Maybe<Scalars["Int"]>;
};

export type GetChatSnippetsFavorites = {
  __typename?: "GetChatSnippetsFavorites";
  favorites?: Maybe<Array<Maybe<ChatSnippetsFavoritesOutput>>>;
  totalNumberOfFavorites?: Maybe<Scalars["Int"]>;
};

export type GetChatsAnalyticsInput = {
  botId?: InputMaybe<Scalars["String"]>;
  calendarInterval: AnalyticsCalendarInterval;
  isWithoutBots?: InputMaybe<Scalars["Boolean"]>;
  period: PeriodInput;
};

export type GetCommentsOnClientOutput2 = {
  __typename?: "GetCommentsOnClientOutput2";
  commentCount?: Maybe<Scalars["Int"]>;
  comments?: Maybe<Array<Maybe<CommentOutput2>>>;
};

export type GetFacebookPagesOutput = {
  __typename?: "GetFacebookPagesOutput";
  data?: Maybe<Array<Maybe<FacebookPagesDataOutput>>>;
  paging?: Maybe<FacebookPagesPagingOutput>;
};

export type GetFlowInput = {
  botId?: InputMaybe<Scalars["String"]>;
  flowId?: InputMaybe<Scalars["String"]>;
};

export type GetNodesAndEdgesInput = {
  botId?: InputMaybe<Scalars["String"]>;
  flowId?: InputMaybe<Scalars["String"]>;
};

export type GetOperatorsAnalyticsInput = {
  calendarInterval: AnalyticsCalendarInterval;
  operatorId?: InputMaybe<Scalars["String"]>;
  period: PeriodInput;
  teamId?: InputMaybe<Scalars["String"]>;
};

export type GetRoom2Output = {
  __typename?: "GetRoom2Output";
  error?: Maybe<Scalars["String"]>;
  room?: Maybe<RoomOutput2>;
};

export type GetRoomHistoryOrganizationOutput = {
  __typename?: "GetRoomHistoryOrganizationOutput";
  organizationName?: Maybe<Scalars["String"]>;
  teamId?: Maybe<Scalars["String"]>;
};

export type GetRoomHistoryOutput = {
  __typename?: "GetRoomHistoryOutput";
  createdAt?: Maybe<Scalars["String"]>;
  eventName?: Maybe<Scalars["String"]>;
  from?: Maybe<UserOutput>;
  operator?: Maybe<UserOutput>;
  organizations?: Maybe<Array<Maybe<GetRoomHistoryOrganizationOutput>>>;
  to?: Maybe<UserOutput>;
};

export type GetRoomInput = {
  id: Scalars["String"];
};

export type GetRooms2Output = {
  __typename?: "GetRooms2Output";
  error?: Maybe<Scalars["String"]>;
  hasUnreadMessages?: Maybe<Scalars["Boolean"]>;
  pagination?: Maybe<PaginationViaCursorOutput>;
  requestedStatus?: Maybe<RoomStatus>;
  rooms?: Maybe<Array<Maybe<RoomOutput2>>>;
};

export type GetRoomsInput = {
  channelsTypes: Array<ChannelType>;
  filter?: InputMaybe<Scalars["String"]>;
  pagination?: InputMaybe<PaginationViaCursorInput>;
  status: RoomStatus;
};

export type GetUsersInput = {
  channelsTypes?: InputMaybe<Array<InputMaybe<ChannelType>>>;
  filter?: InputMaybe<Scalars["String"]>;
  isActive?: InputMaybe<Scalars["Boolean"]>;
  pagination?: InputMaybe<PaginationInput>;
  sortBy?: InputMaybe<SortBy>;
  sortOrder?: InputMaybe<SortOrder>;
  type: UserType;
  usersIds?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type GetUsersOutput = {
  __typename?: "GetUsersOutput";
  pagination?: Maybe<PaginationOutput>;
  users?: Maybe<Array<Maybe<UserOutput>>>;
};

export enum InitiatorType {
  Bot = "BOT",
  Client = "CLIENT",
  InternalUser = "INTERNAL_USER",
  System = "SYSTEM",
}

export type LanguageMap = {
  __typename?: "LanguageMap";
  af?: Maybe<Scalars["String"]>;
  ar?: Maybe<Scalars["String"]>;
  az?: Maybe<Scalars["String"]>;
  bg?: Maybe<Scalars["String"]>;
  bn?: Maybe<Scalars["String"]>;
  ca?: Maybe<Scalars["String"]>;
  cs?: Maybe<Scalars["String"]>;
  da?: Maybe<Scalars["String"]>;
  de?: Maybe<Scalars["String"]>;
  el?: Maybe<Scalars["String"]>;
  en: Scalars["String"];
  es?: Maybe<Scalars["String"]>;
  et?: Maybe<Scalars["String"]>;
  fa?: Maybe<Scalars["String"]>;
  fi?: Maybe<Scalars["String"]>;
  fil?: Maybe<Scalars["String"]>;
  fr?: Maybe<Scalars["String"]>;
  ga?: Maybe<Scalars["String"]>;
  gu?: Maybe<Scalars["String"]>;
  ha?: Maybe<Scalars["String"]>;
  he?: Maybe<Scalars["String"]>;
  hi?: Maybe<Scalars["String"]>;
  hr?: Maybe<Scalars["String"]>;
  hu?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  it?: Maybe<Scalars["String"]>;
  ja?: Maybe<Scalars["String"]>;
  ka?: Maybe<Scalars["String"]>;
  kk?: Maybe<Scalars["String"]>;
  kn?: Maybe<Scalars["String"]>;
  ko?: Maybe<Scalars["String"]>;
  ky?: Maybe<Scalars["String"]>;
  lo?: Maybe<Scalars["String"]>;
  lt?: Maybe<Scalars["String"]>;
  lv?: Maybe<Scalars["String"]>;
  mk?: Maybe<Scalars["String"]>;
  ml?: Maybe<Scalars["String"]>;
  mr?: Maybe<Scalars["String"]>;
  ms?: Maybe<Scalars["String"]>;
  nb?: Maybe<Scalars["String"]>;
  nl?: Maybe<Scalars["String"]>;
  pa?: Maybe<Scalars["String"]>;
  pl?: Maybe<Scalars["String"]>;
  pt?: Maybe<Scalars["String"]>;
  ro?: Maybe<Scalars["String"]>;
  ru?: Maybe<Scalars["String"]>;
  rw?: Maybe<Scalars["String"]>;
  sk?: Maybe<Scalars["String"]>;
  sl?: Maybe<Scalars["String"]>;
  sq?: Maybe<Scalars["String"]>;
  sr?: Maybe<Scalars["String"]>;
  sv?: Maybe<Scalars["String"]>;
  sw?: Maybe<Scalars["String"]>;
  ta?: Maybe<Scalars["String"]>;
  te?: Maybe<Scalars["String"]>;
  th?: Maybe<Scalars["String"]>;
  tr?: Maybe<Scalars["String"]>;
  uk?: Maybe<Scalars["String"]>;
  ur?: Maybe<Scalars["String"]>;
  uz?: Maybe<Scalars["String"]>;
  vi?: Maybe<Scalars["String"]>;
  zh?: Maybe<Scalars["String"]>;
  zu?: Maybe<Scalars["String"]>;
};

export type LastMessageContentOutput = {
  __typename?: "LastMessageContentOutput";
  messageContent?: Maybe<Array<Maybe<MessageContentItemOutput>>>;
  messageText?: Maybe<Scalars["String"]>;
};

export type LineChart = {
  __typename?: "LineChart";
  series: Array<Maybe<LineChartSeries>>;
  xAxis: LineChartXAxisCategories;
};

export type LineChartSeries = {
  __typename?: "LineChartSeries";
  data: Array<Scalars["Int"]>;
  name: ChannelType;
};

export type LineChartXAxisCategories = {
  __typename?: "LineChartXAxisCategories";
  categories: Array<Maybe<Scalars["String"]>>;
};

export type MakeAppsInput = {
  appId: Scalars["ID"];
  installationId?: InputMaybe<Scalars["ID"]>;
  localId?: InputMaybe<Scalars["String"]>;
  method?: InputMaybe<MethodType>;
  settings?: InputMaybe<Scalars["String"]>;
  versionId: Scalars["ID"];
};

export type MakeBotInput = {
  botType: BotType;
  id?: InputMaybe<Scalars["String"]>;
  isActive?: InputMaybe<Scalars["Boolean"]>;
  methodType: MethodType;
  name?: InputMaybe<Scalars["String"]>;
  publishedAt?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<BotStatus>;
  teamsIds?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  templateId?: InputMaybe<Scalars["String"]>;
  userSettingsRaw?: InputMaybe<Scalars["String"]>;
};

export type MakeChannel2Input = {
  apiKey?: InputMaybe<Scalars["String"]>;
  botId?: InputMaybe<Scalars["String"]>;
  channelType?: InputMaybe<ChannelType>;
  id?: InputMaybe<Scalars["String"]>;
  methodType: MethodType;
  name?: InputMaybe<Scalars["String"]>;
  publicId?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<ChannelStatus>;
};

export type MakeCustomFieldInput = {
  dataType?: InputMaybe<CustomFieldDataType>;
  description?: InputMaybe<Scalars["String"]>;
  fieldName?: InputMaybe<Scalars["String"]>;
  fieldOrder?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["String"]>;
  methodType: CustomFieldMethodType;
};

export type MakeFlowInput = {
  botId?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  methodType: MethodType;
  name?: InputMaybe<Scalars["String"]>;
};

export type MakeOrganizationInput = {
  country?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  imageUrl?: InputMaybe<Scalars["String"]>;
  language?: InputMaybe<OrganizationLanguage>;
  method?: InputMaybe<MethodType>;
  name?: InputMaybe<Scalars["String"]>;
  phone?: InputMaybe<Scalars["String"]>;
  tariff?: InputMaybe<OrganizationTariff>;
  timezone?: InputMaybe<Scalars["String"]>;
};

export type MakeRooms2Input = {
  forTeamsIds?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  ids: Array<Scalars["String"]>;
  operatorId?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<RoomStatus>;
};

export type MakeRooms2Output = {
  __typename?: "MakeRooms2Output";
  error?: Maybe<Scalars["String"]>;
  rooms?: Maybe<Array<Maybe<RoomOutput2>>>;
};

export type MakeUserInput = {
  customFields?: InputMaybe<Array<InputMaybe<UserCustomFieldsInput>>>;
  email?: InputMaybe<Scalars["String"]>;
  facebookMessengerPsid?: InputMaybe<Scalars["String"]>;
  firstName?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  instagramBsid?: InputMaybe<Scalars["String"]>;
  instagramProfile?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  method: MethodType;
  oid?: InputMaybe<Scalars["String"]>;
  password?: InputMaybe<Scalars["String"]>;
  phone?: InputMaybe<Scalars["String"]>;
  position?: InputMaybe<Scalars["String"]>;
  rid?: InputMaybe<Scalars["String"]>;
  roleId?: InputMaybe<Scalars["String"]>;
  teamsIds?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  telegramUsername?: InputMaybe<Scalars["String"]>;
  thirdParty?: InputMaybe<Scalars["String"]>;
  type: UserType;
  userpicUrl?: InputMaybe<Scalars["String"]>;
  vkUserId?: InputMaybe<Scalars["String"]>;
  whatsappProfile?: InputMaybe<Scalars["String"]>;
  whatsappUsername?: InputMaybe<Scalars["String"]>;
};

export type MessageContentImageDimension = {
  __typename?: "MessageContentImageDimension";
  height: Scalars["Int"];
  width: Scalars["Int"];
};

export type MessageContentItemOutput = {
  __typename?: "MessageContentItemOutput";
  category?: Maybe<Scalars["String"]>;
  dimensions?: Maybe<MessageContentImageDimension>;
  fileExtension?: Maybe<Scalars["String"]>;
  fileName?: Maybe<Scalars["String"]>;
  fileSize?: Maybe<Scalars["Int"]>;
  mimeType?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["String"]>;
};

export type MessageOutput = {
  __typename?: "MessageOutput";
  channelId?: Maybe<Scalars["String"]>;
  channelTypeName?: Maybe<ChannelType>;
  chatRoomId?: Maybe<Scalars["String"]>;
  chatRoomStatus?: Maybe<Scalars["String"]>;
  isFromBot: Scalars["Boolean"];
  isFromClient: Scalars["Boolean"];
  localMessageId?: Maybe<Scalars["String"]>;
  messageAuthorId?: Maybe<Scalars["String"]>;
  messageChannelId?: Maybe<Scalars["String"]>;
  messageContent?: Maybe<Scalars["String"]>;
  messageCreatedDateTime?: Maybe<Scalars["String"]>;
  messageDeletedDateTime?: Maybe<Scalars["String"]>;
  messageId?: Maybe<Scalars["String"]>;
  messageIsDelivered?: Maybe<Scalars["Boolean"]>;
  messageIsRead?: Maybe<Scalars["Boolean"]>;
  messageIsSent?: Maybe<Scalars["Boolean"]>;
  messageMetadata?: Maybe<Scalars["String"]>;
  messageText?: Maybe<Scalars["String"]>;
  messageUpdatedDateTime?: Maybe<Scalars["String"]>;
};

export enum MessageStatusOutput {
  MessageIsDelivered = "MESSAGE_IS_DELIVERED",
  MessageIsRead = "MESSAGE_IS_READ",
  MessageIsSent = "MESSAGE_IS_SENT",
}

export enum MethodType {
  Create = "CREATE",
  Delete = "DELETE",
  Restore = "RESTORE",
  Update = "UPDATE",
}

export type Mutation = {
  __typename?: "Mutation";
  addSnippetToFavorites?: Maybe<ChatSnippetsFavoritesOutput>;
  createApiKey: ApiKey;
  createCalendar?: Maybe<CalendarOutput>;
  createCalendarEvent?: Maybe<BaseCalendarEventOutput>;
  createChatSnippet?: Maybe<ChatSnippetsOutput>;
  createChatSnippetsCategory?: Maybe<ChatSnippetsCategoriesOutput>;
  createCommentOnClient2?: Maybe<CommentOutput2>;
  createMessage?: Maybe<MessageOutput>;
  createTeam?: Maybe<TeamOutput>;
  createWABATemplate?: Maybe<WabaTemplateOutput>;
  deleteCalendarEvent?: Maybe<BaseCalendarEventOutput>;
  deleteChatSnippet?: Maybe<ChatSnippetsOutput>;
  deleteChatSnippetsCategory?: Maybe<ChatSnippetsCategoriesOutput>;
  deleteComment?: Maybe<MutationStatus>;
  deleteTeam?: Maybe<MutationStatus>;
  deleteWABATemplate?: Maybe<Status>;
  deployOrganization: DeployOrganizationOutput;
  duplicateBot?: Maybe<BotOutput>;
  makeApps?: Maybe<Array<Maybe<AppOutput>>>;
  makeBot?: Maybe<BotOutput>;
  makeChannel2?: Maybe<ChannelOutput2>;
  makeCustomField?: Maybe<CustomFieldOutput>;
  makeFlow?: Maybe<FlowOutput>;
  makeOrganization?: Maybe<Organization>;
  makeRooms2?: Maybe<MakeRooms2Output>;
  makeUser?: Maybe<UserOutput>;
  removeSnippetFromFavorite?: Maybe<ChatSnippetsFavoritesOutput>;
  renameApiKey: ApiKey;
  revokeApiKey: ApiKeyId;
  setNodesAndEdges?: Maybe<SetNodesAndEdgesOutput>;
  setPresenceStatus?: Maybe<UserPresenceStatusOutput>;
  switchOrganization?: Maybe<SwitchOrganizationOutput>;
  updateCalendarEvent?: Maybe<BaseCalendarEventOutput>;
  updateChatSnippet?: Maybe<ChatSnippetsOutput>;
  updateChatSnippetsCategory?: Maybe<ChatSnippetsCategoriesOutput>;
  updateMessageData?: Maybe<UpdateMessageDataOutput>;
  updateTeam?: Maybe<TeamOutput>;
};

export type MutationAddSnippetToFavoritesArgs = {
  snippetId: Scalars["String"];
  userId: Scalars["String"];
};

export type MutationCreateCalendarArgs = {
  params: CreateCalendarInput;
};

export type MutationCreateCalendarEventArgs = {
  params: CreateCalendarEventInput;
};

export type MutationCreateChatSnippetArgs = {
  categoryId?: InputMaybe<Scalars["String"]>;
  snippet: Scalars["String"];
  teamsIds?: InputMaybe<Array<Scalars["String"]>>;
  userId: Scalars["String"];
};

export type MutationCreateChatSnippetsCategoryArgs = {
  categoryName: Scalars["String"];
  parentId?: InputMaybe<Scalars["String"]>;
  userId: Scalars["String"];
};

export type MutationCreateCommentOnClient2Args = {
  input?: InputMaybe<CreateCommentOnClientInput>;
};

export type MutationCreateMessageArgs = {
  input?: InputMaybe<CreateChatRoomMessageInput>;
};

export type MutationCreateTeamArgs = {
  input: CreateTeamInput;
};

export type MutationCreateWabaTemplateArgs = {
  input: WabaTemplateInput;
};

export type MutationDeleteCalendarEventArgs = {
  params: DeleteCalendarInput;
};

export type MutationDeleteChatSnippetArgs = {
  snippetId: Scalars["String"];
  userId: Scalars["String"];
};

export type MutationDeleteChatSnippetsCategoryArgs = {
  categoryId: Scalars["String"];
  userId: Scalars["String"];
};

export type MutationDeleteCommentArgs = {
  commentId: Scalars["String"];
};

export type MutationDeleteTeamArgs = {
  teamId: Scalars["String"];
};

export type MutationDeleteWabaTemplateArgs = {
  channelId: Scalars["String"];
  name: Scalars["String"];
};

export type MutationDeployOrganizationArgs = {
  idToken?: InputMaybe<Scalars["String"]>;
  organizationCountry?: InputMaybe<Scalars["String"]>;
  organizationImageUrl?: InputMaybe<Scalars["String"]>;
  organizationLanguage?: InputMaybe<OrganizationLanguage>;
  organizationName?: InputMaybe<Scalars["String"]>;
  organizationPhone?: InputMaybe<Scalars["String"]>;
  organizationTimezone?: InputMaybe<Scalars["String"]>;
  rootEmail?: InputMaybe<Scalars["String"]>;
  rootPassword?: InputMaybe<Scalars["String"]>;
  teamName?: InputMaybe<Scalars["String"]>;
};

export type MutationDuplicateBotArgs = {
  id: Scalars["String"];
  newName?: InputMaybe<Scalars["String"]>;
};

export type MutationMakeAppsArgs = {
  input?: InputMaybe<Array<InputMaybe<MakeAppsInput>>>;
};

export type MutationMakeBotArgs = {
  input?: InputMaybe<MakeBotInput>;
};

export type MutationMakeChannel2Args = {
  input: MakeChannel2Input;
};

export type MutationMakeCustomFieldArgs = {
  input?: InputMaybe<MakeCustomFieldInput>;
};

export type MutationMakeFlowArgs = {
  input?: InputMaybe<MakeFlowInput>;
};

export type MutationMakeOrganizationArgs = {
  input?: InputMaybe<MakeOrganizationInput>;
};

export type MutationMakeRooms2Args = {
  input?: InputMaybe<MakeRooms2Input>;
};

export type MutationMakeUserArgs = {
  input?: InputMaybe<MakeUserInput>;
};

export type MutationRemoveSnippetFromFavoriteArgs = {
  snippetId: Scalars["String"];
  userId: Scalars["String"];
};

export type MutationRenameApiKeyArgs = {
  id: Scalars["ID"];
  name: Scalars["String"];
};

export type MutationRevokeApiKeyArgs = {
  id: Scalars["ID"];
};

export type MutationSetNodesAndEdgesArgs = {
  input?: InputMaybe<SetNodesAndEdgesInput>;
};

export type MutationSetPresenceStatusArgs = {
  isManual?: InputMaybe<Scalars["Boolean"]>;
  presenceStatus: PresenceStatus;
  userId: Scalars["String"];
};

export type MutationSwitchOrganizationArgs = {
  toOID: Scalars["String"];
};

export type MutationUpdateCalendarEventArgs = {
  params: UpdateCalendarEventInput;
};

export type MutationUpdateChatSnippetArgs = {
  categoryId?: InputMaybe<Scalars["String"]>;
  snippet?: InputMaybe<Scalars["String"]>;
  snippetId: Scalars["String"];
  teamsIds?: InputMaybe<Array<Scalars["String"]>>;
  userId: Scalars["String"];
};

export type MutationUpdateChatSnippetsCategoryArgs = {
  categoryId: Scalars["String"];
  categoryName?: InputMaybe<Scalars["String"]>;
  parentId?: InputMaybe<Scalars["String"]>;
  userId: Scalars["String"];
};

export type MutationUpdateMessageDataArgs = {
  input?: InputMaybe<UpdateMessageDataInput>;
};

export type MutationUpdateTeamArgs = {
  input: UpdateTeamInput;
};

export type MutationStatus = {
  __typename?: "MutationStatus";
  status?: Maybe<Scalars["String"]>;
};

export type NodeInput = {
  appId?: InputMaybe<Scalars["ID"]>;
  appInstalledId?: InputMaybe<Scalars["ID"]>;
  appVersionId?: InputMaybe<Scalars["ID"]>;
  botId?: InputMaybe<Scalars["String"]>;
  buttons?: InputMaybe<Array<InputMaybe<ButtonInput>>>;
  childFlowId?: InputMaybe<Scalars["String"]>;
  coordinates?: InputMaybe<Array<InputMaybe<Scalars["Float"]>>>;
  exceptionText?: InputMaybe<Scalars["String"]>;
  fileUrl?: InputMaybe<Scalars["String"]>;
  flowId?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  imageUrl?: InputMaybe<Scalars["String"]>;
  isDelete?: InputMaybe<Scalars["Boolean"]>;
  localId?: InputMaybe<Scalars["String"]>;
  nodeType?: InputMaybe<NodeType>;
  saveTo?: InputMaybe<SaveTo>;
  teamsIds?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  text?: InputMaybe<Scalars["String"]>;
};

export type NodeOutput = {
  __typename?: "NodeOutput";
  app?: Maybe<AppOutput>;
  appId?: Maybe<Scalars["ID"]>;
  appInstalledId?: Maybe<Scalars["ID"]>;
  appVersionId?: Maybe<Scalars["ID"]>;
  botId?: Maybe<Scalars["String"]>;
  buttons?: Maybe<Array<Maybe<ButtonOutput>>>;
  childFlowId?: Maybe<Scalars["String"]>;
  coordinates?: Maybe<Array<Maybe<Scalars["Float"]>>>;
  errors?: Maybe<Array<Maybe<ValidateBot>>>;
  exceptionText?: Maybe<Scalars["String"]>;
  fileUrl?: Maybe<Scalars["String"]>;
  flowId?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  imageUrl?: Maybe<Scalars["String"]>;
  isDelete?: Maybe<Scalars["Boolean"]>;
  isSaveApp?: Maybe<Scalars["Boolean"]>;
  localId?: Maybe<Scalars["String"]>;
  nodeType?: Maybe<NodeType>;
  saveTo?: Maybe<SaveTo>;
  teamsIds?: Maybe<Array<Maybe<Scalars["String"]>>>;
  text?: Maybe<Scalars["String"]>;
};

export enum NodeType {
  AskNWait = "ASK_N_WAIT",
  GoToAnotherFlow = "GO_TO_ANOTHER_FLOW",
  Init = "INIT",
  Integration = "INTEGRATION",
  Message = "MESSAGE",
  TransferToTeams = "TRANSFER_TO_TEAMS",
}

export type OperatorsAnalyticsOutput = {
  __typename?: "OperatorsAnalyticsOutput";
  acceptedByOperatorsByPeriodMap: Array<Maybe<AnalyticsHeatMapSeriesItem>>;
  avgAccepted: Scalars["Float"];
  avgAcceptedToFirstMessage: Scalars["Float"];
  avgChatsPerOperatorPerDay: Scalars["Float"];
  operatorsMetricsTable: Array<Maybe<AnalyticsOperatorsMetricsTableRow>>;
};

export type OperatorsPerformance = {
  __typename?: "OperatorsPerformance";
  series: Array<Maybe<OperatorsPerformanceSeries>>;
  updatedAt: Scalars["String"];
};

export type OperatorsPerformanceSeries = {
  __typename?: "OperatorsPerformanceSeries";
  data: Array<Maybe<Scalars["Int"]>>;
  name: AnalyticsOperatorsPerformanceSeriesStatus;
};

export type Organization = {
  __typename?: "Organization";
  activeMembersCount?: Maybe<Scalars["Int"]>;
  country?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  imageUrl?: Maybe<Scalars["String"]>;
  isCurrent: Scalars["Boolean"];
  isPendingDeletion?: Maybe<Scalars["Boolean"]>;
  language?: Maybe<OrganizationLanguage>;
  membersCount?: Maybe<Scalars["Int"]>;
  name: Scalars["String"];
  parentOID?: Maybe<Scalars["String"]>;
  phone?: Maybe<Scalars["String"]>;
  rid: Scalars["String"];
  tariff?: Maybe<OrganizationTariff>;
  timezone?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["String"]>;
};

export enum OrganizationLanguage {
  En = "en",
  Ru = "ru",
}

export enum OrganizationTariff {
  Enterprise = "enterprise",
  Free = "free",
  Individual = "individual",
  Trial = "trial",
}

export type OrganizationsOutput = {
  __typename?: "OrganizationsOutput";
  current?: Maybe<Organization>;
  parent?: Maybe<Organization>;
  subsidiaries: Array<Maybe<Organization>>;
};

export type PaginationInput = {
  itemsPerPage: Scalars["Int"];
  pageNumber: Scalars["Int"];
};

export type PaginationOutput = {
  __typename?: "PaginationOutput";
  itemsPerPage?: Maybe<Scalars["Int"]>;
  pageNumber?: Maybe<Scalars["Int"]>;
  totalItems?: Maybe<Scalars["Int"]>;
};

export type PaginationViaCursorInput = {
  limit: Scalars["Int"];
  nextCursor?: InputMaybe<Scalars["String"]>;
};

export type PaginationViaCursorOutput = {
  __typename?: "PaginationViaCursorOutput";
  nextCursor?: Maybe<Scalars["String"]>;
  totalItems: Scalars["Int"];
};

export type PeriodInput = {
  dateEnd: Scalars["String"];
  dateStart: Scalars["String"];
};

export enum PresenceStatus {
  Away = "AWAY",
  Offline = "OFFLINE",
  Online = "ONLINE",
}

export type Query = {
  __typename?: "Query";
  foundMessages?: Maybe<Array<MessageOutput>>;
  getAiBotTemplatesWithFields?: Maybe<
    Array<Maybe<AiBotTemplateWithFieldsOutput>>
  >;
  getAiBotUserSettings?: Maybe<AiBotUserSettingsOutput>;
  getApps?: Maybe<Array<Maybe<AppOutput>>>;
  getAvailableTwilioPhoneNumbers?: Maybe<Array<Maybe<Scalars["String"]>>>;
  getBot?: Maybe<BotOutput>;
  getBots?: Maybe<Array<Maybe<BotOutput>>>;
  getCalendar?: Maybe<Array<Maybe<CalendarOutput>>>;
  getCalendarEvents?: Maybe<Array<Maybe<CalendarEventOutput>>>;
  getChannels2: Array<Maybe<ChannelOutput2>>;
  getChatRoomHistory?: Maybe<RoomHistoryOutput>;
  getChatRoomMessages?: Maybe<GetChatRoomMessagesOutput>;
  getChatSnippets?: Maybe<GetChatSnippets>;
  getChatSnippetsCategories?: Maybe<GetChatSnippetsCategories>;
  getChatSnippetsFavorites?: Maybe<GetChatSnippets>;
  getChatsAnalytics?: Maybe<ChatsAnalyticsOutput>;
  getCommentsOnClient2?: Maybe<GetCommentsOnClientOutput2>;
  getContactsAnalytics?: Maybe<ContactsAnalyticsOutput>;
  getCustomFields?: Maybe<Array<Maybe<CustomFieldOutput>>>;
  getDownloads?: Maybe<Array<Maybe<FileItemOutput>>>;
  getEdges?: Maybe<Array<Maybe<EdgeOutput>>>;
  getFlow?: Maybe<FlowOutput>;
  getFlows?: Maybe<Array<Maybe<FlowOutput>>>;
  getGeneralAnalytics?: Maybe<GeneralAnalyticsOutput>;
  getHiddenApiKeys: Array<Maybe<ApiKey>>;
  getNodes?: Maybe<Array<Maybe<NodeOutput>>>;
  getOperatorsAnalytics?: Maybe<OperatorsAnalyticsOutput>;
  getOrganizations?: Maybe<OrganizationsOutput>;
  getRoles?: Maybe<Array<Maybe<RoleOutput>>>;
  getRoom2?: Maybe<GetRoom2Output>;
  getRoomHistory?: Maybe<Array<Maybe<GetRoomHistoryOutput>>>;
  getRooms2?: Maybe<GetRooms2Output>;
  getRoomsForMobile?: Maybe<GetRooms2Output>;
  getTeams?: Maybe<Array<Maybe<TeamOutput>>>;
  getTimezones: Array<Timezone>;
  getUser?: Maybe<UserOutput>;
  getUsers?: Maybe<GetUsersOutput>;
  getUsersPresenceStatuses?: Maybe<Array<Maybe<UserPresenceStatusOutput>>>;
  getWABATemplates?: Maybe<Array<Maybe<WabaTemplateOutput>>>;
  requestRoomsHistoryAsFile?: Maybe<FileItemOutput>;
  revealApiKey: ApiKeyShort;
  validateBot?: Maybe<Array<Maybe<ValidateBot>>>;
};

export type QueryGetAiBotTemplatesWithFieldsArgs = {
  templateId?: InputMaybe<Scalars["ID"]>;
};

export type QueryGetAiBotUserSettingsArgs = {
  botId: Scalars["ID"];
};

export type QueryGetAppsArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  isAvailableOnly?: InputMaybe<Scalars["Boolean"]>;
  isInstalledOnly?: InputMaybe<Scalars["Boolean"]>;
  types?: InputMaybe<Array<InputMaybe<AppType>>>;
};

export type QueryGetAvailableTwilioPhoneNumbersArgs = {
  areaCode: Scalars["Int"];
  limit?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetBotArgs = {
  id: Scalars["String"];
};

export type QueryGetBotsArgs = {
  status: BotStatus;
};

export type QueryGetChannels2Args = {
  isActiveOnly?: Scalars["Boolean"];
};

export type QueryGetChatRoomHistoryArgs = {
  roomId: Scalars["String"];
};

export type QueryGetChatRoomMessagesArgs = {
  chatRoomId: Scalars["String"];
  fetchSize: Scalars["Int"];
  pagingState?: InputMaybe<Scalars["String"]>;
};

export type QueryGetChatSnippetsArgs = {
  categoryId?: InputMaybe<Scalars["String"]>;
  snippetId?: InputMaybe<Scalars["String"]>;
  userId: Scalars["String"];
};

export type QueryGetChatSnippetsCategoriesArgs = {
  categoryId?: InputMaybe<Scalars["String"]>;
  parentId?: InputMaybe<Scalars["String"]>;
  userId?: InputMaybe<Scalars["String"]>;
};

export type QueryGetChatSnippetsFavoritesArgs = {
  userId: Scalars["String"];
};

export type QueryGetChatsAnalyticsArgs = {
  input?: InputMaybe<GetChatsAnalyticsInput>;
};

export type QueryGetCommentsOnClient2Args = {
  clientId: Scalars["String"];
};

export type QueryGetDownloadsArgs = {
  userId: Scalars["String"];
};

export type QueryGetEdgesArgs = {
  input?: InputMaybe<GetNodesAndEdgesInput>;
};

export type QueryGetFlowArgs = {
  input?: InputMaybe<GetFlowInput>;
};

export type QueryGetFlowsArgs = {
  botId: Scalars["String"];
};

export type QueryGetNodesArgs = {
  input?: InputMaybe<GetNodesAndEdgesInput>;
};

export type QueryGetOperatorsAnalyticsArgs = {
  input?: InputMaybe<GetOperatorsAnalyticsInput>;
};

export type QueryGetRoom2Args = {
  input: GetRoomInput;
};

export type QueryGetRoomHistoryArgs = {
  roomId: Scalars["String"];
};

export type QueryGetRooms2Args = {
  input: GetRoomsInput;
};

export type QueryGetRoomsForMobileArgs = {
  channelsTypes: Array<ChannelType>;
  filter?: InputMaybe<Scalars["String"]>;
  pagination?: InputMaybe<PaginationViaCursorInput>;
  status: RoomStatus;
};

export type QueryGetUserArgs = {
  userId?: InputMaybe<Scalars["String"]>;
};

export type QueryGetUsersArgs = {
  input: GetUsersInput;
};

export type QueryGetUsersPresenceStatusesArgs = {
  presenceStatus?: InputMaybe<PresenceStatus>;
};

export type QueryGetWabaTemplatesArgs = {
  channelId: Scalars["String"];
};

export type QueryRequestRoomsHistoryAsFileArgs = {
  dateEnd?: InputMaybe<Scalars["String"]>;
  dateStart?: InputMaybe<Scalars["String"]>;
  operatorId?: InputMaybe<Scalars["String"]>;
  reportType: ReportType;
  roomId?: InputMaybe<Scalars["String"]>;
  userId: Scalars["String"];
};

export type QueryRevealApiKeyArgs = {
  id: Scalars["ID"];
};

export type QueryValidateBotArgs = {
  id: Scalars["String"];
};

export enum ReportType {
  RoomHistory = "ROOM_HISTORY",
  RoomHistoryByOperator = "ROOM_HISTORY_BY_OPERATOR",
}

export type RoleOutput = {
  __typename?: "RoleOutput";
  roleDescription?: Maybe<Scalars["String"]>;
  roleId?: Maybe<Scalars["String"]>;
  rolePublicName?: Maybe<Scalars["String"]>;
  roleTechnicalName?: Maybe<Scalars["String"]>;
};

export enum RoomEventActionName {
  CreatedInvisible = "CREATED_INVISIBLE",
  CreatedNonAccepted = "CREATED_NON_ACCEPTED",
  FromAcceptedToAccepted = "FROM_ACCEPTED_TO_ACCEPTED",
  FromAcceptedToCompleted = "FROM_ACCEPTED_TO_COMPLETED",
  FromAcceptedToCompletedChannelDeletion = "FROM_ACCEPTED_TO_COMPLETED_CHANNEL_DELETION",
  FromAcceptedToCompletedTimeout = "FROM_ACCEPTED_TO_COMPLETED_TIMEOUT",
  FromAcceptedToNonAccepted = "FROM_ACCEPTED_TO_NON_ACCEPTED",
  FromCompletedToInvisible = "FROM_COMPLETED_TO_INVISIBLE",
  FromCompletedToNonAccepted = "FROM_COMPLETED_TO_NON_ACCEPTED",
  FromInvisibleToCompleted = "FROM_INVISIBLE_TO_COMPLETED",
  FromInvisibleToCompletedBotDeletion = "FROM_INVISIBLE_TO_COMPLETED_BOT_DELETION",
  FromInvisibleToCompletedChannelDeletion = "FROM_INVISIBLE_TO_COMPLETED_CHANNEL_DELETION",
  FromInvisibleToCompletedTimeout = "FROM_INVISIBLE_TO_COMPLETED_TIMEOUT",
  FromInvisibleToNonAccepted = "FROM_INVISIBLE_TO_NON_ACCEPTED",
  FromInvisibleToNonAcceptedException = "FROM_INVISIBLE_TO_NON_ACCEPTED_EXCEPTION",
  FromNonAcceptedToAccepted = "FROM_NON_ACCEPTED_TO_ACCEPTED",
  FromNonAcceptedToCompletedChannelDeletion = "FROM_NON_ACCEPTED_TO_COMPLETED_CHANNEL_DELETION",
  FromNonAcceptedToCompletedTimeout = "FROM_NON_ACCEPTED_TO_COMPLETED_TIMEOUT",
  FromNonAcceptedToNonAccepted = "FROM_NON_ACCEPTED_TO_NON_ACCEPTED",
  Unknown = "UNKNOWN",
}

export type RoomEventOutput = {
  __typename?: "RoomEventOutput";
  actionName: RoomEventActionName;
  eventCreatedAt: Scalars["String"];
  eventInitiator: Scalars["String"];
  fromTeams?: Maybe<Array<Maybe<Scalars["String"]>>>;
  fromUser?: Maybe<Scalars["String"]>;
  initiatorType: InitiatorType;
  isFilled?: Maybe<Scalars["Boolean"]>;
  text?: Maybe<Scalars["String"]>;
  toTeams?: Maybe<Array<Maybe<Scalars["String"]>>>;
  toUser?: Maybe<Scalars["String"]>;
};

export type RoomHistoryOutput = {
  __typename?: "RoomHistoryOutput";
  chatRoomHistory: Array<Maybe<RoomEventOutput>>;
};

export type RoomLinkOutput = {
  __typename?: "RoomLinkOutput";
  channelType?: Maybe<ChannelType>;
  lastTicketAt?: Maybe<Scalars["String"]>;
  roomId?: Maybe<Scalars["String"]>;
  roomStatus?: Maybe<RoomStatus>;
};

export type RoomOutput2 = {
  __typename?: "RoomOutput2";
  activeOperator?: Maybe<UserOutput>;
  channel: ChannelOutput2;
  client: UserOutput;
  id: Scalars["String"];
  isExpired: Scalars["Boolean"];
  lastClientMessageAt?: Maybe<Scalars["String"]>;
  lastMessageAt?: Maybe<Scalars["String"]>;
  lastMessageContent?: Maybe<LastMessageContentOutput>;
  lastTicketAt?: Maybe<Scalars["String"]>;
  operators: Array<Maybe<UserOutput>>;
  previousStatus?: Maybe<RoomStatus>;
  previousTeamsIds?: Maybe<Array<Maybe<Scalars["String"]>>>;
  status: RoomStatus;
  teamsIds: Array<Maybe<Scalars["String"]>>;
  unreadMessagesNumber: Scalars["Int"];
};

export enum RoomStatus {
  Accepted = "ACCEPTED",
  Completed = "COMPLETED",
  Invisible = "INVISIBLE",
  NonAccepted = "NON_ACCEPTED",
}

export enum SaveTo {
  Comments = "COMMENTS",
  Email = "EMAIL",
  FirstName = "FIRST_NAME",
  LastName = "LAST_NAME",
  Phone = "PHONE",
}

export type SetNodesAndEdgesInput = {
  edges?: InputMaybe<Array<InputMaybe<EdgeInput>>>;
  nodes?: InputMaybe<Array<InputMaybe<NodeInput>>>;
};

export type SetNodesAndEdgesOutput = {
  __typename?: "SetNodesAndEdgesOutput";
  edges?: Maybe<Array<Maybe<EdgeOutput>>>;
  nodes?: Maybe<Array<Maybe<NodeOutput>>>;
};

export enum SortBy {
  CreatedAt = "CREATED_AT",
  LastTicketAt = "LAST_TICKET_AT",
  Name = "NAME",
}

export enum SortOrder {
  Asc = "ASC",
  Desc = "DESC",
}

export type Status = {
  __typename?: "Status";
  status?: Maybe<Scalars["Int"]>;
};

export type Subscription = {
  __typename?: "Subscription";
  watchBots?: Maybe<WatchBotsOutput>;
  watchCalendarEvents?: Maybe<BaseCalendarEventOutput>;
  watchChannels?: Maybe<WatchChannelOutput>;
  watchComments?: Maybe<WatchCommentOutput>;
  watchContactsAnalytics?: Maybe<ContactsAnalyticsOutput>;
  watchCreateMessage?: Maybe<MessageOutput>;
  watchDeleteMessage?: Maybe<DeleteMessageOutput>;
  watchDownloads?: Maybe<FileItemOutput>;
  watchGeneralAnalytics?: Maybe<GeneralAnalyticsOutput>;
  watchRooms2?: Maybe<MakeRooms2Output>;
  watchUpdateMessageData?: Maybe<UpdateMessageDataOutput>;
  watchUsers?: Maybe<WatchUsersOutput>;
};

export type SubscriptionWatchCalendarEventsArgs = {
  calendarId: Scalars["String"];
};

export type SubscriptionWatchCreateMessageArgs = {
  channelType: ChannelType;
};

export type SubscriptionWatchDeleteMessageArgs = {
  channelType: ChannelType;
};

export type SubscriptionWatchDownloadsArgs = {
  userId: Scalars["String"];
};

export type SubscriptionWatchRooms2Args = {
  teamId: Scalars["String"];
  userId: Scalars["String"];
};

export type SubscriptionWatchUpdateMessageDataArgs = {
  channelType: ChannelType;
};

export enum Success {
  Ok = "ok",
}

export type SwitchOrganizationOutput = {
  __typename?: "SwitchOrganizationOutput";
  newOID: Scalars["String"];
  userId: Scalars["ID"];
};

export type TeamOutput = {
  __typename?: "TeamOutput";
  createdAt: Scalars["String"];
  deletedAt?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  name: Scalars["String"];
  updatedAt: Scalars["String"];
  users?: Maybe<Array<Maybe<UserOutput>>>;
};

export type Timezone = {
  __typename?: "Timezone";
  countryAlpha2Code: Scalars["String"];
  name: Scalars["String"];
};

export type UpdateCalendarEventInput = {
  event?: InputMaybe<CalendarEventInput>;
  eventId: Scalars["String"];
};

export type UpdateMessageDataInput = {
  chatRoomId: Scalars["String"];
  isClient: Scalars["Boolean"];
  messageStatus: MessageStatusOutput;
  messagesIds: Array<Scalars["String"]>;
};

export type UpdateMessageDataOutput = {
  __typename?: "UpdateMessageDataOutput";
  channelId?: Maybe<Scalars["String"]>;
  channelTypeName?: Maybe<ChannelType>;
  chatRoomId?: Maybe<Scalars["String"]>;
  chatRoomMessages?: Maybe<Array<Maybe<MessageOutput>>>;
  chatRoomStatus?: Maybe<Scalars["String"]>;
  isClient?: Maybe<Scalars["Boolean"]>;
  unreadMessagesNumber?: Maybe<Scalars["Int"]>;
};

export type UpdateTeamInput = {
  id: Scalars["String"];
  name: Scalars["String"];
  usersIds?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type UserCustomFieldOutput = {
  __typename?: "UserCustomFieldOutput";
  dataType: CustomFieldDataType;
  description?: Maybe<Scalars["String"]>;
  fieldId: Scalars["ID"];
  fieldName: Scalars["String"];
  fieldOrder: Scalars["Int"];
  value: Scalars["String"];
};

export type UserCustomFieldsInput = {
  fieldId: Scalars["ID"];
  value: Scalars["String"];
};

export type UserOutput = {
  __typename?: "UserOutput";
  common?: Maybe<CommonUserFields>;
  customFields?: Maybe<Array<Maybe<UserCustomFieldOutput>>>;
  facebookMessengerPsid?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  instagramBsid?: Maybe<Scalars["String"]>;
  instagramProfile?: Maybe<Scalars["String"]>;
  lastTicketAt?: Maybe<Scalars["String"]>;
  mailgunUserEmail?: Maybe<Scalars["String"]>;
  nylasUserEmail?: Maybe<Scalars["String"]>;
  onlineStatus?: Maybe<UserPresenceStatusOutput>;
  optionalName: Scalars["String"];
  position?: Maybe<Scalars["String"]>;
  role?: Maybe<RoleOutput>;
  roleId?: Maybe<Scalars["String"]>;
  roomsLinks?: Maybe<Array<Maybe<RoomLinkOutput>>>;
  teams?: Maybe<Array<Maybe<TeamOutput>>>;
  telegramUserId?: Maybe<Scalars["String"]>;
  telegramUsername?: Maybe<Scalars["String"]>;
  thirdParty?: Maybe<Scalars["String"]>;
  twilioSmsUserId?: Maybe<Scalars["String"]>;
  vkUserId?: Maybe<Scalars["String"]>;
  whatsappProfile?: Maybe<Scalars["String"]>;
  whatsappUsername?: Maybe<Scalars["String"]>;
};

export type UserPresenceStatusOutput = {
  __typename?: "UserPresenceStatusOutput";
  presenceStatus?: Maybe<PresenceStatus>;
  userId?: Maybe<Scalars["String"]>;
};

export enum UserType {
  Client = "CLIENT",
  Internal = "INTERNAL",
}

export type ValidateBot = {
  __typename?: "ValidateBot";
  errorMessage?: Maybe<Scalars["String"]>;
  flowId?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  isInCurrentFlow?: Maybe<Scalars["Boolean"]>;
  isInMain?: Maybe<Scalars["Boolean"]>;
  nodeType?: Maybe<NodeType>;
  statusCode?: Maybe<Scalars["Int"]>;
};

export type WabaTemplateButton = {
  __typename?: "WABATemplateButton";
  example?: Maybe<Array<Maybe<Scalars["String"]>>>;
  phoneNumber?: Maybe<Scalars["String"]>;
  text: Scalars["String"];
  type: WabaTemplateButtonType;
  url?: Maybe<Scalars["String"]>;
};

export type WabaTemplateButtonIn = {
  example?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  phoneNumber?: InputMaybe<Scalars["String"]>;
  text: Scalars["String"];
  type: WabaTemplateButtonType;
  url?: InputMaybe<Scalars["String"]>;
};

export enum WabaTemplateButtonType {
  CopyCode = "COPY_CODE",
  PhoneNumber = "PHONE_NUMBER",
  QuickReply = "QUICK_REPLY",
  Url = "URL",
}

export enum WabaTemplateCategory {
  Authentication = "AUTHENTICATION",
  Marketing = "MARKETING",
  Utility = "UTILITY",
}

export type WabaTemplateComponent = {
  __typename?: "WABATemplateComponent";
  buttons?: Maybe<Array<Maybe<WabaTemplateButton>>>;
  example?: Maybe<WabaTemplateComponentExampleComponent>;
  format?: Maybe<WabaTemplateComponentFormatType>;
  text?: Maybe<Scalars["String"]>;
  type: WabaTemplateComponentType;
};

export type WabaTemplateComponentExampleComponent = {
  __typename?: "WABATemplateComponentExampleComponent";
  bodyText?: Maybe<Array<Scalars["String"]>>;
  headerHandle?: Maybe<Array<Scalars["String"]>>;
  headerText?: Maybe<Array<Scalars["String"]>>;
};

export type WabaTemplateComponentExampleComponentIn = {
  bodyText?: InputMaybe<Array<Scalars["String"]>>;
  headerHandle?: InputMaybe<Array<Scalars["String"]>>;
  headerText?: InputMaybe<Array<Scalars["String"]>>;
};

export enum WabaTemplateComponentFormatType {
  Document = "DOCUMENT",
  Image = "IMAGE",
  Location = "LOCATION",
  Text = "TEXT",
  Video = "VIDEO",
}

export type WabaTemplateComponentIn = {
  buttons?: InputMaybe<Array<InputMaybe<WabaTemplateButtonIn>>>;
  example?: InputMaybe<WabaTemplateComponentExampleComponentIn>;
  format?: InputMaybe<WabaTemplateComponentFormatType>;
  text?: InputMaybe<Scalars["String"]>;
  type: WabaTemplateComponentType;
};

export enum WabaTemplateComponentType {
  Body = "BODY",
  Buttons = "BUTTONS",
  Footer = "FOOTER",
  Header = "HEADER",
}

export type WabaTemplateInput = {
  allowCategoryChange?: InputMaybe<Scalars["Boolean"]>;
  category: WabaTemplateCategory;
  channelId: Scalars["String"];
  components?: InputMaybe<Array<WabaTemplateComponentIn>>;
  language: WabaTemplateLanguage;
  name: Scalars["String"];
};

export enum WabaTemplateLanguage {
  Af = "af",
  Ar = "ar",
  Az = "az",
  Bg = "bg",
  Bn = "bn",
  Ca = "ca",
  Cs = "cs",
  Da = "da",
  De = "de",
  El = "el",
  En = "en",
  EnGb = "en_GB",
  EnUs = "en_US",
  Es = "es",
  EsAr = "es_AR",
  EsEs = "es_ES",
  EsMx = "es_MX",
  Et = "et",
  Fa = "fa",
  Fi = "fi",
  Fil = "fil",
  Fr = "fr",
  Ga = "ga",
  Gu = "gu",
  He = "he",
  Hi = "hi",
  Hr = "hr",
  Hu = "hu",
  Id = "id",
  It = "it",
  Ja = "ja",
  Kk = "kk",
  Kn = "kn",
  Ko = "ko",
  Lo = "lo",
  Lt = "lt",
  Lv = "lv",
  Mk = "mk",
  Mr = "mr",
  Ms = "ms",
  Nb = "nb",
  Nl = "nl",
  Pa = "pa",
  Pl = "pl",
  PtBr = "pt_BR",
  PtPt = "pt_PT",
  Ro = "ro",
  Ru = "ru",
  Sk = "sk",
  Sl = "sl",
  Sq = "sq",
  Sr = "sr",
  Sv = "sv",
  Sw = "sw",
  Ta = "ta",
  Te = "te",
  Th = "th",
  Tr = "tr",
  Uk = "uk",
  Ur = "ur",
  Uz = "uz",
  Vi = "vi",
  ZhCn = "zh_CN",
  ZhHk = "zh_HK",
  ZhTw = "zh_TW",
}

export type WabaTemplateOutput = {
  __typename?: "WABATemplateOutput";
  category: WabaTemplateCategory;
  components?: Maybe<Array<WabaTemplateComponent>>;
  language: WabaTemplateLanguage;
  name: Scalars["String"];
  namespace?: Maybe<Scalars["String"]>;
  rejected_reason?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
};

export type WatchBotsOutput = {
  __typename?: "WatchBotsOutput";
  bots?: Maybe<Array<Maybe<BotOutput>>>;
};

export type WatchChannelOutput = {
  __typename?: "WatchChannelOutput";
  channel?: Maybe<ChannelOutput2>;
  method?: Maybe<MethodType>;
};

export type WatchCommentOutput = {
  __typename?: "WatchCommentOutput";
  comment?: Maybe<CommentOutput2>;
  method?: Maybe<MethodType>;
};

export type WatchUsersOutput = {
  __typename?: "WatchUsersOutput";
  method?: Maybe<MethodType>;
  user?: Maybe<UserOutput>;
};

export type CoreRoleFieldsFragment = {
  __typename?: "RoleOutput";
  roleDescription?: string | null;
  roleId?: string | null;
  rolePublicName?: string | null;
  roleTechnicalName?: string | null;
};

export type CoreTeamFieldsFragment = {
  __typename?: "TeamOutput";
  id: string;
  name: string;
  createdAt: string;
  updatedAt: string;
  deletedAt?: string | null;
};

export type CoreRoomLinkFieldsFragment = {
  __typename?: "RoomLinkOutput";
  roomId?: string | null;
  roomStatus?: RoomStatus | null;
  channelType?: ChannelType | null;
  lastTicketAt?: string | null;
};

export type CoreUserFieldsFragment = {
  __typename?: "UserOutput";
  id: string;
  optionalName: string;
  common?: {
    __typename?: "CommonUserFields";
    createdAt: string;
    autoNickname: string;
    userpicUrl: string;
    firstName?: string | null;
    lastName?: string | null;
    email?: string | null;
    phone?: string | null;
  } | null;
};

export type CoreUserCustomFieldFieldsFragment = {
  __typename?: "UserCustomFieldOutput";
  fieldId: string;
  fieldName: string;
  dataType: CustomFieldDataType;
  description?: string | null;
  fieldOrder: number;
  value: string;
};

export type ClientFieldsFragment = {
  __typename?: "UserOutput";
  whatsappUsername?: string | null;
  whatsappProfile?: string | null;
  telegramUsername?: string | null;
  telegramUserId?: string | null;
  facebookMessengerPsid?: string | null;
  vkUserId?: string | null;
  instagramBsid?: string | null;
  instagramProfile?: string | null;
  thirdParty?: string | null;
  twilioSmsUserId?: string | null;
  nylasUserEmail?: string | null;
  mailgunUserEmail?: string | null;
  lastTicketAt?: string | null;
  roomsLinks?: Array<{
    __typename?: "RoomLinkOutput";
    roomId?: string | null;
    roomStatus?: RoomStatus | null;
    channelType?: ChannelType | null;
    lastTicketAt?: string | null;
  } | null> | null;
  customFields?: Array<{
    __typename?: "UserCustomFieldOutput";
    fieldId: string;
    fieldName: string;
    dataType: CustomFieldDataType;
    description?: string | null;
    fieldOrder: number;
    value: string;
  } | null> | null;
};

export type InternalUserFieldsFragment = {
  __typename?: "UserOutput";
  thirdParty?: string | null;
  roleId?: string | null;
  position?: string | null;
  onlineStatus?: {
    __typename?: "UserPresenceStatusOutput";
    userId?: string | null;
    presenceStatus?: PresenceStatus | null;
  } | null;
  teams?: Array<{
    __typename?: "TeamOutput";
    id: string;
    name: string;
    createdAt: string;
    updatedAt: string;
    deletedAt?: string | null;
  } | null> | null;
  role?: {
    __typename?: "RoleOutput";
    roleDescription?: string | null;
    roleId?: string | null;
    rolePublicName?: string | null;
    roleTechnicalName?: string | null;
  } | null;
};

export type CoreCommentsFieldsFragment = {
  __typename?: "CommentOutput2";
  comment?: string | null;
  commentId?: string | null;
  createdAt?: string | null;
  clientId?: string | null;
  operator?: {
    __typename?: "UserOutput";
    id: string;
    optionalName: string;
    common?: {
      __typename?: "CommonUserFields";
      createdAt: string;
      autoNickname: string;
      userpicUrl: string;
      firstName?: string | null;
      lastName?: string | null;
      email?: string | null;
      phone?: string | null;
    } | null;
  } | null;
};

export type ExtendedTeamFieldsFragment = {
  __typename?: "TeamOutput";
  id: string;
  name: string;
  createdAt: string;
  updatedAt: string;
  deletedAt?: string | null;
  users?: Array<{
    __typename?: "UserOutput";
    id: string;
    optionalName: string;
    thirdParty?: string | null;
    roleId?: string | null;
    position?: string | null;
    common?: {
      __typename?: "CommonUserFields";
      createdAt: string;
      autoNickname: string;
      userpicUrl: string;
      firstName?: string | null;
      lastName?: string | null;
      email?: string | null;
      phone?: string | null;
    } | null;
    onlineStatus?: {
      __typename?: "UserPresenceStatusOutput";
      userId?: string | null;
      presenceStatus?: PresenceStatus | null;
    } | null;
    teams?: Array<{
      __typename?: "TeamOutput";
      id: string;
      name: string;
      createdAt: string;
      updatedAt: string;
      deletedAt?: string | null;
    } | null> | null;
    role?: {
      __typename?: "RoleOutput";
      roleDescription?: string | null;
      roleId?: string | null;
      rolePublicName?: string | null;
      roleTechnicalName?: string | null;
    } | null;
  } | null> | null;
};

export type CoreBotFieldsFragment = {
  __typename?: "BotOutput";
  id: string;
  name?: string | null;
};

export type CoreFlowFieldsFragment = {
  __typename?: "FlowOutput";
  id?: string | null;
  botId?: string | null;
  name?: string | null;
  createdAt?: string | null;
  isMain?: boolean | null;
  updatedAt?: string | null;
};

export type CoreChannelFieldsFragment = {
  __typename?: "ChannelOutput2";
  id: string;
  publicId: string;
  name: string;
  link: string;
  type: ChannelType;
  status: ChannelStatus;
  createdAt: string;
  updatedAt: string;
  deletedAt?: string | null;
  bot?: { __typename?: "BotOutput"; id: string; name?: string | null } | null;
};

export type ExtendedChannelFieldsFragment = {
  __typename?: "ChannelOutput2";
  id: string;
  publicId: string;
  name: string;
  link: string;
  type: ChannelType;
  status: ChannelStatus;
  createdAt: string;
  updatedAt: string;
  deletedAt?: string | null;
  bot?: { __typename?: "BotOutput"; id: string; name?: string | null } | null;
};

export type ExtendedBotFieldsFragment = {
  __typename?: "BotOutput";
  isActive?: boolean | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  publishedAt?: string | null;
  status?: BotStatus | null;
  sessionsCount?: number | null;
  botType?: BotType | null;
  teamsIds?: Array<string | null> | null;
  id: string;
  name?: string | null;
  connectedChannels?: Array<{
    __typename?: "ChannelOutput2";
    id: string;
    publicId: string;
    name: string;
    link: string;
    type: ChannelType;
    status: ChannelStatus;
    createdAt: string;
    updatedAt: string;
    deletedAt?: string | null;
    bot?: { __typename?: "BotOutput"; id: string; name?: string | null } | null;
  } | null> | null;
};

export type CoreRoomFieldsFragment = {
  __typename?: "RoomOutput2";
  id: string;
  status: RoomStatus;
  previousStatus?: RoomStatus | null;
  teamsIds: Array<string | null>;
  previousTeamsIds?: Array<string | null> | null;
  lastMessageAt?: string | null;
  lastClientMessageAt?: string | null;
  lastTicketAt?: string | null;
  unreadMessagesNumber: number;
  isExpired: boolean;
  channel: {
    __typename?: "ChannelOutput2";
    id: string;
    publicId: string;
    name: string;
    link: string;
    type: ChannelType;
    status: ChannelStatus;
    createdAt: string;
    updatedAt: string;
    deletedAt?: string | null;
    bot?: { __typename?: "BotOutput"; id: string; name?: string | null } | null;
  };
  client: {
    __typename?: "UserOutput";
    id: string;
    optionalName: string;
    whatsappUsername?: string | null;
    whatsappProfile?: string | null;
    telegramUsername?: string | null;
    telegramUserId?: string | null;
    facebookMessengerPsid?: string | null;
    vkUserId?: string | null;
    instagramBsid?: string | null;
    instagramProfile?: string | null;
    thirdParty?: string | null;
    twilioSmsUserId?: string | null;
    nylasUserEmail?: string | null;
    mailgunUserEmail?: string | null;
    lastTicketAt?: string | null;
    common?: {
      __typename?: "CommonUserFields";
      createdAt: string;
      autoNickname: string;
      userpicUrl: string;
      firstName?: string | null;
      lastName?: string | null;
      email?: string | null;
      phone?: string | null;
    } | null;
    roomsLinks?: Array<{
      __typename?: "RoomLinkOutput";
      roomId?: string | null;
      roomStatus?: RoomStatus | null;
      channelType?: ChannelType | null;
      lastTicketAt?: string | null;
    } | null> | null;
    customFields?: Array<{
      __typename?: "UserCustomFieldOutput";
      fieldId: string;
      fieldName: string;
      dataType: CustomFieldDataType;
      description?: string | null;
      fieldOrder: number;
      value: string;
    } | null> | null;
  };
  activeOperator?: {
    __typename?: "UserOutput";
    id: string;
    optionalName: string;
    thirdParty?: string | null;
    roleId?: string | null;
    position?: string | null;
    common?: {
      __typename?: "CommonUserFields";
      createdAt: string;
      autoNickname: string;
      userpicUrl: string;
      firstName?: string | null;
      lastName?: string | null;
      email?: string | null;
      phone?: string | null;
    } | null;
    onlineStatus?: {
      __typename?: "UserPresenceStatusOutput";
      userId?: string | null;
      presenceStatus?: PresenceStatus | null;
    } | null;
    teams?: Array<{
      __typename?: "TeamOutput";
      id: string;
      name: string;
      createdAt: string;
      updatedAt: string;
      deletedAt?: string | null;
    } | null> | null;
    role?: {
      __typename?: "RoleOutput";
      roleDescription?: string | null;
      roleId?: string | null;
      rolePublicName?: string | null;
      roleTechnicalName?: string | null;
    } | null;
  } | null;
  operators: Array<{
    __typename?: "UserOutput";
    id: string;
    optionalName: string;
    thirdParty?: string | null;
    roleId?: string | null;
    position?: string | null;
    common?: {
      __typename?: "CommonUserFields";
      createdAt: string;
      autoNickname: string;
      userpicUrl: string;
      firstName?: string | null;
      lastName?: string | null;
      email?: string | null;
      phone?: string | null;
    } | null;
    onlineStatus?: {
      __typename?: "UserPresenceStatusOutput";
      userId?: string | null;
      presenceStatus?: PresenceStatus | null;
    } | null;
    teams?: Array<{
      __typename?: "TeamOutput";
      id: string;
      name: string;
      createdAt: string;
      updatedAt: string;
      deletedAt?: string | null;
    } | null> | null;
    role?: {
      __typename?: "RoleOutput";
      roleDescription?: string | null;
      roleId?: string | null;
      rolePublicName?: string | null;
      roleTechnicalName?: string | null;
    } | null;
  } | null>;
  lastMessageContent?: {
    __typename?: "LastMessageContentOutput";
    messageText?: string | null;
    messageContent?: Array<{
      __typename?: "MessageContentItemOutput";
      category?: string | null;
      fileExtension?: string | null;
      mimeType?: string | null;
      url?: string | null;
      fileName?: string | null;
      fileSize?: number | null;
      dimensions?: {
        __typename?: "MessageContentImageDimension";
        width: number;
        height: number;
      } | null;
    } | null> | null;
  } | null;
};

export type CoreMessageFieldsFragment = {
  __typename?: "MessageOutput";
  chatRoomId?: string | null;
  messageAuthorId?: string | null;
  messageChannelId?: string | null;
  messageContent?: string | null;
  messageCreatedDateTime?: string | null;
  messageDeletedDateTime?: string | null;
  messageId?: string | null;
  messageIsDelivered?: boolean | null;
  chatRoomStatus?: string | null;
  messageIsRead?: boolean | null;
  messageIsSent?: boolean | null;
  messageText?: string | null;
  messageUpdatedDateTime?: string | null;
  isFromClient: boolean;
  isFromBot: boolean;
};

export type CoreValidateBotFieldsFragment = {
  __typename?: "ValidateBot";
  id?: string | null;
  errorMessage?: string | null;
  statusCode?: number | null;
  nodeType?: NodeType | null;
  flowId?: string | null;
};

export type CoreButtonFieldsFragment = {
  __typename?: "ButtonOutput";
  id?: string | null;
  localId?: string | null;
  name?: string | null;
  nodeId?: string | null;
  localNodeId?: string | null;
  buttonOrder?: number | null;
  botId?: string | null;
};

export type CoreLanguageMapFieldsFragment = {
  __typename?: "LanguageMap";
  en: string;
  ru?: string | null;
  af?: string | null;
  sq?: string | null;
  ar?: string | null;
  az?: string | null;
  bn?: string | null;
  bg?: string | null;
  ca?: string | null;
  zh?: string | null;
  hr?: string | null;
  cs?: string | null;
  da?: string | null;
  nl?: string | null;
  et?: string | null;
  fil?: string | null;
  fi?: string | null;
  fr?: string | null;
  ka?: string | null;
  de?: string | null;
  el?: string | null;
  gu?: string | null;
  ha?: string | null;
  he?: string | null;
  hi?: string | null;
  hu?: string | null;
  id?: string | null;
  ga?: string | null;
  it?: string | null;
  ja?: string | null;
  kn?: string | null;
  kk?: string | null;
  rw?: string | null;
  ko?: string | null;
  ky?: string | null;
  lo?: string | null;
  lv?: string | null;
  lt?: string | null;
  mk?: string | null;
  ms?: string | null;
  ml?: string | null;
  mr?: string | null;
  nb?: string | null;
  fa?: string | null;
  pl?: string | null;
  pt?: string | null;
  pa?: string | null;
  ro?: string | null;
  sr?: string | null;
  sk?: string | null;
  sl?: string | null;
  es?: string | null;
  sw?: string | null;
  sv?: string | null;
  ta?: string | null;
  te?: string | null;
  th?: string | null;
  tr?: string | null;
  uk?: string | null;
  ur?: string | null;
  uz?: string | null;
  vi?: string | null;
  zu?: string | null;
};

export type CoreAppSettingsInnerFieldFragment = {
  __typename?: "AppSettingsFieldInner";
  tg: string;
  dt: AppFieldSettingsDataTypeType;
  v: string;
};

export type CoreAppSettingsInnerGroupFragment = {
  __typename?: "AppSettingsGroupInner";
  g: AppFieldsGroupType;
  c: Array<{
    __typename?: "AppSettingsFieldInner";
    tg: string;
    dt: AppFieldSettingsDataTypeType;
    v: string;
  }>;
};

export type CoreAppSettingsFieldFragment = {
  __typename?: "AppSettingsField";
  tg: string;
  dt: AppFieldSettingsDataTypeType;
  v: string;
  c?: Array<
    | {
        __typename?: "AppSettingsFieldInner";
        tg: string;
        dt: AppFieldSettingsDataTypeType;
        v: string;
      }
    | {
        __typename?: "AppSettingsGroupInner";
        g: AppFieldsGroupType;
        c: Array<{
          __typename?: "AppSettingsFieldInner";
          tg: string;
          dt: AppFieldSettingsDataTypeType;
          v: string;
        }>;
      }
  > | null;
};

export type CoreAppSettingsGroupFragment = {
  __typename?: "AppSettingsGroup";
  g: AppFieldsGroupType;
  c: Array<{
    __typename?: "AppSettingsField";
    tg: string;
    dt: AppFieldSettingsDataTypeType;
    v: string;
    c?: Array<
      | {
          __typename?: "AppSettingsFieldInner";
          tg: string;
          dt: AppFieldSettingsDataTypeType;
          v: string;
        }
      | {
          __typename?: "AppSettingsGroupInner";
          g: AppFieldsGroupType;
          c: Array<{
            __typename?: "AppSettingsFieldInner";
            tg: string;
            dt: AppFieldSettingsDataTypeType;
            v: string;
          }>;
        }
    > | null;
  }>;
};

export type CoreInstallationsFieldFragment = {
  __typename?: "AppInstallation";
  id: string;
  createdAt: string;
  method?: MethodType | null;
  localId?: string | null;
  settings?: Array<{
    __typename?: "AppSettingsGroup";
    g: AppFieldsGroupType;
    c: Array<{
      __typename?: "AppSettingsField";
      tg: string;
      dt: AppFieldSettingsDataTypeType;
      v: string;
      c?: Array<
        | {
            __typename?: "AppSettingsFieldInner";
            tg: string;
            dt: AppFieldSettingsDataTypeType;
            v: string;
          }
        | {
            __typename?: "AppSettingsGroupInner";
            g: AppFieldsGroupType;
            c: Array<{
              __typename?: "AppSettingsFieldInner";
              tg: string;
              dt: AppFieldSettingsDataTypeType;
              v: string;
            }>;
          }
      > | null;
    }>;
  } | null> | null;
};

export type CoreAppInnerFieldFragment = {
  __typename?: "AppFieldInner";
  t: AppFieldTypeType;
  tg: string;
  dt?: AppFieldDataTypeType | null;
  dts?: Array<AppFieldDataTypesType> | null;
  r?: boolean | null;
  mn?: number | null;
  mx?: number | null;
  v?: string | null;
  n: {
    __typename?: "LanguageMap";
    en: string;
    ru?: string | null;
    af?: string | null;
    sq?: string | null;
    ar?: string | null;
    az?: string | null;
    bn?: string | null;
    bg?: string | null;
    ca?: string | null;
    zh?: string | null;
    hr?: string | null;
    cs?: string | null;
    da?: string | null;
    nl?: string | null;
    et?: string | null;
    fil?: string | null;
    fi?: string | null;
    fr?: string | null;
    ka?: string | null;
    de?: string | null;
    el?: string | null;
    gu?: string | null;
    ha?: string | null;
    he?: string | null;
    hi?: string | null;
    hu?: string | null;
    id?: string | null;
    ga?: string | null;
    it?: string | null;
    ja?: string | null;
    kn?: string | null;
    kk?: string | null;
    rw?: string | null;
    ko?: string | null;
    ky?: string | null;
    lo?: string | null;
    lv?: string | null;
    lt?: string | null;
    mk?: string | null;
    ms?: string | null;
    ml?: string | null;
    mr?: string | null;
    nb?: string | null;
    fa?: string | null;
    pl?: string | null;
    pt?: string | null;
    pa?: string | null;
    ro?: string | null;
    sr?: string | null;
    sk?: string | null;
    sl?: string | null;
    es?: string | null;
    sw?: string | null;
    sv?: string | null;
    ta?: string | null;
    te?: string | null;
    th?: string | null;
    tr?: string | null;
    uk?: string | null;
    ur?: string | null;
    uz?: string | null;
    vi?: string | null;
    zu?: string | null;
  };
  p?: {
    __typename?: "LanguageMap";
    en: string;
    ru?: string | null;
    af?: string | null;
    sq?: string | null;
    ar?: string | null;
    az?: string | null;
    bn?: string | null;
    bg?: string | null;
    ca?: string | null;
    zh?: string | null;
    hr?: string | null;
    cs?: string | null;
    da?: string | null;
    nl?: string | null;
    et?: string | null;
    fil?: string | null;
    fi?: string | null;
    fr?: string | null;
    ka?: string | null;
    de?: string | null;
    el?: string | null;
    gu?: string | null;
    ha?: string | null;
    he?: string | null;
    hi?: string | null;
    hu?: string | null;
    id?: string | null;
    ga?: string | null;
    it?: string | null;
    ja?: string | null;
    kn?: string | null;
    kk?: string | null;
    rw?: string | null;
    ko?: string | null;
    ky?: string | null;
    lo?: string | null;
    lv?: string | null;
    lt?: string | null;
    mk?: string | null;
    ms?: string | null;
    ml?: string | null;
    mr?: string | null;
    nb?: string | null;
    fa?: string | null;
    pl?: string | null;
    pt?: string | null;
    pa?: string | null;
    ro?: string | null;
    sr?: string | null;
    sk?: string | null;
    sl?: string | null;
    es?: string | null;
    sw?: string | null;
    sv?: string | null;
    ta?: string | null;
    te?: string | null;
    th?: string | null;
    tr?: string | null;
    uk?: string | null;
    ur?: string | null;
    uz?: string | null;
    vi?: string | null;
    zu?: string | null;
  } | null;
  d?: {
    __typename?: "LanguageMap";
    en: string;
    ru?: string | null;
    af?: string | null;
    sq?: string | null;
    ar?: string | null;
    az?: string | null;
    bn?: string | null;
    bg?: string | null;
    ca?: string | null;
    zh?: string | null;
    hr?: string | null;
    cs?: string | null;
    da?: string | null;
    nl?: string | null;
    et?: string | null;
    fil?: string | null;
    fi?: string | null;
    fr?: string | null;
    ka?: string | null;
    de?: string | null;
    el?: string | null;
    gu?: string | null;
    ha?: string | null;
    he?: string | null;
    hi?: string | null;
    hu?: string | null;
    id?: string | null;
    ga?: string | null;
    it?: string | null;
    ja?: string | null;
    kn?: string | null;
    kk?: string | null;
    rw?: string | null;
    ko?: string | null;
    ky?: string | null;
    lo?: string | null;
    lv?: string | null;
    lt?: string | null;
    mk?: string | null;
    ms?: string | null;
    ml?: string | null;
    mr?: string | null;
    nb?: string | null;
    fa?: string | null;
    pl?: string | null;
    pt?: string | null;
    pa?: string | null;
    ro?: string | null;
    sr?: string | null;
    sk?: string | null;
    sl?: string | null;
    es?: string | null;
    sw?: string | null;
    sv?: string | null;
    ta?: string | null;
    te?: string | null;
    th?: string | null;
    tr?: string | null;
    uk?: string | null;
    ur?: string | null;
    uz?: string | null;
    vi?: string | null;
    zu?: string | null;
  } | null;
};

export type CoreAppInnerGroupFragment = {
  __typename?: "AppFieldsGroupInner";
  g: AppFieldsGroupType;
  c: Array<{
    __typename?: "AppFieldInner";
    t: AppFieldTypeType;
    tg: string;
    dt?: AppFieldDataTypeType | null;
    dts?: Array<AppFieldDataTypesType> | null;
    r?: boolean | null;
    mn?: number | null;
    mx?: number | null;
    v?: string | null;
    n: {
      __typename?: "LanguageMap";
      en: string;
      ru?: string | null;
      af?: string | null;
      sq?: string | null;
      ar?: string | null;
      az?: string | null;
      bn?: string | null;
      bg?: string | null;
      ca?: string | null;
      zh?: string | null;
      hr?: string | null;
      cs?: string | null;
      da?: string | null;
      nl?: string | null;
      et?: string | null;
      fil?: string | null;
      fi?: string | null;
      fr?: string | null;
      ka?: string | null;
      de?: string | null;
      el?: string | null;
      gu?: string | null;
      ha?: string | null;
      he?: string | null;
      hi?: string | null;
      hu?: string | null;
      id?: string | null;
      ga?: string | null;
      it?: string | null;
      ja?: string | null;
      kn?: string | null;
      kk?: string | null;
      rw?: string | null;
      ko?: string | null;
      ky?: string | null;
      lo?: string | null;
      lv?: string | null;
      lt?: string | null;
      mk?: string | null;
      ms?: string | null;
      ml?: string | null;
      mr?: string | null;
      nb?: string | null;
      fa?: string | null;
      pl?: string | null;
      pt?: string | null;
      pa?: string | null;
      ro?: string | null;
      sr?: string | null;
      sk?: string | null;
      sl?: string | null;
      es?: string | null;
      sw?: string | null;
      sv?: string | null;
      ta?: string | null;
      te?: string | null;
      th?: string | null;
      tr?: string | null;
      uk?: string | null;
      ur?: string | null;
      uz?: string | null;
      vi?: string | null;
      zu?: string | null;
    };
    p?: {
      __typename?: "LanguageMap";
      en: string;
      ru?: string | null;
      af?: string | null;
      sq?: string | null;
      ar?: string | null;
      az?: string | null;
      bn?: string | null;
      bg?: string | null;
      ca?: string | null;
      zh?: string | null;
      hr?: string | null;
      cs?: string | null;
      da?: string | null;
      nl?: string | null;
      et?: string | null;
      fil?: string | null;
      fi?: string | null;
      fr?: string | null;
      ka?: string | null;
      de?: string | null;
      el?: string | null;
      gu?: string | null;
      ha?: string | null;
      he?: string | null;
      hi?: string | null;
      hu?: string | null;
      id?: string | null;
      ga?: string | null;
      it?: string | null;
      ja?: string | null;
      kn?: string | null;
      kk?: string | null;
      rw?: string | null;
      ko?: string | null;
      ky?: string | null;
      lo?: string | null;
      lv?: string | null;
      lt?: string | null;
      mk?: string | null;
      ms?: string | null;
      ml?: string | null;
      mr?: string | null;
      nb?: string | null;
      fa?: string | null;
      pl?: string | null;
      pt?: string | null;
      pa?: string | null;
      ro?: string | null;
      sr?: string | null;
      sk?: string | null;
      sl?: string | null;
      es?: string | null;
      sw?: string | null;
      sv?: string | null;
      ta?: string | null;
      te?: string | null;
      th?: string | null;
      tr?: string | null;
      uk?: string | null;
      ur?: string | null;
      uz?: string | null;
      vi?: string | null;
      zu?: string | null;
    } | null;
    d?: {
      __typename?: "LanguageMap";
      en: string;
      ru?: string | null;
      af?: string | null;
      sq?: string | null;
      ar?: string | null;
      az?: string | null;
      bn?: string | null;
      bg?: string | null;
      ca?: string | null;
      zh?: string | null;
      hr?: string | null;
      cs?: string | null;
      da?: string | null;
      nl?: string | null;
      et?: string | null;
      fil?: string | null;
      fi?: string | null;
      fr?: string | null;
      ka?: string | null;
      de?: string | null;
      el?: string | null;
      gu?: string | null;
      ha?: string | null;
      he?: string | null;
      hi?: string | null;
      hu?: string | null;
      id?: string | null;
      ga?: string | null;
      it?: string | null;
      ja?: string | null;
      kn?: string | null;
      kk?: string | null;
      rw?: string | null;
      ko?: string | null;
      ky?: string | null;
      lo?: string | null;
      lv?: string | null;
      lt?: string | null;
      mk?: string | null;
      ms?: string | null;
      ml?: string | null;
      mr?: string | null;
      nb?: string | null;
      fa?: string | null;
      pl?: string | null;
      pt?: string | null;
      pa?: string | null;
      ro?: string | null;
      sr?: string | null;
      sk?: string | null;
      sl?: string | null;
      es?: string | null;
      sw?: string | null;
      sv?: string | null;
      ta?: string | null;
      te?: string | null;
      th?: string | null;
      tr?: string | null;
      uk?: string | null;
      ur?: string | null;
      uz?: string | null;
      vi?: string | null;
      zu?: string | null;
    } | null;
  }>;
};

type CoreAppFieldDetails_AppFieldInner_Fragment = {
  __typename?: "AppFieldInner";
  t: AppFieldTypeType;
  tg: string;
  dt?: AppFieldDataTypeType | null;
  dts?: Array<AppFieldDataTypesType> | null;
  r?: boolean | null;
  mn?: number | null;
  mx?: number | null;
  v?: string | null;
  n: {
    __typename?: "LanguageMap";
    en: string;
    ru?: string | null;
    af?: string | null;
    sq?: string | null;
    ar?: string | null;
    az?: string | null;
    bn?: string | null;
    bg?: string | null;
    ca?: string | null;
    zh?: string | null;
    hr?: string | null;
    cs?: string | null;
    da?: string | null;
    nl?: string | null;
    et?: string | null;
    fil?: string | null;
    fi?: string | null;
    fr?: string | null;
    ka?: string | null;
    de?: string | null;
    el?: string | null;
    gu?: string | null;
    ha?: string | null;
    he?: string | null;
    hi?: string | null;
    hu?: string | null;
    id?: string | null;
    ga?: string | null;
    it?: string | null;
    ja?: string | null;
    kn?: string | null;
    kk?: string | null;
    rw?: string | null;
    ko?: string | null;
    ky?: string | null;
    lo?: string | null;
    lv?: string | null;
    lt?: string | null;
    mk?: string | null;
    ms?: string | null;
    ml?: string | null;
    mr?: string | null;
    nb?: string | null;
    fa?: string | null;
    pl?: string | null;
    pt?: string | null;
    pa?: string | null;
    ro?: string | null;
    sr?: string | null;
    sk?: string | null;
    sl?: string | null;
    es?: string | null;
    sw?: string | null;
    sv?: string | null;
    ta?: string | null;
    te?: string | null;
    th?: string | null;
    tr?: string | null;
    uk?: string | null;
    ur?: string | null;
    uz?: string | null;
    vi?: string | null;
    zu?: string | null;
  };
  p?: {
    __typename?: "LanguageMap";
    en: string;
    ru?: string | null;
    af?: string | null;
    sq?: string | null;
    ar?: string | null;
    az?: string | null;
    bn?: string | null;
    bg?: string | null;
    ca?: string | null;
    zh?: string | null;
    hr?: string | null;
    cs?: string | null;
    da?: string | null;
    nl?: string | null;
    et?: string | null;
    fil?: string | null;
    fi?: string | null;
    fr?: string | null;
    ka?: string | null;
    de?: string | null;
    el?: string | null;
    gu?: string | null;
    ha?: string | null;
    he?: string | null;
    hi?: string | null;
    hu?: string | null;
    id?: string | null;
    ga?: string | null;
    it?: string | null;
    ja?: string | null;
    kn?: string | null;
    kk?: string | null;
    rw?: string | null;
    ko?: string | null;
    ky?: string | null;
    lo?: string | null;
    lv?: string | null;
    lt?: string | null;
    mk?: string | null;
    ms?: string | null;
    ml?: string | null;
    mr?: string | null;
    nb?: string | null;
    fa?: string | null;
    pl?: string | null;
    pt?: string | null;
    pa?: string | null;
    ro?: string | null;
    sr?: string | null;
    sk?: string | null;
    sl?: string | null;
    es?: string | null;
    sw?: string | null;
    sv?: string | null;
    ta?: string | null;
    te?: string | null;
    th?: string | null;
    tr?: string | null;
    uk?: string | null;
    ur?: string | null;
    uz?: string | null;
    vi?: string | null;
    zu?: string | null;
  } | null;
  d?: {
    __typename?: "LanguageMap";
    en: string;
    ru?: string | null;
    af?: string | null;
    sq?: string | null;
    ar?: string | null;
    az?: string | null;
    bn?: string | null;
    bg?: string | null;
    ca?: string | null;
    zh?: string | null;
    hr?: string | null;
    cs?: string | null;
    da?: string | null;
    nl?: string | null;
    et?: string | null;
    fil?: string | null;
    fi?: string | null;
    fr?: string | null;
    ka?: string | null;
    de?: string | null;
    el?: string | null;
    gu?: string | null;
    ha?: string | null;
    he?: string | null;
    hi?: string | null;
    hu?: string | null;
    id?: string | null;
    ga?: string | null;
    it?: string | null;
    ja?: string | null;
    kn?: string | null;
    kk?: string | null;
    rw?: string | null;
    ko?: string | null;
    ky?: string | null;
    lo?: string | null;
    lv?: string | null;
    lt?: string | null;
    mk?: string | null;
    ms?: string | null;
    ml?: string | null;
    mr?: string | null;
    nb?: string | null;
    fa?: string | null;
    pl?: string | null;
    pt?: string | null;
    pa?: string | null;
    ro?: string | null;
    sr?: string | null;
    sk?: string | null;
    sl?: string | null;
    es?: string | null;
    sw?: string | null;
    sv?: string | null;
    ta?: string | null;
    te?: string | null;
    th?: string | null;
    tr?: string | null;
    uk?: string | null;
    ur?: string | null;
    uz?: string | null;
    vi?: string | null;
    zu?: string | null;
  } | null;
};

type CoreAppFieldDetails_AppFieldsGroupInner_Fragment = {
  __typename?: "AppFieldsGroupInner";
  g: AppFieldsGroupType;
  c: Array<{
    __typename?: "AppFieldInner";
    t: AppFieldTypeType;
    tg: string;
    dt?: AppFieldDataTypeType | null;
    dts?: Array<AppFieldDataTypesType> | null;
    r?: boolean | null;
    mn?: number | null;
    mx?: number | null;
    v?: string | null;
    n: {
      __typename?: "LanguageMap";
      en: string;
      ru?: string | null;
      af?: string | null;
      sq?: string | null;
      ar?: string | null;
      az?: string | null;
      bn?: string | null;
      bg?: string | null;
      ca?: string | null;
      zh?: string | null;
      hr?: string | null;
      cs?: string | null;
      da?: string | null;
      nl?: string | null;
      et?: string | null;
      fil?: string | null;
      fi?: string | null;
      fr?: string | null;
      ka?: string | null;
      de?: string | null;
      el?: string | null;
      gu?: string | null;
      ha?: string | null;
      he?: string | null;
      hi?: string | null;
      hu?: string | null;
      id?: string | null;
      ga?: string | null;
      it?: string | null;
      ja?: string | null;
      kn?: string | null;
      kk?: string | null;
      rw?: string | null;
      ko?: string | null;
      ky?: string | null;
      lo?: string | null;
      lv?: string | null;
      lt?: string | null;
      mk?: string | null;
      ms?: string | null;
      ml?: string | null;
      mr?: string | null;
      nb?: string | null;
      fa?: string | null;
      pl?: string | null;
      pt?: string | null;
      pa?: string | null;
      ro?: string | null;
      sr?: string | null;
      sk?: string | null;
      sl?: string | null;
      es?: string | null;
      sw?: string | null;
      sv?: string | null;
      ta?: string | null;
      te?: string | null;
      th?: string | null;
      tr?: string | null;
      uk?: string | null;
      ur?: string | null;
      uz?: string | null;
      vi?: string | null;
      zu?: string | null;
    };
    p?: {
      __typename?: "LanguageMap";
      en: string;
      ru?: string | null;
      af?: string | null;
      sq?: string | null;
      ar?: string | null;
      az?: string | null;
      bn?: string | null;
      bg?: string | null;
      ca?: string | null;
      zh?: string | null;
      hr?: string | null;
      cs?: string | null;
      da?: string | null;
      nl?: string | null;
      et?: string | null;
      fil?: string | null;
      fi?: string | null;
      fr?: string | null;
      ka?: string | null;
      de?: string | null;
      el?: string | null;
      gu?: string | null;
      ha?: string | null;
      he?: string | null;
      hi?: string | null;
      hu?: string | null;
      id?: string | null;
      ga?: string | null;
      it?: string | null;
      ja?: string | null;
      kn?: string | null;
      kk?: string | null;
      rw?: string | null;
      ko?: string | null;
      ky?: string | null;
      lo?: string | null;
      lv?: string | null;
      lt?: string | null;
      mk?: string | null;
      ms?: string | null;
      ml?: string | null;
      mr?: string | null;
      nb?: string | null;
      fa?: string | null;
      pl?: string | null;
      pt?: string | null;
      pa?: string | null;
      ro?: string | null;
      sr?: string | null;
      sk?: string | null;
      sl?: string | null;
      es?: string | null;
      sw?: string | null;
      sv?: string | null;
      ta?: string | null;
      te?: string | null;
      th?: string | null;
      tr?: string | null;
      uk?: string | null;
      ur?: string | null;
      uz?: string | null;
      vi?: string | null;
      zu?: string | null;
    } | null;
    d?: {
      __typename?: "LanguageMap";
      en: string;
      ru?: string | null;
      af?: string | null;
      sq?: string | null;
      ar?: string | null;
      az?: string | null;
      bn?: string | null;
      bg?: string | null;
      ca?: string | null;
      zh?: string | null;
      hr?: string | null;
      cs?: string | null;
      da?: string | null;
      nl?: string | null;
      et?: string | null;
      fil?: string | null;
      fi?: string | null;
      fr?: string | null;
      ka?: string | null;
      de?: string | null;
      el?: string | null;
      gu?: string | null;
      ha?: string | null;
      he?: string | null;
      hi?: string | null;
      hu?: string | null;
      id?: string | null;
      ga?: string | null;
      it?: string | null;
      ja?: string | null;
      kn?: string | null;
      kk?: string | null;
      rw?: string | null;
      ko?: string | null;
      ky?: string | null;
      lo?: string | null;
      lv?: string | null;
      lt?: string | null;
      mk?: string | null;
      ms?: string | null;
      ml?: string | null;
      mr?: string | null;
      nb?: string | null;
      fa?: string | null;
      pl?: string | null;
      pt?: string | null;
      pa?: string | null;
      ro?: string | null;
      sr?: string | null;
      sk?: string | null;
      sl?: string | null;
      es?: string | null;
      sw?: string | null;
      sv?: string | null;
      ta?: string | null;
      te?: string | null;
      th?: string | null;
      tr?: string | null;
      uk?: string | null;
      ur?: string | null;
      uz?: string | null;
      vi?: string | null;
      zu?: string | null;
    } | null;
  }>;
};

export type CoreAppFieldDetailsFragment =
  | CoreAppFieldDetails_AppFieldInner_Fragment
  | CoreAppFieldDetails_AppFieldsGroupInner_Fragment;

export type CoreAppFieldFragment = {
  __typename?: "AppField";
  t: AppFieldTypeType;
  tg: string;
  dt?: AppFieldDataTypeType | null;
  dts?: Array<AppFieldDataTypesType> | null;
  r?: boolean | null;
  mn?: number | null;
  mx?: number | null;
  v?: string | null;
  n: {
    __typename?: "LanguageMap";
    en: string;
    ru?: string | null;
    af?: string | null;
    sq?: string | null;
    ar?: string | null;
    az?: string | null;
    bn?: string | null;
    bg?: string | null;
    ca?: string | null;
    zh?: string | null;
    hr?: string | null;
    cs?: string | null;
    da?: string | null;
    nl?: string | null;
    et?: string | null;
    fil?: string | null;
    fi?: string | null;
    fr?: string | null;
    ka?: string | null;
    de?: string | null;
    el?: string | null;
    gu?: string | null;
    ha?: string | null;
    he?: string | null;
    hi?: string | null;
    hu?: string | null;
    id?: string | null;
    ga?: string | null;
    it?: string | null;
    ja?: string | null;
    kn?: string | null;
    kk?: string | null;
    rw?: string | null;
    ko?: string | null;
    ky?: string | null;
    lo?: string | null;
    lv?: string | null;
    lt?: string | null;
    mk?: string | null;
    ms?: string | null;
    ml?: string | null;
    mr?: string | null;
    nb?: string | null;
    fa?: string | null;
    pl?: string | null;
    pt?: string | null;
    pa?: string | null;
    ro?: string | null;
    sr?: string | null;
    sk?: string | null;
    sl?: string | null;
    es?: string | null;
    sw?: string | null;
    sv?: string | null;
    ta?: string | null;
    te?: string | null;
    th?: string | null;
    tr?: string | null;
    uk?: string | null;
    ur?: string | null;
    uz?: string | null;
    vi?: string | null;
    zu?: string | null;
  };
  p?: {
    __typename?: "LanguageMap";
    en: string;
    ru?: string | null;
    af?: string | null;
    sq?: string | null;
    ar?: string | null;
    az?: string | null;
    bn?: string | null;
    bg?: string | null;
    ca?: string | null;
    zh?: string | null;
    hr?: string | null;
    cs?: string | null;
    da?: string | null;
    nl?: string | null;
    et?: string | null;
    fil?: string | null;
    fi?: string | null;
    fr?: string | null;
    ka?: string | null;
    de?: string | null;
    el?: string | null;
    gu?: string | null;
    ha?: string | null;
    he?: string | null;
    hi?: string | null;
    hu?: string | null;
    id?: string | null;
    ga?: string | null;
    it?: string | null;
    ja?: string | null;
    kn?: string | null;
    kk?: string | null;
    rw?: string | null;
    ko?: string | null;
    ky?: string | null;
    lo?: string | null;
    lv?: string | null;
    lt?: string | null;
    mk?: string | null;
    ms?: string | null;
    ml?: string | null;
    mr?: string | null;
    nb?: string | null;
    fa?: string | null;
    pl?: string | null;
    pt?: string | null;
    pa?: string | null;
    ro?: string | null;
    sr?: string | null;
    sk?: string | null;
    sl?: string | null;
    es?: string | null;
    sw?: string | null;
    sv?: string | null;
    ta?: string | null;
    te?: string | null;
    th?: string | null;
    tr?: string | null;
    uk?: string | null;
    ur?: string | null;
    uz?: string | null;
    vi?: string | null;
    zu?: string | null;
  } | null;
  d?: {
    __typename?: "LanguageMap";
    en: string;
    ru?: string | null;
    af?: string | null;
    sq?: string | null;
    ar?: string | null;
    az?: string | null;
    bn?: string | null;
    bg?: string | null;
    ca?: string | null;
    zh?: string | null;
    hr?: string | null;
    cs?: string | null;
    da?: string | null;
    nl?: string | null;
    et?: string | null;
    fil?: string | null;
    fi?: string | null;
    fr?: string | null;
    ka?: string | null;
    de?: string | null;
    el?: string | null;
    gu?: string | null;
    ha?: string | null;
    he?: string | null;
    hi?: string | null;
    hu?: string | null;
    id?: string | null;
    ga?: string | null;
    it?: string | null;
    ja?: string | null;
    kn?: string | null;
    kk?: string | null;
    rw?: string | null;
    ko?: string | null;
    ky?: string | null;
    lo?: string | null;
    lv?: string | null;
    lt?: string | null;
    mk?: string | null;
    ms?: string | null;
    ml?: string | null;
    mr?: string | null;
    nb?: string | null;
    fa?: string | null;
    pl?: string | null;
    pt?: string | null;
    pa?: string | null;
    ro?: string | null;
    sr?: string | null;
    sk?: string | null;
    sl?: string | null;
    es?: string | null;
    sw?: string | null;
    sv?: string | null;
    ta?: string | null;
    te?: string | null;
    th?: string | null;
    tr?: string | null;
    uk?: string | null;
    ur?: string | null;
    uz?: string | null;
    vi?: string | null;
    zu?: string | null;
  } | null;
  c?: Array<
    | {
        __typename?: "AppFieldInner";
        t: AppFieldTypeType;
        tg: string;
        dt?: AppFieldDataTypeType | null;
        dts?: Array<AppFieldDataTypesType> | null;
        r?: boolean | null;
        mn?: number | null;
        mx?: number | null;
        v?: string | null;
        n: {
          __typename?: "LanguageMap";
          en: string;
          ru?: string | null;
          af?: string | null;
          sq?: string | null;
          ar?: string | null;
          az?: string | null;
          bn?: string | null;
          bg?: string | null;
          ca?: string | null;
          zh?: string | null;
          hr?: string | null;
          cs?: string | null;
          da?: string | null;
          nl?: string | null;
          et?: string | null;
          fil?: string | null;
          fi?: string | null;
          fr?: string | null;
          ka?: string | null;
          de?: string | null;
          el?: string | null;
          gu?: string | null;
          ha?: string | null;
          he?: string | null;
          hi?: string | null;
          hu?: string | null;
          id?: string | null;
          ga?: string | null;
          it?: string | null;
          ja?: string | null;
          kn?: string | null;
          kk?: string | null;
          rw?: string | null;
          ko?: string | null;
          ky?: string | null;
          lo?: string | null;
          lv?: string | null;
          lt?: string | null;
          mk?: string | null;
          ms?: string | null;
          ml?: string | null;
          mr?: string | null;
          nb?: string | null;
          fa?: string | null;
          pl?: string | null;
          pt?: string | null;
          pa?: string | null;
          ro?: string | null;
          sr?: string | null;
          sk?: string | null;
          sl?: string | null;
          es?: string | null;
          sw?: string | null;
          sv?: string | null;
          ta?: string | null;
          te?: string | null;
          th?: string | null;
          tr?: string | null;
          uk?: string | null;
          ur?: string | null;
          uz?: string | null;
          vi?: string | null;
          zu?: string | null;
        };
        p?: {
          __typename?: "LanguageMap";
          en: string;
          ru?: string | null;
          af?: string | null;
          sq?: string | null;
          ar?: string | null;
          az?: string | null;
          bn?: string | null;
          bg?: string | null;
          ca?: string | null;
          zh?: string | null;
          hr?: string | null;
          cs?: string | null;
          da?: string | null;
          nl?: string | null;
          et?: string | null;
          fil?: string | null;
          fi?: string | null;
          fr?: string | null;
          ka?: string | null;
          de?: string | null;
          el?: string | null;
          gu?: string | null;
          ha?: string | null;
          he?: string | null;
          hi?: string | null;
          hu?: string | null;
          id?: string | null;
          ga?: string | null;
          it?: string | null;
          ja?: string | null;
          kn?: string | null;
          kk?: string | null;
          rw?: string | null;
          ko?: string | null;
          ky?: string | null;
          lo?: string | null;
          lv?: string | null;
          lt?: string | null;
          mk?: string | null;
          ms?: string | null;
          ml?: string | null;
          mr?: string | null;
          nb?: string | null;
          fa?: string | null;
          pl?: string | null;
          pt?: string | null;
          pa?: string | null;
          ro?: string | null;
          sr?: string | null;
          sk?: string | null;
          sl?: string | null;
          es?: string | null;
          sw?: string | null;
          sv?: string | null;
          ta?: string | null;
          te?: string | null;
          th?: string | null;
          tr?: string | null;
          uk?: string | null;
          ur?: string | null;
          uz?: string | null;
          vi?: string | null;
          zu?: string | null;
        } | null;
        d?: {
          __typename?: "LanguageMap";
          en: string;
          ru?: string | null;
          af?: string | null;
          sq?: string | null;
          ar?: string | null;
          az?: string | null;
          bn?: string | null;
          bg?: string | null;
          ca?: string | null;
          zh?: string | null;
          hr?: string | null;
          cs?: string | null;
          da?: string | null;
          nl?: string | null;
          et?: string | null;
          fil?: string | null;
          fi?: string | null;
          fr?: string | null;
          ka?: string | null;
          de?: string | null;
          el?: string | null;
          gu?: string | null;
          ha?: string | null;
          he?: string | null;
          hi?: string | null;
          hu?: string | null;
          id?: string | null;
          ga?: string | null;
          it?: string | null;
          ja?: string | null;
          kn?: string | null;
          kk?: string | null;
          rw?: string | null;
          ko?: string | null;
          ky?: string | null;
          lo?: string | null;
          lv?: string | null;
          lt?: string | null;
          mk?: string | null;
          ms?: string | null;
          ml?: string | null;
          mr?: string | null;
          nb?: string | null;
          fa?: string | null;
          pl?: string | null;
          pt?: string | null;
          pa?: string | null;
          ro?: string | null;
          sr?: string | null;
          sk?: string | null;
          sl?: string | null;
          es?: string | null;
          sw?: string | null;
          sv?: string | null;
          ta?: string | null;
          te?: string | null;
          th?: string | null;
          tr?: string | null;
          uk?: string | null;
          ur?: string | null;
          uz?: string | null;
          vi?: string | null;
          zu?: string | null;
        } | null;
      }
    | {
        __typename?: "AppFieldsGroupInner";
        g: AppFieldsGroupType;
        c: Array<{
          __typename?: "AppFieldInner";
          t: AppFieldTypeType;
          tg: string;
          dt?: AppFieldDataTypeType | null;
          dts?: Array<AppFieldDataTypesType> | null;
          r?: boolean | null;
          mn?: number | null;
          mx?: number | null;
          v?: string | null;
          n: {
            __typename?: "LanguageMap";
            en: string;
            ru?: string | null;
            af?: string | null;
            sq?: string | null;
            ar?: string | null;
            az?: string | null;
            bn?: string | null;
            bg?: string | null;
            ca?: string | null;
            zh?: string | null;
            hr?: string | null;
            cs?: string | null;
            da?: string | null;
            nl?: string | null;
            et?: string | null;
            fil?: string | null;
            fi?: string | null;
            fr?: string | null;
            ka?: string | null;
            de?: string | null;
            el?: string | null;
            gu?: string | null;
            ha?: string | null;
            he?: string | null;
            hi?: string | null;
            hu?: string | null;
            id?: string | null;
            ga?: string | null;
            it?: string | null;
            ja?: string | null;
            kn?: string | null;
            kk?: string | null;
            rw?: string | null;
            ko?: string | null;
            ky?: string | null;
            lo?: string | null;
            lv?: string | null;
            lt?: string | null;
            mk?: string | null;
            ms?: string | null;
            ml?: string | null;
            mr?: string | null;
            nb?: string | null;
            fa?: string | null;
            pl?: string | null;
            pt?: string | null;
            pa?: string | null;
            ro?: string | null;
            sr?: string | null;
            sk?: string | null;
            sl?: string | null;
            es?: string | null;
            sw?: string | null;
            sv?: string | null;
            ta?: string | null;
            te?: string | null;
            th?: string | null;
            tr?: string | null;
            uk?: string | null;
            ur?: string | null;
            uz?: string | null;
            vi?: string | null;
            zu?: string | null;
          };
          p?: {
            __typename?: "LanguageMap";
            en: string;
            ru?: string | null;
            af?: string | null;
            sq?: string | null;
            ar?: string | null;
            az?: string | null;
            bn?: string | null;
            bg?: string | null;
            ca?: string | null;
            zh?: string | null;
            hr?: string | null;
            cs?: string | null;
            da?: string | null;
            nl?: string | null;
            et?: string | null;
            fil?: string | null;
            fi?: string | null;
            fr?: string | null;
            ka?: string | null;
            de?: string | null;
            el?: string | null;
            gu?: string | null;
            ha?: string | null;
            he?: string | null;
            hi?: string | null;
            hu?: string | null;
            id?: string | null;
            ga?: string | null;
            it?: string | null;
            ja?: string | null;
            kn?: string | null;
            kk?: string | null;
            rw?: string | null;
            ko?: string | null;
            ky?: string | null;
            lo?: string | null;
            lv?: string | null;
            lt?: string | null;
            mk?: string | null;
            ms?: string | null;
            ml?: string | null;
            mr?: string | null;
            nb?: string | null;
            fa?: string | null;
            pl?: string | null;
            pt?: string | null;
            pa?: string | null;
            ro?: string | null;
            sr?: string | null;
            sk?: string | null;
            sl?: string | null;
            es?: string | null;
            sw?: string | null;
            sv?: string | null;
            ta?: string | null;
            te?: string | null;
            th?: string | null;
            tr?: string | null;
            uk?: string | null;
            ur?: string | null;
            uz?: string | null;
            vi?: string | null;
            zu?: string | null;
          } | null;
          d?: {
            __typename?: "LanguageMap";
            en: string;
            ru?: string | null;
            af?: string | null;
            sq?: string | null;
            ar?: string | null;
            az?: string | null;
            bn?: string | null;
            bg?: string | null;
            ca?: string | null;
            zh?: string | null;
            hr?: string | null;
            cs?: string | null;
            da?: string | null;
            nl?: string | null;
            et?: string | null;
            fil?: string | null;
            fi?: string | null;
            fr?: string | null;
            ka?: string | null;
            de?: string | null;
            el?: string | null;
            gu?: string | null;
            ha?: string | null;
            he?: string | null;
            hi?: string | null;
            hu?: string | null;
            id?: string | null;
            ga?: string | null;
            it?: string | null;
            ja?: string | null;
            kn?: string | null;
            kk?: string | null;
            rw?: string | null;
            ko?: string | null;
            ky?: string | null;
            lo?: string | null;
            lv?: string | null;
            lt?: string | null;
            mk?: string | null;
            ms?: string | null;
            ml?: string | null;
            mr?: string | null;
            nb?: string | null;
            fa?: string | null;
            pl?: string | null;
            pt?: string | null;
            pa?: string | null;
            ro?: string | null;
            sr?: string | null;
            sk?: string | null;
            sl?: string | null;
            es?: string | null;
            sw?: string | null;
            sv?: string | null;
            ta?: string | null;
            te?: string | null;
            th?: string | null;
            tr?: string | null;
            uk?: string | null;
            ur?: string | null;
            uz?: string | null;
            vi?: string | null;
            zu?: string | null;
          } | null;
        }>;
      }
  > | null;
};

export type CoreGroupFieldsFragment = {
  __typename?: "AppFieldsGroup";
  g: AppFieldsGroupType;
  c: Array<{
    __typename?: "AppField";
    t: AppFieldTypeType;
    tg: string;
    dt?: AppFieldDataTypeType | null;
    dts?: Array<AppFieldDataTypesType> | null;
    r?: boolean | null;
    mn?: number | null;
    mx?: number | null;
    v?: string | null;
    n: {
      __typename?: "LanguageMap";
      en: string;
      ru?: string | null;
      af?: string | null;
      sq?: string | null;
      ar?: string | null;
      az?: string | null;
      bn?: string | null;
      bg?: string | null;
      ca?: string | null;
      zh?: string | null;
      hr?: string | null;
      cs?: string | null;
      da?: string | null;
      nl?: string | null;
      et?: string | null;
      fil?: string | null;
      fi?: string | null;
      fr?: string | null;
      ka?: string | null;
      de?: string | null;
      el?: string | null;
      gu?: string | null;
      ha?: string | null;
      he?: string | null;
      hi?: string | null;
      hu?: string | null;
      id?: string | null;
      ga?: string | null;
      it?: string | null;
      ja?: string | null;
      kn?: string | null;
      kk?: string | null;
      rw?: string | null;
      ko?: string | null;
      ky?: string | null;
      lo?: string | null;
      lv?: string | null;
      lt?: string | null;
      mk?: string | null;
      ms?: string | null;
      ml?: string | null;
      mr?: string | null;
      nb?: string | null;
      fa?: string | null;
      pl?: string | null;
      pt?: string | null;
      pa?: string | null;
      ro?: string | null;
      sr?: string | null;
      sk?: string | null;
      sl?: string | null;
      es?: string | null;
      sw?: string | null;
      sv?: string | null;
      ta?: string | null;
      te?: string | null;
      th?: string | null;
      tr?: string | null;
      uk?: string | null;
      ur?: string | null;
      uz?: string | null;
      vi?: string | null;
      zu?: string | null;
    };
    p?: {
      __typename?: "LanguageMap";
      en: string;
      ru?: string | null;
      af?: string | null;
      sq?: string | null;
      ar?: string | null;
      az?: string | null;
      bn?: string | null;
      bg?: string | null;
      ca?: string | null;
      zh?: string | null;
      hr?: string | null;
      cs?: string | null;
      da?: string | null;
      nl?: string | null;
      et?: string | null;
      fil?: string | null;
      fi?: string | null;
      fr?: string | null;
      ka?: string | null;
      de?: string | null;
      el?: string | null;
      gu?: string | null;
      ha?: string | null;
      he?: string | null;
      hi?: string | null;
      hu?: string | null;
      id?: string | null;
      ga?: string | null;
      it?: string | null;
      ja?: string | null;
      kn?: string | null;
      kk?: string | null;
      rw?: string | null;
      ko?: string | null;
      ky?: string | null;
      lo?: string | null;
      lv?: string | null;
      lt?: string | null;
      mk?: string | null;
      ms?: string | null;
      ml?: string | null;
      mr?: string | null;
      nb?: string | null;
      fa?: string | null;
      pl?: string | null;
      pt?: string | null;
      pa?: string | null;
      ro?: string | null;
      sr?: string | null;
      sk?: string | null;
      sl?: string | null;
      es?: string | null;
      sw?: string | null;
      sv?: string | null;
      ta?: string | null;
      te?: string | null;
      th?: string | null;
      tr?: string | null;
      uk?: string | null;
      ur?: string | null;
      uz?: string | null;
      vi?: string | null;
      zu?: string | null;
    } | null;
    d?: {
      __typename?: "LanguageMap";
      en: string;
      ru?: string | null;
      af?: string | null;
      sq?: string | null;
      ar?: string | null;
      az?: string | null;
      bn?: string | null;
      bg?: string | null;
      ca?: string | null;
      zh?: string | null;
      hr?: string | null;
      cs?: string | null;
      da?: string | null;
      nl?: string | null;
      et?: string | null;
      fil?: string | null;
      fi?: string | null;
      fr?: string | null;
      ka?: string | null;
      de?: string | null;
      el?: string | null;
      gu?: string | null;
      ha?: string | null;
      he?: string | null;
      hi?: string | null;
      hu?: string | null;
      id?: string | null;
      ga?: string | null;
      it?: string | null;
      ja?: string | null;
      kn?: string | null;
      kk?: string | null;
      rw?: string | null;
      ko?: string | null;
      ky?: string | null;
      lo?: string | null;
      lv?: string | null;
      lt?: string | null;
      mk?: string | null;
      ms?: string | null;
      ml?: string | null;
      mr?: string | null;
      nb?: string | null;
      fa?: string | null;
      pl?: string | null;
      pt?: string | null;
      pa?: string | null;
      ro?: string | null;
      sr?: string | null;
      sk?: string | null;
      sl?: string | null;
      es?: string | null;
      sw?: string | null;
      sv?: string | null;
      ta?: string | null;
      te?: string | null;
      th?: string | null;
      tr?: string | null;
      uk?: string | null;
      ur?: string | null;
      uz?: string | null;
      vi?: string | null;
      zu?: string | null;
    } | null;
    c?: Array<
      | {
          __typename?: "AppFieldInner";
          t: AppFieldTypeType;
          tg: string;
          dt?: AppFieldDataTypeType | null;
          dts?: Array<AppFieldDataTypesType> | null;
          r?: boolean | null;
          mn?: number | null;
          mx?: number | null;
          v?: string | null;
          n: {
            __typename?: "LanguageMap";
            en: string;
            ru?: string | null;
            af?: string | null;
            sq?: string | null;
            ar?: string | null;
            az?: string | null;
            bn?: string | null;
            bg?: string | null;
            ca?: string | null;
            zh?: string | null;
            hr?: string | null;
            cs?: string | null;
            da?: string | null;
            nl?: string | null;
            et?: string | null;
            fil?: string | null;
            fi?: string | null;
            fr?: string | null;
            ka?: string | null;
            de?: string | null;
            el?: string | null;
            gu?: string | null;
            ha?: string | null;
            he?: string | null;
            hi?: string | null;
            hu?: string | null;
            id?: string | null;
            ga?: string | null;
            it?: string | null;
            ja?: string | null;
            kn?: string | null;
            kk?: string | null;
            rw?: string | null;
            ko?: string | null;
            ky?: string | null;
            lo?: string | null;
            lv?: string | null;
            lt?: string | null;
            mk?: string | null;
            ms?: string | null;
            ml?: string | null;
            mr?: string | null;
            nb?: string | null;
            fa?: string | null;
            pl?: string | null;
            pt?: string | null;
            pa?: string | null;
            ro?: string | null;
            sr?: string | null;
            sk?: string | null;
            sl?: string | null;
            es?: string | null;
            sw?: string | null;
            sv?: string | null;
            ta?: string | null;
            te?: string | null;
            th?: string | null;
            tr?: string | null;
            uk?: string | null;
            ur?: string | null;
            uz?: string | null;
            vi?: string | null;
            zu?: string | null;
          };
          p?: {
            __typename?: "LanguageMap";
            en: string;
            ru?: string | null;
            af?: string | null;
            sq?: string | null;
            ar?: string | null;
            az?: string | null;
            bn?: string | null;
            bg?: string | null;
            ca?: string | null;
            zh?: string | null;
            hr?: string | null;
            cs?: string | null;
            da?: string | null;
            nl?: string | null;
            et?: string | null;
            fil?: string | null;
            fi?: string | null;
            fr?: string | null;
            ka?: string | null;
            de?: string | null;
            el?: string | null;
            gu?: string | null;
            ha?: string | null;
            he?: string | null;
            hi?: string | null;
            hu?: string | null;
            id?: string | null;
            ga?: string | null;
            it?: string | null;
            ja?: string | null;
            kn?: string | null;
            kk?: string | null;
            rw?: string | null;
            ko?: string | null;
            ky?: string | null;
            lo?: string | null;
            lv?: string | null;
            lt?: string | null;
            mk?: string | null;
            ms?: string | null;
            ml?: string | null;
            mr?: string | null;
            nb?: string | null;
            fa?: string | null;
            pl?: string | null;
            pt?: string | null;
            pa?: string | null;
            ro?: string | null;
            sr?: string | null;
            sk?: string | null;
            sl?: string | null;
            es?: string | null;
            sw?: string | null;
            sv?: string | null;
            ta?: string | null;
            te?: string | null;
            th?: string | null;
            tr?: string | null;
            uk?: string | null;
            ur?: string | null;
            uz?: string | null;
            vi?: string | null;
            zu?: string | null;
          } | null;
          d?: {
            __typename?: "LanguageMap";
            en: string;
            ru?: string | null;
            af?: string | null;
            sq?: string | null;
            ar?: string | null;
            az?: string | null;
            bn?: string | null;
            bg?: string | null;
            ca?: string | null;
            zh?: string | null;
            hr?: string | null;
            cs?: string | null;
            da?: string | null;
            nl?: string | null;
            et?: string | null;
            fil?: string | null;
            fi?: string | null;
            fr?: string | null;
            ka?: string | null;
            de?: string | null;
            el?: string | null;
            gu?: string | null;
            ha?: string | null;
            he?: string | null;
            hi?: string | null;
            hu?: string | null;
            id?: string | null;
            ga?: string | null;
            it?: string | null;
            ja?: string | null;
            kn?: string | null;
            kk?: string | null;
            rw?: string | null;
            ko?: string | null;
            ky?: string | null;
            lo?: string | null;
            lv?: string | null;
            lt?: string | null;
            mk?: string | null;
            ms?: string | null;
            ml?: string | null;
            mr?: string | null;
            nb?: string | null;
            fa?: string | null;
            pl?: string | null;
            pt?: string | null;
            pa?: string | null;
            ro?: string | null;
            sr?: string | null;
            sk?: string | null;
            sl?: string | null;
            es?: string | null;
            sw?: string | null;
            sv?: string | null;
            ta?: string | null;
            te?: string | null;
            th?: string | null;
            tr?: string | null;
            uk?: string | null;
            ur?: string | null;
            uz?: string | null;
            vi?: string | null;
            zu?: string | null;
          } | null;
        }
      | {
          __typename?: "AppFieldsGroupInner";
          g: AppFieldsGroupType;
          c: Array<{
            __typename?: "AppFieldInner";
            t: AppFieldTypeType;
            tg: string;
            dt?: AppFieldDataTypeType | null;
            dts?: Array<AppFieldDataTypesType> | null;
            r?: boolean | null;
            mn?: number | null;
            mx?: number | null;
            v?: string | null;
            n: {
              __typename?: "LanguageMap";
              en: string;
              ru?: string | null;
              af?: string | null;
              sq?: string | null;
              ar?: string | null;
              az?: string | null;
              bn?: string | null;
              bg?: string | null;
              ca?: string | null;
              zh?: string | null;
              hr?: string | null;
              cs?: string | null;
              da?: string | null;
              nl?: string | null;
              et?: string | null;
              fil?: string | null;
              fi?: string | null;
              fr?: string | null;
              ka?: string | null;
              de?: string | null;
              el?: string | null;
              gu?: string | null;
              ha?: string | null;
              he?: string | null;
              hi?: string | null;
              hu?: string | null;
              id?: string | null;
              ga?: string | null;
              it?: string | null;
              ja?: string | null;
              kn?: string | null;
              kk?: string | null;
              rw?: string | null;
              ko?: string | null;
              ky?: string | null;
              lo?: string | null;
              lv?: string | null;
              lt?: string | null;
              mk?: string | null;
              ms?: string | null;
              ml?: string | null;
              mr?: string | null;
              nb?: string | null;
              fa?: string | null;
              pl?: string | null;
              pt?: string | null;
              pa?: string | null;
              ro?: string | null;
              sr?: string | null;
              sk?: string | null;
              sl?: string | null;
              es?: string | null;
              sw?: string | null;
              sv?: string | null;
              ta?: string | null;
              te?: string | null;
              th?: string | null;
              tr?: string | null;
              uk?: string | null;
              ur?: string | null;
              uz?: string | null;
              vi?: string | null;
              zu?: string | null;
            };
            p?: {
              __typename?: "LanguageMap";
              en: string;
              ru?: string | null;
              af?: string | null;
              sq?: string | null;
              ar?: string | null;
              az?: string | null;
              bn?: string | null;
              bg?: string | null;
              ca?: string | null;
              zh?: string | null;
              hr?: string | null;
              cs?: string | null;
              da?: string | null;
              nl?: string | null;
              et?: string | null;
              fil?: string | null;
              fi?: string | null;
              fr?: string | null;
              ka?: string | null;
              de?: string | null;
              el?: string | null;
              gu?: string | null;
              ha?: string | null;
              he?: string | null;
              hi?: string | null;
              hu?: string | null;
              id?: string | null;
              ga?: string | null;
              it?: string | null;
              ja?: string | null;
              kn?: string | null;
              kk?: string | null;
              rw?: string | null;
              ko?: string | null;
              ky?: string | null;
              lo?: string | null;
              lv?: string | null;
              lt?: string | null;
              mk?: string | null;
              ms?: string | null;
              ml?: string | null;
              mr?: string | null;
              nb?: string | null;
              fa?: string | null;
              pl?: string | null;
              pt?: string | null;
              pa?: string | null;
              ro?: string | null;
              sr?: string | null;
              sk?: string | null;
              sl?: string | null;
              es?: string | null;
              sw?: string | null;
              sv?: string | null;
              ta?: string | null;
              te?: string | null;
              th?: string | null;
              tr?: string | null;
              uk?: string | null;
              ur?: string | null;
              uz?: string | null;
              vi?: string | null;
              zu?: string | null;
            } | null;
            d?: {
              __typename?: "LanguageMap";
              en: string;
              ru?: string | null;
              af?: string | null;
              sq?: string | null;
              ar?: string | null;
              az?: string | null;
              bn?: string | null;
              bg?: string | null;
              ca?: string | null;
              zh?: string | null;
              hr?: string | null;
              cs?: string | null;
              da?: string | null;
              nl?: string | null;
              et?: string | null;
              fil?: string | null;
              fi?: string | null;
              fr?: string | null;
              ka?: string | null;
              de?: string | null;
              el?: string | null;
              gu?: string | null;
              ha?: string | null;
              he?: string | null;
              hi?: string | null;
              hu?: string | null;
              id?: string | null;
              ga?: string | null;
              it?: string | null;
              ja?: string | null;
              kn?: string | null;
              kk?: string | null;
              rw?: string | null;
              ko?: string | null;
              ky?: string | null;
              lo?: string | null;
              lv?: string | null;
              lt?: string | null;
              mk?: string | null;
              ms?: string | null;
              ml?: string | null;
              mr?: string | null;
              nb?: string | null;
              fa?: string | null;
              pl?: string | null;
              pt?: string | null;
              pa?: string | null;
              ro?: string | null;
              sr?: string | null;
              sk?: string | null;
              sl?: string | null;
              es?: string | null;
              sw?: string | null;
              sv?: string | null;
              ta?: string | null;
              te?: string | null;
              th?: string | null;
              tr?: string | null;
              uk?: string | null;
              ur?: string | null;
              uz?: string | null;
              vi?: string | null;
              zu?: string | null;
            } | null;
          }>;
        }
    > | null;
  }>;
};

export type CoreVersionFieldsFragment = {
  __typename?: "AppVersion";
  version: string;
  createdAt: string;
  id: string;
  isInstalled: boolean;
  fields: Array<{
    __typename?: "AppFieldsGroup";
    g: AppFieldsGroupType;
    c: Array<{
      __typename?: "AppField";
      t: AppFieldTypeType;
      tg: string;
      dt?: AppFieldDataTypeType | null;
      dts?: Array<AppFieldDataTypesType> | null;
      r?: boolean | null;
      mn?: number | null;
      mx?: number | null;
      v?: string | null;
      n: {
        __typename?: "LanguageMap";
        en: string;
        ru?: string | null;
        af?: string | null;
        sq?: string | null;
        ar?: string | null;
        az?: string | null;
        bn?: string | null;
        bg?: string | null;
        ca?: string | null;
        zh?: string | null;
        hr?: string | null;
        cs?: string | null;
        da?: string | null;
        nl?: string | null;
        et?: string | null;
        fil?: string | null;
        fi?: string | null;
        fr?: string | null;
        ka?: string | null;
        de?: string | null;
        el?: string | null;
        gu?: string | null;
        ha?: string | null;
        he?: string | null;
        hi?: string | null;
        hu?: string | null;
        id?: string | null;
        ga?: string | null;
        it?: string | null;
        ja?: string | null;
        kn?: string | null;
        kk?: string | null;
        rw?: string | null;
        ko?: string | null;
        ky?: string | null;
        lo?: string | null;
        lv?: string | null;
        lt?: string | null;
        mk?: string | null;
        ms?: string | null;
        ml?: string | null;
        mr?: string | null;
        nb?: string | null;
        fa?: string | null;
        pl?: string | null;
        pt?: string | null;
        pa?: string | null;
        ro?: string | null;
        sr?: string | null;
        sk?: string | null;
        sl?: string | null;
        es?: string | null;
        sw?: string | null;
        sv?: string | null;
        ta?: string | null;
        te?: string | null;
        th?: string | null;
        tr?: string | null;
        uk?: string | null;
        ur?: string | null;
        uz?: string | null;
        vi?: string | null;
        zu?: string | null;
      };
      p?: {
        __typename?: "LanguageMap";
        en: string;
        ru?: string | null;
        af?: string | null;
        sq?: string | null;
        ar?: string | null;
        az?: string | null;
        bn?: string | null;
        bg?: string | null;
        ca?: string | null;
        zh?: string | null;
        hr?: string | null;
        cs?: string | null;
        da?: string | null;
        nl?: string | null;
        et?: string | null;
        fil?: string | null;
        fi?: string | null;
        fr?: string | null;
        ka?: string | null;
        de?: string | null;
        el?: string | null;
        gu?: string | null;
        ha?: string | null;
        he?: string | null;
        hi?: string | null;
        hu?: string | null;
        id?: string | null;
        ga?: string | null;
        it?: string | null;
        ja?: string | null;
        kn?: string | null;
        kk?: string | null;
        rw?: string | null;
        ko?: string | null;
        ky?: string | null;
        lo?: string | null;
        lv?: string | null;
        lt?: string | null;
        mk?: string | null;
        ms?: string | null;
        ml?: string | null;
        mr?: string | null;
        nb?: string | null;
        fa?: string | null;
        pl?: string | null;
        pt?: string | null;
        pa?: string | null;
        ro?: string | null;
        sr?: string | null;
        sk?: string | null;
        sl?: string | null;
        es?: string | null;
        sw?: string | null;
        sv?: string | null;
        ta?: string | null;
        te?: string | null;
        th?: string | null;
        tr?: string | null;
        uk?: string | null;
        ur?: string | null;
        uz?: string | null;
        vi?: string | null;
        zu?: string | null;
      } | null;
      d?: {
        __typename?: "LanguageMap";
        en: string;
        ru?: string | null;
        af?: string | null;
        sq?: string | null;
        ar?: string | null;
        az?: string | null;
        bn?: string | null;
        bg?: string | null;
        ca?: string | null;
        zh?: string | null;
        hr?: string | null;
        cs?: string | null;
        da?: string | null;
        nl?: string | null;
        et?: string | null;
        fil?: string | null;
        fi?: string | null;
        fr?: string | null;
        ka?: string | null;
        de?: string | null;
        el?: string | null;
        gu?: string | null;
        ha?: string | null;
        he?: string | null;
        hi?: string | null;
        hu?: string | null;
        id?: string | null;
        ga?: string | null;
        it?: string | null;
        ja?: string | null;
        kn?: string | null;
        kk?: string | null;
        rw?: string | null;
        ko?: string | null;
        ky?: string | null;
        lo?: string | null;
        lv?: string | null;
        lt?: string | null;
        mk?: string | null;
        ms?: string | null;
        ml?: string | null;
        mr?: string | null;
        nb?: string | null;
        fa?: string | null;
        pl?: string | null;
        pt?: string | null;
        pa?: string | null;
        ro?: string | null;
        sr?: string | null;
        sk?: string | null;
        sl?: string | null;
        es?: string | null;
        sw?: string | null;
        sv?: string | null;
        ta?: string | null;
        te?: string | null;
        th?: string | null;
        tr?: string | null;
        uk?: string | null;
        ur?: string | null;
        uz?: string | null;
        vi?: string | null;
        zu?: string | null;
      } | null;
      c?: Array<
        | {
            __typename?: "AppFieldInner";
            t: AppFieldTypeType;
            tg: string;
            dt?: AppFieldDataTypeType | null;
            dts?: Array<AppFieldDataTypesType> | null;
            r?: boolean | null;
            mn?: number | null;
            mx?: number | null;
            v?: string | null;
            n: {
              __typename?: "LanguageMap";
              en: string;
              ru?: string | null;
              af?: string | null;
              sq?: string | null;
              ar?: string | null;
              az?: string | null;
              bn?: string | null;
              bg?: string | null;
              ca?: string | null;
              zh?: string | null;
              hr?: string | null;
              cs?: string | null;
              da?: string | null;
              nl?: string | null;
              et?: string | null;
              fil?: string | null;
              fi?: string | null;
              fr?: string | null;
              ka?: string | null;
              de?: string | null;
              el?: string | null;
              gu?: string | null;
              ha?: string | null;
              he?: string | null;
              hi?: string | null;
              hu?: string | null;
              id?: string | null;
              ga?: string | null;
              it?: string | null;
              ja?: string | null;
              kn?: string | null;
              kk?: string | null;
              rw?: string | null;
              ko?: string | null;
              ky?: string | null;
              lo?: string | null;
              lv?: string | null;
              lt?: string | null;
              mk?: string | null;
              ms?: string | null;
              ml?: string | null;
              mr?: string | null;
              nb?: string | null;
              fa?: string | null;
              pl?: string | null;
              pt?: string | null;
              pa?: string | null;
              ro?: string | null;
              sr?: string | null;
              sk?: string | null;
              sl?: string | null;
              es?: string | null;
              sw?: string | null;
              sv?: string | null;
              ta?: string | null;
              te?: string | null;
              th?: string | null;
              tr?: string | null;
              uk?: string | null;
              ur?: string | null;
              uz?: string | null;
              vi?: string | null;
              zu?: string | null;
            };
            p?: {
              __typename?: "LanguageMap";
              en: string;
              ru?: string | null;
              af?: string | null;
              sq?: string | null;
              ar?: string | null;
              az?: string | null;
              bn?: string | null;
              bg?: string | null;
              ca?: string | null;
              zh?: string | null;
              hr?: string | null;
              cs?: string | null;
              da?: string | null;
              nl?: string | null;
              et?: string | null;
              fil?: string | null;
              fi?: string | null;
              fr?: string | null;
              ka?: string | null;
              de?: string | null;
              el?: string | null;
              gu?: string | null;
              ha?: string | null;
              he?: string | null;
              hi?: string | null;
              hu?: string | null;
              id?: string | null;
              ga?: string | null;
              it?: string | null;
              ja?: string | null;
              kn?: string | null;
              kk?: string | null;
              rw?: string | null;
              ko?: string | null;
              ky?: string | null;
              lo?: string | null;
              lv?: string | null;
              lt?: string | null;
              mk?: string | null;
              ms?: string | null;
              ml?: string | null;
              mr?: string | null;
              nb?: string | null;
              fa?: string | null;
              pl?: string | null;
              pt?: string | null;
              pa?: string | null;
              ro?: string | null;
              sr?: string | null;
              sk?: string | null;
              sl?: string | null;
              es?: string | null;
              sw?: string | null;
              sv?: string | null;
              ta?: string | null;
              te?: string | null;
              th?: string | null;
              tr?: string | null;
              uk?: string | null;
              ur?: string | null;
              uz?: string | null;
              vi?: string | null;
              zu?: string | null;
            } | null;
            d?: {
              __typename?: "LanguageMap";
              en: string;
              ru?: string | null;
              af?: string | null;
              sq?: string | null;
              ar?: string | null;
              az?: string | null;
              bn?: string | null;
              bg?: string | null;
              ca?: string | null;
              zh?: string | null;
              hr?: string | null;
              cs?: string | null;
              da?: string | null;
              nl?: string | null;
              et?: string | null;
              fil?: string | null;
              fi?: string | null;
              fr?: string | null;
              ka?: string | null;
              de?: string | null;
              el?: string | null;
              gu?: string | null;
              ha?: string | null;
              he?: string | null;
              hi?: string | null;
              hu?: string | null;
              id?: string | null;
              ga?: string | null;
              it?: string | null;
              ja?: string | null;
              kn?: string | null;
              kk?: string | null;
              rw?: string | null;
              ko?: string | null;
              ky?: string | null;
              lo?: string | null;
              lv?: string | null;
              lt?: string | null;
              mk?: string | null;
              ms?: string | null;
              ml?: string | null;
              mr?: string | null;
              nb?: string | null;
              fa?: string | null;
              pl?: string | null;
              pt?: string | null;
              pa?: string | null;
              ro?: string | null;
              sr?: string | null;
              sk?: string | null;
              sl?: string | null;
              es?: string | null;
              sw?: string | null;
              sv?: string | null;
              ta?: string | null;
              te?: string | null;
              th?: string | null;
              tr?: string | null;
              uk?: string | null;
              ur?: string | null;
              uz?: string | null;
              vi?: string | null;
              zu?: string | null;
            } | null;
          }
        | {
            __typename?: "AppFieldsGroupInner";
            g: AppFieldsGroupType;
            c: Array<{
              __typename?: "AppFieldInner";
              t: AppFieldTypeType;
              tg: string;
              dt?: AppFieldDataTypeType | null;
              dts?: Array<AppFieldDataTypesType> | null;
              r?: boolean | null;
              mn?: number | null;
              mx?: number | null;
              v?: string | null;
              n: {
                __typename?: "LanguageMap";
                en: string;
                ru?: string | null;
                af?: string | null;
                sq?: string | null;
                ar?: string | null;
                az?: string | null;
                bn?: string | null;
                bg?: string | null;
                ca?: string | null;
                zh?: string | null;
                hr?: string | null;
                cs?: string | null;
                da?: string | null;
                nl?: string | null;
                et?: string | null;
                fil?: string | null;
                fi?: string | null;
                fr?: string | null;
                ka?: string | null;
                de?: string | null;
                el?: string | null;
                gu?: string | null;
                ha?: string | null;
                he?: string | null;
                hi?: string | null;
                hu?: string | null;
                id?: string | null;
                ga?: string | null;
                it?: string | null;
                ja?: string | null;
                kn?: string | null;
                kk?: string | null;
                rw?: string | null;
                ko?: string | null;
                ky?: string | null;
                lo?: string | null;
                lv?: string | null;
                lt?: string | null;
                mk?: string | null;
                ms?: string | null;
                ml?: string | null;
                mr?: string | null;
                nb?: string | null;
                fa?: string | null;
                pl?: string | null;
                pt?: string | null;
                pa?: string | null;
                ro?: string | null;
                sr?: string | null;
                sk?: string | null;
                sl?: string | null;
                es?: string | null;
                sw?: string | null;
                sv?: string | null;
                ta?: string | null;
                te?: string | null;
                th?: string | null;
                tr?: string | null;
                uk?: string | null;
                ur?: string | null;
                uz?: string | null;
                vi?: string | null;
                zu?: string | null;
              };
              p?: {
                __typename?: "LanguageMap";
                en: string;
                ru?: string | null;
                af?: string | null;
                sq?: string | null;
                ar?: string | null;
                az?: string | null;
                bn?: string | null;
                bg?: string | null;
                ca?: string | null;
                zh?: string | null;
                hr?: string | null;
                cs?: string | null;
                da?: string | null;
                nl?: string | null;
                et?: string | null;
                fil?: string | null;
                fi?: string | null;
                fr?: string | null;
                ka?: string | null;
                de?: string | null;
                el?: string | null;
                gu?: string | null;
                ha?: string | null;
                he?: string | null;
                hi?: string | null;
                hu?: string | null;
                id?: string | null;
                ga?: string | null;
                it?: string | null;
                ja?: string | null;
                kn?: string | null;
                kk?: string | null;
                rw?: string | null;
                ko?: string | null;
                ky?: string | null;
                lo?: string | null;
                lv?: string | null;
                lt?: string | null;
                mk?: string | null;
                ms?: string | null;
                ml?: string | null;
                mr?: string | null;
                nb?: string | null;
                fa?: string | null;
                pl?: string | null;
                pt?: string | null;
                pa?: string | null;
                ro?: string | null;
                sr?: string | null;
                sk?: string | null;
                sl?: string | null;
                es?: string | null;
                sw?: string | null;
                sv?: string | null;
                ta?: string | null;
                te?: string | null;
                th?: string | null;
                tr?: string | null;
                uk?: string | null;
                ur?: string | null;
                uz?: string | null;
                vi?: string | null;
                zu?: string | null;
              } | null;
              d?: {
                __typename?: "LanguageMap";
                en: string;
                ru?: string | null;
                af?: string | null;
                sq?: string | null;
                ar?: string | null;
                az?: string | null;
                bn?: string | null;
                bg?: string | null;
                ca?: string | null;
                zh?: string | null;
                hr?: string | null;
                cs?: string | null;
                da?: string | null;
                nl?: string | null;
                et?: string | null;
                fil?: string | null;
                fi?: string | null;
                fr?: string | null;
                ka?: string | null;
                de?: string | null;
                el?: string | null;
                gu?: string | null;
                ha?: string | null;
                he?: string | null;
                hi?: string | null;
                hu?: string | null;
                id?: string | null;
                ga?: string | null;
                it?: string | null;
                ja?: string | null;
                kn?: string | null;
                kk?: string | null;
                rw?: string | null;
                ko?: string | null;
                ky?: string | null;
                lo?: string | null;
                lv?: string | null;
                lt?: string | null;
                mk?: string | null;
                ms?: string | null;
                ml?: string | null;
                mr?: string | null;
                nb?: string | null;
                fa?: string | null;
                pl?: string | null;
                pt?: string | null;
                pa?: string | null;
                ro?: string | null;
                sr?: string | null;
                sk?: string | null;
                sl?: string | null;
                es?: string | null;
                sw?: string | null;
                sv?: string | null;
                ta?: string | null;
                te?: string | null;
                th?: string | null;
                tr?: string | null;
                uk?: string | null;
                ur?: string | null;
                uz?: string | null;
                vi?: string | null;
                zu?: string | null;
              } | null;
            }>;
          }
      > | null;
    }>;
  } | null>;
  installations: Array<{
    __typename?: "AppInstallation";
    id: string;
    createdAt: string;
    method?: MethodType | null;
    localId?: string | null;
    settings?: Array<{
      __typename?: "AppSettingsGroup";
      g: AppFieldsGroupType;
      c: Array<{
        __typename?: "AppSettingsField";
        tg: string;
        dt: AppFieldSettingsDataTypeType;
        v: string;
        c?: Array<
          | {
              __typename?: "AppSettingsFieldInner";
              tg: string;
              dt: AppFieldSettingsDataTypeType;
              v: string;
            }
          | {
              __typename?: "AppSettingsGroupInner";
              g: AppFieldsGroupType;
              c: Array<{
                __typename?: "AppSettingsFieldInner";
                tg: string;
                dt: AppFieldSettingsDataTypeType;
                v: string;
              }>;
            }
        > | null;
      }>;
    } | null> | null;
  } | null>;
};

export type CoreAppFieldsFragment = {
  __typename?: "AppOutput";
  id: string;
  iconUrl: string;
  gallery?: string | null;
  type: AppType;
  tag: string;
  latestVersion: string;
  createdAt: string;
  isAvailable: boolean;
  isInstalled: boolean;
  name: {
    __typename?: "LanguageMap";
    en: string;
    ru?: string | null;
    af?: string | null;
    sq?: string | null;
    ar?: string | null;
    az?: string | null;
    bn?: string | null;
    bg?: string | null;
    ca?: string | null;
    zh?: string | null;
    hr?: string | null;
    cs?: string | null;
    da?: string | null;
    nl?: string | null;
    et?: string | null;
    fil?: string | null;
    fi?: string | null;
    fr?: string | null;
    ka?: string | null;
    de?: string | null;
    el?: string | null;
    gu?: string | null;
    ha?: string | null;
    he?: string | null;
    hi?: string | null;
    hu?: string | null;
    id?: string | null;
    ga?: string | null;
    it?: string | null;
    ja?: string | null;
    kn?: string | null;
    kk?: string | null;
    rw?: string | null;
    ko?: string | null;
    ky?: string | null;
    lo?: string | null;
    lv?: string | null;
    lt?: string | null;
    mk?: string | null;
    ms?: string | null;
    ml?: string | null;
    mr?: string | null;
    nb?: string | null;
    fa?: string | null;
    pl?: string | null;
    pt?: string | null;
    pa?: string | null;
    ro?: string | null;
    sr?: string | null;
    sk?: string | null;
    sl?: string | null;
    es?: string | null;
    sw?: string | null;
    sv?: string | null;
    ta?: string | null;
    te?: string | null;
    th?: string | null;
    tr?: string | null;
    uk?: string | null;
    ur?: string | null;
    uz?: string | null;
    vi?: string | null;
    zu?: string | null;
  };
  description?: {
    __typename?: "LanguageMap";
    en: string;
    ru?: string | null;
    af?: string | null;
    sq?: string | null;
    ar?: string | null;
    az?: string | null;
    bn?: string | null;
    bg?: string | null;
    ca?: string | null;
    zh?: string | null;
    hr?: string | null;
    cs?: string | null;
    da?: string | null;
    nl?: string | null;
    et?: string | null;
    fil?: string | null;
    fi?: string | null;
    fr?: string | null;
    ka?: string | null;
    de?: string | null;
    el?: string | null;
    gu?: string | null;
    ha?: string | null;
    he?: string | null;
    hi?: string | null;
    hu?: string | null;
    id?: string | null;
    ga?: string | null;
    it?: string | null;
    ja?: string | null;
    kn?: string | null;
    kk?: string | null;
    rw?: string | null;
    ko?: string | null;
    ky?: string | null;
    lo?: string | null;
    lv?: string | null;
    lt?: string | null;
    mk?: string | null;
    ms?: string | null;
    ml?: string | null;
    mr?: string | null;
    nb?: string | null;
    fa?: string | null;
    pl?: string | null;
    pt?: string | null;
    pa?: string | null;
    ro?: string | null;
    sr?: string | null;
    sk?: string | null;
    sl?: string | null;
    es?: string | null;
    sw?: string | null;
    sv?: string | null;
    ta?: string | null;
    te?: string | null;
    th?: string | null;
    tr?: string | null;
    uk?: string | null;
    ur?: string | null;
    uz?: string | null;
    vi?: string | null;
    zu?: string | null;
  } | null;
  instructions?: {
    __typename?: "LanguageMap";
    en: string;
    ru?: string | null;
    af?: string | null;
    sq?: string | null;
    ar?: string | null;
    az?: string | null;
    bn?: string | null;
    bg?: string | null;
    ca?: string | null;
    zh?: string | null;
    hr?: string | null;
    cs?: string | null;
    da?: string | null;
    nl?: string | null;
    et?: string | null;
    fil?: string | null;
    fi?: string | null;
    fr?: string | null;
    ka?: string | null;
    de?: string | null;
    el?: string | null;
    gu?: string | null;
    ha?: string | null;
    he?: string | null;
    hi?: string | null;
    hu?: string | null;
    id?: string | null;
    ga?: string | null;
    it?: string | null;
    ja?: string | null;
    kn?: string | null;
    kk?: string | null;
    rw?: string | null;
    ko?: string | null;
    ky?: string | null;
    lo?: string | null;
    lv?: string | null;
    lt?: string | null;
    mk?: string | null;
    ms?: string | null;
    ml?: string | null;
    mr?: string | null;
    nb?: string | null;
    fa?: string | null;
    pl?: string | null;
    pt?: string | null;
    pa?: string | null;
    ro?: string | null;
    sr?: string | null;
    sk?: string | null;
    sl?: string | null;
    es?: string | null;
    sw?: string | null;
    sv?: string | null;
    ta?: string | null;
    te?: string | null;
    th?: string | null;
    tr?: string | null;
    uk?: string | null;
    ur?: string | null;
    uz?: string | null;
    vi?: string | null;
    zu?: string | null;
  } | null;
  versions: Array<{
    __typename?: "AppVersion";
    version: string;
    createdAt: string;
    id: string;
    isInstalled: boolean;
    fields: Array<{
      __typename?: "AppFieldsGroup";
      g: AppFieldsGroupType;
      c: Array<{
        __typename?: "AppField";
        t: AppFieldTypeType;
        tg: string;
        dt?: AppFieldDataTypeType | null;
        dts?: Array<AppFieldDataTypesType> | null;
        r?: boolean | null;
        mn?: number | null;
        mx?: number | null;
        v?: string | null;
        n: {
          __typename?: "LanguageMap";
          en: string;
          ru?: string | null;
          af?: string | null;
          sq?: string | null;
          ar?: string | null;
          az?: string | null;
          bn?: string | null;
          bg?: string | null;
          ca?: string | null;
          zh?: string | null;
          hr?: string | null;
          cs?: string | null;
          da?: string | null;
          nl?: string | null;
          et?: string | null;
          fil?: string | null;
          fi?: string | null;
          fr?: string | null;
          ka?: string | null;
          de?: string | null;
          el?: string | null;
          gu?: string | null;
          ha?: string | null;
          he?: string | null;
          hi?: string | null;
          hu?: string | null;
          id?: string | null;
          ga?: string | null;
          it?: string | null;
          ja?: string | null;
          kn?: string | null;
          kk?: string | null;
          rw?: string | null;
          ko?: string | null;
          ky?: string | null;
          lo?: string | null;
          lv?: string | null;
          lt?: string | null;
          mk?: string | null;
          ms?: string | null;
          ml?: string | null;
          mr?: string | null;
          nb?: string | null;
          fa?: string | null;
          pl?: string | null;
          pt?: string | null;
          pa?: string | null;
          ro?: string | null;
          sr?: string | null;
          sk?: string | null;
          sl?: string | null;
          es?: string | null;
          sw?: string | null;
          sv?: string | null;
          ta?: string | null;
          te?: string | null;
          th?: string | null;
          tr?: string | null;
          uk?: string | null;
          ur?: string | null;
          uz?: string | null;
          vi?: string | null;
          zu?: string | null;
        };
        p?: {
          __typename?: "LanguageMap";
          en: string;
          ru?: string | null;
          af?: string | null;
          sq?: string | null;
          ar?: string | null;
          az?: string | null;
          bn?: string | null;
          bg?: string | null;
          ca?: string | null;
          zh?: string | null;
          hr?: string | null;
          cs?: string | null;
          da?: string | null;
          nl?: string | null;
          et?: string | null;
          fil?: string | null;
          fi?: string | null;
          fr?: string | null;
          ka?: string | null;
          de?: string | null;
          el?: string | null;
          gu?: string | null;
          ha?: string | null;
          he?: string | null;
          hi?: string | null;
          hu?: string | null;
          id?: string | null;
          ga?: string | null;
          it?: string | null;
          ja?: string | null;
          kn?: string | null;
          kk?: string | null;
          rw?: string | null;
          ko?: string | null;
          ky?: string | null;
          lo?: string | null;
          lv?: string | null;
          lt?: string | null;
          mk?: string | null;
          ms?: string | null;
          ml?: string | null;
          mr?: string | null;
          nb?: string | null;
          fa?: string | null;
          pl?: string | null;
          pt?: string | null;
          pa?: string | null;
          ro?: string | null;
          sr?: string | null;
          sk?: string | null;
          sl?: string | null;
          es?: string | null;
          sw?: string | null;
          sv?: string | null;
          ta?: string | null;
          te?: string | null;
          th?: string | null;
          tr?: string | null;
          uk?: string | null;
          ur?: string | null;
          uz?: string | null;
          vi?: string | null;
          zu?: string | null;
        } | null;
        d?: {
          __typename?: "LanguageMap";
          en: string;
          ru?: string | null;
          af?: string | null;
          sq?: string | null;
          ar?: string | null;
          az?: string | null;
          bn?: string | null;
          bg?: string | null;
          ca?: string | null;
          zh?: string | null;
          hr?: string | null;
          cs?: string | null;
          da?: string | null;
          nl?: string | null;
          et?: string | null;
          fil?: string | null;
          fi?: string | null;
          fr?: string | null;
          ka?: string | null;
          de?: string | null;
          el?: string | null;
          gu?: string | null;
          ha?: string | null;
          he?: string | null;
          hi?: string | null;
          hu?: string | null;
          id?: string | null;
          ga?: string | null;
          it?: string | null;
          ja?: string | null;
          kn?: string | null;
          kk?: string | null;
          rw?: string | null;
          ko?: string | null;
          ky?: string | null;
          lo?: string | null;
          lv?: string | null;
          lt?: string | null;
          mk?: string | null;
          ms?: string | null;
          ml?: string | null;
          mr?: string | null;
          nb?: string | null;
          fa?: string | null;
          pl?: string | null;
          pt?: string | null;
          pa?: string | null;
          ro?: string | null;
          sr?: string | null;
          sk?: string | null;
          sl?: string | null;
          es?: string | null;
          sw?: string | null;
          sv?: string | null;
          ta?: string | null;
          te?: string | null;
          th?: string | null;
          tr?: string | null;
          uk?: string | null;
          ur?: string | null;
          uz?: string | null;
          vi?: string | null;
          zu?: string | null;
        } | null;
        c?: Array<
          | {
              __typename?: "AppFieldInner";
              t: AppFieldTypeType;
              tg: string;
              dt?: AppFieldDataTypeType | null;
              dts?: Array<AppFieldDataTypesType> | null;
              r?: boolean | null;
              mn?: number | null;
              mx?: number | null;
              v?: string | null;
              n: {
                __typename?: "LanguageMap";
                en: string;
                ru?: string | null;
                af?: string | null;
                sq?: string | null;
                ar?: string | null;
                az?: string | null;
                bn?: string | null;
                bg?: string | null;
                ca?: string | null;
                zh?: string | null;
                hr?: string | null;
                cs?: string | null;
                da?: string | null;
                nl?: string | null;
                et?: string | null;
                fil?: string | null;
                fi?: string | null;
                fr?: string | null;
                ka?: string | null;
                de?: string | null;
                el?: string | null;
                gu?: string | null;
                ha?: string | null;
                he?: string | null;
                hi?: string | null;
                hu?: string | null;
                id?: string | null;
                ga?: string | null;
                it?: string | null;
                ja?: string | null;
                kn?: string | null;
                kk?: string | null;
                rw?: string | null;
                ko?: string | null;
                ky?: string | null;
                lo?: string | null;
                lv?: string | null;
                lt?: string | null;
                mk?: string | null;
                ms?: string | null;
                ml?: string | null;
                mr?: string | null;
                nb?: string | null;
                fa?: string | null;
                pl?: string | null;
                pt?: string | null;
                pa?: string | null;
                ro?: string | null;
                sr?: string | null;
                sk?: string | null;
                sl?: string | null;
                es?: string | null;
                sw?: string | null;
                sv?: string | null;
                ta?: string | null;
                te?: string | null;
                th?: string | null;
                tr?: string | null;
                uk?: string | null;
                ur?: string | null;
                uz?: string | null;
                vi?: string | null;
                zu?: string | null;
              };
              p?: {
                __typename?: "LanguageMap";
                en: string;
                ru?: string | null;
                af?: string | null;
                sq?: string | null;
                ar?: string | null;
                az?: string | null;
                bn?: string | null;
                bg?: string | null;
                ca?: string | null;
                zh?: string | null;
                hr?: string | null;
                cs?: string | null;
                da?: string | null;
                nl?: string | null;
                et?: string | null;
                fil?: string | null;
                fi?: string | null;
                fr?: string | null;
                ka?: string | null;
                de?: string | null;
                el?: string | null;
                gu?: string | null;
                ha?: string | null;
                he?: string | null;
                hi?: string | null;
                hu?: string | null;
                id?: string | null;
                ga?: string | null;
                it?: string | null;
                ja?: string | null;
                kn?: string | null;
                kk?: string | null;
                rw?: string | null;
                ko?: string | null;
                ky?: string | null;
                lo?: string | null;
                lv?: string | null;
                lt?: string | null;
                mk?: string | null;
                ms?: string | null;
                ml?: string | null;
                mr?: string | null;
                nb?: string | null;
                fa?: string | null;
                pl?: string | null;
                pt?: string | null;
                pa?: string | null;
                ro?: string | null;
                sr?: string | null;
                sk?: string | null;
                sl?: string | null;
                es?: string | null;
                sw?: string | null;
                sv?: string | null;
                ta?: string | null;
                te?: string | null;
                th?: string | null;
                tr?: string | null;
                uk?: string | null;
                ur?: string | null;
                uz?: string | null;
                vi?: string | null;
                zu?: string | null;
              } | null;
              d?: {
                __typename?: "LanguageMap";
                en: string;
                ru?: string | null;
                af?: string | null;
                sq?: string | null;
                ar?: string | null;
                az?: string | null;
                bn?: string | null;
                bg?: string | null;
                ca?: string | null;
                zh?: string | null;
                hr?: string | null;
                cs?: string | null;
                da?: string | null;
                nl?: string | null;
                et?: string | null;
                fil?: string | null;
                fi?: string | null;
                fr?: string | null;
                ka?: string | null;
                de?: string | null;
                el?: string | null;
                gu?: string | null;
                ha?: string | null;
                he?: string | null;
                hi?: string | null;
                hu?: string | null;
                id?: string | null;
                ga?: string | null;
                it?: string | null;
                ja?: string | null;
                kn?: string | null;
                kk?: string | null;
                rw?: string | null;
                ko?: string | null;
                ky?: string | null;
                lo?: string | null;
                lv?: string | null;
                lt?: string | null;
                mk?: string | null;
                ms?: string | null;
                ml?: string | null;
                mr?: string | null;
                nb?: string | null;
                fa?: string | null;
                pl?: string | null;
                pt?: string | null;
                pa?: string | null;
                ro?: string | null;
                sr?: string | null;
                sk?: string | null;
                sl?: string | null;
                es?: string | null;
                sw?: string | null;
                sv?: string | null;
                ta?: string | null;
                te?: string | null;
                th?: string | null;
                tr?: string | null;
                uk?: string | null;
                ur?: string | null;
                uz?: string | null;
                vi?: string | null;
                zu?: string | null;
              } | null;
            }
          | {
              __typename?: "AppFieldsGroupInner";
              g: AppFieldsGroupType;
              c: Array<{
                __typename?: "AppFieldInner";
                t: AppFieldTypeType;
                tg: string;
                dt?: AppFieldDataTypeType | null;
                dts?: Array<AppFieldDataTypesType> | null;
                r?: boolean | null;
                mn?: number | null;
                mx?: number | null;
                v?: string | null;
                n: {
                  __typename?: "LanguageMap";
                  en: string;
                  ru?: string | null;
                  af?: string | null;
                  sq?: string | null;
                  ar?: string | null;
                  az?: string | null;
                  bn?: string | null;
                  bg?: string | null;
                  ca?: string | null;
                  zh?: string | null;
                  hr?: string | null;
                  cs?: string | null;
                  da?: string | null;
                  nl?: string | null;
                  et?: string | null;
                  fil?: string | null;
                  fi?: string | null;
                  fr?: string | null;
                  ka?: string | null;
                  de?: string | null;
                  el?: string | null;
                  gu?: string | null;
                  ha?: string | null;
                  he?: string | null;
                  hi?: string | null;
                  hu?: string | null;
                  id?: string | null;
                  ga?: string | null;
                  it?: string | null;
                  ja?: string | null;
                  kn?: string | null;
                  kk?: string | null;
                  rw?: string | null;
                  ko?: string | null;
                  ky?: string | null;
                  lo?: string | null;
                  lv?: string | null;
                  lt?: string | null;
                  mk?: string | null;
                  ms?: string | null;
                  ml?: string | null;
                  mr?: string | null;
                  nb?: string | null;
                  fa?: string | null;
                  pl?: string | null;
                  pt?: string | null;
                  pa?: string | null;
                  ro?: string | null;
                  sr?: string | null;
                  sk?: string | null;
                  sl?: string | null;
                  es?: string | null;
                  sw?: string | null;
                  sv?: string | null;
                  ta?: string | null;
                  te?: string | null;
                  th?: string | null;
                  tr?: string | null;
                  uk?: string | null;
                  ur?: string | null;
                  uz?: string | null;
                  vi?: string | null;
                  zu?: string | null;
                };
                p?: {
                  __typename?: "LanguageMap";
                  en: string;
                  ru?: string | null;
                  af?: string | null;
                  sq?: string | null;
                  ar?: string | null;
                  az?: string | null;
                  bn?: string | null;
                  bg?: string | null;
                  ca?: string | null;
                  zh?: string | null;
                  hr?: string | null;
                  cs?: string | null;
                  da?: string | null;
                  nl?: string | null;
                  et?: string | null;
                  fil?: string | null;
                  fi?: string | null;
                  fr?: string | null;
                  ka?: string | null;
                  de?: string | null;
                  el?: string | null;
                  gu?: string | null;
                  ha?: string | null;
                  he?: string | null;
                  hi?: string | null;
                  hu?: string | null;
                  id?: string | null;
                  ga?: string | null;
                  it?: string | null;
                  ja?: string | null;
                  kn?: string | null;
                  kk?: string | null;
                  rw?: string | null;
                  ko?: string | null;
                  ky?: string | null;
                  lo?: string | null;
                  lv?: string | null;
                  lt?: string | null;
                  mk?: string | null;
                  ms?: string | null;
                  ml?: string | null;
                  mr?: string | null;
                  nb?: string | null;
                  fa?: string | null;
                  pl?: string | null;
                  pt?: string | null;
                  pa?: string | null;
                  ro?: string | null;
                  sr?: string | null;
                  sk?: string | null;
                  sl?: string | null;
                  es?: string | null;
                  sw?: string | null;
                  sv?: string | null;
                  ta?: string | null;
                  te?: string | null;
                  th?: string | null;
                  tr?: string | null;
                  uk?: string | null;
                  ur?: string | null;
                  uz?: string | null;
                  vi?: string | null;
                  zu?: string | null;
                } | null;
                d?: {
                  __typename?: "LanguageMap";
                  en: string;
                  ru?: string | null;
                  af?: string | null;
                  sq?: string | null;
                  ar?: string | null;
                  az?: string | null;
                  bn?: string | null;
                  bg?: string | null;
                  ca?: string | null;
                  zh?: string | null;
                  hr?: string | null;
                  cs?: string | null;
                  da?: string | null;
                  nl?: string | null;
                  et?: string | null;
                  fil?: string | null;
                  fi?: string | null;
                  fr?: string | null;
                  ka?: string | null;
                  de?: string | null;
                  el?: string | null;
                  gu?: string | null;
                  ha?: string | null;
                  he?: string | null;
                  hi?: string | null;
                  hu?: string | null;
                  id?: string | null;
                  ga?: string | null;
                  it?: string | null;
                  ja?: string | null;
                  kn?: string | null;
                  kk?: string | null;
                  rw?: string | null;
                  ko?: string | null;
                  ky?: string | null;
                  lo?: string | null;
                  lv?: string | null;
                  lt?: string | null;
                  mk?: string | null;
                  ms?: string | null;
                  ml?: string | null;
                  mr?: string | null;
                  nb?: string | null;
                  fa?: string | null;
                  pl?: string | null;
                  pt?: string | null;
                  pa?: string | null;
                  ro?: string | null;
                  sr?: string | null;
                  sk?: string | null;
                  sl?: string | null;
                  es?: string | null;
                  sw?: string | null;
                  sv?: string | null;
                  ta?: string | null;
                  te?: string | null;
                  th?: string | null;
                  tr?: string | null;
                  uk?: string | null;
                  ur?: string | null;
                  uz?: string | null;
                  vi?: string | null;
                  zu?: string | null;
                } | null;
              }>;
            }
        > | null;
      }>;
    } | null>;
    installations: Array<{
      __typename?: "AppInstallation";
      id: string;
      createdAt: string;
      method?: MethodType | null;
      localId?: string | null;
      settings?: Array<{
        __typename?: "AppSettingsGroup";
        g: AppFieldsGroupType;
        c: Array<{
          __typename?: "AppSettingsField";
          tg: string;
          dt: AppFieldSettingsDataTypeType;
          v: string;
          c?: Array<
            | {
                __typename?: "AppSettingsFieldInner";
                tg: string;
                dt: AppFieldSettingsDataTypeType;
                v: string;
              }
            | {
                __typename?: "AppSettingsGroupInner";
                g: AppFieldsGroupType;
                c: Array<{
                  __typename?: "AppSettingsFieldInner";
                  tg: string;
                  dt: AppFieldSettingsDataTypeType;
                  v: string;
                }>;
              }
          > | null;
        }>;
      } | null> | null;
    } | null>;
  } | null>;
};

export type CoreNodeFieldsFragment = {
  __typename?: "NodeOutput";
  id?: string | null;
  localId?: string | null;
  flowId?: string | null;
  coordinates?: Array<number | null> | null;
  nodeType?: NodeType | null;
  fileUrl?: string | null;
  imageUrl?: string | null;
  text?: string | null;
  childFlowId?: string | null;
  isDelete?: boolean | null;
  teamsIds?: Array<string | null> | null;
  botId?: string | null;
  exceptionText?: string | null;
  saveTo?: SaveTo | null;
  appId?: string | null;
  appVersionId?: string | null;
  appInstalledId?: string | null;
  buttons?: Array<{
    __typename?: "ButtonOutput";
    id?: string | null;
    localId?: string | null;
    name?: string | null;
    nodeId?: string | null;
    localNodeId?: string | null;
    buttonOrder?: number | null;
    botId?: string | null;
  } | null> | null;
};

export type CoreEdgeFieldsFragment = {
  __typename?: "EdgeOutput";
  id?: string | null;
  localId?: string | null;
  botId?: string | null;
  flowId?: string | null;
  childId?: string | null;
  localChildId?: string | null;
  parentId?: string | null;
  localParentId?: string | null;
  handleParentId?: string | null;
  localHandleParentId?: string | null;
  isDelete?: boolean | null;
};

export type CoreSnippetCategoryFieldsFragment = {
  __typename?: "ChatSnippetsCategoriesOutput";
  categoryId?: string | null;
  categoryName?: string | null;
  parentId?: string | null;
  userId?: string | null;
  totalSnippetsCount?: number | null;
};

export type CoreSnippetFieldsFragment = {
  __typename?: "ChatSnippetsOutput";
  snippetId?: string | null;
  userId?: string | null;
  categoryId?: string | null;
  snippet?: string | null;
  teamsIds?: Array<string | null> | null;
  isFavorite: boolean;
};

export type CoreSnippetFavoriteFieldsFragment = {
  __typename?: "ChatSnippetsFavoritesOutput";
  snippetId?: string | null;
  userId?: string | null;
  isFavorite: boolean;
};

export type CoreOperatorsPerformanceSeriesFieldsFragment = {
  __typename?: "OperatorsPerformanceSeries";
  name: AnalyticsOperatorsPerformanceSeriesStatus;
  data: Array<number | null>;
};

export type CoreOperatorsPerformanceFieldsFragment = {
  __typename?: "OperatorsPerformance";
  updatedAt: string;
  series: Array<{
    __typename?: "OperatorsPerformanceSeries";
    name: AnalyticsOperatorsPerformanceSeriesStatus;
    data: Array<number | null>;
  } | null>;
};

export type CoreDataByChannelStatusFieldsFragment = {
  __typename?: "DataByChannelType";
  labels: Array<ChannelType | null>;
  series: Array<number | null>;
};

export type CoreDataByRoomStatusFieldsFragment = {
  __typename?: "DataByRoomStatus";
  labels: Array<RoomStatus | null>;
  series: Array<number | null>;
};

export type CoreNewChats7dSeriesFieldsFragment = {
  __typename?: "LineChartSeries";
  name: ChannelType;
  data: Array<number>;
};

export type CoreNewChats7dCategoriesFieldsFragment = {
  __typename?: "LineChartXAxisCategories";
  categories: Array<string | null>;
};

export type CoreLineChartFieldsFragment = {
  __typename?: "LineChart";
  series: Array<{
    __typename?: "LineChartSeries";
    name: ChannelType;
    data: Array<number>;
  } | null>;
  xAxis: {
    __typename?: "LineChartXAxisCategories";
    categories: Array<string | null>;
  };
};

export type CoreChatsDistributionFieldsFragment = {
  __typename?: "ChatsDistribution";
  labels: Array<AnalyticsChatsDistributionLabels>;
  series: Array<number>;
};

export type CoreChatsRepeatedFieldsFragment = {
  __typename?: "ChatsRepeated";
  labels: Array<AnalyticsChatsRepeatedLabels>;
  series: Array<number>;
};

export type CoreAnalyticsHeatMapSeriesItemFieldsFragment = {
  __typename?: "AnalyticsHeatMapSeriesItem";
  name: string;
  data: Array<{
    __typename?: "AnalyticsHeatMapSeriesDataItem";
    x: string;
    y: number;
  } | null>;
};

export type CoreAnalyticsOperatorsMetricsTableRowFieldsFragment = {
  __typename?: "AnalyticsOperatorsMetricsTableRow";
  operatorName: string;
  completedChats: number;
  avgFirstMessageSec: number;
  avgAcceptedSec: number;
};

export type CoreRoomEventFieldsFragment = {
  __typename?: "RoomEventOutput";
  eventCreatedAt: string;
  eventInitiator: string;
  initiatorType: InitiatorType;
  toUser?: string | null;
  fromUser?: string | null;
  toTeams?: Array<string | null> | null;
  fromTeams?: Array<string | null> | null;
  actionName: RoomEventActionName;
  text?: string | null;
};

export type CoreCalendarFieldsFragment = {
  __typename?: "CalendarOutput";
  id?: string | null;
  accountId?: string | null;
  description?: string | null;
  name?: string | null;
  isPrimary?: boolean | null;
  location?: string | null;
  object?: string | null;
  readOnly?: boolean | null;
  timezone?: string | null;
};

export type EventParticipantFieldsFragment = {
  __typename?: "EventParticipantOutput";
  comment?: string | null;
  email?: string | null;
  name?: string | null;
  phoneNumber?: string | null;
  status?: string | null;
};

export type CoreCalendarEventFieldsFragment = {
  __typename?: "CalendarEventOutput";
  accountId?: string | null;
  busy?: boolean | null;
  calendarId?: string | null;
  customerEventId?: string | null;
  description?: string | null;
  hideParticipants?: boolean | null;
  icalUid?: string | null;
  id?: string | null;
  location?: string | null;
  messageId?: string | null;
  object?: string | null;
  organizerEmail?: string | null;
  organizerName?: string | null;
  owner?: string | null;
  readOnly?: boolean | null;
  reminders?: string | null;
  status?: string | null;
  title?: string | null;
  updatedAt?: string | null;
  visibility?: string | null;
  startDateTime?: string | null;
  endDateTime?: string | null;
  participants?: Array<{
    __typename?: "EventParticipantOutput";
    comment?: string | null;
    email?: string | null;
    name?: string | null;
    phoneNumber?: string | null;
    status?: string | null;
  } | null> | null;
};

export type CoreOrganizationFieldsFragment = {
  __typename?: "Organization";
  id: string;
  parentOID?: string | null;
  phone?: string | null;
  email?: string | null;
  name: string;
  tariff?: OrganizationTariff | null;
  country?: string | null;
  timezone?: string | null;
  language?: OrganizationLanguage | null;
  activeMembersCount?: number | null;
  membersCount?: number | null;
  imageUrl?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  isPendingDeletion?: boolean | null;
  isCurrent: boolean;
  rid: string;
};

export type CoreCustomFieldFieldsFragment = {
  __typename?: "CustomFieldOutput";
  id: string;
  fieldName: string;
  dataType: CustomFieldDataType;
  description?: string | null;
  fieldOrder: number;
};

export type MakeUserMutationVariables = Exact<{
  input?: InputMaybe<MakeUserInput>;
}>;

export type MakeUserMutation = {
  __typename?: "Mutation";
  makeUser?: {
    __typename?: "UserOutput";
    id: string;
    optionalName: string;
    thirdParty?: string | null;
    roleId?: string | null;
    position?: string | null;
    whatsappUsername?: string | null;
    whatsappProfile?: string | null;
    telegramUsername?: string | null;
    telegramUserId?: string | null;
    facebookMessengerPsid?: string | null;
    vkUserId?: string | null;
    instagramBsid?: string | null;
    instagramProfile?: string | null;
    twilioSmsUserId?: string | null;
    nylasUserEmail?: string | null;
    mailgunUserEmail?: string | null;
    lastTicketAt?: string | null;
    common?: {
      __typename?: "CommonUserFields";
      createdAt: string;
      autoNickname: string;
      userpicUrl: string;
      firstName?: string | null;
      lastName?: string | null;
      email?: string | null;
      phone?: string | null;
    } | null;
    onlineStatus?: {
      __typename?: "UserPresenceStatusOutput";
      userId?: string | null;
      presenceStatus?: PresenceStatus | null;
    } | null;
    teams?: Array<{
      __typename?: "TeamOutput";
      id: string;
      name: string;
      createdAt: string;
      updatedAt: string;
      deletedAt?: string | null;
    } | null> | null;
    role?: {
      __typename?: "RoleOutput";
      roleDescription?: string | null;
      roleId?: string | null;
      rolePublicName?: string | null;
      roleTechnicalName?: string | null;
    } | null;
    roomsLinks?: Array<{
      __typename?: "RoomLinkOutput";
      roomId?: string | null;
      roomStatus?: RoomStatus | null;
      channelType?: ChannelType | null;
      lastTicketAt?: string | null;
    } | null> | null;
    customFields?: Array<{
      __typename?: "UserCustomFieldOutput";
      fieldId: string;
      fieldName: string;
      dataType: CustomFieldDataType;
      description?: string | null;
      fieldOrder: number;
      value: string;
    } | null> | null;
  } | null;
};

export type MakeChannel2MutationVariables = Exact<{
  input: MakeChannel2Input;
}>;

export type MakeChannel2Mutation = {
  __typename?: "Mutation";
  makeChannel2?: {
    __typename?: "ChannelOutput2";
    id: string;
    publicId: string;
    name: string;
    link: string;
    type: ChannelType;
    status: ChannelStatus;
    createdAt: string;
    updatedAt: string;
    deletedAt?: string | null;
    bot?: { __typename?: "BotOutput"; id: string; name?: string | null } | null;
  } | null;
};

export type MakeBotMutationVariables = Exact<{
  input?: InputMaybe<MakeBotInput>;
}>;

export type MakeBotMutation = {
  __typename?: "Mutation";
  makeBot?: {
    __typename?: "BotOutput";
    isActive?: boolean | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    publishedAt?: string | null;
    status?: BotStatus | null;
    sessionsCount?: number | null;
    botType?: BotType | null;
    teamsIds?: Array<string | null> | null;
    id: string;
    name?: string | null;
    connectedChannels?: Array<{
      __typename?: "ChannelOutput2";
      id: string;
      publicId: string;
      name: string;
      link: string;
      type: ChannelType;
      status: ChannelStatus;
      createdAt: string;
      updatedAt: string;
      deletedAt?: string | null;
      bot?: {
        __typename?: "BotOutput";
        id: string;
        name?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type MakeFlowMutationVariables = Exact<{
  input?: InputMaybe<MakeFlowInput>;
}>;

export type MakeFlowMutation = {
  __typename?: "Mutation";
  makeFlow?: {
    __typename?: "FlowOutput";
    id?: string | null;
    botId?: string | null;
    name?: string | null;
    createdAt?: string | null;
    isMain?: boolean | null;
    updatedAt?: string | null;
  } | null;
};

export type DuplicateBotMutationVariables = Exact<{
  id: Scalars["String"];
  newName?: InputMaybe<Scalars["String"]>;
}>;

export type DuplicateBotMutation = {
  __typename?: "Mutation";
  duplicateBot?: {
    __typename?: "BotOutput";
    isActive?: boolean | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    publishedAt?: string | null;
    status?: BotStatus | null;
    sessionsCount?: number | null;
    botType?: BotType | null;
    teamsIds?: Array<string | null> | null;
    id: string;
    name?: string | null;
    connectedChannels?: Array<{
      __typename?: "ChannelOutput2";
      id: string;
      publicId: string;
      name: string;
      link: string;
      type: ChannelType;
      status: ChannelStatus;
      createdAt: string;
      updatedAt: string;
      deletedAt?: string | null;
      bot?: {
        __typename?: "BotOutput";
        id: string;
        name?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type MakeRooms2MutationVariables = Exact<{
  input?: InputMaybe<MakeRooms2Input>;
}>;

export type MakeRooms2Mutation = {
  __typename?: "Mutation";
  makeRooms2?: {
    __typename?: "MakeRooms2Output";
    error?: string | null;
    rooms?: Array<{
      __typename?: "RoomOutput2";
      id: string;
      status: RoomStatus;
      previousStatus?: RoomStatus | null;
      teamsIds: Array<string | null>;
      previousTeamsIds?: Array<string | null> | null;
      lastMessageAt?: string | null;
      lastClientMessageAt?: string | null;
      lastTicketAt?: string | null;
      unreadMessagesNumber: number;
      isExpired: boolean;
      channel: {
        __typename?: "ChannelOutput2";
        id: string;
        publicId: string;
        name: string;
        link: string;
        type: ChannelType;
        status: ChannelStatus;
        createdAt: string;
        updatedAt: string;
        deletedAt?: string | null;
        bot?: {
          __typename?: "BotOutput";
          id: string;
          name?: string | null;
        } | null;
      };
      client: {
        __typename?: "UserOutput";
        id: string;
        optionalName: string;
        whatsappUsername?: string | null;
        whatsappProfile?: string | null;
        telegramUsername?: string | null;
        telegramUserId?: string | null;
        facebookMessengerPsid?: string | null;
        vkUserId?: string | null;
        instagramBsid?: string | null;
        instagramProfile?: string | null;
        thirdParty?: string | null;
        twilioSmsUserId?: string | null;
        nylasUserEmail?: string | null;
        mailgunUserEmail?: string | null;
        lastTicketAt?: string | null;
        common?: {
          __typename?: "CommonUserFields";
          createdAt: string;
          autoNickname: string;
          userpicUrl: string;
          firstName?: string | null;
          lastName?: string | null;
          email?: string | null;
          phone?: string | null;
        } | null;
        roomsLinks?: Array<{
          __typename?: "RoomLinkOutput";
          roomId?: string | null;
          roomStatus?: RoomStatus | null;
          channelType?: ChannelType | null;
          lastTicketAt?: string | null;
        } | null> | null;
        customFields?: Array<{
          __typename?: "UserCustomFieldOutput";
          fieldId: string;
          fieldName: string;
          dataType: CustomFieldDataType;
          description?: string | null;
          fieldOrder: number;
          value: string;
        } | null> | null;
      };
      activeOperator?: {
        __typename?: "UserOutput";
        id: string;
        optionalName: string;
        thirdParty?: string | null;
        roleId?: string | null;
        position?: string | null;
        common?: {
          __typename?: "CommonUserFields";
          createdAt: string;
          autoNickname: string;
          userpicUrl: string;
          firstName?: string | null;
          lastName?: string | null;
          email?: string | null;
          phone?: string | null;
        } | null;
        onlineStatus?: {
          __typename?: "UserPresenceStatusOutput";
          userId?: string | null;
          presenceStatus?: PresenceStatus | null;
        } | null;
        teams?: Array<{
          __typename?: "TeamOutput";
          id: string;
          name: string;
          createdAt: string;
          updatedAt: string;
          deletedAt?: string | null;
        } | null> | null;
        role?: {
          __typename?: "RoleOutput";
          roleDescription?: string | null;
          roleId?: string | null;
          rolePublicName?: string | null;
          roleTechnicalName?: string | null;
        } | null;
      } | null;
      operators: Array<{
        __typename?: "UserOutput";
        id: string;
        optionalName: string;
        thirdParty?: string | null;
        roleId?: string | null;
        position?: string | null;
        common?: {
          __typename?: "CommonUserFields";
          createdAt: string;
          autoNickname: string;
          userpicUrl: string;
          firstName?: string | null;
          lastName?: string | null;
          email?: string | null;
          phone?: string | null;
        } | null;
        onlineStatus?: {
          __typename?: "UserPresenceStatusOutput";
          userId?: string | null;
          presenceStatus?: PresenceStatus | null;
        } | null;
        teams?: Array<{
          __typename?: "TeamOutput";
          id: string;
          name: string;
          createdAt: string;
          updatedAt: string;
          deletedAt?: string | null;
        } | null> | null;
        role?: {
          __typename?: "RoleOutput";
          roleDescription?: string | null;
          roleId?: string | null;
          rolePublicName?: string | null;
          roleTechnicalName?: string | null;
        } | null;
      } | null>;
      lastMessageContent?: {
        __typename?: "LastMessageContentOutput";
        messageText?: string | null;
        messageContent?: Array<{
          __typename?: "MessageContentItemOutput";
          category?: string | null;
          fileExtension?: string | null;
          mimeType?: string | null;
          url?: string | null;
          fileName?: string | null;
          fileSize?: number | null;
          dimensions?: {
            __typename?: "MessageContentImageDimension";
            width: number;
            height: number;
          } | null;
        } | null> | null;
      } | null;
    } | null> | null;
  } | null;
};

export type UpdateMessageDataMutationVariables = Exact<{
  input?: InputMaybe<UpdateMessageDataInput>;
}>;

export type UpdateMessageDataMutation = {
  __typename?: "Mutation";
  updateMessageData?: {
    __typename?: "UpdateMessageDataOutput";
    channelId?: string | null;
    channelTypeName?: ChannelType | null;
    chatRoomId?: string | null;
    chatRoomStatus?: string | null;
    isClient?: boolean | null;
    unreadMessagesNumber?: number | null;
    chatRoomMessages?: Array<{
      __typename?: "MessageOutput";
      chatRoomId?: string | null;
      messageAuthorId?: string | null;
      messageChannelId?: string | null;
      messageContent?: string | null;
      messageCreatedDateTime?: string | null;
      messageDeletedDateTime?: string | null;
      messageId?: string | null;
      messageIsDelivered?: boolean | null;
      chatRoomStatus?: string | null;
      messageIsRead?: boolean | null;
      messageIsSent?: boolean | null;
      messageText?: string | null;
      messageUpdatedDateTime?: string | null;
      isFromClient: boolean;
      isFromBot: boolean;
    } | null> | null;
  } | null;
};

export type CreateMessageMutationVariables = Exact<{
  input?: InputMaybe<CreateChatRoomMessageInput>;
}>;

export type CreateMessageMutation = {
  __typename?: "Mutation";
  createMessage?: {
    __typename?: "MessageOutput";
    chatRoomId?: string | null;
    messageAuthorId?: string | null;
    messageChannelId?: string | null;
    messageContent?: string | null;
    messageCreatedDateTime?: string | null;
    messageDeletedDateTime?: string | null;
    messageId?: string | null;
    messageIsDelivered?: boolean | null;
    chatRoomStatus?: string | null;
    messageIsRead?: boolean | null;
    messageIsSent?: boolean | null;
    messageText?: string | null;
    messageUpdatedDateTime?: string | null;
    isFromClient: boolean;
    isFromBot: boolean;
  } | null;
};

export type CreateCommentOnClient2MutationVariables = Exact<{
  input?: InputMaybe<CreateCommentOnClientInput>;
}>;

export type CreateCommentOnClient2Mutation = {
  __typename?: "Mutation";
  createCommentOnClient2?: {
    __typename?: "CommentOutput2";
    comment?: string | null;
    commentId?: string | null;
    createdAt?: string | null;
    clientId?: string | null;
    operator?: {
      __typename?: "UserOutput";
      id: string;
      optionalName: string;
      common?: {
        __typename?: "CommonUserFields";
        createdAt: string;
        autoNickname: string;
        userpicUrl: string;
        firstName?: string | null;
        lastName?: string | null;
        email?: string | null;
        phone?: string | null;
      } | null;
    } | null;
  } | null;
};

export type DeleteCommentMutationVariables = Exact<{
  commentId: Scalars["String"];
}>;

export type DeleteCommentMutation = {
  __typename?: "Mutation";
  deleteComment?: {
    __typename?: "MutationStatus";
    status?: string | null;
  } | null;
};

export type SetNodesAndEdgesMutationVariables = Exact<{
  input?: InputMaybe<SetNodesAndEdgesInput>;
}>;

export type SetNodesAndEdgesMutation = {
  __typename?: "Mutation";
  setNodesAndEdges?: {
    __typename?: "SetNodesAndEdgesOutput";
    nodes?: Array<{
      __typename?: "NodeOutput";
      id?: string | null;
      localId?: string | null;
      flowId?: string | null;
      coordinates?: Array<number | null> | null;
      nodeType?: NodeType | null;
      fileUrl?: string | null;
      imageUrl?: string | null;
      text?: string | null;
      childFlowId?: string | null;
      isDelete?: boolean | null;
      teamsIds?: Array<string | null> | null;
      botId?: string | null;
      exceptionText?: string | null;
      saveTo?: SaveTo | null;
      appId?: string | null;
      appVersionId?: string | null;
      appInstalledId?: string | null;
      buttons?: Array<{
        __typename?: "ButtonOutput";
        id?: string | null;
        localId?: string | null;
        name?: string | null;
        nodeId?: string | null;
        localNodeId?: string | null;
        buttonOrder?: number | null;
        botId?: string | null;
      } | null> | null;
    } | null> | null;
    edges?: Array<{
      __typename?: "EdgeOutput";
      id?: string | null;
      localId?: string | null;
      botId?: string | null;
      flowId?: string | null;
      childId?: string | null;
      localChildId?: string | null;
      parentId?: string | null;
      localParentId?: string | null;
      handleParentId?: string | null;
      localHandleParentId?: string | null;
      isDelete?: boolean | null;
    } | null> | null;
  } | null;
};

export type CreateChatSnippetMutationVariables = Exact<{
  userId: Scalars["String"];
  snippet: Scalars["String"];
  categoryId?: InputMaybe<Scalars["String"]>;
  teamsIds?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
}>;

export type CreateChatSnippetMutation = {
  __typename?: "Mutation";
  createChatSnippet?: {
    __typename?: "ChatSnippetsOutput";
    snippetId?: string | null;
    userId?: string | null;
    categoryId?: string | null;
    snippet?: string | null;
    teamsIds?: Array<string | null> | null;
    isFavorite: boolean;
  } | null;
};

export type UpdateChatSnippetMutationVariables = Exact<{
  snippetId: Scalars["String"];
  userId: Scalars["String"];
  snippet?: InputMaybe<Scalars["String"]>;
  categoryId?: InputMaybe<Scalars["String"]>;
  teamsIds?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
}>;

export type UpdateChatSnippetMutation = {
  __typename?: "Mutation";
  updateChatSnippet?: {
    __typename?: "ChatSnippetsOutput";
    snippetId?: string | null;
    userId?: string | null;
    categoryId?: string | null;
    snippet?: string | null;
    teamsIds?: Array<string | null> | null;
    isFavorite: boolean;
  } | null;
};

export type CreateChatSnippetsCategoryMutationVariables = Exact<{
  userId: Scalars["String"];
  categoryName: Scalars["String"];
  parentId?: InputMaybe<Scalars["String"]>;
}>;

export type CreateChatSnippetsCategoryMutation = {
  __typename?: "Mutation";
  createChatSnippetsCategory?: {
    __typename?: "ChatSnippetsCategoriesOutput";
    categoryId?: string | null;
    categoryName?: string | null;
    parentId?: string | null;
    userId?: string | null;
    totalSnippetsCount?: number | null;
  } | null;
};

export type UpdateChatSnippetsCategoryMutationVariables = Exact<{
  categoryId: Scalars["String"];
  userId: Scalars["String"];
  categoryName?: InputMaybe<Scalars["String"]>;
  parentId?: InputMaybe<Scalars["String"]>;
}>;

export type UpdateChatSnippetsCategoryMutation = {
  __typename?: "Mutation";
  updateChatSnippetsCategory?: {
    __typename?: "ChatSnippetsCategoriesOutput";
    categoryId?: string | null;
    categoryName?: string | null;
    parentId?: string | null;
    userId?: string | null;
    totalSnippetsCount?: number | null;
  } | null;
};

export type DeleteChatSnippetsCategoryMutationVariables = Exact<{
  userId: Scalars["String"];
  categoryId: Scalars["String"];
}>;

export type DeleteChatSnippetsCategoryMutation = {
  __typename?: "Mutation";
  deleteChatSnippetsCategory?: {
    __typename?: "ChatSnippetsCategoriesOutput";
    categoryId?: string | null;
    categoryName?: string | null;
    parentId?: string | null;
    userId?: string | null;
    totalSnippetsCount?: number | null;
  } | null;
};

export type AddSnippetToFavoritesMutationVariables = Exact<{
  userId: Scalars["String"];
  snippetId: Scalars["String"];
}>;

export type AddSnippetToFavoritesMutation = {
  __typename?: "Mutation";
  addSnippetToFavorites?: {
    __typename?: "ChatSnippetsFavoritesOutput";
    snippetId?: string | null;
    userId?: string | null;
    isFavorite: boolean;
  } | null;
};

export type RemoveSnippetFromFavoriteMutationVariables = Exact<{
  userId: Scalars["String"];
  snippetId: Scalars["String"];
}>;

export type RemoveSnippetFromFavoriteMutation = {
  __typename?: "Mutation";
  removeSnippetFromFavorite?: {
    __typename?: "ChatSnippetsFavoritesOutput";
    snippetId?: string | null;
    userId?: string | null;
    isFavorite: boolean;
  } | null;
};

export type DeleteChatSnippetMutationVariables = Exact<{
  userId: Scalars["String"];
  snippetId: Scalars["String"];
}>;

export type DeleteChatSnippetMutation = {
  __typename?: "Mutation";
  deleteChatSnippet?: {
    __typename?: "ChatSnippetsOutput";
    snippetId?: string | null;
    userId?: string | null;
    categoryId?: string | null;
    snippet?: string | null;
    teamsIds?: Array<string | null> | null;
    isFavorite: boolean;
  } | null;
};

export type CreateTeamMutationVariables = Exact<{
  input: CreateTeamInput;
}>;

export type CreateTeamMutation = {
  __typename?: "Mutation";
  createTeam?: {
    __typename?: "TeamOutput";
    id: string;
    name: string;
    createdAt: string;
    updatedAt: string;
    deletedAt?: string | null;
    users?: Array<{
      __typename?: "UserOutput";
      id: string;
      optionalName: string;
      thirdParty?: string | null;
      roleId?: string | null;
      position?: string | null;
      common?: {
        __typename?: "CommonUserFields";
        createdAt: string;
        autoNickname: string;
        userpicUrl: string;
        firstName?: string | null;
        lastName?: string | null;
        email?: string | null;
        phone?: string | null;
      } | null;
      onlineStatus?: {
        __typename?: "UserPresenceStatusOutput";
        userId?: string | null;
        presenceStatus?: PresenceStatus | null;
      } | null;
      teams?: Array<{
        __typename?: "TeamOutput";
        id: string;
        name: string;
        createdAt: string;
        updatedAt: string;
        deletedAt?: string | null;
      } | null> | null;
      role?: {
        __typename?: "RoleOutput";
        roleDescription?: string | null;
        roleId?: string | null;
        rolePublicName?: string | null;
        roleTechnicalName?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type UpdateTeamMutationVariables = Exact<{
  input: UpdateTeamInput;
}>;

export type UpdateTeamMutation = {
  __typename?: "Mutation";
  updateTeam?: {
    __typename?: "TeamOutput";
    id: string;
    name: string;
    createdAt: string;
    updatedAt: string;
    deletedAt?: string | null;
    users?: Array<{
      __typename?: "UserOutput";
      id: string;
      optionalName: string;
      thirdParty?: string | null;
      roleId?: string | null;
      position?: string | null;
      common?: {
        __typename?: "CommonUserFields";
        createdAt: string;
        autoNickname: string;
        userpicUrl: string;
        firstName?: string | null;
        lastName?: string | null;
        email?: string | null;
        phone?: string | null;
      } | null;
      onlineStatus?: {
        __typename?: "UserPresenceStatusOutput";
        userId?: string | null;
        presenceStatus?: PresenceStatus | null;
      } | null;
      teams?: Array<{
        __typename?: "TeamOutput";
        id: string;
        name: string;
        createdAt: string;
        updatedAt: string;
        deletedAt?: string | null;
      } | null> | null;
      role?: {
        __typename?: "RoleOutput";
        roleDescription?: string | null;
        roleId?: string | null;
        rolePublicName?: string | null;
        roleTechnicalName?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type DeleteTeamMutationVariables = Exact<{
  teamId: Scalars["String"];
}>;

export type DeleteTeamMutation = {
  __typename?: "Mutation";
  deleteTeam?: { __typename?: "MutationStatus"; status?: string | null } | null;
};

export type MakeAppsMutationVariables = Exact<{
  input?: InputMaybe<
    Array<InputMaybe<MakeAppsInput>> | InputMaybe<MakeAppsInput>
  >;
}>;

export type MakeAppsMutation = {
  __typename?: "Mutation";
  makeApps?: Array<{
    __typename?: "AppOutput";
    id: string;
    iconUrl: string;
    gallery?: string | null;
    type: AppType;
    tag: string;
    latestVersion: string;
    createdAt: string;
    isAvailable: boolean;
    isInstalled: boolean;
    name: {
      __typename?: "LanguageMap";
      en: string;
      ru?: string | null;
      af?: string | null;
      sq?: string | null;
      ar?: string | null;
      az?: string | null;
      bn?: string | null;
      bg?: string | null;
      ca?: string | null;
      zh?: string | null;
      hr?: string | null;
      cs?: string | null;
      da?: string | null;
      nl?: string | null;
      et?: string | null;
      fil?: string | null;
      fi?: string | null;
      fr?: string | null;
      ka?: string | null;
      de?: string | null;
      el?: string | null;
      gu?: string | null;
      ha?: string | null;
      he?: string | null;
      hi?: string | null;
      hu?: string | null;
      id?: string | null;
      ga?: string | null;
      it?: string | null;
      ja?: string | null;
      kn?: string | null;
      kk?: string | null;
      rw?: string | null;
      ko?: string | null;
      ky?: string | null;
      lo?: string | null;
      lv?: string | null;
      lt?: string | null;
      mk?: string | null;
      ms?: string | null;
      ml?: string | null;
      mr?: string | null;
      nb?: string | null;
      fa?: string | null;
      pl?: string | null;
      pt?: string | null;
      pa?: string | null;
      ro?: string | null;
      sr?: string | null;
      sk?: string | null;
      sl?: string | null;
      es?: string | null;
      sw?: string | null;
      sv?: string | null;
      ta?: string | null;
      te?: string | null;
      th?: string | null;
      tr?: string | null;
      uk?: string | null;
      ur?: string | null;
      uz?: string | null;
      vi?: string | null;
      zu?: string | null;
    };
    description?: {
      __typename?: "LanguageMap";
      en: string;
      ru?: string | null;
      af?: string | null;
      sq?: string | null;
      ar?: string | null;
      az?: string | null;
      bn?: string | null;
      bg?: string | null;
      ca?: string | null;
      zh?: string | null;
      hr?: string | null;
      cs?: string | null;
      da?: string | null;
      nl?: string | null;
      et?: string | null;
      fil?: string | null;
      fi?: string | null;
      fr?: string | null;
      ka?: string | null;
      de?: string | null;
      el?: string | null;
      gu?: string | null;
      ha?: string | null;
      he?: string | null;
      hi?: string | null;
      hu?: string | null;
      id?: string | null;
      ga?: string | null;
      it?: string | null;
      ja?: string | null;
      kn?: string | null;
      kk?: string | null;
      rw?: string | null;
      ko?: string | null;
      ky?: string | null;
      lo?: string | null;
      lv?: string | null;
      lt?: string | null;
      mk?: string | null;
      ms?: string | null;
      ml?: string | null;
      mr?: string | null;
      nb?: string | null;
      fa?: string | null;
      pl?: string | null;
      pt?: string | null;
      pa?: string | null;
      ro?: string | null;
      sr?: string | null;
      sk?: string | null;
      sl?: string | null;
      es?: string | null;
      sw?: string | null;
      sv?: string | null;
      ta?: string | null;
      te?: string | null;
      th?: string | null;
      tr?: string | null;
      uk?: string | null;
      ur?: string | null;
      uz?: string | null;
      vi?: string | null;
      zu?: string | null;
    } | null;
    instructions?: {
      __typename?: "LanguageMap";
      en: string;
      ru?: string | null;
      af?: string | null;
      sq?: string | null;
      ar?: string | null;
      az?: string | null;
      bn?: string | null;
      bg?: string | null;
      ca?: string | null;
      zh?: string | null;
      hr?: string | null;
      cs?: string | null;
      da?: string | null;
      nl?: string | null;
      et?: string | null;
      fil?: string | null;
      fi?: string | null;
      fr?: string | null;
      ka?: string | null;
      de?: string | null;
      el?: string | null;
      gu?: string | null;
      ha?: string | null;
      he?: string | null;
      hi?: string | null;
      hu?: string | null;
      id?: string | null;
      ga?: string | null;
      it?: string | null;
      ja?: string | null;
      kn?: string | null;
      kk?: string | null;
      rw?: string | null;
      ko?: string | null;
      ky?: string | null;
      lo?: string | null;
      lv?: string | null;
      lt?: string | null;
      mk?: string | null;
      ms?: string | null;
      ml?: string | null;
      mr?: string | null;
      nb?: string | null;
      fa?: string | null;
      pl?: string | null;
      pt?: string | null;
      pa?: string | null;
      ro?: string | null;
      sr?: string | null;
      sk?: string | null;
      sl?: string | null;
      es?: string | null;
      sw?: string | null;
      sv?: string | null;
      ta?: string | null;
      te?: string | null;
      th?: string | null;
      tr?: string | null;
      uk?: string | null;
      ur?: string | null;
      uz?: string | null;
      vi?: string | null;
      zu?: string | null;
    } | null;
    versions: Array<{
      __typename?: "AppVersion";
      version: string;
      createdAt: string;
      id: string;
      isInstalled: boolean;
      fields: Array<{
        __typename?: "AppFieldsGroup";
        g: AppFieldsGroupType;
        c: Array<{
          __typename?: "AppField";
          t: AppFieldTypeType;
          tg: string;
          dt?: AppFieldDataTypeType | null;
          dts?: Array<AppFieldDataTypesType> | null;
          r?: boolean | null;
          mn?: number | null;
          mx?: number | null;
          v?: string | null;
          n: {
            __typename?: "LanguageMap";
            en: string;
            ru?: string | null;
            af?: string | null;
            sq?: string | null;
            ar?: string | null;
            az?: string | null;
            bn?: string | null;
            bg?: string | null;
            ca?: string | null;
            zh?: string | null;
            hr?: string | null;
            cs?: string | null;
            da?: string | null;
            nl?: string | null;
            et?: string | null;
            fil?: string | null;
            fi?: string | null;
            fr?: string | null;
            ka?: string | null;
            de?: string | null;
            el?: string | null;
            gu?: string | null;
            ha?: string | null;
            he?: string | null;
            hi?: string | null;
            hu?: string | null;
            id?: string | null;
            ga?: string | null;
            it?: string | null;
            ja?: string | null;
            kn?: string | null;
            kk?: string | null;
            rw?: string | null;
            ko?: string | null;
            ky?: string | null;
            lo?: string | null;
            lv?: string | null;
            lt?: string | null;
            mk?: string | null;
            ms?: string | null;
            ml?: string | null;
            mr?: string | null;
            nb?: string | null;
            fa?: string | null;
            pl?: string | null;
            pt?: string | null;
            pa?: string | null;
            ro?: string | null;
            sr?: string | null;
            sk?: string | null;
            sl?: string | null;
            es?: string | null;
            sw?: string | null;
            sv?: string | null;
            ta?: string | null;
            te?: string | null;
            th?: string | null;
            tr?: string | null;
            uk?: string | null;
            ur?: string | null;
            uz?: string | null;
            vi?: string | null;
            zu?: string | null;
          };
          p?: {
            __typename?: "LanguageMap";
            en: string;
            ru?: string | null;
            af?: string | null;
            sq?: string | null;
            ar?: string | null;
            az?: string | null;
            bn?: string | null;
            bg?: string | null;
            ca?: string | null;
            zh?: string | null;
            hr?: string | null;
            cs?: string | null;
            da?: string | null;
            nl?: string | null;
            et?: string | null;
            fil?: string | null;
            fi?: string | null;
            fr?: string | null;
            ka?: string | null;
            de?: string | null;
            el?: string | null;
            gu?: string | null;
            ha?: string | null;
            he?: string | null;
            hi?: string | null;
            hu?: string | null;
            id?: string | null;
            ga?: string | null;
            it?: string | null;
            ja?: string | null;
            kn?: string | null;
            kk?: string | null;
            rw?: string | null;
            ko?: string | null;
            ky?: string | null;
            lo?: string | null;
            lv?: string | null;
            lt?: string | null;
            mk?: string | null;
            ms?: string | null;
            ml?: string | null;
            mr?: string | null;
            nb?: string | null;
            fa?: string | null;
            pl?: string | null;
            pt?: string | null;
            pa?: string | null;
            ro?: string | null;
            sr?: string | null;
            sk?: string | null;
            sl?: string | null;
            es?: string | null;
            sw?: string | null;
            sv?: string | null;
            ta?: string | null;
            te?: string | null;
            th?: string | null;
            tr?: string | null;
            uk?: string | null;
            ur?: string | null;
            uz?: string | null;
            vi?: string | null;
            zu?: string | null;
          } | null;
          d?: {
            __typename?: "LanguageMap";
            en: string;
            ru?: string | null;
            af?: string | null;
            sq?: string | null;
            ar?: string | null;
            az?: string | null;
            bn?: string | null;
            bg?: string | null;
            ca?: string | null;
            zh?: string | null;
            hr?: string | null;
            cs?: string | null;
            da?: string | null;
            nl?: string | null;
            et?: string | null;
            fil?: string | null;
            fi?: string | null;
            fr?: string | null;
            ka?: string | null;
            de?: string | null;
            el?: string | null;
            gu?: string | null;
            ha?: string | null;
            he?: string | null;
            hi?: string | null;
            hu?: string | null;
            id?: string | null;
            ga?: string | null;
            it?: string | null;
            ja?: string | null;
            kn?: string | null;
            kk?: string | null;
            rw?: string | null;
            ko?: string | null;
            ky?: string | null;
            lo?: string | null;
            lv?: string | null;
            lt?: string | null;
            mk?: string | null;
            ms?: string | null;
            ml?: string | null;
            mr?: string | null;
            nb?: string | null;
            fa?: string | null;
            pl?: string | null;
            pt?: string | null;
            pa?: string | null;
            ro?: string | null;
            sr?: string | null;
            sk?: string | null;
            sl?: string | null;
            es?: string | null;
            sw?: string | null;
            sv?: string | null;
            ta?: string | null;
            te?: string | null;
            th?: string | null;
            tr?: string | null;
            uk?: string | null;
            ur?: string | null;
            uz?: string | null;
            vi?: string | null;
            zu?: string | null;
          } | null;
          c?: Array<
            | {
                __typename?: "AppFieldInner";
                t: AppFieldTypeType;
                tg: string;
                dt?: AppFieldDataTypeType | null;
                dts?: Array<AppFieldDataTypesType> | null;
                r?: boolean | null;
                mn?: number | null;
                mx?: number | null;
                v?: string | null;
                n: {
                  __typename?: "LanguageMap";
                  en: string;
                  ru?: string | null;
                  af?: string | null;
                  sq?: string | null;
                  ar?: string | null;
                  az?: string | null;
                  bn?: string | null;
                  bg?: string | null;
                  ca?: string | null;
                  zh?: string | null;
                  hr?: string | null;
                  cs?: string | null;
                  da?: string | null;
                  nl?: string | null;
                  et?: string | null;
                  fil?: string | null;
                  fi?: string | null;
                  fr?: string | null;
                  ka?: string | null;
                  de?: string | null;
                  el?: string | null;
                  gu?: string | null;
                  ha?: string | null;
                  he?: string | null;
                  hi?: string | null;
                  hu?: string | null;
                  id?: string | null;
                  ga?: string | null;
                  it?: string | null;
                  ja?: string | null;
                  kn?: string | null;
                  kk?: string | null;
                  rw?: string | null;
                  ko?: string | null;
                  ky?: string | null;
                  lo?: string | null;
                  lv?: string | null;
                  lt?: string | null;
                  mk?: string | null;
                  ms?: string | null;
                  ml?: string | null;
                  mr?: string | null;
                  nb?: string | null;
                  fa?: string | null;
                  pl?: string | null;
                  pt?: string | null;
                  pa?: string | null;
                  ro?: string | null;
                  sr?: string | null;
                  sk?: string | null;
                  sl?: string | null;
                  es?: string | null;
                  sw?: string | null;
                  sv?: string | null;
                  ta?: string | null;
                  te?: string | null;
                  th?: string | null;
                  tr?: string | null;
                  uk?: string | null;
                  ur?: string | null;
                  uz?: string | null;
                  vi?: string | null;
                  zu?: string | null;
                };
                p?: {
                  __typename?: "LanguageMap";
                  en: string;
                  ru?: string | null;
                  af?: string | null;
                  sq?: string | null;
                  ar?: string | null;
                  az?: string | null;
                  bn?: string | null;
                  bg?: string | null;
                  ca?: string | null;
                  zh?: string | null;
                  hr?: string | null;
                  cs?: string | null;
                  da?: string | null;
                  nl?: string | null;
                  et?: string | null;
                  fil?: string | null;
                  fi?: string | null;
                  fr?: string | null;
                  ka?: string | null;
                  de?: string | null;
                  el?: string | null;
                  gu?: string | null;
                  ha?: string | null;
                  he?: string | null;
                  hi?: string | null;
                  hu?: string | null;
                  id?: string | null;
                  ga?: string | null;
                  it?: string | null;
                  ja?: string | null;
                  kn?: string | null;
                  kk?: string | null;
                  rw?: string | null;
                  ko?: string | null;
                  ky?: string | null;
                  lo?: string | null;
                  lv?: string | null;
                  lt?: string | null;
                  mk?: string | null;
                  ms?: string | null;
                  ml?: string | null;
                  mr?: string | null;
                  nb?: string | null;
                  fa?: string | null;
                  pl?: string | null;
                  pt?: string | null;
                  pa?: string | null;
                  ro?: string | null;
                  sr?: string | null;
                  sk?: string | null;
                  sl?: string | null;
                  es?: string | null;
                  sw?: string | null;
                  sv?: string | null;
                  ta?: string | null;
                  te?: string | null;
                  th?: string | null;
                  tr?: string | null;
                  uk?: string | null;
                  ur?: string | null;
                  uz?: string | null;
                  vi?: string | null;
                  zu?: string | null;
                } | null;
                d?: {
                  __typename?: "LanguageMap";
                  en: string;
                  ru?: string | null;
                  af?: string | null;
                  sq?: string | null;
                  ar?: string | null;
                  az?: string | null;
                  bn?: string | null;
                  bg?: string | null;
                  ca?: string | null;
                  zh?: string | null;
                  hr?: string | null;
                  cs?: string | null;
                  da?: string | null;
                  nl?: string | null;
                  et?: string | null;
                  fil?: string | null;
                  fi?: string | null;
                  fr?: string | null;
                  ka?: string | null;
                  de?: string | null;
                  el?: string | null;
                  gu?: string | null;
                  ha?: string | null;
                  he?: string | null;
                  hi?: string | null;
                  hu?: string | null;
                  id?: string | null;
                  ga?: string | null;
                  it?: string | null;
                  ja?: string | null;
                  kn?: string | null;
                  kk?: string | null;
                  rw?: string | null;
                  ko?: string | null;
                  ky?: string | null;
                  lo?: string | null;
                  lv?: string | null;
                  lt?: string | null;
                  mk?: string | null;
                  ms?: string | null;
                  ml?: string | null;
                  mr?: string | null;
                  nb?: string | null;
                  fa?: string | null;
                  pl?: string | null;
                  pt?: string | null;
                  pa?: string | null;
                  ro?: string | null;
                  sr?: string | null;
                  sk?: string | null;
                  sl?: string | null;
                  es?: string | null;
                  sw?: string | null;
                  sv?: string | null;
                  ta?: string | null;
                  te?: string | null;
                  th?: string | null;
                  tr?: string | null;
                  uk?: string | null;
                  ur?: string | null;
                  uz?: string | null;
                  vi?: string | null;
                  zu?: string | null;
                } | null;
              }
            | {
                __typename?: "AppFieldsGroupInner";
                g: AppFieldsGroupType;
                c: Array<{
                  __typename?: "AppFieldInner";
                  t: AppFieldTypeType;
                  tg: string;
                  dt?: AppFieldDataTypeType | null;
                  dts?: Array<AppFieldDataTypesType> | null;
                  r?: boolean | null;
                  mn?: number | null;
                  mx?: number | null;
                  v?: string | null;
                  n: {
                    __typename?: "LanguageMap";
                    en: string;
                    ru?: string | null;
                    af?: string | null;
                    sq?: string | null;
                    ar?: string | null;
                    az?: string | null;
                    bn?: string | null;
                    bg?: string | null;
                    ca?: string | null;
                    zh?: string | null;
                    hr?: string | null;
                    cs?: string | null;
                    da?: string | null;
                    nl?: string | null;
                    et?: string | null;
                    fil?: string | null;
                    fi?: string | null;
                    fr?: string | null;
                    ka?: string | null;
                    de?: string | null;
                    el?: string | null;
                    gu?: string | null;
                    ha?: string | null;
                    he?: string | null;
                    hi?: string | null;
                    hu?: string | null;
                    id?: string | null;
                    ga?: string | null;
                    it?: string | null;
                    ja?: string | null;
                    kn?: string | null;
                    kk?: string | null;
                    rw?: string | null;
                    ko?: string | null;
                    ky?: string | null;
                    lo?: string | null;
                    lv?: string | null;
                    lt?: string | null;
                    mk?: string | null;
                    ms?: string | null;
                    ml?: string | null;
                    mr?: string | null;
                    nb?: string | null;
                    fa?: string | null;
                    pl?: string | null;
                    pt?: string | null;
                    pa?: string | null;
                    ro?: string | null;
                    sr?: string | null;
                    sk?: string | null;
                    sl?: string | null;
                    es?: string | null;
                    sw?: string | null;
                    sv?: string | null;
                    ta?: string | null;
                    te?: string | null;
                    th?: string | null;
                    tr?: string | null;
                    uk?: string | null;
                    ur?: string | null;
                    uz?: string | null;
                    vi?: string | null;
                    zu?: string | null;
                  };
                  p?: {
                    __typename?: "LanguageMap";
                    en: string;
                    ru?: string | null;
                    af?: string | null;
                    sq?: string | null;
                    ar?: string | null;
                    az?: string | null;
                    bn?: string | null;
                    bg?: string | null;
                    ca?: string | null;
                    zh?: string | null;
                    hr?: string | null;
                    cs?: string | null;
                    da?: string | null;
                    nl?: string | null;
                    et?: string | null;
                    fil?: string | null;
                    fi?: string | null;
                    fr?: string | null;
                    ka?: string | null;
                    de?: string | null;
                    el?: string | null;
                    gu?: string | null;
                    ha?: string | null;
                    he?: string | null;
                    hi?: string | null;
                    hu?: string | null;
                    id?: string | null;
                    ga?: string | null;
                    it?: string | null;
                    ja?: string | null;
                    kn?: string | null;
                    kk?: string | null;
                    rw?: string | null;
                    ko?: string | null;
                    ky?: string | null;
                    lo?: string | null;
                    lv?: string | null;
                    lt?: string | null;
                    mk?: string | null;
                    ms?: string | null;
                    ml?: string | null;
                    mr?: string | null;
                    nb?: string | null;
                    fa?: string | null;
                    pl?: string | null;
                    pt?: string | null;
                    pa?: string | null;
                    ro?: string | null;
                    sr?: string | null;
                    sk?: string | null;
                    sl?: string | null;
                    es?: string | null;
                    sw?: string | null;
                    sv?: string | null;
                    ta?: string | null;
                    te?: string | null;
                    th?: string | null;
                    tr?: string | null;
                    uk?: string | null;
                    ur?: string | null;
                    uz?: string | null;
                    vi?: string | null;
                    zu?: string | null;
                  } | null;
                  d?: {
                    __typename?: "LanguageMap";
                    en: string;
                    ru?: string | null;
                    af?: string | null;
                    sq?: string | null;
                    ar?: string | null;
                    az?: string | null;
                    bn?: string | null;
                    bg?: string | null;
                    ca?: string | null;
                    zh?: string | null;
                    hr?: string | null;
                    cs?: string | null;
                    da?: string | null;
                    nl?: string | null;
                    et?: string | null;
                    fil?: string | null;
                    fi?: string | null;
                    fr?: string | null;
                    ka?: string | null;
                    de?: string | null;
                    el?: string | null;
                    gu?: string | null;
                    ha?: string | null;
                    he?: string | null;
                    hi?: string | null;
                    hu?: string | null;
                    id?: string | null;
                    ga?: string | null;
                    it?: string | null;
                    ja?: string | null;
                    kn?: string | null;
                    kk?: string | null;
                    rw?: string | null;
                    ko?: string | null;
                    ky?: string | null;
                    lo?: string | null;
                    lv?: string | null;
                    lt?: string | null;
                    mk?: string | null;
                    ms?: string | null;
                    ml?: string | null;
                    mr?: string | null;
                    nb?: string | null;
                    fa?: string | null;
                    pl?: string | null;
                    pt?: string | null;
                    pa?: string | null;
                    ro?: string | null;
                    sr?: string | null;
                    sk?: string | null;
                    sl?: string | null;
                    es?: string | null;
                    sw?: string | null;
                    sv?: string | null;
                    ta?: string | null;
                    te?: string | null;
                    th?: string | null;
                    tr?: string | null;
                    uk?: string | null;
                    ur?: string | null;
                    uz?: string | null;
                    vi?: string | null;
                    zu?: string | null;
                  } | null;
                }>;
              }
          > | null;
        }>;
      } | null>;
      installations: Array<{
        __typename?: "AppInstallation";
        id: string;
        createdAt: string;
        method?: MethodType | null;
        localId?: string | null;
        settings?: Array<{
          __typename?: "AppSettingsGroup";
          g: AppFieldsGroupType;
          c: Array<{
            __typename?: "AppSettingsField";
            tg: string;
            dt: AppFieldSettingsDataTypeType;
            v: string;
            c?: Array<
              | {
                  __typename?: "AppSettingsFieldInner";
                  tg: string;
                  dt: AppFieldSettingsDataTypeType;
                  v: string;
                }
              | {
                  __typename?: "AppSettingsGroupInner";
                  g: AppFieldsGroupType;
                  c: Array<{
                    __typename?: "AppSettingsFieldInner";
                    tg: string;
                    dt: AppFieldSettingsDataTypeType;
                    v: string;
                  }>;
                }
            > | null;
          }>;
        } | null> | null;
      } | null>;
    } | null>;
  } | null> | null;
};

export type CreateCalendarEventMutationVariables = Exact<{
  params: CreateCalendarEventInput;
}>;

export type CreateCalendarEventMutation = {
  __typename?: "Mutation";
  createCalendarEvent?: {
    __typename?: "BaseCalendarEventOutput";
    actionType?: CalendarEventActionType | null;
    event?: {
      __typename?: "CalendarEventOutput";
      accountId?: string | null;
      busy?: boolean | null;
      calendarId?: string | null;
      customerEventId?: string | null;
      description?: string | null;
      hideParticipants?: boolean | null;
      icalUid?: string | null;
      id?: string | null;
      location?: string | null;
      messageId?: string | null;
      object?: string | null;
      organizerEmail?: string | null;
      organizerName?: string | null;
      owner?: string | null;
      readOnly?: boolean | null;
      reminders?: string | null;
      status?: string | null;
      title?: string | null;
      updatedAt?: string | null;
      visibility?: string | null;
      startDateTime?: string | null;
      endDateTime?: string | null;
      participants?: Array<{
        __typename?: "EventParticipantOutput";
        comment?: string | null;
        email?: string | null;
        name?: string | null;
        phoneNumber?: string | null;
        status?: string | null;
      } | null> | null;
    } | null;
  } | null;
};

export type UpdateCalendarEventMutationVariables = Exact<{
  params: UpdateCalendarEventInput;
}>;

export type UpdateCalendarEventMutation = {
  __typename?: "Mutation";
  updateCalendarEvent?: {
    __typename?: "BaseCalendarEventOutput";
    actionType?: CalendarEventActionType | null;
    event?: {
      __typename?: "CalendarEventOutput";
      accountId?: string | null;
      busy?: boolean | null;
      calendarId?: string | null;
      customerEventId?: string | null;
      description?: string | null;
      hideParticipants?: boolean | null;
      icalUid?: string | null;
      id?: string | null;
      location?: string | null;
      messageId?: string | null;
      object?: string | null;
      organizerEmail?: string | null;
      organizerName?: string | null;
      owner?: string | null;
      readOnly?: boolean | null;
      reminders?: string | null;
      status?: string | null;
      title?: string | null;
      updatedAt?: string | null;
      visibility?: string | null;
      startDateTime?: string | null;
      endDateTime?: string | null;
      participants?: Array<{
        __typename?: "EventParticipantOutput";
        comment?: string | null;
        email?: string | null;
        name?: string | null;
        phoneNumber?: string | null;
        status?: string | null;
      } | null> | null;
    } | null;
  } | null;
};

export type DeleteCalendarEventMutationVariables = Exact<{
  params: DeleteCalendarInput;
}>;

export type DeleteCalendarEventMutation = {
  __typename?: "Mutation";
  deleteCalendarEvent?: {
    __typename?: "BaseCalendarEventOutput";
    actionType?: CalendarEventActionType | null;
    event?: {
      __typename?: "CalendarEventOutput";
      accountId?: string | null;
      busy?: boolean | null;
      calendarId?: string | null;
      customerEventId?: string | null;
      description?: string | null;
      hideParticipants?: boolean | null;
      icalUid?: string | null;
      id?: string | null;
      location?: string | null;
      messageId?: string | null;
      object?: string | null;
      organizerEmail?: string | null;
      organizerName?: string | null;
      owner?: string | null;
      readOnly?: boolean | null;
      reminders?: string | null;
      status?: string | null;
      title?: string | null;
      updatedAt?: string | null;
      visibility?: string | null;
      startDateTime?: string | null;
      endDateTime?: string | null;
      participants?: Array<{
        __typename?: "EventParticipantOutput";
        comment?: string | null;
        email?: string | null;
        name?: string | null;
        phoneNumber?: string | null;
        status?: string | null;
      } | null> | null;
    } | null;
  } | null;
};

export type CreateCalendarMutationVariables = Exact<{
  params: CreateCalendarInput;
}>;

export type CreateCalendarMutation = {
  __typename?: "Mutation";
  createCalendar?: {
    __typename?: "CalendarOutput";
    id?: string | null;
    accountId?: string | null;
    description?: string | null;
    name?: string | null;
    isPrimary?: boolean | null;
    location?: string | null;
    object?: string | null;
    readOnly?: boolean | null;
    timezone?: string | null;
  } | null;
};

export type MakeCustomFieldMutationVariables = Exact<{
  input?: InputMaybe<MakeCustomFieldInput>;
}>;

export type MakeCustomFieldMutation = {
  __typename?: "Mutation";
  makeCustomField?: {
    __typename?: "CustomFieldOutput";
    id: string;
    fieldName: string;
    dataType: CustomFieldDataType;
    description?: string | null;
    fieldOrder: number;
  } | null;
};

export type GetRolesQueryVariables = Exact<{ [key: string]: never }>;

export type GetRolesQuery = {
  __typename?: "Query";
  getRoles?: Array<{
    __typename?: "RoleOutput";
    roleDescription?: string | null;
    roleId?: string | null;
    rolePublicName?: string | null;
    roleTechnicalName?: string | null;
  } | null> | null;
};

export type GetUserQueryVariables = Exact<{
  userId?: InputMaybe<Scalars["String"]>;
}>;

export type GetUserQuery = {
  __typename?: "Query";
  getUser?: {
    __typename?: "UserOutput";
    id: string;
    optionalName: string;
    thirdParty?: string | null;
    roleId?: string | null;
    position?: string | null;
    common?: {
      __typename?: "CommonUserFields";
      createdAt: string;
      autoNickname: string;
      userpicUrl: string;
      firstName?: string | null;
      lastName?: string | null;
      email?: string | null;
      phone?: string | null;
    } | null;
    onlineStatus?: {
      __typename?: "UserPresenceStatusOutput";
      userId?: string | null;
      presenceStatus?: PresenceStatus | null;
    } | null;
    teams?: Array<{
      __typename?: "TeamOutput";
      id: string;
      name: string;
      createdAt: string;
      updatedAt: string;
      deletedAt?: string | null;
    } | null> | null;
    role?: {
      __typename?: "RoleOutput";
      roleDescription?: string | null;
      roleId?: string | null;
      rolePublicName?: string | null;
      roleTechnicalName?: string | null;
    } | null;
  } | null;
};

export type GetCommentsOnClient2QueryVariables = Exact<{
  clientId: Scalars["String"];
}>;

export type GetCommentsOnClient2Query = {
  __typename?: "Query";
  getCommentsOnClient2?: {
    __typename?: "GetCommentsOnClientOutput2";
    commentCount?: number | null;
    comments?: Array<{
      __typename?: "CommentOutput2";
      comment?: string | null;
      commentId?: string | null;
      createdAt?: string | null;
      clientId?: string | null;
      operator?: {
        __typename?: "UserOutput";
        id: string;
        optionalName: string;
        common?: {
          __typename?: "CommonUserFields";
          createdAt: string;
          autoNickname: string;
          userpicUrl: string;
          firstName?: string | null;
          lastName?: string | null;
          email?: string | null;
          phone?: string | null;
        } | null;
      } | null;
    } | null> | null;
  } | null;
};

export type GetUsersQueryVariables = Exact<{
  input: GetUsersInput;
}>;

export type GetUsersQuery = {
  __typename?: "Query";
  getUsers?: {
    __typename?: "GetUsersOutput";
    users?: Array<{
      __typename?: "UserOutput";
      id: string;
      optionalName: string;
      whatsappUsername?: string | null;
      whatsappProfile?: string | null;
      telegramUsername?: string | null;
      telegramUserId?: string | null;
      facebookMessengerPsid?: string | null;
      vkUserId?: string | null;
      instagramBsid?: string | null;
      instagramProfile?: string | null;
      thirdParty?: string | null;
      twilioSmsUserId?: string | null;
      nylasUserEmail?: string | null;
      mailgunUserEmail?: string | null;
      lastTicketAt?: string | null;
      roleId?: string | null;
      position?: string | null;
      common?: {
        __typename?: "CommonUserFields";
        createdAt: string;
        autoNickname: string;
        userpicUrl: string;
        firstName?: string | null;
        lastName?: string | null;
        email?: string | null;
        phone?: string | null;
      } | null;
      roomsLinks?: Array<{
        __typename?: "RoomLinkOutput";
        roomId?: string | null;
        roomStatus?: RoomStatus | null;
        channelType?: ChannelType | null;
        lastTicketAt?: string | null;
      } | null> | null;
      customFields?: Array<{
        __typename?: "UserCustomFieldOutput";
        fieldId: string;
        fieldName: string;
        dataType: CustomFieldDataType;
        description?: string | null;
        fieldOrder: number;
        value: string;
      } | null> | null;
      onlineStatus?: {
        __typename?: "UserPresenceStatusOutput";
        userId?: string | null;
        presenceStatus?: PresenceStatus | null;
      } | null;
      teams?: Array<{
        __typename?: "TeamOutput";
        id: string;
        name: string;
        createdAt: string;
        updatedAt: string;
        deletedAt?: string | null;
      } | null> | null;
      role?: {
        __typename?: "RoleOutput";
        roleDescription?: string | null;
        roleId?: string | null;
        rolePublicName?: string | null;
        roleTechnicalName?: string | null;
      } | null;
    } | null> | null;
    pagination?: {
      __typename?: "PaginationOutput";
      itemsPerPage?: number | null;
      pageNumber?: number | null;
      totalItems?: number | null;
    } | null;
  } | null;
};

export type GetChannels2QueryVariables = Exact<{
  isActiveOnly?: Scalars["Boolean"];
}>;

export type GetChannels2Query = {
  __typename?: "Query";
  getChannels2: Array<{
    __typename?: "ChannelOutput2";
    id: string;
    publicId: string;
    name: string;
    link: string;
    type: ChannelType;
    status: ChannelStatus;
    createdAt: string;
    updatedAt: string;
    deletedAt?: string | null;
    bot?: { __typename?: "BotOutput"; id: string; name?: string | null } | null;
  } | null>;
};

export type GetBotsQueryVariables = Exact<{
  status: BotStatus;
}>;

export type GetBotsQuery = {
  __typename?: "Query";
  getBots?: Array<{
    __typename?: "BotOutput";
    isActive?: boolean | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    publishedAt?: string | null;
    status?: BotStatus | null;
    sessionsCount?: number | null;
    botType?: BotType | null;
    teamsIds?: Array<string | null> | null;
    id: string;
    name?: string | null;
    connectedChannels?: Array<{
      __typename?: "ChannelOutput2";
      id: string;
      publicId: string;
      name: string;
      link: string;
      type: ChannelType;
      status: ChannelStatus;
      createdAt: string;
      updatedAt: string;
      deletedAt?: string | null;
      bot?: {
        __typename?: "BotOutput";
        id: string;
        name?: string | null;
      } | null;
    } | null> | null;
  } | null> | null;
};

export type GetBotQueryVariables = Exact<{
  id: Scalars["String"];
}>;

export type GetBotQuery = {
  __typename?: "Query";
  getBot?: {
    __typename?: "BotOutput";
    isActive?: boolean | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    publishedAt?: string | null;
    status?: BotStatus | null;
    sessionsCount?: number | null;
    botType?: BotType | null;
    teamsIds?: Array<string | null> | null;
    id: string;
    name?: string | null;
    connectedChannels?: Array<{
      __typename?: "ChannelOutput2";
      id: string;
      publicId: string;
      name: string;
      link: string;
      type: ChannelType;
      status: ChannelStatus;
      createdAt: string;
      updatedAt: string;
      deletedAt?: string | null;
      bot?: {
        __typename?: "BotOutput";
        id: string;
        name?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type GetFlowsQueryVariables = Exact<{
  botId: Scalars["String"];
}>;

export type GetFlowsQuery = {
  __typename?: "Query";
  getFlows?: Array<{
    __typename?: "FlowOutput";
    id?: string | null;
    botId?: string | null;
    name?: string | null;
    createdAt?: string | null;
    isMain?: boolean | null;
    updatedAt?: string | null;
  } | null> | null;
};

export type GetFlowQueryVariables = Exact<{
  input?: InputMaybe<GetFlowInput>;
}>;

export type GetFlowQuery = {
  __typename?: "Query";
  getFlow?: {
    __typename?: "FlowOutput";
    id?: string | null;
    botId?: string | null;
    name?: string | null;
    createdAt?: string | null;
    isMain?: boolean | null;
    updatedAt?: string | null;
  } | null;
};

export type GetTeamsQueryVariables = Exact<{ [key: string]: never }>;

export type GetTeamsQuery = {
  __typename?: "Query";
  getTeams?: Array<{
    __typename?: "TeamOutput";
    id: string;
    name: string;
    createdAt: string;
    updatedAt: string;
    deletedAt?: string | null;
    users?: Array<{
      __typename?: "UserOutput";
      id: string;
      optionalName: string;
      thirdParty?: string | null;
      roleId?: string | null;
      position?: string | null;
      common?: {
        __typename?: "CommonUserFields";
        createdAt: string;
        autoNickname: string;
        userpicUrl: string;
        firstName?: string | null;
        lastName?: string | null;
        email?: string | null;
        phone?: string | null;
      } | null;
      onlineStatus?: {
        __typename?: "UserPresenceStatusOutput";
        userId?: string | null;
        presenceStatus?: PresenceStatus | null;
      } | null;
      teams?: Array<{
        __typename?: "TeamOutput";
        id: string;
        name: string;
        createdAt: string;
        updatedAt: string;
        deletedAt?: string | null;
      } | null> | null;
      role?: {
        __typename?: "RoleOutput";
        roleDescription?: string | null;
        roleId?: string | null;
        rolePublicName?: string | null;
        roleTechnicalName?: string | null;
      } | null;
    } | null> | null;
  } | null> | null;
};

export type GetRooms2QueryVariables = Exact<{
  input: GetRoomsInput;
}>;

export type GetRooms2Query = {
  __typename?: "Query";
  getRooms2?: {
    __typename?: "GetRooms2Output";
    hasUnreadMessages?: boolean | null;
    requestedStatus?: RoomStatus | null;
    error?: string | null;
    rooms?: Array<{
      __typename?: "RoomOutput2";
      id: string;
      status: RoomStatus;
      previousStatus?: RoomStatus | null;
      teamsIds: Array<string | null>;
      previousTeamsIds?: Array<string | null> | null;
      lastMessageAt?: string | null;
      lastClientMessageAt?: string | null;
      lastTicketAt?: string | null;
      unreadMessagesNumber: number;
      isExpired: boolean;
      channel: {
        __typename?: "ChannelOutput2";
        id: string;
        publicId: string;
        name: string;
        link: string;
        type: ChannelType;
        status: ChannelStatus;
        createdAt: string;
        updatedAt: string;
        deletedAt?: string | null;
        bot?: {
          __typename?: "BotOutput";
          id: string;
          name?: string | null;
        } | null;
      };
      client: {
        __typename?: "UserOutput";
        id: string;
        optionalName: string;
        whatsappUsername?: string | null;
        whatsappProfile?: string | null;
        telegramUsername?: string | null;
        telegramUserId?: string | null;
        facebookMessengerPsid?: string | null;
        vkUserId?: string | null;
        instagramBsid?: string | null;
        instagramProfile?: string | null;
        thirdParty?: string | null;
        twilioSmsUserId?: string | null;
        nylasUserEmail?: string | null;
        mailgunUserEmail?: string | null;
        lastTicketAt?: string | null;
        common?: {
          __typename?: "CommonUserFields";
          createdAt: string;
          autoNickname: string;
          userpicUrl: string;
          firstName?: string | null;
          lastName?: string | null;
          email?: string | null;
          phone?: string | null;
        } | null;
        roomsLinks?: Array<{
          __typename?: "RoomLinkOutput";
          roomId?: string | null;
          roomStatus?: RoomStatus | null;
          channelType?: ChannelType | null;
          lastTicketAt?: string | null;
        } | null> | null;
        customFields?: Array<{
          __typename?: "UserCustomFieldOutput";
          fieldId: string;
          fieldName: string;
          dataType: CustomFieldDataType;
          description?: string | null;
          fieldOrder: number;
          value: string;
        } | null> | null;
      };
      activeOperator?: {
        __typename?: "UserOutput";
        id: string;
        optionalName: string;
        thirdParty?: string | null;
        roleId?: string | null;
        position?: string | null;
        common?: {
          __typename?: "CommonUserFields";
          createdAt: string;
          autoNickname: string;
          userpicUrl: string;
          firstName?: string | null;
          lastName?: string | null;
          email?: string | null;
          phone?: string | null;
        } | null;
        onlineStatus?: {
          __typename?: "UserPresenceStatusOutput";
          userId?: string | null;
          presenceStatus?: PresenceStatus | null;
        } | null;
        teams?: Array<{
          __typename?: "TeamOutput";
          id: string;
          name: string;
          createdAt: string;
          updatedAt: string;
          deletedAt?: string | null;
        } | null> | null;
        role?: {
          __typename?: "RoleOutput";
          roleDescription?: string | null;
          roleId?: string | null;
          rolePublicName?: string | null;
          roleTechnicalName?: string | null;
        } | null;
      } | null;
      operators: Array<{
        __typename?: "UserOutput";
        id: string;
        optionalName: string;
        thirdParty?: string | null;
        roleId?: string | null;
        position?: string | null;
        common?: {
          __typename?: "CommonUserFields";
          createdAt: string;
          autoNickname: string;
          userpicUrl: string;
          firstName?: string | null;
          lastName?: string | null;
          email?: string | null;
          phone?: string | null;
        } | null;
        onlineStatus?: {
          __typename?: "UserPresenceStatusOutput";
          userId?: string | null;
          presenceStatus?: PresenceStatus | null;
        } | null;
        teams?: Array<{
          __typename?: "TeamOutput";
          id: string;
          name: string;
          createdAt: string;
          updatedAt: string;
          deletedAt?: string | null;
        } | null> | null;
        role?: {
          __typename?: "RoleOutput";
          roleDescription?: string | null;
          roleId?: string | null;
          rolePublicName?: string | null;
          roleTechnicalName?: string | null;
        } | null;
      } | null>;
      lastMessageContent?: {
        __typename?: "LastMessageContentOutput";
        messageText?: string | null;
        messageContent?: Array<{
          __typename?: "MessageContentItemOutput";
          category?: string | null;
          fileExtension?: string | null;
          mimeType?: string | null;
          url?: string | null;
          fileName?: string | null;
          fileSize?: number | null;
          dimensions?: {
            __typename?: "MessageContentImageDimension";
            width: number;
            height: number;
          } | null;
        } | null> | null;
      } | null;
    } | null> | null;
    pagination?: {
      __typename?: "PaginationViaCursorOutput";
      totalItems: number;
      nextCursor?: string | null;
    } | null;
  } | null;
};

export type GetRoom2QueryVariables = Exact<{
  input: GetRoomInput;
}>;

export type GetRoom2Query = {
  __typename?: "Query";
  getRoom2?: {
    __typename?: "GetRoom2Output";
    error?: string | null;
    room?: {
      __typename?: "RoomOutput2";
      id: string;
      status: RoomStatus;
      previousStatus?: RoomStatus | null;
      teamsIds: Array<string | null>;
      previousTeamsIds?: Array<string | null> | null;
      lastMessageAt?: string | null;
      lastClientMessageAt?: string | null;
      lastTicketAt?: string | null;
      unreadMessagesNumber: number;
      isExpired: boolean;
      channel: {
        __typename?: "ChannelOutput2";
        id: string;
        publicId: string;
        name: string;
        link: string;
        type: ChannelType;
        status: ChannelStatus;
        createdAt: string;
        updatedAt: string;
        deletedAt?: string | null;
        bot?: {
          __typename?: "BotOutput";
          id: string;
          name?: string | null;
        } | null;
      };
      client: {
        __typename?: "UserOutput";
        id: string;
        optionalName: string;
        whatsappUsername?: string | null;
        whatsappProfile?: string | null;
        telegramUsername?: string | null;
        telegramUserId?: string | null;
        facebookMessengerPsid?: string | null;
        vkUserId?: string | null;
        instagramBsid?: string | null;
        instagramProfile?: string | null;
        thirdParty?: string | null;
        twilioSmsUserId?: string | null;
        nylasUserEmail?: string | null;
        mailgunUserEmail?: string | null;
        lastTicketAt?: string | null;
        common?: {
          __typename?: "CommonUserFields";
          createdAt: string;
          autoNickname: string;
          userpicUrl: string;
          firstName?: string | null;
          lastName?: string | null;
          email?: string | null;
          phone?: string | null;
        } | null;
        roomsLinks?: Array<{
          __typename?: "RoomLinkOutput";
          roomId?: string | null;
          roomStatus?: RoomStatus | null;
          channelType?: ChannelType | null;
          lastTicketAt?: string | null;
        } | null> | null;
        customFields?: Array<{
          __typename?: "UserCustomFieldOutput";
          fieldId: string;
          fieldName: string;
          dataType: CustomFieldDataType;
          description?: string | null;
          fieldOrder: number;
          value: string;
        } | null> | null;
      };
      activeOperator?: {
        __typename?: "UserOutput";
        id: string;
        optionalName: string;
        thirdParty?: string | null;
        roleId?: string | null;
        position?: string | null;
        common?: {
          __typename?: "CommonUserFields";
          createdAt: string;
          autoNickname: string;
          userpicUrl: string;
          firstName?: string | null;
          lastName?: string | null;
          email?: string | null;
          phone?: string | null;
        } | null;
        onlineStatus?: {
          __typename?: "UserPresenceStatusOutput";
          userId?: string | null;
          presenceStatus?: PresenceStatus | null;
        } | null;
        teams?: Array<{
          __typename?: "TeamOutput";
          id: string;
          name: string;
          createdAt: string;
          updatedAt: string;
          deletedAt?: string | null;
        } | null> | null;
        role?: {
          __typename?: "RoleOutput";
          roleDescription?: string | null;
          roleId?: string | null;
          rolePublicName?: string | null;
          roleTechnicalName?: string | null;
        } | null;
      } | null;
      operators: Array<{
        __typename?: "UserOutput";
        id: string;
        optionalName: string;
        thirdParty?: string | null;
        roleId?: string | null;
        position?: string | null;
        common?: {
          __typename?: "CommonUserFields";
          createdAt: string;
          autoNickname: string;
          userpicUrl: string;
          firstName?: string | null;
          lastName?: string | null;
          email?: string | null;
          phone?: string | null;
        } | null;
        onlineStatus?: {
          __typename?: "UserPresenceStatusOutput";
          userId?: string | null;
          presenceStatus?: PresenceStatus | null;
        } | null;
        teams?: Array<{
          __typename?: "TeamOutput";
          id: string;
          name: string;
          createdAt: string;
          updatedAt: string;
          deletedAt?: string | null;
        } | null> | null;
        role?: {
          __typename?: "RoleOutput";
          roleDescription?: string | null;
          roleId?: string | null;
          rolePublicName?: string | null;
          roleTechnicalName?: string | null;
        } | null;
      } | null>;
      lastMessageContent?: {
        __typename?: "LastMessageContentOutput";
        messageText?: string | null;
        messageContent?: Array<{
          __typename?: "MessageContentItemOutput";
          category?: string | null;
          fileExtension?: string | null;
          mimeType?: string | null;
          url?: string | null;
          fileName?: string | null;
          fileSize?: number | null;
          dimensions?: {
            __typename?: "MessageContentImageDimension";
            width: number;
            height: number;
          } | null;
        } | null> | null;
      } | null;
    } | null;
  } | null;
};

export type GetChatRoomMessagesQueryVariables = Exact<{
  chatRoomId: Scalars["String"];
  fetchSize: Scalars["Int"];
  pagingState?: InputMaybe<Scalars["String"]>;
}>;

export type GetChatRoomMessagesQuery = {
  __typename?: "Query";
  getChatRoomMessages?: {
    __typename?: "GetChatRoomMessagesOutput";
    roomId?: string | null;
    pagingState?: string | null;
    chatRoomMessages?: Array<{
      __typename?: "MessageOutput";
      chatRoomId?: string | null;
      messageAuthorId?: string | null;
      messageChannelId?: string | null;
      messageContent?: string | null;
      messageCreatedDateTime?: string | null;
      messageDeletedDateTime?: string | null;
      messageId?: string | null;
      messageIsDelivered?: boolean | null;
      chatRoomStatus?: string | null;
      messageIsRead?: boolean | null;
      messageIsSent?: boolean | null;
      messageText?: string | null;
      messageUpdatedDateTime?: string | null;
      isFromClient: boolean;
      isFromBot: boolean;
    } | null> | null;
  } | null;
};

export type GetFoundMessagesQueryVariables = Exact<{
  chatRoomId: Scalars["String"];
  search: Scalars["String"];
}>;

export type GetFoundMessagesQuery = {
  __typename?: "Query";
  foundMessages?: Array<{
    __typename?: "MessageOutput";
    chatRoomId?: string | null;
    messageAuthorId?: string | null;
    messageChannelId?: string | null;
    messageContent?: string | null;
    messageCreatedDateTime?: string | null;
    messageDeletedDateTime?: string | null;
    messageId?: string | null;
    messageIsDelivered?: boolean | null;
    chatRoomStatus?: string | null;
    messageIsRead?: boolean | null;
    messageIsSent?: boolean | null;
    messageText?: string | null;
    messageUpdatedDateTime?: string | null;
    isFromClient: boolean;
    isFromBot: boolean;
  }> | null;
};

export type GetNodesQueryVariables = Exact<{
  input?: InputMaybe<GetNodesAndEdgesInput>;
}>;

export type GetNodesQuery = {
  __typename?: "Query";
  getNodes?: Array<{
    __typename?: "NodeOutput";
    id?: string | null;
    localId?: string | null;
    flowId?: string | null;
    coordinates?: Array<number | null> | null;
    nodeType?: NodeType | null;
    fileUrl?: string | null;
    imageUrl?: string | null;
    text?: string | null;
    childFlowId?: string | null;
    isDelete?: boolean | null;
    teamsIds?: Array<string | null> | null;
    botId?: string | null;
    exceptionText?: string | null;
    saveTo?: SaveTo | null;
    appId?: string | null;
    appVersionId?: string | null;
    appInstalledId?: string | null;
    buttons?: Array<{
      __typename?: "ButtonOutput";
      id?: string | null;
      localId?: string | null;
      name?: string | null;
      nodeId?: string | null;
      localNodeId?: string | null;
      buttonOrder?: number | null;
      botId?: string | null;
    } | null> | null;
  } | null> | null;
};

export type GetEdgesQueryVariables = Exact<{
  input?: InputMaybe<GetNodesAndEdgesInput>;
}>;

export type GetEdgesQuery = {
  __typename?: "Query";
  getEdges?: Array<{
    __typename?: "EdgeOutput";
    id?: string | null;
    localId?: string | null;
    botId?: string | null;
    flowId?: string | null;
    childId?: string | null;
    localChildId?: string | null;
    parentId?: string | null;
    localParentId?: string | null;
    handleParentId?: string | null;
    localHandleParentId?: string | null;
    isDelete?: boolean | null;
  } | null> | null;
};

export type GetChatSnippetsQueryVariables = Exact<{
  snippetId?: InputMaybe<Scalars["String"]>;
  categoryId?: InputMaybe<Scalars["String"]>;
  userId: Scalars["String"];
}>;

export type GetChatSnippetsQuery = {
  __typename?: "Query";
  getChatSnippets?: {
    __typename?: "GetChatSnippets";
    totalNumberOfSnippets?: number | null;
    snippets?: Array<{
      __typename?: "ChatSnippetsOutput";
      snippetId?: string | null;
      userId?: string | null;
      categoryId?: string | null;
      snippet?: string | null;
      teamsIds?: Array<string | null> | null;
      isFavorite: boolean;
    } | null> | null;
  } | null;
};

export type GetChatSnippetsFavoritesQueryVariables = Exact<{
  userId: Scalars["String"];
}>;

export type GetChatSnippetsFavoritesQuery = {
  __typename?: "Query";
  getChatSnippetsFavorites?: {
    __typename?: "GetChatSnippets";
    totalNumberOfSnippets?: number | null;
    snippets?: Array<{
      __typename?: "ChatSnippetsOutput";
      snippetId?: string | null;
      userId?: string | null;
      categoryId?: string | null;
      snippet?: string | null;
      teamsIds?: Array<string | null> | null;
      isFavorite: boolean;
    } | null> | null;
  } | null;
};

export type GetChatSnippetsCategoriesQueryVariables = Exact<{
  parentId?: InputMaybe<Scalars["String"]>;
  categoryId?: InputMaybe<Scalars["String"]>;
  userId?: InputMaybe<Scalars["String"]>;
}>;

export type GetChatSnippetsCategoriesQuery = {
  __typename?: "Query";
  getChatSnippetsCategories?: {
    __typename?: "GetChatSnippetsCategories";
    totalNumberOfCategories?: number | null;
    categories?: Array<{
      __typename?: "ChatSnippetsCategoriesOutput";
      categoryId?: string | null;
      categoryName?: string | null;
      parentId?: string | null;
      userId?: string | null;
      totalSnippetsCount?: number | null;
    } | null> | null;
  } | null;
};

export type RequestRoomsHistoryAsFileQueryVariables = Exact<{
  reportType: ReportType;
  userId: Scalars["String"];
  roomId?: InputMaybe<Scalars["String"]>;
  operatorId?: InputMaybe<Scalars["String"]>;
  dateStart?: InputMaybe<Scalars["String"]>;
  dateEnd?: InputMaybe<Scalars["String"]>;
}>;

export type RequestRoomsHistoryAsFileQuery = {
  __typename?: "Query";
  requestRoomsHistoryAsFile?: {
    __typename?: "FileItemOutput";
    id?: string | null;
    userId?: string | null;
    name?: string | null;
    url?: string | null;
    status?: FileStatus | null;
    createdAt?: string | null;
  } | null;
};

export type ValidateBotQueryVariables = Exact<{
  id: Scalars["String"];
}>;

export type ValidateBotQuery = {
  __typename?: "Query";
  validateBot?: Array<{
    __typename?: "ValidateBot";
    id?: string | null;
    errorMessage?: string | null;
    statusCode?: number | null;
    nodeType?: NodeType | null;
    flowId?: string | null;
  } | null> | null;
};

export type GetGeneralAnalyticsQueryVariables = Exact<{ [key: string]: never }>;

export type GetGeneralAnalyticsQuery = {
  __typename?: "Query";
  getGeneralAnalytics?: {
    __typename?: "GeneralAnalyticsOutput";
    totalChats: number;
    activeChats: number;
    totalContacts: number;
    operatorsPerformance: {
      __typename?: "OperatorsPerformance";
      updatedAt: string;
      series: Array<{
        __typename?: "OperatorsPerformanceSeries";
        name: AnalyticsOperatorsPerformanceSeriesStatus;
        data: Array<number | null>;
      } | null>;
    };
    chatsByChannels: {
      __typename?: "DataByChannelType";
      labels: Array<ChannelType | null>;
      series: Array<number | null>;
    };
    newChats7d: {
      __typename?: "LineChart";
      series: Array<{
        __typename?: "LineChartSeries";
        name: ChannelType;
        data: Array<number>;
      } | null>;
      xAxis: {
        __typename?: "LineChartXAxisCategories";
        categories: Array<string | null>;
      };
    };
  } | null;
};

export type GetContactsAnalyticsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetContactsAnalyticsQuery = {
  __typename?: "Query";
  getContactsAnalytics?: {
    __typename?: "ContactsAnalyticsOutput";
    totalContacts: number;
    newContactsToday: number;
    contactsByChannels: {
      __typename?: "DataByChannelType";
      labels: Array<ChannelType | null>;
      series: Array<number | null>;
    };
    activeChats: {
      __typename?: "DataByRoomStatus";
      labels: Array<RoomStatus | null>;
      series: Array<number | null>;
    };
    newContacts7d: {
      __typename?: "LineChart";
      series: Array<{
        __typename?: "LineChartSeries";
        name: ChannelType;
        data: Array<number>;
      } | null>;
      xAxis: {
        __typename?: "LineChartXAxisCategories";
        categories: Array<string | null>;
      };
    };
  } | null;
};

export type GetChatsAnalyticsQueryVariables = Exact<{
  input?: InputMaybe<GetChatsAnalyticsInput>;
}>;

export type GetChatsAnalyticsQuery = {
  __typename?: "Query";
  getChatsAnalytics?: {
    __typename?: "ChatsAnalyticsOutput";
    chatsProcessed: number;
    chatsUnprocessed: number;
    avgInvisible: number;
    avgNonAccepted: number;
    avgAccepted: number;
    chatsDistribution: {
      __typename?: "ChatsDistribution";
      labels: Array<AnalyticsChatsDistributionLabels>;
      series: Array<number>;
    };
    chatsRepeated: {
      __typename?: "ChatsRepeated";
      labels: Array<AnalyticsChatsRepeatedLabels>;
      series: Array<number>;
    };
    chatsByChannels: {
      __typename?: "LineChart";
      series: Array<{
        __typename?: "LineChartSeries";
        name: ChannelType;
        data: Array<number>;
      } | null>;
      xAxis: {
        __typename?: "LineChartXAxisCategories";
        categories: Array<string | null>;
      };
    };
  } | null;
};

export type GetOperatorsAnalyticsQueryVariables = Exact<{
  input?: InputMaybe<GetOperatorsAnalyticsInput>;
}>;

export type GetOperatorsAnalyticsQuery = {
  __typename?: "Query";
  getOperatorsAnalytics?: {
    __typename?: "OperatorsAnalyticsOutput";
    avgChatsPerOperatorPerDay: number;
    avgAcceptedToFirstMessage: number;
    avgAccepted: number;
    acceptedByOperatorsByPeriodMap: Array<{
      __typename?: "AnalyticsHeatMapSeriesItem";
      name: string;
      data: Array<{
        __typename?: "AnalyticsHeatMapSeriesDataItem";
        x: string;
        y: number;
      } | null>;
    } | null>;
    operatorsMetricsTable: Array<{
      __typename?: "AnalyticsOperatorsMetricsTableRow";
      operatorName: string;
      completedChats: number;
      avgFirstMessageSec: number;
      avgAcceptedSec: number;
    } | null>;
  } | null;
};

export type GetAppsQueryVariables = Exact<{
  ids?: InputMaybe<
    Array<InputMaybe<Scalars["ID"]>> | InputMaybe<Scalars["ID"]>
  >;
  types?: InputMaybe<Array<InputMaybe<AppType>> | InputMaybe<AppType>>;
  isAvailableOnly?: InputMaybe<Scalars["Boolean"]>;
  isInstalledOnly?: InputMaybe<Scalars["Boolean"]>;
}>;

export type GetAppsQuery = {
  __typename?: "Query";
  getApps?: Array<{
    __typename?: "AppOutput";
    id: string;
    iconUrl: string;
    gallery?: string | null;
    type: AppType;
    tag: string;
    latestVersion: string;
    createdAt: string;
    isAvailable: boolean;
    isInstalled: boolean;
    name: {
      __typename?: "LanguageMap";
      en: string;
      ru?: string | null;
      af?: string | null;
      sq?: string | null;
      ar?: string | null;
      az?: string | null;
      bn?: string | null;
      bg?: string | null;
      ca?: string | null;
      zh?: string | null;
      hr?: string | null;
      cs?: string | null;
      da?: string | null;
      nl?: string | null;
      et?: string | null;
      fil?: string | null;
      fi?: string | null;
      fr?: string | null;
      ka?: string | null;
      de?: string | null;
      el?: string | null;
      gu?: string | null;
      ha?: string | null;
      he?: string | null;
      hi?: string | null;
      hu?: string | null;
      id?: string | null;
      ga?: string | null;
      it?: string | null;
      ja?: string | null;
      kn?: string | null;
      kk?: string | null;
      rw?: string | null;
      ko?: string | null;
      ky?: string | null;
      lo?: string | null;
      lv?: string | null;
      lt?: string | null;
      mk?: string | null;
      ms?: string | null;
      ml?: string | null;
      mr?: string | null;
      nb?: string | null;
      fa?: string | null;
      pl?: string | null;
      pt?: string | null;
      pa?: string | null;
      ro?: string | null;
      sr?: string | null;
      sk?: string | null;
      sl?: string | null;
      es?: string | null;
      sw?: string | null;
      sv?: string | null;
      ta?: string | null;
      te?: string | null;
      th?: string | null;
      tr?: string | null;
      uk?: string | null;
      ur?: string | null;
      uz?: string | null;
      vi?: string | null;
      zu?: string | null;
    };
    description?: {
      __typename?: "LanguageMap";
      en: string;
      ru?: string | null;
      af?: string | null;
      sq?: string | null;
      ar?: string | null;
      az?: string | null;
      bn?: string | null;
      bg?: string | null;
      ca?: string | null;
      zh?: string | null;
      hr?: string | null;
      cs?: string | null;
      da?: string | null;
      nl?: string | null;
      et?: string | null;
      fil?: string | null;
      fi?: string | null;
      fr?: string | null;
      ka?: string | null;
      de?: string | null;
      el?: string | null;
      gu?: string | null;
      ha?: string | null;
      he?: string | null;
      hi?: string | null;
      hu?: string | null;
      id?: string | null;
      ga?: string | null;
      it?: string | null;
      ja?: string | null;
      kn?: string | null;
      kk?: string | null;
      rw?: string | null;
      ko?: string | null;
      ky?: string | null;
      lo?: string | null;
      lv?: string | null;
      lt?: string | null;
      mk?: string | null;
      ms?: string | null;
      ml?: string | null;
      mr?: string | null;
      nb?: string | null;
      fa?: string | null;
      pl?: string | null;
      pt?: string | null;
      pa?: string | null;
      ro?: string | null;
      sr?: string | null;
      sk?: string | null;
      sl?: string | null;
      es?: string | null;
      sw?: string | null;
      sv?: string | null;
      ta?: string | null;
      te?: string | null;
      th?: string | null;
      tr?: string | null;
      uk?: string | null;
      ur?: string | null;
      uz?: string | null;
      vi?: string | null;
      zu?: string | null;
    } | null;
    instructions?: {
      __typename?: "LanguageMap";
      en: string;
      ru?: string | null;
      af?: string | null;
      sq?: string | null;
      ar?: string | null;
      az?: string | null;
      bn?: string | null;
      bg?: string | null;
      ca?: string | null;
      zh?: string | null;
      hr?: string | null;
      cs?: string | null;
      da?: string | null;
      nl?: string | null;
      et?: string | null;
      fil?: string | null;
      fi?: string | null;
      fr?: string | null;
      ka?: string | null;
      de?: string | null;
      el?: string | null;
      gu?: string | null;
      ha?: string | null;
      he?: string | null;
      hi?: string | null;
      hu?: string | null;
      id?: string | null;
      ga?: string | null;
      it?: string | null;
      ja?: string | null;
      kn?: string | null;
      kk?: string | null;
      rw?: string | null;
      ko?: string | null;
      ky?: string | null;
      lo?: string | null;
      lv?: string | null;
      lt?: string | null;
      mk?: string | null;
      ms?: string | null;
      ml?: string | null;
      mr?: string | null;
      nb?: string | null;
      fa?: string | null;
      pl?: string | null;
      pt?: string | null;
      pa?: string | null;
      ro?: string | null;
      sr?: string | null;
      sk?: string | null;
      sl?: string | null;
      es?: string | null;
      sw?: string | null;
      sv?: string | null;
      ta?: string | null;
      te?: string | null;
      th?: string | null;
      tr?: string | null;
      uk?: string | null;
      ur?: string | null;
      uz?: string | null;
      vi?: string | null;
      zu?: string | null;
    } | null;
    versions: Array<{
      __typename?: "AppVersion";
      version: string;
      createdAt: string;
      id: string;
      isInstalled: boolean;
      fields: Array<{
        __typename?: "AppFieldsGroup";
        g: AppFieldsGroupType;
        c: Array<{
          __typename?: "AppField";
          t: AppFieldTypeType;
          tg: string;
          dt?: AppFieldDataTypeType | null;
          dts?: Array<AppFieldDataTypesType> | null;
          r?: boolean | null;
          mn?: number | null;
          mx?: number | null;
          v?: string | null;
          n: {
            __typename?: "LanguageMap";
            en: string;
            ru?: string | null;
            af?: string | null;
            sq?: string | null;
            ar?: string | null;
            az?: string | null;
            bn?: string | null;
            bg?: string | null;
            ca?: string | null;
            zh?: string | null;
            hr?: string | null;
            cs?: string | null;
            da?: string | null;
            nl?: string | null;
            et?: string | null;
            fil?: string | null;
            fi?: string | null;
            fr?: string | null;
            ka?: string | null;
            de?: string | null;
            el?: string | null;
            gu?: string | null;
            ha?: string | null;
            he?: string | null;
            hi?: string | null;
            hu?: string | null;
            id?: string | null;
            ga?: string | null;
            it?: string | null;
            ja?: string | null;
            kn?: string | null;
            kk?: string | null;
            rw?: string | null;
            ko?: string | null;
            ky?: string | null;
            lo?: string | null;
            lv?: string | null;
            lt?: string | null;
            mk?: string | null;
            ms?: string | null;
            ml?: string | null;
            mr?: string | null;
            nb?: string | null;
            fa?: string | null;
            pl?: string | null;
            pt?: string | null;
            pa?: string | null;
            ro?: string | null;
            sr?: string | null;
            sk?: string | null;
            sl?: string | null;
            es?: string | null;
            sw?: string | null;
            sv?: string | null;
            ta?: string | null;
            te?: string | null;
            th?: string | null;
            tr?: string | null;
            uk?: string | null;
            ur?: string | null;
            uz?: string | null;
            vi?: string | null;
            zu?: string | null;
          };
          p?: {
            __typename?: "LanguageMap";
            en: string;
            ru?: string | null;
            af?: string | null;
            sq?: string | null;
            ar?: string | null;
            az?: string | null;
            bn?: string | null;
            bg?: string | null;
            ca?: string | null;
            zh?: string | null;
            hr?: string | null;
            cs?: string | null;
            da?: string | null;
            nl?: string | null;
            et?: string | null;
            fil?: string | null;
            fi?: string | null;
            fr?: string | null;
            ka?: string | null;
            de?: string | null;
            el?: string | null;
            gu?: string | null;
            ha?: string | null;
            he?: string | null;
            hi?: string | null;
            hu?: string | null;
            id?: string | null;
            ga?: string | null;
            it?: string | null;
            ja?: string | null;
            kn?: string | null;
            kk?: string | null;
            rw?: string | null;
            ko?: string | null;
            ky?: string | null;
            lo?: string | null;
            lv?: string | null;
            lt?: string | null;
            mk?: string | null;
            ms?: string | null;
            ml?: string | null;
            mr?: string | null;
            nb?: string | null;
            fa?: string | null;
            pl?: string | null;
            pt?: string | null;
            pa?: string | null;
            ro?: string | null;
            sr?: string | null;
            sk?: string | null;
            sl?: string | null;
            es?: string | null;
            sw?: string | null;
            sv?: string | null;
            ta?: string | null;
            te?: string | null;
            th?: string | null;
            tr?: string | null;
            uk?: string | null;
            ur?: string | null;
            uz?: string | null;
            vi?: string | null;
            zu?: string | null;
          } | null;
          d?: {
            __typename?: "LanguageMap";
            en: string;
            ru?: string | null;
            af?: string | null;
            sq?: string | null;
            ar?: string | null;
            az?: string | null;
            bn?: string | null;
            bg?: string | null;
            ca?: string | null;
            zh?: string | null;
            hr?: string | null;
            cs?: string | null;
            da?: string | null;
            nl?: string | null;
            et?: string | null;
            fil?: string | null;
            fi?: string | null;
            fr?: string | null;
            ka?: string | null;
            de?: string | null;
            el?: string | null;
            gu?: string | null;
            ha?: string | null;
            he?: string | null;
            hi?: string | null;
            hu?: string | null;
            id?: string | null;
            ga?: string | null;
            it?: string | null;
            ja?: string | null;
            kn?: string | null;
            kk?: string | null;
            rw?: string | null;
            ko?: string | null;
            ky?: string | null;
            lo?: string | null;
            lv?: string | null;
            lt?: string | null;
            mk?: string | null;
            ms?: string | null;
            ml?: string | null;
            mr?: string | null;
            nb?: string | null;
            fa?: string | null;
            pl?: string | null;
            pt?: string | null;
            pa?: string | null;
            ro?: string | null;
            sr?: string | null;
            sk?: string | null;
            sl?: string | null;
            es?: string | null;
            sw?: string | null;
            sv?: string | null;
            ta?: string | null;
            te?: string | null;
            th?: string | null;
            tr?: string | null;
            uk?: string | null;
            ur?: string | null;
            uz?: string | null;
            vi?: string | null;
            zu?: string | null;
          } | null;
          c?: Array<
            | {
                __typename?: "AppFieldInner";
                t: AppFieldTypeType;
                tg: string;
                dt?: AppFieldDataTypeType | null;
                dts?: Array<AppFieldDataTypesType> | null;
                r?: boolean | null;
                mn?: number | null;
                mx?: number | null;
                v?: string | null;
                n: {
                  __typename?: "LanguageMap";
                  en: string;
                  ru?: string | null;
                  af?: string | null;
                  sq?: string | null;
                  ar?: string | null;
                  az?: string | null;
                  bn?: string | null;
                  bg?: string | null;
                  ca?: string | null;
                  zh?: string | null;
                  hr?: string | null;
                  cs?: string | null;
                  da?: string | null;
                  nl?: string | null;
                  et?: string | null;
                  fil?: string | null;
                  fi?: string | null;
                  fr?: string | null;
                  ka?: string | null;
                  de?: string | null;
                  el?: string | null;
                  gu?: string | null;
                  ha?: string | null;
                  he?: string | null;
                  hi?: string | null;
                  hu?: string | null;
                  id?: string | null;
                  ga?: string | null;
                  it?: string | null;
                  ja?: string | null;
                  kn?: string | null;
                  kk?: string | null;
                  rw?: string | null;
                  ko?: string | null;
                  ky?: string | null;
                  lo?: string | null;
                  lv?: string | null;
                  lt?: string | null;
                  mk?: string | null;
                  ms?: string | null;
                  ml?: string | null;
                  mr?: string | null;
                  nb?: string | null;
                  fa?: string | null;
                  pl?: string | null;
                  pt?: string | null;
                  pa?: string | null;
                  ro?: string | null;
                  sr?: string | null;
                  sk?: string | null;
                  sl?: string | null;
                  es?: string | null;
                  sw?: string | null;
                  sv?: string | null;
                  ta?: string | null;
                  te?: string | null;
                  th?: string | null;
                  tr?: string | null;
                  uk?: string | null;
                  ur?: string | null;
                  uz?: string | null;
                  vi?: string | null;
                  zu?: string | null;
                };
                p?: {
                  __typename?: "LanguageMap";
                  en: string;
                  ru?: string | null;
                  af?: string | null;
                  sq?: string | null;
                  ar?: string | null;
                  az?: string | null;
                  bn?: string | null;
                  bg?: string | null;
                  ca?: string | null;
                  zh?: string | null;
                  hr?: string | null;
                  cs?: string | null;
                  da?: string | null;
                  nl?: string | null;
                  et?: string | null;
                  fil?: string | null;
                  fi?: string | null;
                  fr?: string | null;
                  ka?: string | null;
                  de?: string | null;
                  el?: string | null;
                  gu?: string | null;
                  ha?: string | null;
                  he?: string | null;
                  hi?: string | null;
                  hu?: string | null;
                  id?: string | null;
                  ga?: string | null;
                  it?: string | null;
                  ja?: string | null;
                  kn?: string | null;
                  kk?: string | null;
                  rw?: string | null;
                  ko?: string | null;
                  ky?: string | null;
                  lo?: string | null;
                  lv?: string | null;
                  lt?: string | null;
                  mk?: string | null;
                  ms?: string | null;
                  ml?: string | null;
                  mr?: string | null;
                  nb?: string | null;
                  fa?: string | null;
                  pl?: string | null;
                  pt?: string | null;
                  pa?: string | null;
                  ro?: string | null;
                  sr?: string | null;
                  sk?: string | null;
                  sl?: string | null;
                  es?: string | null;
                  sw?: string | null;
                  sv?: string | null;
                  ta?: string | null;
                  te?: string | null;
                  th?: string | null;
                  tr?: string | null;
                  uk?: string | null;
                  ur?: string | null;
                  uz?: string | null;
                  vi?: string | null;
                  zu?: string | null;
                } | null;
                d?: {
                  __typename?: "LanguageMap";
                  en: string;
                  ru?: string | null;
                  af?: string | null;
                  sq?: string | null;
                  ar?: string | null;
                  az?: string | null;
                  bn?: string | null;
                  bg?: string | null;
                  ca?: string | null;
                  zh?: string | null;
                  hr?: string | null;
                  cs?: string | null;
                  da?: string | null;
                  nl?: string | null;
                  et?: string | null;
                  fil?: string | null;
                  fi?: string | null;
                  fr?: string | null;
                  ka?: string | null;
                  de?: string | null;
                  el?: string | null;
                  gu?: string | null;
                  ha?: string | null;
                  he?: string | null;
                  hi?: string | null;
                  hu?: string | null;
                  id?: string | null;
                  ga?: string | null;
                  it?: string | null;
                  ja?: string | null;
                  kn?: string | null;
                  kk?: string | null;
                  rw?: string | null;
                  ko?: string | null;
                  ky?: string | null;
                  lo?: string | null;
                  lv?: string | null;
                  lt?: string | null;
                  mk?: string | null;
                  ms?: string | null;
                  ml?: string | null;
                  mr?: string | null;
                  nb?: string | null;
                  fa?: string | null;
                  pl?: string | null;
                  pt?: string | null;
                  pa?: string | null;
                  ro?: string | null;
                  sr?: string | null;
                  sk?: string | null;
                  sl?: string | null;
                  es?: string | null;
                  sw?: string | null;
                  sv?: string | null;
                  ta?: string | null;
                  te?: string | null;
                  th?: string | null;
                  tr?: string | null;
                  uk?: string | null;
                  ur?: string | null;
                  uz?: string | null;
                  vi?: string | null;
                  zu?: string | null;
                } | null;
              }
            | {
                __typename?: "AppFieldsGroupInner";
                g: AppFieldsGroupType;
                c: Array<{
                  __typename?: "AppFieldInner";
                  t: AppFieldTypeType;
                  tg: string;
                  dt?: AppFieldDataTypeType | null;
                  dts?: Array<AppFieldDataTypesType> | null;
                  r?: boolean | null;
                  mn?: number | null;
                  mx?: number | null;
                  v?: string | null;
                  n: {
                    __typename?: "LanguageMap";
                    en: string;
                    ru?: string | null;
                    af?: string | null;
                    sq?: string | null;
                    ar?: string | null;
                    az?: string | null;
                    bn?: string | null;
                    bg?: string | null;
                    ca?: string | null;
                    zh?: string | null;
                    hr?: string | null;
                    cs?: string | null;
                    da?: string | null;
                    nl?: string | null;
                    et?: string | null;
                    fil?: string | null;
                    fi?: string | null;
                    fr?: string | null;
                    ka?: string | null;
                    de?: string | null;
                    el?: string | null;
                    gu?: string | null;
                    ha?: string | null;
                    he?: string | null;
                    hi?: string | null;
                    hu?: string | null;
                    id?: string | null;
                    ga?: string | null;
                    it?: string | null;
                    ja?: string | null;
                    kn?: string | null;
                    kk?: string | null;
                    rw?: string | null;
                    ko?: string | null;
                    ky?: string | null;
                    lo?: string | null;
                    lv?: string | null;
                    lt?: string | null;
                    mk?: string | null;
                    ms?: string | null;
                    ml?: string | null;
                    mr?: string | null;
                    nb?: string | null;
                    fa?: string | null;
                    pl?: string | null;
                    pt?: string | null;
                    pa?: string | null;
                    ro?: string | null;
                    sr?: string | null;
                    sk?: string | null;
                    sl?: string | null;
                    es?: string | null;
                    sw?: string | null;
                    sv?: string | null;
                    ta?: string | null;
                    te?: string | null;
                    th?: string | null;
                    tr?: string | null;
                    uk?: string | null;
                    ur?: string | null;
                    uz?: string | null;
                    vi?: string | null;
                    zu?: string | null;
                  };
                  p?: {
                    __typename?: "LanguageMap";
                    en: string;
                    ru?: string | null;
                    af?: string | null;
                    sq?: string | null;
                    ar?: string | null;
                    az?: string | null;
                    bn?: string | null;
                    bg?: string | null;
                    ca?: string | null;
                    zh?: string | null;
                    hr?: string | null;
                    cs?: string | null;
                    da?: string | null;
                    nl?: string | null;
                    et?: string | null;
                    fil?: string | null;
                    fi?: string | null;
                    fr?: string | null;
                    ka?: string | null;
                    de?: string | null;
                    el?: string | null;
                    gu?: string | null;
                    ha?: string | null;
                    he?: string | null;
                    hi?: string | null;
                    hu?: string | null;
                    id?: string | null;
                    ga?: string | null;
                    it?: string | null;
                    ja?: string | null;
                    kn?: string | null;
                    kk?: string | null;
                    rw?: string | null;
                    ko?: string | null;
                    ky?: string | null;
                    lo?: string | null;
                    lv?: string | null;
                    lt?: string | null;
                    mk?: string | null;
                    ms?: string | null;
                    ml?: string | null;
                    mr?: string | null;
                    nb?: string | null;
                    fa?: string | null;
                    pl?: string | null;
                    pt?: string | null;
                    pa?: string | null;
                    ro?: string | null;
                    sr?: string | null;
                    sk?: string | null;
                    sl?: string | null;
                    es?: string | null;
                    sw?: string | null;
                    sv?: string | null;
                    ta?: string | null;
                    te?: string | null;
                    th?: string | null;
                    tr?: string | null;
                    uk?: string | null;
                    ur?: string | null;
                    uz?: string | null;
                    vi?: string | null;
                    zu?: string | null;
                  } | null;
                  d?: {
                    __typename?: "LanguageMap";
                    en: string;
                    ru?: string | null;
                    af?: string | null;
                    sq?: string | null;
                    ar?: string | null;
                    az?: string | null;
                    bn?: string | null;
                    bg?: string | null;
                    ca?: string | null;
                    zh?: string | null;
                    hr?: string | null;
                    cs?: string | null;
                    da?: string | null;
                    nl?: string | null;
                    et?: string | null;
                    fil?: string | null;
                    fi?: string | null;
                    fr?: string | null;
                    ka?: string | null;
                    de?: string | null;
                    el?: string | null;
                    gu?: string | null;
                    ha?: string | null;
                    he?: string | null;
                    hi?: string | null;
                    hu?: string | null;
                    id?: string | null;
                    ga?: string | null;
                    it?: string | null;
                    ja?: string | null;
                    kn?: string | null;
                    kk?: string | null;
                    rw?: string | null;
                    ko?: string | null;
                    ky?: string | null;
                    lo?: string | null;
                    lv?: string | null;
                    lt?: string | null;
                    mk?: string | null;
                    ms?: string | null;
                    ml?: string | null;
                    mr?: string | null;
                    nb?: string | null;
                    fa?: string | null;
                    pl?: string | null;
                    pt?: string | null;
                    pa?: string | null;
                    ro?: string | null;
                    sr?: string | null;
                    sk?: string | null;
                    sl?: string | null;
                    es?: string | null;
                    sw?: string | null;
                    sv?: string | null;
                    ta?: string | null;
                    te?: string | null;
                    th?: string | null;
                    tr?: string | null;
                    uk?: string | null;
                    ur?: string | null;
                    uz?: string | null;
                    vi?: string | null;
                    zu?: string | null;
                  } | null;
                }>;
              }
          > | null;
        }>;
      } | null>;
      installations: Array<{
        __typename?: "AppInstallation";
        id: string;
        createdAt: string;
        method?: MethodType | null;
        localId?: string | null;
        settings?: Array<{
          __typename?: "AppSettingsGroup";
          g: AppFieldsGroupType;
          c: Array<{
            __typename?: "AppSettingsField";
            tg: string;
            dt: AppFieldSettingsDataTypeType;
            v: string;
            c?: Array<
              | {
                  __typename?: "AppSettingsFieldInner";
                  tg: string;
                  dt: AppFieldSettingsDataTypeType;
                  v: string;
                }
              | {
                  __typename?: "AppSettingsGroupInner";
                  g: AppFieldsGroupType;
                  c: Array<{
                    __typename?: "AppSettingsFieldInner";
                    tg: string;
                    dt: AppFieldSettingsDataTypeType;
                    v: string;
                  }>;
                }
            > | null;
          }>;
        } | null> | null;
      } | null>;
    } | null>;
  } | null> | null;
};

export type GetChatRoomHistoryQueryVariables = Exact<{
  roomId: Scalars["String"];
}>;

export type GetChatRoomHistoryQuery = {
  __typename?: "Query";
  getChatRoomHistory?: {
    __typename?: "RoomHistoryOutput";
    chatRoomHistory: Array<{
      __typename?: "RoomEventOutput";
      eventCreatedAt: string;
      eventInitiator: string;
      initiatorType: InitiatorType;
      toUser?: string | null;
      fromUser?: string | null;
      toTeams?: Array<string | null> | null;
      fromTeams?: Array<string | null> | null;
      actionName: RoomEventActionName;
      text?: string | null;
    } | null>;
  } | null;
};

export type GetAvailableTwilioPhoneNumbersQueryVariables = Exact<{
  areaCode: Scalars["Int"];
  limit?: InputMaybe<Scalars["Int"]>;
}>;

export type GetAvailableTwilioPhoneNumbersQuery = {
  __typename?: "Query";
  getAvailableTwilioPhoneNumbers?: Array<string | null> | null;
};

export type GetCalendarQueryVariables = Exact<{ [key: string]: never }>;

export type GetCalendarQuery = {
  __typename?: "Query";
  getCalendar?: Array<{
    __typename?: "CalendarOutput";
    id?: string | null;
    accountId?: string | null;
    description?: string | null;
    name?: string | null;
    isPrimary?: boolean | null;
    location?: string | null;
    object?: string | null;
    readOnly?: boolean | null;
    timezone?: string | null;
  } | null> | null;
};

export type GetCalendarEventsQueryVariables = Exact<{ [key: string]: never }>;

export type GetCalendarEventsQuery = {
  __typename?: "Query";
  getCalendarEvents?: Array<{
    __typename?: "CalendarEventOutput";
    accountId?: string | null;
    busy?: boolean | null;
    calendarId?: string | null;
    customerEventId?: string | null;
    description?: string | null;
    hideParticipants?: boolean | null;
    icalUid?: string | null;
    id?: string | null;
    location?: string | null;
    messageId?: string | null;
    object?: string | null;
    organizerEmail?: string | null;
    organizerName?: string | null;
    owner?: string | null;
    readOnly?: boolean | null;
    reminders?: string | null;
    status?: string | null;
    title?: string | null;
    updatedAt?: string | null;
    visibility?: string | null;
    startDateTime?: string | null;
    endDateTime?: string | null;
    participants?: Array<{
      __typename?: "EventParticipantOutput";
      comment?: string | null;
      email?: string | null;
      name?: string | null;
      phoneNumber?: string | null;
      status?: string | null;
    } | null> | null;
  } | null> | null;
};

export type GetAiBotTemplatesWithFieldsQueryVariables = Exact<{
  templateId?: InputMaybe<Scalars["ID"]>;
}>;

export type GetAiBotTemplatesWithFieldsQuery = {
  __typename?: "Query";
  getAiBotTemplatesWithFields?: Array<{
    __typename?: "AiBotTemplateWithFieldsOutput";
    id: string;
    createdAt: string;
    isAvailable: boolean;
    fields: string;
    name: {
      __typename?: "LanguageMap";
      en: string;
      ru?: string | null;
      af?: string | null;
      sq?: string | null;
      ar?: string | null;
      az?: string | null;
      bn?: string | null;
      bg?: string | null;
      ca?: string | null;
      zh?: string | null;
      hr?: string | null;
      cs?: string | null;
      da?: string | null;
      nl?: string | null;
      et?: string | null;
      fil?: string | null;
      fi?: string | null;
      fr?: string | null;
      ka?: string | null;
      de?: string | null;
      el?: string | null;
      gu?: string | null;
      ha?: string | null;
      he?: string | null;
      hi?: string | null;
      hu?: string | null;
      id?: string | null;
      ga?: string | null;
      it?: string | null;
      ja?: string | null;
      kn?: string | null;
      kk?: string | null;
      rw?: string | null;
      ko?: string | null;
      ky?: string | null;
      lo?: string | null;
      lv?: string | null;
      lt?: string | null;
      mk?: string | null;
      ms?: string | null;
      ml?: string | null;
      mr?: string | null;
      nb?: string | null;
      fa?: string | null;
      pl?: string | null;
      pt?: string | null;
      pa?: string | null;
      ro?: string | null;
      sr?: string | null;
      sk?: string | null;
      sl?: string | null;
      es?: string | null;
      sw?: string | null;
      sv?: string | null;
      ta?: string | null;
      te?: string | null;
      th?: string | null;
      tr?: string | null;
      uk?: string | null;
      ur?: string | null;
      uz?: string | null;
      vi?: string | null;
      zu?: string | null;
    };
    description?: {
      __typename?: "LanguageMap";
      en: string;
      ru?: string | null;
      af?: string | null;
      sq?: string | null;
      ar?: string | null;
      az?: string | null;
      bn?: string | null;
      bg?: string | null;
      ca?: string | null;
      zh?: string | null;
      hr?: string | null;
      cs?: string | null;
      da?: string | null;
      nl?: string | null;
      et?: string | null;
      fil?: string | null;
      fi?: string | null;
      fr?: string | null;
      ka?: string | null;
      de?: string | null;
      el?: string | null;
      gu?: string | null;
      ha?: string | null;
      he?: string | null;
      hi?: string | null;
      hu?: string | null;
      id?: string | null;
      ga?: string | null;
      it?: string | null;
      ja?: string | null;
      kn?: string | null;
      kk?: string | null;
      rw?: string | null;
      ko?: string | null;
      ky?: string | null;
      lo?: string | null;
      lv?: string | null;
      lt?: string | null;
      mk?: string | null;
      ms?: string | null;
      ml?: string | null;
      mr?: string | null;
      nb?: string | null;
      fa?: string | null;
      pl?: string | null;
      pt?: string | null;
      pa?: string | null;
      ro?: string | null;
      sr?: string | null;
      sk?: string | null;
      sl?: string | null;
      es?: string | null;
      sw?: string | null;
      sv?: string | null;
      ta?: string | null;
      te?: string | null;
      th?: string | null;
      tr?: string | null;
      uk?: string | null;
      ur?: string | null;
      uz?: string | null;
      vi?: string | null;
      zu?: string | null;
    } | null;
  } | null> | null;
};

export type GetAiBotUserSettingsQueryVariables = Exact<{
  botId: Scalars["ID"];
}>;

export type GetAiBotUserSettingsQuery = {
  __typename?: "Query";
  getAiBotUserSettings?: {
    __typename?: "AiBotUserSettingsOutput";
    id: string;
    createdAt: string;
    updatedAt: string;
    templateId: string;
    botId: string;
    userSettings?: string | null;
  } | null;
};

export type GetOrganizationsQueryVariables = Exact<{ [key: string]: never }>;

export type GetOrganizationsQuery = {
  __typename?: "Query";
  getOrganizations?: {
    __typename?: "OrganizationsOutput";
    current?: {
      __typename?: "Organization";
      id: string;
      parentOID?: string | null;
      phone?: string | null;
      email?: string | null;
      name: string;
      tariff?: OrganizationTariff | null;
      country?: string | null;
      timezone?: string | null;
      language?: OrganizationLanguage | null;
      activeMembersCount?: number | null;
      membersCount?: number | null;
      imageUrl?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      isPendingDeletion?: boolean | null;
      isCurrent: boolean;
      rid: string;
    } | null;
    parent?: {
      __typename?: "Organization";
      id: string;
      parentOID?: string | null;
      phone?: string | null;
      email?: string | null;
      name: string;
      tariff?: OrganizationTariff | null;
      country?: string | null;
      timezone?: string | null;
      language?: OrganizationLanguage | null;
      activeMembersCount?: number | null;
      membersCount?: number | null;
      imageUrl?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      isPendingDeletion?: boolean | null;
      isCurrent: boolean;
      rid: string;
    } | null;
    subsidiaries: Array<{
      __typename?: "Organization";
      id: string;
      parentOID?: string | null;
      phone?: string | null;
      email?: string | null;
      name: string;
      tariff?: OrganizationTariff | null;
      country?: string | null;
      timezone?: string | null;
      language?: OrganizationLanguage | null;
      activeMembersCount?: number | null;
      membersCount?: number | null;
      imageUrl?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      isPendingDeletion?: boolean | null;
      isCurrent: boolean;
      rid: string;
    } | null>;
  } | null;
};

export type GetCustomFieldsQueryVariables = Exact<{ [key: string]: never }>;

export type GetCustomFieldsQuery = {
  __typename?: "Query";
  getCustomFields?: Array<{
    __typename?: "CustomFieldOutput";
    id: string;
    fieldName: string;
    dataType: CustomFieldDataType;
    description?: string | null;
    fieldOrder: number;
  } | null> | null;
};

export type WatchUsersSubscriptionVariables = Exact<{ [key: string]: never }>;

export type WatchUsersSubscription = {
  __typename?: "Subscription";
  watchUsers?: {
    __typename?: "WatchUsersOutput";
    method?: MethodType | null;
    user?: {
      __typename?: "UserOutput";
      id: string;
      optionalName: string;
      thirdParty?: string | null;
      roleId?: string | null;
      position?: string | null;
      common?: {
        __typename?: "CommonUserFields";
        createdAt: string;
        autoNickname: string;
        userpicUrl: string;
        firstName?: string | null;
        lastName?: string | null;
        email?: string | null;
        phone?: string | null;
      } | null;
      onlineStatus?: {
        __typename?: "UserPresenceStatusOutput";
        userId?: string | null;
        presenceStatus?: PresenceStatus | null;
      } | null;
      teams?: Array<{
        __typename?: "TeamOutput";
        id: string;
        name: string;
        createdAt: string;
        updatedAt: string;
        deletedAt?: string | null;
      } | null> | null;
      role?: {
        __typename?: "RoleOutput";
        roleDescription?: string | null;
        roleId?: string | null;
        rolePublicName?: string | null;
        roleTechnicalName?: string | null;
      } | null;
    } | null;
  } | null;
};

export type WatchRooms2SubscriptionVariables = Exact<{
  teamId: Scalars["String"];
  userId: Scalars["String"];
}>;

export type WatchRooms2Subscription = {
  __typename?: "Subscription";
  watchRooms2?: {
    __typename?: "MakeRooms2Output";
    error?: string | null;
    rooms?: Array<{
      __typename?: "RoomOutput2";
      id: string;
      status: RoomStatus;
      previousStatus?: RoomStatus | null;
      teamsIds: Array<string | null>;
      previousTeamsIds?: Array<string | null> | null;
      lastMessageAt?: string | null;
      lastClientMessageAt?: string | null;
      lastTicketAt?: string | null;
      unreadMessagesNumber: number;
      isExpired: boolean;
      channel: {
        __typename?: "ChannelOutput2";
        id: string;
        publicId: string;
        name: string;
        link: string;
        type: ChannelType;
        status: ChannelStatus;
        createdAt: string;
        updatedAt: string;
        deletedAt?: string | null;
        bot?: {
          __typename?: "BotOutput";
          id: string;
          name?: string | null;
        } | null;
      };
      client: {
        __typename?: "UserOutput";
        id: string;
        optionalName: string;
        whatsappUsername?: string | null;
        whatsappProfile?: string | null;
        telegramUsername?: string | null;
        telegramUserId?: string | null;
        facebookMessengerPsid?: string | null;
        vkUserId?: string | null;
        instagramBsid?: string | null;
        instagramProfile?: string | null;
        thirdParty?: string | null;
        twilioSmsUserId?: string | null;
        nylasUserEmail?: string | null;
        mailgunUserEmail?: string | null;
        lastTicketAt?: string | null;
        common?: {
          __typename?: "CommonUserFields";
          createdAt: string;
          autoNickname: string;
          userpicUrl: string;
          firstName?: string | null;
          lastName?: string | null;
          email?: string | null;
          phone?: string | null;
        } | null;
        roomsLinks?: Array<{
          __typename?: "RoomLinkOutput";
          roomId?: string | null;
          roomStatus?: RoomStatus | null;
          channelType?: ChannelType | null;
          lastTicketAt?: string | null;
        } | null> | null;
        customFields?: Array<{
          __typename?: "UserCustomFieldOutput";
          fieldId: string;
          fieldName: string;
          dataType: CustomFieldDataType;
          description?: string | null;
          fieldOrder: number;
          value: string;
        } | null> | null;
      };
      activeOperator?: {
        __typename?: "UserOutput";
        id: string;
        optionalName: string;
        thirdParty?: string | null;
        roleId?: string | null;
        position?: string | null;
        common?: {
          __typename?: "CommonUserFields";
          createdAt: string;
          autoNickname: string;
          userpicUrl: string;
          firstName?: string | null;
          lastName?: string | null;
          email?: string | null;
          phone?: string | null;
        } | null;
        onlineStatus?: {
          __typename?: "UserPresenceStatusOutput";
          userId?: string | null;
          presenceStatus?: PresenceStatus | null;
        } | null;
        teams?: Array<{
          __typename?: "TeamOutput";
          id: string;
          name: string;
          createdAt: string;
          updatedAt: string;
          deletedAt?: string | null;
        } | null> | null;
        role?: {
          __typename?: "RoleOutput";
          roleDescription?: string | null;
          roleId?: string | null;
          rolePublicName?: string | null;
          roleTechnicalName?: string | null;
        } | null;
      } | null;
      operators: Array<{
        __typename?: "UserOutput";
        id: string;
        optionalName: string;
        thirdParty?: string | null;
        roleId?: string | null;
        position?: string | null;
        common?: {
          __typename?: "CommonUserFields";
          createdAt: string;
          autoNickname: string;
          userpicUrl: string;
          firstName?: string | null;
          lastName?: string | null;
          email?: string | null;
          phone?: string | null;
        } | null;
        onlineStatus?: {
          __typename?: "UserPresenceStatusOutput";
          userId?: string | null;
          presenceStatus?: PresenceStatus | null;
        } | null;
        teams?: Array<{
          __typename?: "TeamOutput";
          id: string;
          name: string;
          createdAt: string;
          updatedAt: string;
          deletedAt?: string | null;
        } | null> | null;
        role?: {
          __typename?: "RoleOutput";
          roleDescription?: string | null;
          roleId?: string | null;
          rolePublicName?: string | null;
          roleTechnicalName?: string | null;
        } | null;
      } | null>;
      lastMessageContent?: {
        __typename?: "LastMessageContentOutput";
        messageText?: string | null;
        messageContent?: Array<{
          __typename?: "MessageContentItemOutput";
          category?: string | null;
          fileExtension?: string | null;
          mimeType?: string | null;
          url?: string | null;
          fileName?: string | null;
          fileSize?: number | null;
          dimensions?: {
            __typename?: "MessageContentImageDimension";
            width: number;
            height: number;
          } | null;
        } | null> | null;
      } | null;
    } | null> | null;
  } | null;
};

export type WatchCreateMessageSubscriptionVariables = Exact<{
  channelType: ChannelType;
}>;

export type WatchCreateMessageSubscription = {
  __typename?: "Subscription";
  watchCreateMessage?: {
    __typename?: "MessageOutput";
    chatRoomId?: string | null;
    messageAuthorId?: string | null;
    messageChannelId?: string | null;
    messageContent?: string | null;
    messageCreatedDateTime?: string | null;
    messageDeletedDateTime?: string | null;
    messageId?: string | null;
    messageIsDelivered?: boolean | null;
    chatRoomStatus?: string | null;
    messageIsRead?: boolean | null;
    messageIsSent?: boolean | null;
    messageText?: string | null;
    messageUpdatedDateTime?: string | null;
    isFromClient: boolean;
    isFromBot: boolean;
  } | null;
};

export type WatchUpdateMessageDataSubscriptionVariables = Exact<{
  channelType: ChannelType;
}>;

export type WatchUpdateMessageDataSubscription = {
  __typename?: "Subscription";
  watchUpdateMessageData?: {
    __typename?: "UpdateMessageDataOutput";
    channelId?: string | null;
    channelTypeName?: ChannelType | null;
    chatRoomId?: string | null;
    chatRoomStatus?: string | null;
    isClient?: boolean | null;
    unreadMessagesNumber?: number | null;
    chatRoomMessages?: Array<{
      __typename?: "MessageOutput";
      chatRoomId?: string | null;
      messageAuthorId?: string | null;
      messageChannelId?: string | null;
      messageContent?: string | null;
      messageCreatedDateTime?: string | null;
      messageDeletedDateTime?: string | null;
      messageId?: string | null;
      messageIsDelivered?: boolean | null;
      chatRoomStatus?: string | null;
      messageIsRead?: boolean | null;
      messageIsSent?: boolean | null;
      messageText?: string | null;
      messageUpdatedDateTime?: string | null;
      isFromClient: boolean;
      isFromBot: boolean;
    } | null> | null;
  } | null;
};

export type WatchDeleteMessageSubscriptionVariables = Exact<{
  channelType: ChannelType;
}>;

export type WatchDeleteMessageSubscription = {
  __typename?: "Subscription";
  watchDeleteMessage?: {
    __typename?: "DeleteMessageOutput";
    channelType?: ChannelType | null;
    channelId?: string | null;
    chatRoomId?: string | null;
    messageId?: string | null;
  } | null;
};

export type WatchChannelsSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type WatchChannelsSubscription = {
  __typename?: "Subscription";
  watchChannels?: {
    __typename?: "WatchChannelOutput";
    method?: MethodType | null;
    channel?: {
      __typename?: "ChannelOutput2";
      id: string;
      publicId: string;
      name: string;
      link: string;
      type: ChannelType;
      status: ChannelStatus;
      createdAt: string;
      updatedAt: string;
      deletedAt?: string | null;
      bot?: {
        __typename?: "BotOutput";
        id: string;
        name?: string | null;
      } | null;
    } | null;
  } | null;
};

export type WatchCommentsSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type WatchCommentsSubscription = {
  __typename?: "Subscription";
  watchComments?: {
    __typename?: "WatchCommentOutput";
    method?: MethodType | null;
    comment?: {
      __typename?: "CommentOutput2";
      comment?: string | null;
      commentId?: string | null;
      createdAt?: string | null;
      clientId?: string | null;
      operator?: {
        __typename?: "UserOutput";
        id: string;
        optionalName: string;
        common?: {
          __typename?: "CommonUserFields";
          createdAt: string;
          autoNickname: string;
          userpicUrl: string;
          firstName?: string | null;
          lastName?: string | null;
          email?: string | null;
          phone?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type WatchBotsSubscriptionVariables = Exact<{ [key: string]: never }>;

export type WatchBotsSubscription = {
  __typename?: "Subscription";
  watchBots?: {
    __typename?: "WatchBotsOutput";
    bots?: Array<{
      __typename?: "BotOutput";
      isActive?: boolean | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      publishedAt?: string | null;
      status?: BotStatus | null;
      sessionsCount?: number | null;
      botType?: BotType | null;
      teamsIds?: Array<string | null> | null;
      id: string;
      name?: string | null;
      connectedChannels?: Array<{
        __typename?: "ChannelOutput2";
        id: string;
        publicId: string;
        name: string;
        link: string;
        type: ChannelType;
        status: ChannelStatus;
        createdAt: string;
        updatedAt: string;
        deletedAt?: string | null;
        bot?: {
          __typename?: "BotOutput";
          id: string;
          name?: string | null;
        } | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type WatchContactsAnalyticsSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type WatchContactsAnalyticsSubscription = {
  __typename?: "Subscription";
  watchContactsAnalytics?: {
    __typename?: "ContactsAnalyticsOutput";
    totalContacts: number;
    newContactsToday: number;
    contactsByChannels: {
      __typename?: "DataByChannelType";
      labels: Array<ChannelType | null>;
      series: Array<number | null>;
    };
    activeChats: {
      __typename?: "DataByRoomStatus";
      labels: Array<RoomStatus | null>;
      series: Array<number | null>;
    };
    newContacts7d: {
      __typename?: "LineChart";
      series: Array<{
        __typename?: "LineChartSeries";
        name: ChannelType;
        data: Array<number>;
      } | null>;
      xAxis: {
        __typename?: "LineChartXAxisCategories";
        categories: Array<string | null>;
      };
    };
  } | null;
};

export type WatchGeneralAnalyticsSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type WatchGeneralAnalyticsSubscription = {
  __typename?: "Subscription";
  watchGeneralAnalytics?: {
    __typename?: "GeneralAnalyticsOutput";
    totalChats: number;
    activeChats: number;
    totalContacts: number;
    operatorsPerformance: {
      __typename?: "OperatorsPerformance";
      updatedAt: string;
      series: Array<{
        __typename?: "OperatorsPerformanceSeries";
        name: AnalyticsOperatorsPerformanceSeriesStatus;
        data: Array<number | null>;
      } | null>;
    };
    chatsByChannels: {
      __typename?: "DataByChannelType";
      labels: Array<ChannelType | null>;
      series: Array<number | null>;
    };
    newChats7d: {
      __typename?: "LineChart";
      series: Array<{
        __typename?: "LineChartSeries";
        name: ChannelType;
        data: Array<number>;
      } | null>;
      xAxis: {
        __typename?: "LineChartXAxisCategories";
        categories: Array<string | null>;
      };
    };
  } | null;
};

export type WatchCalendarEventsSubscriptionVariables = Exact<{
  calendarId: Scalars["String"];
}>;

export type WatchCalendarEventsSubscription = {
  __typename?: "Subscription";
  watchCalendarEvents?: {
    __typename?: "BaseCalendarEventOutput";
    actionType?: CalendarEventActionType | null;
    event?: {
      __typename?: "CalendarEventOutput";
      accountId?: string | null;
      busy?: boolean | null;
      calendarId?: string | null;
      customerEventId?: string | null;
      description?: string | null;
      hideParticipants?: boolean | null;
      icalUid?: string | null;
      id?: string | null;
      location?: string | null;
      messageId?: string | null;
      object?: string | null;
      organizerEmail?: string | null;
      organizerName?: string | null;
      owner?: string | null;
      readOnly?: boolean | null;
      reminders?: string | null;
      status?: string | null;
      title?: string | null;
      updatedAt?: string | null;
      visibility?: string | null;
      startDateTime?: string | null;
      endDateTime?: string | null;
      participants?: Array<{
        __typename?: "EventParticipantOutput";
        comment?: string | null;
        email?: string | null;
        name?: string | null;
        phoneNumber?: string | null;
        status?: string | null;
      } | null> | null;
    } | null;
  } | null;
};

export const CoreUserFieldsFragmentDoc = gql`
  fragment CoreUserFields on UserOutput {
    id
    common {
      createdAt
      autoNickname
      userpicUrl
      firstName
      lastName
      email
      phone
    }
    optionalName @client
  }
`;
export const CoreCommentsFieldsFragmentDoc = gql`
  fragment CoreCommentsFields on CommentOutput2 {
    comment
    commentId
    createdAt
    clientId
    operator {
      ...CoreUserFields
    }
  }
  ${CoreUserFieldsFragmentDoc}
`;
export const CoreTeamFieldsFragmentDoc = gql`
  fragment CoreTeamFields on TeamOutput {
    id
    name
    createdAt
    updatedAt
    deletedAt
  }
`;
export const CoreRoleFieldsFragmentDoc = gql`
  fragment CoreRoleFields on RoleOutput {
    roleDescription
    roleId
    rolePublicName
    roleTechnicalName
  }
`;
export const InternalUserFieldsFragmentDoc = gql`
  fragment InternalUserFields on UserOutput {
    thirdParty
    onlineStatus {
      userId
      presenceStatus
    }
    teams {
      ...CoreTeamFields
    }
    roleId
    position
    role @client {
      ...CoreRoleFields
    }
  }
  ${CoreTeamFieldsFragmentDoc}
  ${CoreRoleFieldsFragmentDoc}
`;
export const ExtendedTeamFieldsFragmentDoc = gql`
  fragment ExtendedTeamFields on TeamOutput {
    ...CoreTeamFields
    users {
      ...CoreUserFields
      ...InternalUserFields
    }
  }
  ${CoreTeamFieldsFragmentDoc}
  ${CoreUserFieldsFragmentDoc}
  ${InternalUserFieldsFragmentDoc}
`;
export const CoreFlowFieldsFragmentDoc = gql`
  fragment CoreFlowFields on FlowOutput {
    id
    botId
    name
    createdAt
    isMain
    updatedAt
  }
`;
export const CoreBotFieldsFragmentDoc = gql`
  fragment CoreBotFields on BotOutput {
    id
    name
  }
`;
export const CoreChannelFieldsFragmentDoc = gql`
  fragment CoreChannelFields on ChannelOutput2 {
    id
    publicId
    bot {
      ...CoreBotFields
    }
    name
    link
    type
    status
    createdAt
    updatedAt
    deletedAt
  }
  ${CoreBotFieldsFragmentDoc}
`;
export const ExtendedChannelFieldsFragmentDoc = gql`
  fragment ExtendedChannelFields on ChannelOutput2 {
    ...CoreChannelFields
    bot {
      ...CoreBotFields
    }
  }
  ${CoreChannelFieldsFragmentDoc}
  ${CoreBotFieldsFragmentDoc}
`;
export const ExtendedBotFieldsFragmentDoc = gql`
  fragment ExtendedBotFields on BotOutput {
    ...CoreBotFields
    isActive
    createdAt
    updatedAt
    publishedAt
    status
    sessionsCount
    botType
    teamsIds
    connectedChannels @client {
      ...CoreChannelFields
    }
  }
  ${CoreBotFieldsFragmentDoc}
  ${CoreChannelFieldsFragmentDoc}
`;
export const CoreRoomLinkFieldsFragmentDoc = gql`
  fragment CoreRoomLinkFields on RoomLinkOutput {
    roomId
    roomStatus
    channelType
    lastTicketAt
  }
`;
export const CoreUserCustomFieldFieldsFragmentDoc = gql`
  fragment CoreUserCustomFieldFields on UserCustomFieldOutput {
    fieldId
    fieldName
    dataType
    description
    fieldOrder
    value
  }
`;
export const ClientFieldsFragmentDoc = gql`
  fragment ClientFields on UserOutput {
    whatsappUsername
    whatsappProfile
    telegramUsername
    telegramUserId
    facebookMessengerPsid
    vkUserId
    instagramBsid
    instagramProfile
    thirdParty
    twilioSmsUserId
    nylasUserEmail
    mailgunUserEmail
    roomsLinks {
      ...CoreRoomLinkFields
    }
    customFields {
      ...CoreUserCustomFieldFields
    }
    lastTicketAt @client
  }
  ${CoreRoomLinkFieldsFragmentDoc}
  ${CoreUserCustomFieldFieldsFragmentDoc}
`;
export const CoreRoomFieldsFragmentDoc = gql`
  fragment CoreRoomFields on RoomOutput2 {
    id
    status
    previousStatus
    channel {
      ...CoreChannelFields
    }
    client {
      ...CoreUserFields
      ...ClientFields
    }
    teamsIds
    previousTeamsIds
    activeOperator {
      ...CoreUserFields
      ...InternalUserFields
    }
    operators {
      ...CoreUserFields
      ...InternalUserFields
    }
    lastMessageContent {
      messageText
      messageContent {
        category
        fileExtension
        mimeType
        url
        fileName
        fileSize
        dimensions {
          width
          height
        }
      }
    }
    lastMessageAt
    lastClientMessageAt
    lastTicketAt
    unreadMessagesNumber
    isExpired @client
  }
  ${CoreChannelFieldsFragmentDoc}
  ${CoreUserFieldsFragmentDoc}
  ${ClientFieldsFragmentDoc}
  ${InternalUserFieldsFragmentDoc}
`;
export const CoreMessageFieldsFragmentDoc = gql`
  fragment CoreMessageFields on MessageOutput {
    chatRoomId
    messageAuthorId
    messageChannelId
    messageContent
    messageCreatedDateTime
    messageDeletedDateTime
    messageId
    messageIsDelivered
    chatRoomStatus
    messageIsRead
    messageIsSent
    messageText
    messageUpdatedDateTime
    isFromClient @client
    isFromBot @client
  }
`;
export const CoreValidateBotFieldsFragmentDoc = gql`
  fragment CoreValidateBotFields on ValidateBot {
    id
    errorMessage
    statusCode
    nodeType
    flowId
  }
`;
export const CoreLanguageMapFieldsFragmentDoc = gql`
  fragment CoreLanguageMapFields on LanguageMap {
    en
    ru
    af
    sq
    ar
    az
    bn
    bg
    ca
    zh
    hr
    cs
    da
    nl
    et
    fil
    fi
    fr
    ka
    de
    el
    gu
    ha
    he
    hi
    hu
    id
    ga
    it
    ja
    kn
    kk
    rw
    ko
    ky
    lo
    lv
    lt
    mk
    ms
    ml
    mr
    nb
    fa
    pl
    pt
    pa
    ro
    sr
    sk
    sl
    es
    sw
    sv
    ta
    te
    th
    tr
    uk
    ur
    uz
    vi
    zu
  }
`;
export const CoreAppInnerFieldFragmentDoc = gql`
  fragment CoreAppInnerField on AppFieldInner {
    t
    tg
    dt
    dts
    n {
      ...CoreLanguageMapFields
    }
    p {
      ...CoreLanguageMapFields
    }
    d {
      ...CoreLanguageMapFields
    }
    r
    mn
    mx
    v @client
  }
  ${CoreLanguageMapFieldsFragmentDoc}
`;
export const CoreAppInnerGroupFragmentDoc = gql`
  fragment CoreAppInnerGroup on AppFieldsGroupInner {
    g
    c {
      ...CoreAppInnerField
    }
  }
  ${CoreAppInnerFieldFragmentDoc}
`;
export const CoreAppFieldDetailsFragmentDoc = gql`
  fragment CoreAppFieldDetails on AppFieldDetails {
    ... on AppFieldInner {
      ...CoreAppInnerField
    }
    ... on AppFieldsGroupInner {
      ...CoreAppInnerGroup
    }
  }
  ${CoreAppInnerFieldFragmentDoc}
  ${CoreAppInnerGroupFragmentDoc}
`;
export const CoreAppFieldFragmentDoc = gql`
  fragment CoreAppField on AppField {
    t
    tg
    dt
    dts
    n {
      ...CoreLanguageMapFields
    }
    p {
      ...CoreLanguageMapFields
    }
    d {
      ...CoreLanguageMapFields
    }
    r
    mn
    mx
    c {
      ... on AppFieldInner {
        ...CoreAppInnerField
      }
      ... on AppFieldsGroupInner {
        ...CoreAppInnerGroup
      }
    }
    v @client
  }
  ${CoreLanguageMapFieldsFragmentDoc}
  ${CoreAppInnerFieldFragmentDoc}
  ${CoreAppInnerGroupFragmentDoc}
`;
export const CoreGroupFieldsFragmentDoc = gql`
  fragment CoreGroupFields on AppFieldsGroup {
    g
    c {
      ...CoreAppField
    }
  }
  ${CoreAppFieldFragmentDoc}
`;
export const CoreAppSettingsInnerFieldFragmentDoc = gql`
  fragment CoreAppSettingsInnerField on AppSettingsFieldInner {
    tg
    dt
    v
  }
`;
export const CoreAppSettingsInnerGroupFragmentDoc = gql`
  fragment CoreAppSettingsInnerGroup on AppSettingsGroupInner {
    g
    c {
      ...CoreAppSettingsInnerField
    }
  }
  ${CoreAppSettingsInnerFieldFragmentDoc}
`;
export const CoreAppSettingsFieldFragmentDoc = gql`
  fragment CoreAppSettingsField on AppSettingsField {
    tg
    dt
    v
    c {
      ... on AppSettingsFieldInner {
        ...CoreAppSettingsInnerField
      }
      ... on AppSettingsGroupInner {
        ...CoreAppSettingsInnerGroup
      }
    }
  }
  ${CoreAppSettingsInnerFieldFragmentDoc}
  ${CoreAppSettingsInnerGroupFragmentDoc}
`;
export const CoreAppSettingsGroupFragmentDoc = gql`
  fragment CoreAppSettingsGroup on AppSettingsGroup {
    g
    c {
      ...CoreAppSettingsField
    }
  }
  ${CoreAppSettingsFieldFragmentDoc}
`;
export const CoreInstallationsFieldFragmentDoc = gql`
  fragment CoreInstallationsField on AppInstallation {
    id
    createdAt
    method
    localId
    settings {
      ...CoreAppSettingsGroup
    }
  }
  ${CoreAppSettingsGroupFragmentDoc}
`;
export const CoreVersionFieldsFragmentDoc = gql`
  fragment CoreVersionFields on AppVersion {
    version
    createdAt
    id
    fields {
      ...CoreGroupFields
    }
    installations {
      ...CoreInstallationsField
    }
    isInstalled
  }
  ${CoreGroupFieldsFragmentDoc}
  ${CoreInstallationsFieldFragmentDoc}
`;
export const CoreAppFieldsFragmentDoc = gql`
  fragment CoreAppFields on AppOutput {
    id
    name {
      ...CoreLanguageMapFields
    }
    iconUrl
    gallery
    description {
      ...CoreLanguageMapFields
    }
    instructions {
      ...CoreLanguageMapFields
    }
    type
    tag
    latestVersion
    createdAt
    isAvailable
    isInstalled
    versions {
      ...CoreVersionFields
    }
  }
  ${CoreLanguageMapFieldsFragmentDoc}
  ${CoreVersionFieldsFragmentDoc}
`;
export const CoreButtonFieldsFragmentDoc = gql`
  fragment CoreButtonFields on ButtonOutput {
    id
    localId
    name
    nodeId
    localNodeId
    buttonOrder
    botId
  }
`;
export const CoreNodeFieldsFragmentDoc = gql`
  fragment CoreNodeFields on NodeOutput {
    id
    localId
    flowId
    coordinates
    nodeType
    fileUrl
    imageUrl
    text
    childFlowId
    isDelete
    teamsIds
    buttons {
      ...CoreButtonFields
    }
    botId
    exceptionText
    saveTo
    appId
    appVersionId
    appInstalledId
  }
  ${CoreButtonFieldsFragmentDoc}
`;
export const CoreEdgeFieldsFragmentDoc = gql`
  fragment CoreEdgeFields on EdgeOutput {
    id
    localId
    botId
    flowId
    childId
    localChildId
    parentId
    localParentId
    handleParentId
    localHandleParentId
    isDelete
  }
`;
export const CoreSnippetCategoryFieldsFragmentDoc = gql`
  fragment CoreSnippetCategoryFields on ChatSnippetsCategoriesOutput {
    categoryId
    categoryName
    parentId
    userId
    totalSnippetsCount
  }
`;
export const CoreSnippetFieldsFragmentDoc = gql`
  fragment CoreSnippetFields on ChatSnippetsOutput {
    snippetId
    userId
    categoryId
    snippet
    teamsIds
    isFavorite @client
  }
`;
export const CoreSnippetFavoriteFieldsFragmentDoc = gql`
  fragment CoreSnippetFavoriteFields on ChatSnippetsFavoritesOutput {
    snippetId
    userId
    isFavorite @client
  }
`;
export const CoreOperatorsPerformanceSeriesFieldsFragmentDoc = gql`
  fragment CoreOperatorsPerformanceSeriesFields on OperatorsPerformanceSeries {
    name
    data
  }
`;
export const CoreOperatorsPerformanceFieldsFragmentDoc = gql`
  fragment CoreOperatorsPerformanceFields on OperatorsPerformance {
    series {
      ...CoreOperatorsPerformanceSeriesFields
    }
    updatedAt
  }
  ${CoreOperatorsPerformanceSeriesFieldsFragmentDoc}
`;
export const CoreDataByChannelStatusFieldsFragmentDoc = gql`
  fragment CoreDataByChannelStatusFields on DataByChannelType {
    labels
    series
  }
`;
export const CoreDataByRoomStatusFieldsFragmentDoc = gql`
  fragment CoreDataByRoomStatusFields on DataByRoomStatus {
    labels
    series
  }
`;
export const CoreNewChats7dSeriesFieldsFragmentDoc = gql`
  fragment CoreNewChats7dSeriesFields on LineChartSeries {
    name
    data
  }
`;
export const CoreNewChats7dCategoriesFieldsFragmentDoc = gql`
  fragment CoreNewChats7dCategoriesFields on LineChartXAxisCategories {
    categories
  }
`;
export const CoreLineChartFieldsFragmentDoc = gql`
  fragment CoreLineChartFields on LineChart {
    series {
      ...CoreNewChats7dSeriesFields
    }
    xAxis {
      ...CoreNewChats7dCategoriesFields
    }
  }
  ${CoreNewChats7dSeriesFieldsFragmentDoc}
  ${CoreNewChats7dCategoriesFieldsFragmentDoc}
`;
export const CoreChatsDistributionFieldsFragmentDoc = gql`
  fragment CoreChatsDistributionFields on ChatsDistribution {
    labels
    series
  }
`;
export const CoreChatsRepeatedFieldsFragmentDoc = gql`
  fragment CoreChatsRepeatedFields on ChatsRepeated {
    labels
    series
  }
`;
export const CoreAnalyticsHeatMapSeriesItemFieldsFragmentDoc = gql`
  fragment CoreAnalyticsHeatMapSeriesItemFields on AnalyticsHeatMapSeriesItem {
    name
    data {
      x
      y
    }
  }
`;
export const CoreAnalyticsOperatorsMetricsTableRowFieldsFragmentDoc = gql`
  fragment CoreAnalyticsOperatorsMetricsTableRowFields on AnalyticsOperatorsMetricsTableRow {
    operatorName
    completedChats
    avgFirstMessageSec
    avgAcceptedSec
  }
`;
export const CoreRoomEventFieldsFragmentDoc = gql`
  fragment CoreRoomEventFields on RoomEventOutput {
    eventCreatedAt
    eventInitiator
    initiatorType
    toUser
    fromUser
    toTeams
    fromTeams
    actionName
    text @client
  }
`;
export const CoreCalendarFieldsFragmentDoc = gql`
  fragment CoreCalendarFields on CalendarOutput {
    id
    accountId
    description
    name
    isPrimary
    location
    object
    readOnly
    timezone
  }
`;
export const EventParticipantFieldsFragmentDoc = gql`
  fragment EventParticipantFields on EventParticipantOutput {
    comment
    email
    name
    phoneNumber
    status
  }
`;
export const CoreCalendarEventFieldsFragmentDoc = gql`
  fragment CoreCalendarEventFields on CalendarEventOutput {
    accountId
    busy
    calendarId
    customerEventId
    description
    hideParticipants
    icalUid
    id
    location
    messageId
    object
    organizerEmail
    organizerName
    owner
    participants {
      ...EventParticipantFields
    }
    readOnly
    reminders
    status
    title
    updatedAt
    visibility
    startDateTime
    endDateTime
  }
  ${EventParticipantFieldsFragmentDoc}
`;
export const CoreOrganizationFieldsFragmentDoc = gql`
  fragment CoreOrganizationFields on Organization {
    id
    parentOID
    phone
    email
    name
    tariff
    country
    timezone
    language
    activeMembersCount
    membersCount
    imageUrl
    createdAt
    updatedAt
    isPendingDeletion
    isCurrent
    rid
  }
`;
export const CoreCustomFieldFieldsFragmentDoc = gql`
  fragment CoreCustomFieldFields on CustomFieldOutput {
    id
    fieldName
    dataType
    description
    fieldOrder
  }
`;
export const MakeUserDocument = gql`
  mutation MakeUser($input: MakeUserInput) {
    makeUser(input: $input) {
      ...CoreUserFields
      ...InternalUserFields
      ...ClientFields
    }
  }
  ${CoreUserFieldsFragmentDoc}
  ${InternalUserFieldsFragmentDoc}
  ${ClientFieldsFragmentDoc}
`;
export type MakeUserMutationFn = Apollo.MutationFunction<
  MakeUserMutation,
  MakeUserMutationVariables
>;

/**
 * __useMakeUserMutation__
 *
 * To run a mutation, you first call `useMakeUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMakeUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [makeUserMutation, { data, loading, error }] = useMakeUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMakeUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MakeUserMutation,
    MakeUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MakeUserMutation, MakeUserMutationVariables>(
    MakeUserDocument,
    options
  );
}
export type MakeUserMutationHookResult = ReturnType<typeof useMakeUserMutation>;
export type MakeUserMutationResult = Apollo.MutationResult<MakeUserMutation>;
export type MakeUserMutationOptions = Apollo.BaseMutationOptions<
  MakeUserMutation,
  MakeUserMutationVariables
>;
export const MakeChannel2Document = gql`
  mutation MakeChannel2($input: MakeChannel2Input!) {
    makeChannel2(input: $input) {
      ...ExtendedChannelFields
    }
  }
  ${ExtendedChannelFieldsFragmentDoc}
`;
export type MakeChannel2MutationFn = Apollo.MutationFunction<
  MakeChannel2Mutation,
  MakeChannel2MutationVariables
>;

/**
 * __useMakeChannel2Mutation__
 *
 * To run a mutation, you first call `useMakeChannel2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMakeChannel2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [makeChannel2Mutation, { data, loading, error }] = useMakeChannel2Mutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMakeChannel2Mutation(
  baseOptions?: Apollo.MutationHookOptions<
    MakeChannel2Mutation,
    MakeChannel2MutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MakeChannel2Mutation,
    MakeChannel2MutationVariables
  >(MakeChannel2Document, options);
}
export type MakeChannel2MutationHookResult = ReturnType<
  typeof useMakeChannel2Mutation
>;
export type MakeChannel2MutationResult =
  Apollo.MutationResult<MakeChannel2Mutation>;
export type MakeChannel2MutationOptions = Apollo.BaseMutationOptions<
  MakeChannel2Mutation,
  MakeChannel2MutationVariables
>;
export const MakeBotDocument = gql`
  mutation MakeBot($input: MakeBotInput) {
    makeBot(input: $input) {
      ...ExtendedBotFields
    }
  }
  ${ExtendedBotFieldsFragmentDoc}
`;
export type MakeBotMutationFn = Apollo.MutationFunction<
  MakeBotMutation,
  MakeBotMutationVariables
>;

/**
 * __useMakeBotMutation__
 *
 * To run a mutation, you first call `useMakeBotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMakeBotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [makeBotMutation, { data, loading, error }] = useMakeBotMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMakeBotMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MakeBotMutation,
    MakeBotMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MakeBotMutation, MakeBotMutationVariables>(
    MakeBotDocument,
    options
  );
}
export type MakeBotMutationHookResult = ReturnType<typeof useMakeBotMutation>;
export type MakeBotMutationResult = Apollo.MutationResult<MakeBotMutation>;
export type MakeBotMutationOptions = Apollo.BaseMutationOptions<
  MakeBotMutation,
  MakeBotMutationVariables
>;
export const MakeFlowDocument = gql`
  mutation MakeFlow($input: MakeFlowInput) {
    makeFlow(input: $input) {
      ...CoreFlowFields
    }
  }
  ${CoreFlowFieldsFragmentDoc}
`;
export type MakeFlowMutationFn = Apollo.MutationFunction<
  MakeFlowMutation,
  MakeFlowMutationVariables
>;

/**
 * __useMakeFlowMutation__
 *
 * To run a mutation, you first call `useMakeFlowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMakeFlowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [makeFlowMutation, { data, loading, error }] = useMakeFlowMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMakeFlowMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MakeFlowMutation,
    MakeFlowMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MakeFlowMutation, MakeFlowMutationVariables>(
    MakeFlowDocument,
    options
  );
}
export type MakeFlowMutationHookResult = ReturnType<typeof useMakeFlowMutation>;
export type MakeFlowMutationResult = Apollo.MutationResult<MakeFlowMutation>;
export type MakeFlowMutationOptions = Apollo.BaseMutationOptions<
  MakeFlowMutation,
  MakeFlowMutationVariables
>;
export const DuplicateBotDocument = gql`
  mutation DuplicateBot($id: String!, $newName: String) {
    duplicateBot(id: $id, newName: $newName) {
      ...ExtendedBotFields
    }
  }
  ${ExtendedBotFieldsFragmentDoc}
`;
export type DuplicateBotMutationFn = Apollo.MutationFunction<
  DuplicateBotMutation,
  DuplicateBotMutationVariables
>;

/**
 * __useDuplicateBotMutation__
 *
 * To run a mutation, you first call `useDuplicateBotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateBotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateBotMutation, { data, loading, error }] = useDuplicateBotMutation({
 *   variables: {
 *      id: // value for 'id'
 *      newName: // value for 'newName'
 *   },
 * });
 */
export function useDuplicateBotMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DuplicateBotMutation,
    DuplicateBotMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DuplicateBotMutation,
    DuplicateBotMutationVariables
  >(DuplicateBotDocument, options);
}
export type DuplicateBotMutationHookResult = ReturnType<
  typeof useDuplicateBotMutation
>;
export type DuplicateBotMutationResult =
  Apollo.MutationResult<DuplicateBotMutation>;
export type DuplicateBotMutationOptions = Apollo.BaseMutationOptions<
  DuplicateBotMutation,
  DuplicateBotMutationVariables
>;
export const MakeRooms2Document = gql`
  mutation MakeRooms2($input: MakeRooms2Input) {
    makeRooms2(input: $input) {
      rooms {
        ...CoreRoomFields
      }
      error
    }
  }
  ${CoreRoomFieldsFragmentDoc}
`;
export type MakeRooms2MutationFn = Apollo.MutationFunction<
  MakeRooms2Mutation,
  MakeRooms2MutationVariables
>;

/**
 * __useMakeRooms2Mutation__
 *
 * To run a mutation, you first call `useMakeRooms2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMakeRooms2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [makeRooms2Mutation, { data, loading, error }] = useMakeRooms2Mutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMakeRooms2Mutation(
  baseOptions?: Apollo.MutationHookOptions<
    MakeRooms2Mutation,
    MakeRooms2MutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MakeRooms2Mutation, MakeRooms2MutationVariables>(
    MakeRooms2Document,
    options
  );
}
export type MakeRooms2MutationHookResult = ReturnType<
  typeof useMakeRooms2Mutation
>;
export type MakeRooms2MutationResult =
  Apollo.MutationResult<MakeRooms2Mutation>;
export type MakeRooms2MutationOptions = Apollo.BaseMutationOptions<
  MakeRooms2Mutation,
  MakeRooms2MutationVariables
>;
export const UpdateMessageDataDocument = gql`
  mutation UpdateMessageData($input: UpdateMessageDataInput) {
    updateMessageData(input: $input) {
      channelId
      channelTypeName
      chatRoomId
      chatRoomMessages {
        ...CoreMessageFields
      }
      chatRoomStatus
      isClient
      unreadMessagesNumber
    }
  }
  ${CoreMessageFieldsFragmentDoc}
`;
export type UpdateMessageDataMutationFn = Apollo.MutationFunction<
  UpdateMessageDataMutation,
  UpdateMessageDataMutationVariables
>;

/**
 * __useUpdateMessageDataMutation__
 *
 * To run a mutation, you first call `useUpdateMessageDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMessageDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMessageDataMutation, { data, loading, error }] = useUpdateMessageDataMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMessageDataMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMessageDataMutation,
    UpdateMessageDataMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateMessageDataMutation,
    UpdateMessageDataMutationVariables
  >(UpdateMessageDataDocument, options);
}
export type UpdateMessageDataMutationHookResult = ReturnType<
  typeof useUpdateMessageDataMutation
>;
export type UpdateMessageDataMutationResult =
  Apollo.MutationResult<UpdateMessageDataMutation>;
export type UpdateMessageDataMutationOptions = Apollo.BaseMutationOptions<
  UpdateMessageDataMutation,
  UpdateMessageDataMutationVariables
>;
export const CreateMessageDocument = gql`
  mutation CreateMessage($input: CreateChatRoomMessageInput) {
    createMessage(input: $input) {
      ...CoreMessageFields
    }
  }
  ${CoreMessageFieldsFragmentDoc}
`;
export type CreateMessageMutationFn = Apollo.MutationFunction<
  CreateMessageMutation,
  CreateMessageMutationVariables
>;

/**
 * __useCreateMessageMutation__
 *
 * To run a mutation, you first call `useCreateMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMessageMutation, { data, loading, error }] = useCreateMessageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateMessageMutation,
    CreateMessageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateMessageMutation,
    CreateMessageMutationVariables
  >(CreateMessageDocument, options);
}
export type CreateMessageMutationHookResult = ReturnType<
  typeof useCreateMessageMutation
>;
export type CreateMessageMutationResult =
  Apollo.MutationResult<CreateMessageMutation>;
export type CreateMessageMutationOptions = Apollo.BaseMutationOptions<
  CreateMessageMutation,
  CreateMessageMutationVariables
>;
export const CreateCommentOnClient2Document = gql`
  mutation CreateCommentOnClient2($input: CreateCommentOnClientInput) {
    createCommentOnClient2(input: $input) {
      ...CoreCommentsFields
    }
  }
  ${CoreCommentsFieldsFragmentDoc}
`;
export type CreateCommentOnClient2MutationFn = Apollo.MutationFunction<
  CreateCommentOnClient2Mutation,
  CreateCommentOnClient2MutationVariables
>;

/**
 * __useCreateCommentOnClient2Mutation__
 *
 * To run a mutation, you first call `useCreateCommentOnClient2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCommentOnClient2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCommentOnClient2Mutation, { data, loading, error }] = useCreateCommentOnClient2Mutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCommentOnClient2Mutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCommentOnClient2Mutation,
    CreateCommentOnClient2MutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateCommentOnClient2Mutation,
    CreateCommentOnClient2MutationVariables
  >(CreateCommentOnClient2Document, options);
}
export type CreateCommentOnClient2MutationHookResult = ReturnType<
  typeof useCreateCommentOnClient2Mutation
>;
export type CreateCommentOnClient2MutationResult =
  Apollo.MutationResult<CreateCommentOnClient2Mutation>;
export type CreateCommentOnClient2MutationOptions = Apollo.BaseMutationOptions<
  CreateCommentOnClient2Mutation,
  CreateCommentOnClient2MutationVariables
>;
export const DeleteCommentDocument = gql`
  mutation DeleteComment($commentId: String!) {
    deleteComment(commentId: $commentId) {
      status
    }
  }
`;
export type DeleteCommentMutationFn = Apollo.MutationFunction<
  DeleteCommentMutation,
  DeleteCommentMutationVariables
>;

/**
 * __useDeleteCommentMutation__
 *
 * To run a mutation, you first call `useDeleteCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCommentMutation, { data, loading, error }] = useDeleteCommentMutation({
 *   variables: {
 *      commentId: // value for 'commentId'
 *   },
 * });
 */
export function useDeleteCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCommentMutation,
    DeleteCommentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteCommentMutation,
    DeleteCommentMutationVariables
  >(DeleteCommentDocument, options);
}
export type DeleteCommentMutationHookResult = ReturnType<
  typeof useDeleteCommentMutation
>;
export type DeleteCommentMutationResult =
  Apollo.MutationResult<DeleteCommentMutation>;
export type DeleteCommentMutationOptions = Apollo.BaseMutationOptions<
  DeleteCommentMutation,
  DeleteCommentMutationVariables
>;
export const SetNodesAndEdgesDocument = gql`
  mutation SetNodesAndEdges($input: SetNodesAndEdgesInput) {
    setNodesAndEdges(input: $input) {
      nodes {
        ...CoreNodeFields
      }
      edges {
        ...CoreEdgeFields
      }
    }
  }
  ${CoreNodeFieldsFragmentDoc}
  ${CoreEdgeFieldsFragmentDoc}
`;
export type SetNodesAndEdgesMutationFn = Apollo.MutationFunction<
  SetNodesAndEdgesMutation,
  SetNodesAndEdgesMutationVariables
>;

/**
 * __useSetNodesAndEdgesMutation__
 *
 * To run a mutation, you first call `useSetNodesAndEdgesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetNodesAndEdgesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setNodesAndEdgesMutation, { data, loading, error }] = useSetNodesAndEdgesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetNodesAndEdgesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetNodesAndEdgesMutation,
    SetNodesAndEdgesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetNodesAndEdgesMutation,
    SetNodesAndEdgesMutationVariables
  >(SetNodesAndEdgesDocument, options);
}
export type SetNodesAndEdgesMutationHookResult = ReturnType<
  typeof useSetNodesAndEdgesMutation
>;
export type SetNodesAndEdgesMutationResult =
  Apollo.MutationResult<SetNodesAndEdgesMutation>;
export type SetNodesAndEdgesMutationOptions = Apollo.BaseMutationOptions<
  SetNodesAndEdgesMutation,
  SetNodesAndEdgesMutationVariables
>;
export const CreateChatSnippetDocument = gql`
  mutation CreateChatSnippet(
    $userId: String!
    $snippet: String!
    $categoryId: String
    $teamsIds: [String!]
  ) {
    createChatSnippet(
      userId: $userId
      snippet: $snippet
      categoryId: $categoryId
      teamsIds: $teamsIds
    ) {
      ...CoreSnippetFields
    }
  }
  ${CoreSnippetFieldsFragmentDoc}
`;
export type CreateChatSnippetMutationFn = Apollo.MutationFunction<
  CreateChatSnippetMutation,
  CreateChatSnippetMutationVariables
>;

/**
 * __useCreateChatSnippetMutation__
 *
 * To run a mutation, you first call `useCreateChatSnippetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChatSnippetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChatSnippetMutation, { data, loading, error }] = useCreateChatSnippetMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      snippet: // value for 'snippet'
 *      categoryId: // value for 'categoryId'
 *      teamsIds: // value for 'teamsIds'
 *   },
 * });
 */
export function useCreateChatSnippetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateChatSnippetMutation,
    CreateChatSnippetMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateChatSnippetMutation,
    CreateChatSnippetMutationVariables
  >(CreateChatSnippetDocument, options);
}
export type CreateChatSnippetMutationHookResult = ReturnType<
  typeof useCreateChatSnippetMutation
>;
export type CreateChatSnippetMutationResult =
  Apollo.MutationResult<CreateChatSnippetMutation>;
export type CreateChatSnippetMutationOptions = Apollo.BaseMutationOptions<
  CreateChatSnippetMutation,
  CreateChatSnippetMutationVariables
>;
export const UpdateChatSnippetDocument = gql`
  mutation UpdateChatSnippet(
    $snippetId: String!
    $userId: String!
    $snippet: String
    $categoryId: String
    $teamsIds: [String!]
  ) {
    updateChatSnippet(
      snippetId: $snippetId
      userId: $userId
      snippet: $snippet
      categoryId: $categoryId
      teamsIds: $teamsIds
    ) {
      ...CoreSnippetFields
    }
  }
  ${CoreSnippetFieldsFragmentDoc}
`;
export type UpdateChatSnippetMutationFn = Apollo.MutationFunction<
  UpdateChatSnippetMutation,
  UpdateChatSnippetMutationVariables
>;

/**
 * __useUpdateChatSnippetMutation__
 *
 * To run a mutation, you first call `useUpdateChatSnippetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChatSnippetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChatSnippetMutation, { data, loading, error }] = useUpdateChatSnippetMutation({
 *   variables: {
 *      snippetId: // value for 'snippetId'
 *      userId: // value for 'userId'
 *      snippet: // value for 'snippet'
 *      categoryId: // value for 'categoryId'
 *      teamsIds: // value for 'teamsIds'
 *   },
 * });
 */
export function useUpdateChatSnippetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateChatSnippetMutation,
    UpdateChatSnippetMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateChatSnippetMutation,
    UpdateChatSnippetMutationVariables
  >(UpdateChatSnippetDocument, options);
}
export type UpdateChatSnippetMutationHookResult = ReturnType<
  typeof useUpdateChatSnippetMutation
>;
export type UpdateChatSnippetMutationResult =
  Apollo.MutationResult<UpdateChatSnippetMutation>;
export type UpdateChatSnippetMutationOptions = Apollo.BaseMutationOptions<
  UpdateChatSnippetMutation,
  UpdateChatSnippetMutationVariables
>;
export const CreateChatSnippetsCategoryDocument = gql`
  mutation CreateChatSnippetsCategory(
    $userId: String!
    $categoryName: String!
    $parentId: String
  ) {
    createChatSnippetsCategory(
      userId: $userId
      categoryName: $categoryName
      parentId: $parentId
    ) {
      ...CoreSnippetCategoryFields
    }
  }
  ${CoreSnippetCategoryFieldsFragmentDoc}
`;
export type CreateChatSnippetsCategoryMutationFn = Apollo.MutationFunction<
  CreateChatSnippetsCategoryMutation,
  CreateChatSnippetsCategoryMutationVariables
>;

/**
 * __useCreateChatSnippetsCategoryMutation__
 *
 * To run a mutation, you first call `useCreateChatSnippetsCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChatSnippetsCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChatSnippetsCategoryMutation, { data, loading, error }] = useCreateChatSnippetsCategoryMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      categoryName: // value for 'categoryName'
 *      parentId: // value for 'parentId'
 *   },
 * });
 */
export function useCreateChatSnippetsCategoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateChatSnippetsCategoryMutation,
    CreateChatSnippetsCategoryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateChatSnippetsCategoryMutation,
    CreateChatSnippetsCategoryMutationVariables
  >(CreateChatSnippetsCategoryDocument, options);
}
export type CreateChatSnippetsCategoryMutationHookResult = ReturnType<
  typeof useCreateChatSnippetsCategoryMutation
>;
export type CreateChatSnippetsCategoryMutationResult =
  Apollo.MutationResult<CreateChatSnippetsCategoryMutation>;
export type CreateChatSnippetsCategoryMutationOptions =
  Apollo.BaseMutationOptions<
    CreateChatSnippetsCategoryMutation,
    CreateChatSnippetsCategoryMutationVariables
  >;
export const UpdateChatSnippetsCategoryDocument = gql`
  mutation UpdateChatSnippetsCategory(
    $categoryId: String!
    $userId: String!
    $categoryName: String
    $parentId: String
  ) {
    updateChatSnippetsCategory(
      userId: $userId
      categoryName: $categoryName
      parentId: $parentId
      categoryId: $categoryId
    ) {
      ...CoreSnippetCategoryFields
    }
  }
  ${CoreSnippetCategoryFieldsFragmentDoc}
`;
export type UpdateChatSnippetsCategoryMutationFn = Apollo.MutationFunction<
  UpdateChatSnippetsCategoryMutation,
  UpdateChatSnippetsCategoryMutationVariables
>;

/**
 * __useUpdateChatSnippetsCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateChatSnippetsCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChatSnippetsCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChatSnippetsCategoryMutation, { data, loading, error }] = useUpdateChatSnippetsCategoryMutation({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *      userId: // value for 'userId'
 *      categoryName: // value for 'categoryName'
 *      parentId: // value for 'parentId'
 *   },
 * });
 */
export function useUpdateChatSnippetsCategoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateChatSnippetsCategoryMutation,
    UpdateChatSnippetsCategoryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateChatSnippetsCategoryMutation,
    UpdateChatSnippetsCategoryMutationVariables
  >(UpdateChatSnippetsCategoryDocument, options);
}
export type UpdateChatSnippetsCategoryMutationHookResult = ReturnType<
  typeof useUpdateChatSnippetsCategoryMutation
>;
export type UpdateChatSnippetsCategoryMutationResult =
  Apollo.MutationResult<UpdateChatSnippetsCategoryMutation>;
export type UpdateChatSnippetsCategoryMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateChatSnippetsCategoryMutation,
    UpdateChatSnippetsCategoryMutationVariables
  >;
export const DeleteChatSnippetsCategoryDocument = gql`
  mutation DeleteChatSnippetsCategory($userId: String!, $categoryId: String!) {
    deleteChatSnippetsCategory(userId: $userId, categoryId: $categoryId) {
      ...CoreSnippetCategoryFields
    }
  }
  ${CoreSnippetCategoryFieldsFragmentDoc}
`;
export type DeleteChatSnippetsCategoryMutationFn = Apollo.MutationFunction<
  DeleteChatSnippetsCategoryMutation,
  DeleteChatSnippetsCategoryMutationVariables
>;

/**
 * __useDeleteChatSnippetsCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteChatSnippetsCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteChatSnippetsCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteChatSnippetsCategoryMutation, { data, loading, error }] = useDeleteChatSnippetsCategoryMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      categoryId: // value for 'categoryId'
 *   },
 * });
 */
export function useDeleteChatSnippetsCategoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteChatSnippetsCategoryMutation,
    DeleteChatSnippetsCategoryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteChatSnippetsCategoryMutation,
    DeleteChatSnippetsCategoryMutationVariables
  >(DeleteChatSnippetsCategoryDocument, options);
}
export type DeleteChatSnippetsCategoryMutationHookResult = ReturnType<
  typeof useDeleteChatSnippetsCategoryMutation
>;
export type DeleteChatSnippetsCategoryMutationResult =
  Apollo.MutationResult<DeleteChatSnippetsCategoryMutation>;
export type DeleteChatSnippetsCategoryMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteChatSnippetsCategoryMutation,
    DeleteChatSnippetsCategoryMutationVariables
  >;
export const AddSnippetToFavoritesDocument = gql`
  mutation AddSnippetToFavorites($userId: String!, $snippetId: String!) {
    addSnippetToFavorites(userId: $userId, snippetId: $snippetId) {
      ...CoreSnippetFavoriteFields
    }
  }
  ${CoreSnippetFavoriteFieldsFragmentDoc}
`;
export type AddSnippetToFavoritesMutationFn = Apollo.MutationFunction<
  AddSnippetToFavoritesMutation,
  AddSnippetToFavoritesMutationVariables
>;

/**
 * __useAddSnippetToFavoritesMutation__
 *
 * To run a mutation, you first call `useAddSnippetToFavoritesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSnippetToFavoritesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSnippetToFavoritesMutation, { data, loading, error }] = useAddSnippetToFavoritesMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      snippetId: // value for 'snippetId'
 *   },
 * });
 */
export function useAddSnippetToFavoritesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddSnippetToFavoritesMutation,
    AddSnippetToFavoritesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddSnippetToFavoritesMutation,
    AddSnippetToFavoritesMutationVariables
  >(AddSnippetToFavoritesDocument, options);
}
export type AddSnippetToFavoritesMutationHookResult = ReturnType<
  typeof useAddSnippetToFavoritesMutation
>;
export type AddSnippetToFavoritesMutationResult =
  Apollo.MutationResult<AddSnippetToFavoritesMutation>;
export type AddSnippetToFavoritesMutationOptions = Apollo.BaseMutationOptions<
  AddSnippetToFavoritesMutation,
  AddSnippetToFavoritesMutationVariables
>;
export const RemoveSnippetFromFavoriteDocument = gql`
  mutation RemoveSnippetFromFavorite($userId: String!, $snippetId: String!) {
    removeSnippetFromFavorite(userId: $userId, snippetId: $snippetId) {
      ...CoreSnippetFavoriteFields
    }
  }
  ${CoreSnippetFavoriteFieldsFragmentDoc}
`;
export type RemoveSnippetFromFavoriteMutationFn = Apollo.MutationFunction<
  RemoveSnippetFromFavoriteMutation,
  RemoveSnippetFromFavoriteMutationVariables
>;

/**
 * __useRemoveSnippetFromFavoriteMutation__
 *
 * To run a mutation, you first call `useRemoveSnippetFromFavoriteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveSnippetFromFavoriteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeSnippetFromFavoriteMutation, { data, loading, error }] = useRemoveSnippetFromFavoriteMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      snippetId: // value for 'snippetId'
 *   },
 * });
 */
export function useRemoveSnippetFromFavoriteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveSnippetFromFavoriteMutation,
    RemoveSnippetFromFavoriteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveSnippetFromFavoriteMutation,
    RemoveSnippetFromFavoriteMutationVariables
  >(RemoveSnippetFromFavoriteDocument, options);
}
export type RemoveSnippetFromFavoriteMutationHookResult = ReturnType<
  typeof useRemoveSnippetFromFavoriteMutation
>;
export type RemoveSnippetFromFavoriteMutationResult =
  Apollo.MutationResult<RemoveSnippetFromFavoriteMutation>;
export type RemoveSnippetFromFavoriteMutationOptions =
  Apollo.BaseMutationOptions<
    RemoveSnippetFromFavoriteMutation,
    RemoveSnippetFromFavoriteMutationVariables
  >;
export const DeleteChatSnippetDocument = gql`
  mutation DeleteChatSnippet($userId: String!, $snippetId: String!) {
    deleteChatSnippet(userId: $userId, snippetId: $snippetId) {
      ...CoreSnippetFields
    }
  }
  ${CoreSnippetFieldsFragmentDoc}
`;
export type DeleteChatSnippetMutationFn = Apollo.MutationFunction<
  DeleteChatSnippetMutation,
  DeleteChatSnippetMutationVariables
>;

/**
 * __useDeleteChatSnippetMutation__
 *
 * To run a mutation, you first call `useDeleteChatSnippetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteChatSnippetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteChatSnippetMutation, { data, loading, error }] = useDeleteChatSnippetMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      snippetId: // value for 'snippetId'
 *   },
 * });
 */
export function useDeleteChatSnippetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteChatSnippetMutation,
    DeleteChatSnippetMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteChatSnippetMutation,
    DeleteChatSnippetMutationVariables
  >(DeleteChatSnippetDocument, options);
}
export type DeleteChatSnippetMutationHookResult = ReturnType<
  typeof useDeleteChatSnippetMutation
>;
export type DeleteChatSnippetMutationResult =
  Apollo.MutationResult<DeleteChatSnippetMutation>;
export type DeleteChatSnippetMutationOptions = Apollo.BaseMutationOptions<
  DeleteChatSnippetMutation,
  DeleteChatSnippetMutationVariables
>;
export const CreateTeamDocument = gql`
  mutation CreateTeam($input: CreateTeamInput!) {
    createTeam(input: $input) {
      ...ExtendedTeamFields
    }
  }
  ${ExtendedTeamFieldsFragmentDoc}
`;
export type CreateTeamMutationFn = Apollo.MutationFunction<
  CreateTeamMutation,
  CreateTeamMutationVariables
>;

/**
 * __useCreateTeamMutation__
 *
 * To run a mutation, you first call `useCreateTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTeamMutation, { data, loading, error }] = useCreateTeamMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTeamMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateTeamMutation,
    CreateTeamMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateTeamMutation, CreateTeamMutationVariables>(
    CreateTeamDocument,
    options
  );
}
export type CreateTeamMutationHookResult = ReturnType<
  typeof useCreateTeamMutation
>;
export type CreateTeamMutationResult =
  Apollo.MutationResult<CreateTeamMutation>;
export type CreateTeamMutationOptions = Apollo.BaseMutationOptions<
  CreateTeamMutation,
  CreateTeamMutationVariables
>;
export const UpdateTeamDocument = gql`
  mutation UpdateTeam($input: UpdateTeamInput!) {
    updateTeam(input: $input) {
      ...ExtendedTeamFields
    }
  }
  ${ExtendedTeamFieldsFragmentDoc}
`;
export type UpdateTeamMutationFn = Apollo.MutationFunction<
  UpdateTeamMutation,
  UpdateTeamMutationVariables
>;

/**
 * __useUpdateTeamMutation__
 *
 * To run a mutation, you first call `useUpdateTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTeamMutation, { data, loading, error }] = useUpdateTeamMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTeamMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTeamMutation,
    UpdateTeamMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateTeamMutation, UpdateTeamMutationVariables>(
    UpdateTeamDocument,
    options
  );
}
export type UpdateTeamMutationHookResult = ReturnType<
  typeof useUpdateTeamMutation
>;
export type UpdateTeamMutationResult =
  Apollo.MutationResult<UpdateTeamMutation>;
export type UpdateTeamMutationOptions = Apollo.BaseMutationOptions<
  UpdateTeamMutation,
  UpdateTeamMutationVariables
>;
export const DeleteTeamDocument = gql`
  mutation DeleteTeam($teamId: String!) {
    deleteTeam(teamId: $teamId) {
      status
    }
  }
`;
export type DeleteTeamMutationFn = Apollo.MutationFunction<
  DeleteTeamMutation,
  DeleteTeamMutationVariables
>;

/**
 * __useDeleteTeamMutation__
 *
 * To run a mutation, you first call `useDeleteTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTeamMutation, { data, loading, error }] = useDeleteTeamMutation({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useDeleteTeamMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteTeamMutation,
    DeleteTeamMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteTeamMutation, DeleteTeamMutationVariables>(
    DeleteTeamDocument,
    options
  );
}
export type DeleteTeamMutationHookResult = ReturnType<
  typeof useDeleteTeamMutation
>;
export type DeleteTeamMutationResult =
  Apollo.MutationResult<DeleteTeamMutation>;
export type DeleteTeamMutationOptions = Apollo.BaseMutationOptions<
  DeleteTeamMutation,
  DeleteTeamMutationVariables
>;
export const MakeAppsDocument = gql`
  mutation MakeApps($input: [MakeAppsInput]) {
    makeApps(input: $input) {
      ...CoreAppFields
    }
  }
  ${CoreAppFieldsFragmentDoc}
`;
export type MakeAppsMutationFn = Apollo.MutationFunction<
  MakeAppsMutation,
  MakeAppsMutationVariables
>;

/**
 * __useMakeAppsMutation__
 *
 * To run a mutation, you first call `useMakeAppsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMakeAppsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [makeAppsMutation, { data, loading, error }] = useMakeAppsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMakeAppsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MakeAppsMutation,
    MakeAppsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MakeAppsMutation, MakeAppsMutationVariables>(
    MakeAppsDocument,
    options
  );
}
export type MakeAppsMutationHookResult = ReturnType<typeof useMakeAppsMutation>;
export type MakeAppsMutationResult = Apollo.MutationResult<MakeAppsMutation>;
export type MakeAppsMutationOptions = Apollo.BaseMutationOptions<
  MakeAppsMutation,
  MakeAppsMutationVariables
>;
export const CreateCalendarEventDocument = gql`
  mutation CreateCalendarEvent($params: CreateCalendarEventInput!) {
    createCalendarEvent(params: $params) {
      actionType
      event {
        ...CoreCalendarEventFields
      }
    }
  }
  ${CoreCalendarEventFieldsFragmentDoc}
`;
export type CreateCalendarEventMutationFn = Apollo.MutationFunction<
  CreateCalendarEventMutation,
  CreateCalendarEventMutationVariables
>;

/**
 * __useCreateCalendarEventMutation__
 *
 * To run a mutation, you first call `useCreateCalendarEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCalendarEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCalendarEventMutation, { data, loading, error }] = useCreateCalendarEventMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useCreateCalendarEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCalendarEventMutation,
    CreateCalendarEventMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateCalendarEventMutation,
    CreateCalendarEventMutationVariables
  >(CreateCalendarEventDocument, options);
}
export type CreateCalendarEventMutationHookResult = ReturnType<
  typeof useCreateCalendarEventMutation
>;
export type CreateCalendarEventMutationResult =
  Apollo.MutationResult<CreateCalendarEventMutation>;
export type CreateCalendarEventMutationOptions = Apollo.BaseMutationOptions<
  CreateCalendarEventMutation,
  CreateCalendarEventMutationVariables
>;
export const UpdateCalendarEventDocument = gql`
  mutation UpdateCalendarEvent($params: UpdateCalendarEventInput!) {
    updateCalendarEvent(params: $params) {
      actionType
      event {
        ...CoreCalendarEventFields
      }
    }
  }
  ${CoreCalendarEventFieldsFragmentDoc}
`;
export type UpdateCalendarEventMutationFn = Apollo.MutationFunction<
  UpdateCalendarEventMutation,
  UpdateCalendarEventMutationVariables
>;

/**
 * __useUpdateCalendarEventMutation__
 *
 * To run a mutation, you first call `useUpdateCalendarEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCalendarEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCalendarEventMutation, { data, loading, error }] = useUpdateCalendarEventMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useUpdateCalendarEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCalendarEventMutation,
    UpdateCalendarEventMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCalendarEventMutation,
    UpdateCalendarEventMutationVariables
  >(UpdateCalendarEventDocument, options);
}
export type UpdateCalendarEventMutationHookResult = ReturnType<
  typeof useUpdateCalendarEventMutation
>;
export type UpdateCalendarEventMutationResult =
  Apollo.MutationResult<UpdateCalendarEventMutation>;
export type UpdateCalendarEventMutationOptions = Apollo.BaseMutationOptions<
  UpdateCalendarEventMutation,
  UpdateCalendarEventMutationVariables
>;
export const DeleteCalendarEventDocument = gql`
  mutation DeleteCalendarEvent($params: DeleteCalendarInput!) {
    deleteCalendarEvent(params: $params) {
      actionType
      event {
        ...CoreCalendarEventFields
      }
    }
  }
  ${CoreCalendarEventFieldsFragmentDoc}
`;
export type DeleteCalendarEventMutationFn = Apollo.MutationFunction<
  DeleteCalendarEventMutation,
  DeleteCalendarEventMutationVariables
>;

/**
 * __useDeleteCalendarEventMutation__
 *
 * To run a mutation, you first call `useDeleteCalendarEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCalendarEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCalendarEventMutation, { data, loading, error }] = useDeleteCalendarEventMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useDeleteCalendarEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCalendarEventMutation,
    DeleteCalendarEventMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteCalendarEventMutation,
    DeleteCalendarEventMutationVariables
  >(DeleteCalendarEventDocument, options);
}
export type DeleteCalendarEventMutationHookResult = ReturnType<
  typeof useDeleteCalendarEventMutation
>;
export type DeleteCalendarEventMutationResult =
  Apollo.MutationResult<DeleteCalendarEventMutation>;
export type DeleteCalendarEventMutationOptions = Apollo.BaseMutationOptions<
  DeleteCalendarEventMutation,
  DeleteCalendarEventMutationVariables
>;
export const CreateCalendarDocument = gql`
  mutation CreateCalendar($params: CreateCalendarInput!) {
    createCalendar(params: $params) {
      id
      accountId
      description
      name
      isPrimary
      location
      object
      readOnly
      timezone
    }
  }
`;
export type CreateCalendarMutationFn = Apollo.MutationFunction<
  CreateCalendarMutation,
  CreateCalendarMutationVariables
>;

/**
 * __useCreateCalendarMutation__
 *
 * To run a mutation, you first call `useCreateCalendarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCalendarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCalendarMutation, { data, loading, error }] = useCreateCalendarMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useCreateCalendarMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCalendarMutation,
    CreateCalendarMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateCalendarMutation,
    CreateCalendarMutationVariables
  >(CreateCalendarDocument, options);
}
export type CreateCalendarMutationHookResult = ReturnType<
  typeof useCreateCalendarMutation
>;
export type CreateCalendarMutationResult =
  Apollo.MutationResult<CreateCalendarMutation>;
export type CreateCalendarMutationOptions = Apollo.BaseMutationOptions<
  CreateCalendarMutation,
  CreateCalendarMutationVariables
>;
export const MakeCustomFieldDocument = gql`
  mutation MakeCustomField($input: MakeCustomFieldInput) {
    makeCustomField(input: $input) {
      ...CoreCustomFieldFields
    }
  }
  ${CoreCustomFieldFieldsFragmentDoc}
`;
export type MakeCustomFieldMutationFn = Apollo.MutationFunction<
  MakeCustomFieldMutation,
  MakeCustomFieldMutationVariables
>;

/**
 * __useMakeCustomFieldMutation__
 *
 * To run a mutation, you first call `useMakeCustomFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMakeCustomFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [makeCustomFieldMutation, { data, loading, error }] = useMakeCustomFieldMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMakeCustomFieldMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MakeCustomFieldMutation,
    MakeCustomFieldMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MakeCustomFieldMutation,
    MakeCustomFieldMutationVariables
  >(MakeCustomFieldDocument, options);
}
export type MakeCustomFieldMutationHookResult = ReturnType<
  typeof useMakeCustomFieldMutation
>;
export type MakeCustomFieldMutationResult =
  Apollo.MutationResult<MakeCustomFieldMutation>;
export type MakeCustomFieldMutationOptions = Apollo.BaseMutationOptions<
  MakeCustomFieldMutation,
  MakeCustomFieldMutationVariables
>;
export const GetRolesDocument = gql`
  query GetRoles {
    getRoles {
      ...CoreRoleFields
    }
  }
  ${CoreRoleFieldsFragmentDoc}
`;

/**
 * __useGetRolesQuery__
 *
 * To run a query within a React component, call `useGetRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRolesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetRolesQuery, GetRolesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRolesQuery, GetRolesQueryVariables>(
    GetRolesDocument,
    options
  );
}
export function useGetRolesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRolesQuery,
    GetRolesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRolesQuery, GetRolesQueryVariables>(
    GetRolesDocument,
    options
  );
}
export type GetRolesQueryHookResult = ReturnType<typeof useGetRolesQuery>;
export type GetRolesLazyQueryHookResult = ReturnType<
  typeof useGetRolesLazyQuery
>;
export type GetRolesQueryResult = Apollo.QueryResult<
  GetRolesQuery,
  GetRolesQueryVariables
>;
export const GetUserDocument = gql`
  query GetUser($userId: String) {
    getUser(userId: $userId) {
      ...CoreUserFields
      ...InternalUserFields
    }
  }
  ${CoreUserFieldsFragmentDoc}
  ${InternalUserFieldsFragmentDoc}
`;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserQuery(
  baseOptions?: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(
    GetUserDocument,
    options
  );
}
export function useGetUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(
    GetUserDocument,
    options
  );
}
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<
  GetUserQuery,
  GetUserQueryVariables
>;
export const GetCommentsOnClient2Document = gql`
  query getCommentsOnClient2($clientId: String!) {
    getCommentsOnClient2(clientId: $clientId) {
      commentCount
      comments {
        ...CoreCommentsFields
      }
    }
  }
  ${CoreCommentsFieldsFragmentDoc}
`;

/**
 * __useGetCommentsOnClient2Query__
 *
 * To run a query within a React component, call `useGetCommentsOnClient2Query` and pass it any options that fit your needs.
 * When your component renders, `useGetCommentsOnClient2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommentsOnClient2Query({
 *   variables: {
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useGetCommentsOnClient2Query(
  baseOptions: Apollo.QueryHookOptions<
    GetCommentsOnClient2Query,
    GetCommentsOnClient2QueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCommentsOnClient2Query,
    GetCommentsOnClient2QueryVariables
  >(GetCommentsOnClient2Document, options);
}
export function useGetCommentsOnClient2LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCommentsOnClient2Query,
    GetCommentsOnClient2QueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCommentsOnClient2Query,
    GetCommentsOnClient2QueryVariables
  >(GetCommentsOnClient2Document, options);
}
export type GetCommentsOnClient2QueryHookResult = ReturnType<
  typeof useGetCommentsOnClient2Query
>;
export type GetCommentsOnClient2LazyQueryHookResult = ReturnType<
  typeof useGetCommentsOnClient2LazyQuery
>;
export type GetCommentsOnClient2QueryResult = Apollo.QueryResult<
  GetCommentsOnClient2Query,
  GetCommentsOnClient2QueryVariables
>;
export const GetUsersDocument = gql`
  query GetUsers($input: GetUsersInput!) {
    getUsers(input: $input) {
      users {
        ...CoreUserFields
        ...ClientFields
        ...InternalUserFields
      }
      pagination {
        itemsPerPage
        pageNumber
        totalItems
      }
    }
  }
  ${CoreUserFieldsFragmentDoc}
  ${ClientFieldsFragmentDoc}
  ${InternalUserFieldsFragmentDoc}
`;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetUsersQuery(
  baseOptions: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(
    GetUsersDocument,
    options
  );
}
export function useGetUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUsersQuery,
    GetUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(
    GetUsersDocument,
    options
  );
}
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<
  typeof useGetUsersLazyQuery
>;
export type GetUsersQueryResult = Apollo.QueryResult<
  GetUsersQuery,
  GetUsersQueryVariables
>;
export const GetChannels2Document = gql`
  query GetChannels2($isActiveOnly: Boolean! = true) {
    getChannels2(isActiveOnly: $isActiveOnly) {
      ...ExtendedChannelFields
    }
  }
  ${ExtendedChannelFieldsFragmentDoc}
`;

/**
 * __useGetChannels2Query__
 *
 * To run a query within a React component, call `useGetChannels2Query` and pass it any options that fit your needs.
 * When your component renders, `useGetChannels2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChannels2Query({
 *   variables: {
 *      isActiveOnly: // value for 'isActiveOnly'
 *   },
 * });
 */
export function useGetChannels2Query(
  baseOptions?: Apollo.QueryHookOptions<
    GetChannels2Query,
    GetChannels2QueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetChannels2Query, GetChannels2QueryVariables>(
    GetChannels2Document,
    options
  );
}
export function useGetChannels2LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetChannels2Query,
    GetChannels2QueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetChannels2Query, GetChannels2QueryVariables>(
    GetChannels2Document,
    options
  );
}
export type GetChannels2QueryHookResult = ReturnType<
  typeof useGetChannels2Query
>;
export type GetChannels2LazyQueryHookResult = ReturnType<
  typeof useGetChannels2LazyQuery
>;
export type GetChannels2QueryResult = Apollo.QueryResult<
  GetChannels2Query,
  GetChannels2QueryVariables
>;
export const GetBotsDocument = gql`
  query GetBots($status: BotStatus!) {
    getBots(status: $status) {
      ...ExtendedBotFields
    }
  }
  ${ExtendedBotFieldsFragmentDoc}
`;

/**
 * __useGetBotsQuery__
 *
 * To run a query within a React component, call `useGetBotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBotsQuery({
 *   variables: {
 *      status: // value for 'status'
 *   },
 * });
 */
export function useGetBotsQuery(
  baseOptions: Apollo.QueryHookOptions<GetBotsQuery, GetBotsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBotsQuery, GetBotsQueryVariables>(
    GetBotsDocument,
    options
  );
}
export function useGetBotsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetBotsQuery, GetBotsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetBotsQuery, GetBotsQueryVariables>(
    GetBotsDocument,
    options
  );
}
export type GetBotsQueryHookResult = ReturnType<typeof useGetBotsQuery>;
export type GetBotsLazyQueryHookResult = ReturnType<typeof useGetBotsLazyQuery>;
export type GetBotsQueryResult = Apollo.QueryResult<
  GetBotsQuery,
  GetBotsQueryVariables
>;
export const GetBotDocument = gql`
  query GetBot($id: String!) {
    getBot(id: $id) {
      ...ExtendedBotFields
    }
  }
  ${ExtendedBotFieldsFragmentDoc}
`;

/**
 * __useGetBotQuery__
 *
 * To run a query within a React component, call `useGetBotQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBotQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBotQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBotQuery(
  baseOptions: Apollo.QueryHookOptions<GetBotQuery, GetBotQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBotQuery, GetBotQueryVariables>(
    GetBotDocument,
    options
  );
}
export function useGetBotLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetBotQuery, GetBotQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetBotQuery, GetBotQueryVariables>(
    GetBotDocument,
    options
  );
}
export type GetBotQueryHookResult = ReturnType<typeof useGetBotQuery>;
export type GetBotLazyQueryHookResult = ReturnType<typeof useGetBotLazyQuery>;
export type GetBotQueryResult = Apollo.QueryResult<
  GetBotQuery,
  GetBotQueryVariables
>;
export const GetFlowsDocument = gql`
  query GetFlows($botId: String!) {
    getFlows(botId: $botId) {
      ...CoreFlowFields
    }
  }
  ${CoreFlowFieldsFragmentDoc}
`;

/**
 * __useGetFlowsQuery__
 *
 * To run a query within a React component, call `useGetFlowsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFlowsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFlowsQuery({
 *   variables: {
 *      botId: // value for 'botId'
 *   },
 * });
 */
export function useGetFlowsQuery(
  baseOptions: Apollo.QueryHookOptions<GetFlowsQuery, GetFlowsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetFlowsQuery, GetFlowsQueryVariables>(
    GetFlowsDocument,
    options
  );
}
export function useGetFlowsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFlowsQuery,
    GetFlowsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetFlowsQuery, GetFlowsQueryVariables>(
    GetFlowsDocument,
    options
  );
}
export type GetFlowsQueryHookResult = ReturnType<typeof useGetFlowsQuery>;
export type GetFlowsLazyQueryHookResult = ReturnType<
  typeof useGetFlowsLazyQuery
>;
export type GetFlowsQueryResult = Apollo.QueryResult<
  GetFlowsQuery,
  GetFlowsQueryVariables
>;
export const GetFlowDocument = gql`
  query GetFlow($input: GetFlowInput) {
    getFlow(input: $input) {
      ...CoreFlowFields
    }
  }
  ${CoreFlowFieldsFragmentDoc}
`;

/**
 * __useGetFlowQuery__
 *
 * To run a query within a React component, call `useGetFlowQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFlowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFlowQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetFlowQuery(
  baseOptions?: Apollo.QueryHookOptions<GetFlowQuery, GetFlowQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetFlowQuery, GetFlowQueryVariables>(
    GetFlowDocument,
    options
  );
}
export function useGetFlowLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetFlowQuery, GetFlowQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetFlowQuery, GetFlowQueryVariables>(
    GetFlowDocument,
    options
  );
}
export type GetFlowQueryHookResult = ReturnType<typeof useGetFlowQuery>;
export type GetFlowLazyQueryHookResult = ReturnType<typeof useGetFlowLazyQuery>;
export type GetFlowQueryResult = Apollo.QueryResult<
  GetFlowQuery,
  GetFlowQueryVariables
>;
export const GetTeamsDocument = gql`
  query GetTeams {
    getTeams {
      ...ExtendedTeamFields
    }
  }
  ${ExtendedTeamFieldsFragmentDoc}
`;

/**
 * __useGetTeamsQuery__
 *
 * To run a query within a React component, call `useGetTeamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTeamsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetTeamsQuery, GetTeamsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTeamsQuery, GetTeamsQueryVariables>(
    GetTeamsDocument,
    options
  );
}
export function useGetTeamsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTeamsQuery,
    GetTeamsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTeamsQuery, GetTeamsQueryVariables>(
    GetTeamsDocument,
    options
  );
}
export type GetTeamsQueryHookResult = ReturnType<typeof useGetTeamsQuery>;
export type GetTeamsLazyQueryHookResult = ReturnType<
  typeof useGetTeamsLazyQuery
>;
export type GetTeamsQueryResult = Apollo.QueryResult<
  GetTeamsQuery,
  GetTeamsQueryVariables
>;
export const GetRooms2Document = gql`
  query GetRooms2($input: GetRoomsInput!) {
    getRooms2(input: $input) {
      rooms {
        ...CoreRoomFields
      }
      hasUnreadMessages
      pagination {
        totalItems
        nextCursor
      }
      requestedStatus
      error
    }
  }
  ${CoreRoomFieldsFragmentDoc}
`;

/**
 * __useGetRooms2Query__
 *
 * To run a query within a React component, call `useGetRooms2Query` and pass it any options that fit your needs.
 * When your component renders, `useGetRooms2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRooms2Query({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetRooms2Query(
  baseOptions: Apollo.QueryHookOptions<GetRooms2Query, GetRooms2QueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRooms2Query, GetRooms2QueryVariables>(
    GetRooms2Document,
    options
  );
}
export function useGetRooms2LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRooms2Query,
    GetRooms2QueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRooms2Query, GetRooms2QueryVariables>(
    GetRooms2Document,
    options
  );
}
export type GetRooms2QueryHookResult = ReturnType<typeof useGetRooms2Query>;
export type GetRooms2LazyQueryHookResult = ReturnType<
  typeof useGetRooms2LazyQuery
>;
export type GetRooms2QueryResult = Apollo.QueryResult<
  GetRooms2Query,
  GetRooms2QueryVariables
>;
export const GetRoom2Document = gql`
  query GetRoom2($input: GetRoomInput!) {
    getRoom2(input: $input) {
      room {
        ...CoreRoomFields
      }
      error
    }
  }
  ${CoreRoomFieldsFragmentDoc}
`;

/**
 * __useGetRoom2Query__
 *
 * To run a query within a React component, call `useGetRoom2Query` and pass it any options that fit your needs.
 * When your component renders, `useGetRoom2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoom2Query({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetRoom2Query(
  baseOptions: Apollo.QueryHookOptions<GetRoom2Query, GetRoom2QueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRoom2Query, GetRoom2QueryVariables>(
    GetRoom2Document,
    options
  );
}
export function useGetRoom2LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRoom2Query,
    GetRoom2QueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRoom2Query, GetRoom2QueryVariables>(
    GetRoom2Document,
    options
  );
}
export type GetRoom2QueryHookResult = ReturnType<typeof useGetRoom2Query>;
export type GetRoom2LazyQueryHookResult = ReturnType<
  typeof useGetRoom2LazyQuery
>;
export type GetRoom2QueryResult = Apollo.QueryResult<
  GetRoom2Query,
  GetRoom2QueryVariables
>;
export const GetChatRoomMessagesDocument = gql`
  query GetChatRoomMessages(
    $chatRoomId: String!
    $fetchSize: Int!
    $pagingState: String
  ) {
    getChatRoomMessages(
      chatRoomId: $chatRoomId
      fetchSize: $fetchSize
      pagingState: $pagingState
    ) {
      chatRoomMessages {
        ...CoreMessageFields
      }
      roomId
      pagingState
    }
  }
  ${CoreMessageFieldsFragmentDoc}
`;

/**
 * __useGetChatRoomMessagesQuery__
 *
 * To run a query within a React component, call `useGetChatRoomMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChatRoomMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChatRoomMessagesQuery({
 *   variables: {
 *      chatRoomId: // value for 'chatRoomId'
 *      fetchSize: // value for 'fetchSize'
 *      pagingState: // value for 'pagingState'
 *   },
 * });
 */
export function useGetChatRoomMessagesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetChatRoomMessagesQuery,
    GetChatRoomMessagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetChatRoomMessagesQuery,
    GetChatRoomMessagesQueryVariables
  >(GetChatRoomMessagesDocument, options);
}
export function useGetChatRoomMessagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetChatRoomMessagesQuery,
    GetChatRoomMessagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetChatRoomMessagesQuery,
    GetChatRoomMessagesQueryVariables
  >(GetChatRoomMessagesDocument, options);
}
export type GetChatRoomMessagesQueryHookResult = ReturnType<
  typeof useGetChatRoomMessagesQuery
>;
export type GetChatRoomMessagesLazyQueryHookResult = ReturnType<
  typeof useGetChatRoomMessagesLazyQuery
>;
export type GetChatRoomMessagesQueryResult = Apollo.QueryResult<
  GetChatRoomMessagesQuery,
  GetChatRoomMessagesQueryVariables
>;
export const GetFoundMessagesDocument = gql`
  query GetFoundMessages($chatRoomId: String!, $search: String!) {
    foundMessages @client {
      ...CoreMessageFields
    }
  }
  ${CoreMessageFieldsFragmentDoc}
`;

/**
 * __useGetFoundMessagesQuery__
 *
 * To run a query within a React component, call `useGetFoundMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFoundMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFoundMessagesQuery({
 *   variables: {
 *      chatRoomId: // value for 'chatRoomId'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetFoundMessagesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetFoundMessagesQuery,
    GetFoundMessagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetFoundMessagesQuery, GetFoundMessagesQueryVariables>(
    GetFoundMessagesDocument,
    options
  );
}
export function useGetFoundMessagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFoundMessagesQuery,
    GetFoundMessagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetFoundMessagesQuery,
    GetFoundMessagesQueryVariables
  >(GetFoundMessagesDocument, options);
}
export type GetFoundMessagesQueryHookResult = ReturnType<
  typeof useGetFoundMessagesQuery
>;
export type GetFoundMessagesLazyQueryHookResult = ReturnType<
  typeof useGetFoundMessagesLazyQuery
>;
export type GetFoundMessagesQueryResult = Apollo.QueryResult<
  GetFoundMessagesQuery,
  GetFoundMessagesQueryVariables
>;
export const GetNodesDocument = gql`
  query GetNodes($input: GetNodesAndEdgesInput) {
    getNodes(input: $input) @persist {
      ...CoreNodeFields
    }
  }
  ${CoreNodeFieldsFragmentDoc}
`;

/**
 * __useGetNodesQuery__
 *
 * To run a query within a React component, call `useGetNodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNodesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetNodesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetNodesQuery, GetNodesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetNodesQuery, GetNodesQueryVariables>(
    GetNodesDocument,
    options
  );
}
export function useGetNodesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetNodesQuery,
    GetNodesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetNodesQuery, GetNodesQueryVariables>(
    GetNodesDocument,
    options
  );
}
export type GetNodesQueryHookResult = ReturnType<typeof useGetNodesQuery>;
export type GetNodesLazyQueryHookResult = ReturnType<
  typeof useGetNodesLazyQuery
>;
export type GetNodesQueryResult = Apollo.QueryResult<
  GetNodesQuery,
  GetNodesQueryVariables
>;
export const GetEdgesDocument = gql`
  query GetEdges($input: GetNodesAndEdgesInput) {
    getEdges(input: $input) @persist {
      ...CoreEdgeFields
    }
  }
  ${CoreEdgeFieldsFragmentDoc}
`;

/**
 * __useGetEdgesQuery__
 *
 * To run a query within a React component, call `useGetEdgesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEdgesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEdgesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetEdgesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetEdgesQuery, GetEdgesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetEdgesQuery, GetEdgesQueryVariables>(
    GetEdgesDocument,
    options
  );
}
export function useGetEdgesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEdgesQuery,
    GetEdgesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetEdgesQuery, GetEdgesQueryVariables>(
    GetEdgesDocument,
    options
  );
}
export type GetEdgesQueryHookResult = ReturnType<typeof useGetEdgesQuery>;
export type GetEdgesLazyQueryHookResult = ReturnType<
  typeof useGetEdgesLazyQuery
>;
export type GetEdgesQueryResult = Apollo.QueryResult<
  GetEdgesQuery,
  GetEdgesQueryVariables
>;
export const GetChatSnippetsDocument = gql`
  query GetChatSnippets(
    $snippetId: String
    $categoryId: String
    $userId: String!
  ) {
    getChatSnippets(
      snippetId: $snippetId
      categoryId: $categoryId
      userId: $userId
    ) {
      snippets {
        ...CoreSnippetFields
      }
      totalNumberOfSnippets
    }
  }
  ${CoreSnippetFieldsFragmentDoc}
`;

/**
 * __useGetChatSnippetsQuery__
 *
 * To run a query within a React component, call `useGetChatSnippetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChatSnippetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChatSnippetsQuery({
 *   variables: {
 *      snippetId: // value for 'snippetId'
 *      categoryId: // value for 'categoryId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetChatSnippetsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetChatSnippetsQuery,
    GetChatSnippetsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetChatSnippetsQuery, GetChatSnippetsQueryVariables>(
    GetChatSnippetsDocument,
    options
  );
}
export function useGetChatSnippetsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetChatSnippetsQuery,
    GetChatSnippetsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetChatSnippetsQuery,
    GetChatSnippetsQueryVariables
  >(GetChatSnippetsDocument, options);
}
export type GetChatSnippetsQueryHookResult = ReturnType<
  typeof useGetChatSnippetsQuery
>;
export type GetChatSnippetsLazyQueryHookResult = ReturnType<
  typeof useGetChatSnippetsLazyQuery
>;
export type GetChatSnippetsQueryResult = Apollo.QueryResult<
  GetChatSnippetsQuery,
  GetChatSnippetsQueryVariables
>;
export const GetChatSnippetsFavoritesDocument = gql`
  query getChatSnippetsFavorites($userId: String!) {
    getChatSnippetsFavorites(userId: $userId) {
      snippets {
        ...CoreSnippetFields
      }
      totalNumberOfSnippets
    }
  }
  ${CoreSnippetFieldsFragmentDoc}
`;

/**
 * __useGetChatSnippetsFavoritesQuery__
 *
 * To run a query within a React component, call `useGetChatSnippetsFavoritesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChatSnippetsFavoritesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChatSnippetsFavoritesQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetChatSnippetsFavoritesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetChatSnippetsFavoritesQuery,
    GetChatSnippetsFavoritesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetChatSnippetsFavoritesQuery,
    GetChatSnippetsFavoritesQueryVariables
  >(GetChatSnippetsFavoritesDocument, options);
}
export function useGetChatSnippetsFavoritesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetChatSnippetsFavoritesQuery,
    GetChatSnippetsFavoritesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetChatSnippetsFavoritesQuery,
    GetChatSnippetsFavoritesQueryVariables
  >(GetChatSnippetsFavoritesDocument, options);
}
export type GetChatSnippetsFavoritesQueryHookResult = ReturnType<
  typeof useGetChatSnippetsFavoritesQuery
>;
export type GetChatSnippetsFavoritesLazyQueryHookResult = ReturnType<
  typeof useGetChatSnippetsFavoritesLazyQuery
>;
export type GetChatSnippetsFavoritesQueryResult = Apollo.QueryResult<
  GetChatSnippetsFavoritesQuery,
  GetChatSnippetsFavoritesQueryVariables
>;
export const GetChatSnippetsCategoriesDocument = gql`
  query GetChatSnippetsCategories(
    $parentId: String
    $categoryId: String
    $userId: String
  ) {
    getChatSnippetsCategories(
      parentId: $parentId
      categoryId: $categoryId
      userId: $userId
    ) {
      categories {
        ...CoreSnippetCategoryFields
      }
      totalNumberOfCategories
    }
  }
  ${CoreSnippetCategoryFieldsFragmentDoc}
`;

/**
 * __useGetChatSnippetsCategoriesQuery__
 *
 * To run a query within a React component, call `useGetChatSnippetsCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChatSnippetsCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChatSnippetsCategoriesQuery({
 *   variables: {
 *      parentId: // value for 'parentId'
 *      categoryId: // value for 'categoryId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetChatSnippetsCategoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetChatSnippetsCategoriesQuery,
    GetChatSnippetsCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetChatSnippetsCategoriesQuery,
    GetChatSnippetsCategoriesQueryVariables
  >(GetChatSnippetsCategoriesDocument, options);
}
export function useGetChatSnippetsCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetChatSnippetsCategoriesQuery,
    GetChatSnippetsCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetChatSnippetsCategoriesQuery,
    GetChatSnippetsCategoriesQueryVariables
  >(GetChatSnippetsCategoriesDocument, options);
}
export type GetChatSnippetsCategoriesQueryHookResult = ReturnType<
  typeof useGetChatSnippetsCategoriesQuery
>;
export type GetChatSnippetsCategoriesLazyQueryHookResult = ReturnType<
  typeof useGetChatSnippetsCategoriesLazyQuery
>;
export type GetChatSnippetsCategoriesQueryResult = Apollo.QueryResult<
  GetChatSnippetsCategoriesQuery,
  GetChatSnippetsCategoriesQueryVariables
>;
export const RequestRoomsHistoryAsFileDocument = gql`
  query RequestRoomsHistoryAsFile(
    $reportType: ReportType!
    $userId: String!
    $roomId: String
    $operatorId: String
    $dateStart: String
    $dateEnd: String
  ) {
    requestRoomsHistoryAsFile(
      reportType: $reportType
      userId: $userId
      roomId: $roomId
      operatorId: $operatorId
      dateStart: $dateStart
      dateEnd: $dateEnd
    ) {
      id
      userId
      name
      url
      status
      createdAt
    }
  }
`;

/**
 * __useRequestRoomsHistoryAsFileQuery__
 *
 * To run a query within a React component, call `useRequestRoomsHistoryAsFileQuery` and pass it any options that fit your needs.
 * When your component renders, `useRequestRoomsHistoryAsFileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRequestRoomsHistoryAsFileQuery({
 *   variables: {
 *      reportType: // value for 'reportType'
 *      userId: // value for 'userId'
 *      roomId: // value for 'roomId'
 *      operatorId: // value for 'operatorId'
 *      dateStart: // value for 'dateStart'
 *      dateEnd: // value for 'dateEnd'
 *   },
 * });
 */
export function useRequestRoomsHistoryAsFileQuery(
  baseOptions: Apollo.QueryHookOptions<
    RequestRoomsHistoryAsFileQuery,
    RequestRoomsHistoryAsFileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RequestRoomsHistoryAsFileQuery,
    RequestRoomsHistoryAsFileQueryVariables
  >(RequestRoomsHistoryAsFileDocument, options);
}
export function useRequestRoomsHistoryAsFileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RequestRoomsHistoryAsFileQuery,
    RequestRoomsHistoryAsFileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RequestRoomsHistoryAsFileQuery,
    RequestRoomsHistoryAsFileQueryVariables
  >(RequestRoomsHistoryAsFileDocument, options);
}
export type RequestRoomsHistoryAsFileQueryHookResult = ReturnType<
  typeof useRequestRoomsHistoryAsFileQuery
>;
export type RequestRoomsHistoryAsFileLazyQueryHookResult = ReturnType<
  typeof useRequestRoomsHistoryAsFileLazyQuery
>;
export type RequestRoomsHistoryAsFileQueryResult = Apollo.QueryResult<
  RequestRoomsHistoryAsFileQuery,
  RequestRoomsHistoryAsFileQueryVariables
>;
export const ValidateBotDocument = gql`
  query ValidateBot($id: String!) {
    validateBot(id: $id) {
      ...CoreValidateBotFields
    }
  }
  ${CoreValidateBotFieldsFragmentDoc}
`;

/**
 * __useValidateBotQuery__
 *
 * To run a query within a React component, call `useValidateBotQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateBotQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateBotQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useValidateBotQuery(
  baseOptions: Apollo.QueryHookOptions<
    ValidateBotQuery,
    ValidateBotQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ValidateBotQuery, ValidateBotQueryVariables>(
    ValidateBotDocument,
    options
  );
}
export function useValidateBotLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ValidateBotQuery,
    ValidateBotQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ValidateBotQuery, ValidateBotQueryVariables>(
    ValidateBotDocument,
    options
  );
}
export type ValidateBotQueryHookResult = ReturnType<typeof useValidateBotQuery>;
export type ValidateBotLazyQueryHookResult = ReturnType<
  typeof useValidateBotLazyQuery
>;
export type ValidateBotQueryResult = Apollo.QueryResult<
  ValidateBotQuery,
  ValidateBotQueryVariables
>;
export const GetGeneralAnalyticsDocument = gql`
  query getGeneralAnalytics {
    getGeneralAnalytics {
      totalChats
      activeChats
      totalContacts
      operatorsPerformance {
        ...CoreOperatorsPerformanceFields
      }
      chatsByChannels {
        ...CoreDataByChannelStatusFields
      }
      newChats7d {
        ...CoreLineChartFields
      }
    }
  }
  ${CoreOperatorsPerformanceFieldsFragmentDoc}
  ${CoreDataByChannelStatusFieldsFragmentDoc}
  ${CoreLineChartFieldsFragmentDoc}
`;

/**
 * __useGetGeneralAnalyticsQuery__
 *
 * To run a query within a React component, call `useGetGeneralAnalyticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGeneralAnalyticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGeneralAnalyticsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGeneralAnalyticsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetGeneralAnalyticsQuery,
    GetGeneralAnalyticsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetGeneralAnalyticsQuery,
    GetGeneralAnalyticsQueryVariables
  >(GetGeneralAnalyticsDocument, options);
}
export function useGetGeneralAnalyticsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetGeneralAnalyticsQuery,
    GetGeneralAnalyticsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetGeneralAnalyticsQuery,
    GetGeneralAnalyticsQueryVariables
  >(GetGeneralAnalyticsDocument, options);
}
export type GetGeneralAnalyticsQueryHookResult = ReturnType<
  typeof useGetGeneralAnalyticsQuery
>;
export type GetGeneralAnalyticsLazyQueryHookResult = ReturnType<
  typeof useGetGeneralAnalyticsLazyQuery
>;
export type GetGeneralAnalyticsQueryResult = Apollo.QueryResult<
  GetGeneralAnalyticsQuery,
  GetGeneralAnalyticsQueryVariables
>;
export const GetContactsAnalyticsDocument = gql`
  query getContactsAnalytics {
    getContactsAnalytics {
      totalContacts
      newContactsToday
      contactsByChannels {
        ...CoreDataByChannelStatusFields
      }
      activeChats {
        ...CoreDataByRoomStatusFields
      }
      newContacts7d {
        ...CoreLineChartFields
      }
    }
  }
  ${CoreDataByChannelStatusFieldsFragmentDoc}
  ${CoreDataByRoomStatusFieldsFragmentDoc}
  ${CoreLineChartFieldsFragmentDoc}
`;

/**
 * __useGetContactsAnalyticsQuery__
 *
 * To run a query within a React component, call `useGetContactsAnalyticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactsAnalyticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactsAnalyticsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetContactsAnalyticsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetContactsAnalyticsQuery,
    GetContactsAnalyticsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetContactsAnalyticsQuery,
    GetContactsAnalyticsQueryVariables
  >(GetContactsAnalyticsDocument, options);
}
export function useGetContactsAnalyticsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetContactsAnalyticsQuery,
    GetContactsAnalyticsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetContactsAnalyticsQuery,
    GetContactsAnalyticsQueryVariables
  >(GetContactsAnalyticsDocument, options);
}
export type GetContactsAnalyticsQueryHookResult = ReturnType<
  typeof useGetContactsAnalyticsQuery
>;
export type GetContactsAnalyticsLazyQueryHookResult = ReturnType<
  typeof useGetContactsAnalyticsLazyQuery
>;
export type GetContactsAnalyticsQueryResult = Apollo.QueryResult<
  GetContactsAnalyticsQuery,
  GetContactsAnalyticsQueryVariables
>;
export const GetChatsAnalyticsDocument = gql`
  query getChatsAnalytics($input: GetChatsAnalyticsInput) {
    getChatsAnalytics(input: $input) {
      chatsProcessed
      chatsUnprocessed
      avgInvisible
      avgNonAccepted
      avgAccepted
      chatsDistribution {
        ...CoreChatsDistributionFields
      }
      chatsRepeated {
        ...CoreChatsRepeatedFields
      }
      chatsByChannels {
        ...CoreLineChartFields
      }
    }
  }
  ${CoreChatsDistributionFieldsFragmentDoc}
  ${CoreChatsRepeatedFieldsFragmentDoc}
  ${CoreLineChartFieldsFragmentDoc}
`;

/**
 * __useGetChatsAnalyticsQuery__
 *
 * To run a query within a React component, call `useGetChatsAnalyticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChatsAnalyticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChatsAnalyticsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetChatsAnalyticsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetChatsAnalyticsQuery,
    GetChatsAnalyticsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetChatsAnalyticsQuery,
    GetChatsAnalyticsQueryVariables
  >(GetChatsAnalyticsDocument, options);
}
export function useGetChatsAnalyticsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetChatsAnalyticsQuery,
    GetChatsAnalyticsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetChatsAnalyticsQuery,
    GetChatsAnalyticsQueryVariables
  >(GetChatsAnalyticsDocument, options);
}
export type GetChatsAnalyticsQueryHookResult = ReturnType<
  typeof useGetChatsAnalyticsQuery
>;
export type GetChatsAnalyticsLazyQueryHookResult = ReturnType<
  typeof useGetChatsAnalyticsLazyQuery
>;
export type GetChatsAnalyticsQueryResult = Apollo.QueryResult<
  GetChatsAnalyticsQuery,
  GetChatsAnalyticsQueryVariables
>;
export const GetOperatorsAnalyticsDocument = gql`
  query getOperatorsAnalytics($input: GetOperatorsAnalyticsInput) {
    getOperatorsAnalytics(input: $input) {
      avgChatsPerOperatorPerDay
      avgAcceptedToFirstMessage
      avgAccepted
      acceptedByOperatorsByPeriodMap {
        ...CoreAnalyticsHeatMapSeriesItemFields
      }
      operatorsMetricsTable {
        ...CoreAnalyticsOperatorsMetricsTableRowFields
      }
    }
  }
  ${CoreAnalyticsHeatMapSeriesItemFieldsFragmentDoc}
  ${CoreAnalyticsOperatorsMetricsTableRowFieldsFragmentDoc}
`;

/**
 * __useGetOperatorsAnalyticsQuery__
 *
 * To run a query within a React component, call `useGetOperatorsAnalyticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOperatorsAnalyticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOperatorsAnalyticsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetOperatorsAnalyticsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetOperatorsAnalyticsQuery,
    GetOperatorsAnalyticsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetOperatorsAnalyticsQuery,
    GetOperatorsAnalyticsQueryVariables
  >(GetOperatorsAnalyticsDocument, options);
}
export function useGetOperatorsAnalyticsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOperatorsAnalyticsQuery,
    GetOperatorsAnalyticsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOperatorsAnalyticsQuery,
    GetOperatorsAnalyticsQueryVariables
  >(GetOperatorsAnalyticsDocument, options);
}
export type GetOperatorsAnalyticsQueryHookResult = ReturnType<
  typeof useGetOperatorsAnalyticsQuery
>;
export type GetOperatorsAnalyticsLazyQueryHookResult = ReturnType<
  typeof useGetOperatorsAnalyticsLazyQuery
>;
export type GetOperatorsAnalyticsQueryResult = Apollo.QueryResult<
  GetOperatorsAnalyticsQuery,
  GetOperatorsAnalyticsQueryVariables
>;
export const GetAppsDocument = gql`
  query GetApps(
    $ids: [ID]
    $types: [AppType]
    $isAvailableOnly: Boolean
    $isInstalledOnly: Boolean
  ) {
    getApps(
      ids: $ids
      types: $types
      isAvailableOnly: $isAvailableOnly
      isInstalledOnly: $isInstalledOnly
    ) {
      ...CoreAppFields
    }
  }
  ${CoreAppFieldsFragmentDoc}
`;

/**
 * __useGetAppsQuery__
 *
 * To run a query within a React component, call `useGetAppsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *      types: // value for 'types'
 *      isAvailableOnly: // value for 'isAvailableOnly'
 *      isInstalledOnly: // value for 'isInstalledOnly'
 *   },
 * });
 */
export function useGetAppsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAppsQuery, GetAppsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAppsQuery, GetAppsQueryVariables>(
    GetAppsDocument,
    options
  );
}
export function useGetAppsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAppsQuery, GetAppsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAppsQuery, GetAppsQueryVariables>(
    GetAppsDocument,
    options
  );
}
export type GetAppsQueryHookResult = ReturnType<typeof useGetAppsQuery>;
export type GetAppsLazyQueryHookResult = ReturnType<typeof useGetAppsLazyQuery>;
export type GetAppsQueryResult = Apollo.QueryResult<
  GetAppsQuery,
  GetAppsQueryVariables
>;
export const GetChatRoomHistoryDocument = gql`
  query GetChatRoomHistory($roomId: String!) {
    getChatRoomHistory(roomId: $roomId) {
      chatRoomHistory {
        ...CoreRoomEventFields
      }
    }
  }
  ${CoreRoomEventFieldsFragmentDoc}
`;

/**
 * __useGetChatRoomHistoryQuery__
 *
 * To run a query within a React component, call `useGetChatRoomHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChatRoomHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChatRoomHistoryQuery({
 *   variables: {
 *      roomId: // value for 'roomId'
 *   },
 * });
 */
export function useGetChatRoomHistoryQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetChatRoomHistoryQuery,
    GetChatRoomHistoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetChatRoomHistoryQuery,
    GetChatRoomHistoryQueryVariables
  >(GetChatRoomHistoryDocument, options);
}
export function useGetChatRoomHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetChatRoomHistoryQuery,
    GetChatRoomHistoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetChatRoomHistoryQuery,
    GetChatRoomHistoryQueryVariables
  >(GetChatRoomHistoryDocument, options);
}
export type GetChatRoomHistoryQueryHookResult = ReturnType<
  typeof useGetChatRoomHistoryQuery
>;
export type GetChatRoomHistoryLazyQueryHookResult = ReturnType<
  typeof useGetChatRoomHistoryLazyQuery
>;
export type GetChatRoomHistoryQueryResult = Apollo.QueryResult<
  GetChatRoomHistoryQuery,
  GetChatRoomHistoryQueryVariables
>;
export const GetAvailableTwilioPhoneNumbersDocument = gql`
  query getAvailableTwilioPhoneNumbers($areaCode: Int!, $limit: Int) {
    getAvailableTwilioPhoneNumbers(areaCode: $areaCode, limit: $limit)
  }
`;

/**
 * __useGetAvailableTwilioPhoneNumbersQuery__
 *
 * To run a query within a React component, call `useGetAvailableTwilioPhoneNumbersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAvailableTwilioPhoneNumbersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAvailableTwilioPhoneNumbersQuery({
 *   variables: {
 *      areaCode: // value for 'areaCode'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetAvailableTwilioPhoneNumbersQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAvailableTwilioPhoneNumbersQuery,
    GetAvailableTwilioPhoneNumbersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAvailableTwilioPhoneNumbersQuery,
    GetAvailableTwilioPhoneNumbersQueryVariables
  >(GetAvailableTwilioPhoneNumbersDocument, options);
}
export function useGetAvailableTwilioPhoneNumbersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAvailableTwilioPhoneNumbersQuery,
    GetAvailableTwilioPhoneNumbersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAvailableTwilioPhoneNumbersQuery,
    GetAvailableTwilioPhoneNumbersQueryVariables
  >(GetAvailableTwilioPhoneNumbersDocument, options);
}
export type GetAvailableTwilioPhoneNumbersQueryHookResult = ReturnType<
  typeof useGetAvailableTwilioPhoneNumbersQuery
>;
export type GetAvailableTwilioPhoneNumbersLazyQueryHookResult = ReturnType<
  typeof useGetAvailableTwilioPhoneNumbersLazyQuery
>;
export type GetAvailableTwilioPhoneNumbersQueryResult = Apollo.QueryResult<
  GetAvailableTwilioPhoneNumbersQuery,
  GetAvailableTwilioPhoneNumbersQueryVariables
>;
export const GetCalendarDocument = gql`
  query GetCalendar {
    getCalendar @persist {
      ...CoreCalendarFields
    }
  }
  ${CoreCalendarFieldsFragmentDoc}
`;

/**
 * __useGetCalendarQuery__
 *
 * To run a query within a React component, call `useGetCalendarQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCalendarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCalendarQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCalendarQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCalendarQuery,
    GetCalendarQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCalendarQuery, GetCalendarQueryVariables>(
    GetCalendarDocument,
    options
  );
}
export function useGetCalendarLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCalendarQuery,
    GetCalendarQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCalendarQuery, GetCalendarQueryVariables>(
    GetCalendarDocument,
    options
  );
}
export type GetCalendarQueryHookResult = ReturnType<typeof useGetCalendarQuery>;
export type GetCalendarLazyQueryHookResult = ReturnType<
  typeof useGetCalendarLazyQuery
>;
export type GetCalendarQueryResult = Apollo.QueryResult<
  GetCalendarQuery,
  GetCalendarQueryVariables
>;
export const GetCalendarEventsDocument = gql`
  query GetCalendarEvents {
    getCalendarEvents @persist {
      ...CoreCalendarEventFields
    }
  }
  ${CoreCalendarEventFieldsFragmentDoc}
`;

/**
 * __useGetCalendarEventsQuery__
 *
 * To run a query within a React component, call `useGetCalendarEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCalendarEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCalendarEventsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCalendarEventsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCalendarEventsQuery,
    GetCalendarEventsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCalendarEventsQuery,
    GetCalendarEventsQueryVariables
  >(GetCalendarEventsDocument, options);
}
export function useGetCalendarEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCalendarEventsQuery,
    GetCalendarEventsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCalendarEventsQuery,
    GetCalendarEventsQueryVariables
  >(GetCalendarEventsDocument, options);
}
export type GetCalendarEventsQueryHookResult = ReturnType<
  typeof useGetCalendarEventsQuery
>;
export type GetCalendarEventsLazyQueryHookResult = ReturnType<
  typeof useGetCalendarEventsLazyQuery
>;
export type GetCalendarEventsQueryResult = Apollo.QueryResult<
  GetCalendarEventsQuery,
  GetCalendarEventsQueryVariables
>;
export const GetAiBotTemplatesWithFieldsDocument = gql`
  query GetAiBotTemplatesWithFields($templateId: ID) {
    getAiBotTemplatesWithFields(templateId: $templateId) {
      id
      createdAt
      isAvailable
      name {
        ...CoreLanguageMapFields
      }
      description {
        ...CoreLanguageMapFields
      }
      fields
    }
  }
  ${CoreLanguageMapFieldsFragmentDoc}
`;

/**
 * __useGetAiBotTemplatesWithFieldsQuery__
 *
 * To run a query within a React component, call `useGetAiBotTemplatesWithFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAiBotTemplatesWithFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAiBotTemplatesWithFieldsQuery({
 *   variables: {
 *      templateId: // value for 'templateId'
 *   },
 * });
 */
export function useGetAiBotTemplatesWithFieldsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAiBotTemplatesWithFieldsQuery,
    GetAiBotTemplatesWithFieldsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAiBotTemplatesWithFieldsQuery,
    GetAiBotTemplatesWithFieldsQueryVariables
  >(GetAiBotTemplatesWithFieldsDocument, options);
}
export function useGetAiBotTemplatesWithFieldsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAiBotTemplatesWithFieldsQuery,
    GetAiBotTemplatesWithFieldsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAiBotTemplatesWithFieldsQuery,
    GetAiBotTemplatesWithFieldsQueryVariables
  >(GetAiBotTemplatesWithFieldsDocument, options);
}
export type GetAiBotTemplatesWithFieldsQueryHookResult = ReturnType<
  typeof useGetAiBotTemplatesWithFieldsQuery
>;
export type GetAiBotTemplatesWithFieldsLazyQueryHookResult = ReturnType<
  typeof useGetAiBotTemplatesWithFieldsLazyQuery
>;
export type GetAiBotTemplatesWithFieldsQueryResult = Apollo.QueryResult<
  GetAiBotTemplatesWithFieldsQuery,
  GetAiBotTemplatesWithFieldsQueryVariables
>;
export const GetAiBotUserSettingsDocument = gql`
  query GetAiBotUserSettings($botId: ID!) {
    getAiBotUserSettings(botId: $botId) {
      id
      createdAt
      updatedAt
      templateId
      botId
      userSettings
    }
  }
`;

/**
 * __useGetAiBotUserSettingsQuery__
 *
 * To run a query within a React component, call `useGetAiBotUserSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAiBotUserSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAiBotUserSettingsQuery({
 *   variables: {
 *      botId: // value for 'botId'
 *   },
 * });
 */
export function useGetAiBotUserSettingsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAiBotUserSettingsQuery,
    GetAiBotUserSettingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAiBotUserSettingsQuery,
    GetAiBotUserSettingsQueryVariables
  >(GetAiBotUserSettingsDocument, options);
}
export function useGetAiBotUserSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAiBotUserSettingsQuery,
    GetAiBotUserSettingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAiBotUserSettingsQuery,
    GetAiBotUserSettingsQueryVariables
  >(GetAiBotUserSettingsDocument, options);
}
export type GetAiBotUserSettingsQueryHookResult = ReturnType<
  typeof useGetAiBotUserSettingsQuery
>;
export type GetAiBotUserSettingsLazyQueryHookResult = ReturnType<
  typeof useGetAiBotUserSettingsLazyQuery
>;
export type GetAiBotUserSettingsQueryResult = Apollo.QueryResult<
  GetAiBotUserSettingsQuery,
  GetAiBotUserSettingsQueryVariables
>;
export const GetOrganizationsDocument = gql`
  query GetOrganizations {
    getOrganizations {
      current {
        ...CoreOrganizationFields
      }
      parent {
        ...CoreOrganizationFields
      }
      subsidiaries {
        ...CoreOrganizationFields
      }
    }
  }
  ${CoreOrganizationFieldsFragmentDoc}
`;

/**
 * __useGetOrganizationsQuery__
 *
 * To run a query within a React component, call `useGetOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrganizationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetOrganizationsQuery,
    GetOrganizationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOrganizationsQuery, GetOrganizationsQueryVariables>(
    GetOrganizationsDocument,
    options
  );
}
export function useGetOrganizationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOrganizationsQuery,
    GetOrganizationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOrganizationsQuery,
    GetOrganizationsQueryVariables
  >(GetOrganizationsDocument, options);
}
export type GetOrganizationsQueryHookResult = ReturnType<
  typeof useGetOrganizationsQuery
>;
export type GetOrganizationsLazyQueryHookResult = ReturnType<
  typeof useGetOrganizationsLazyQuery
>;
export type GetOrganizationsQueryResult = Apollo.QueryResult<
  GetOrganizationsQuery,
  GetOrganizationsQueryVariables
>;
export const GetCustomFieldsDocument = gql`
  query GetCustomFields {
    getCustomFields {
      ...CoreCustomFieldFields
    }
  }
  ${CoreCustomFieldFieldsFragmentDoc}
`;

/**
 * __useGetCustomFieldsQuery__
 *
 * To run a query within a React component, call `useGetCustomFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomFieldsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCustomFieldsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCustomFieldsQuery,
    GetCustomFieldsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCustomFieldsQuery, GetCustomFieldsQueryVariables>(
    GetCustomFieldsDocument,
    options
  );
}
export function useGetCustomFieldsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCustomFieldsQuery,
    GetCustomFieldsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCustomFieldsQuery,
    GetCustomFieldsQueryVariables
  >(GetCustomFieldsDocument, options);
}
export type GetCustomFieldsQueryHookResult = ReturnType<
  typeof useGetCustomFieldsQuery
>;
export type GetCustomFieldsLazyQueryHookResult = ReturnType<
  typeof useGetCustomFieldsLazyQuery
>;
export type GetCustomFieldsQueryResult = Apollo.QueryResult<
  GetCustomFieldsQuery,
  GetCustomFieldsQueryVariables
>;
export const WatchUsersDocument = gql`
  subscription WatchUsers {
    watchUsers {
      user {
        ...CoreUserFields
        ...InternalUserFields
      }
      method
    }
  }
  ${CoreUserFieldsFragmentDoc}
  ${InternalUserFieldsFragmentDoc}
`;

/**
 * __useWatchUsersSubscription__
 *
 * To run a query within a React component, call `useWatchUsersSubscription` and pass it any options that fit your needs.
 * When your component renders, `useWatchUsersSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWatchUsersSubscription({
 *   variables: {
 *   },
 * });
 */
export function useWatchUsersSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    WatchUsersSubscription,
    WatchUsersSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    WatchUsersSubscription,
    WatchUsersSubscriptionVariables
  >(WatchUsersDocument, options);
}
export type WatchUsersSubscriptionHookResult = ReturnType<
  typeof useWatchUsersSubscription
>;
export type WatchUsersSubscriptionResult =
  Apollo.SubscriptionResult<WatchUsersSubscription>;
export const WatchRooms2Document = gql`
  subscription WatchRooms2($teamId: String!, $userId: String!) {
    watchRooms2(teamId: $teamId, userId: $userId) {
      rooms {
        ...CoreRoomFields
      }
      error
    }
  }
  ${CoreRoomFieldsFragmentDoc}
`;

/**
 * __useWatchRooms2Subscription__
 *
 * To run a query within a React component, call `useWatchRooms2Subscription` and pass it any options that fit your needs.
 * When your component renders, `useWatchRooms2Subscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWatchRooms2Subscription({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useWatchRooms2Subscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    WatchRooms2Subscription,
    WatchRooms2SubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    WatchRooms2Subscription,
    WatchRooms2SubscriptionVariables
  >(WatchRooms2Document, options);
}
export type WatchRooms2SubscriptionHookResult = ReturnType<
  typeof useWatchRooms2Subscription
>;
export type WatchRooms2SubscriptionResult =
  Apollo.SubscriptionResult<WatchRooms2Subscription>;
export const WatchCreateMessageDocument = gql`
  subscription WatchCreateMessage($channelType: ChannelType!) {
    watchCreateMessage(channelType: $channelType) {
      ...CoreMessageFields
    }
  }
  ${CoreMessageFieldsFragmentDoc}
`;

/**
 * __useWatchCreateMessageSubscription__
 *
 * To run a query within a React component, call `useWatchCreateMessageSubscription` and pass it any options that fit your needs.
 * When your component renders, `useWatchCreateMessageSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWatchCreateMessageSubscription({
 *   variables: {
 *      channelType: // value for 'channelType'
 *   },
 * });
 */
export function useWatchCreateMessageSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    WatchCreateMessageSubscription,
    WatchCreateMessageSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    WatchCreateMessageSubscription,
    WatchCreateMessageSubscriptionVariables
  >(WatchCreateMessageDocument, options);
}
export type WatchCreateMessageSubscriptionHookResult = ReturnType<
  typeof useWatchCreateMessageSubscription
>;
export type WatchCreateMessageSubscriptionResult =
  Apollo.SubscriptionResult<WatchCreateMessageSubscription>;
export const WatchUpdateMessageDataDocument = gql`
  subscription WatchUpdateMessageData($channelType: ChannelType!) {
    watchUpdateMessageData(channelType: $channelType) {
      channelId
      channelTypeName
      chatRoomId
      chatRoomMessages {
        ...CoreMessageFields
      }
      chatRoomStatus
      isClient
      unreadMessagesNumber
    }
  }
  ${CoreMessageFieldsFragmentDoc}
`;

/**
 * __useWatchUpdateMessageDataSubscription__
 *
 * To run a query within a React component, call `useWatchUpdateMessageDataSubscription` and pass it any options that fit your needs.
 * When your component renders, `useWatchUpdateMessageDataSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWatchUpdateMessageDataSubscription({
 *   variables: {
 *      channelType: // value for 'channelType'
 *   },
 * });
 */
export function useWatchUpdateMessageDataSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    WatchUpdateMessageDataSubscription,
    WatchUpdateMessageDataSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    WatchUpdateMessageDataSubscription,
    WatchUpdateMessageDataSubscriptionVariables
  >(WatchUpdateMessageDataDocument, options);
}
export type WatchUpdateMessageDataSubscriptionHookResult = ReturnType<
  typeof useWatchUpdateMessageDataSubscription
>;
export type WatchUpdateMessageDataSubscriptionResult =
  Apollo.SubscriptionResult<WatchUpdateMessageDataSubscription>;
export const WatchDeleteMessageDocument = gql`
  subscription WatchDeleteMessage($channelType: ChannelType!) {
    watchDeleteMessage(channelType: $channelType) {
      channelType
      channelId
      chatRoomId
      messageId
    }
  }
`;

/**
 * __useWatchDeleteMessageSubscription__
 *
 * To run a query within a React component, call `useWatchDeleteMessageSubscription` and pass it any options that fit your needs.
 * When your component renders, `useWatchDeleteMessageSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWatchDeleteMessageSubscription({
 *   variables: {
 *      channelType: // value for 'channelType'
 *   },
 * });
 */
export function useWatchDeleteMessageSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    WatchDeleteMessageSubscription,
    WatchDeleteMessageSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    WatchDeleteMessageSubscription,
    WatchDeleteMessageSubscriptionVariables
  >(WatchDeleteMessageDocument, options);
}
export type WatchDeleteMessageSubscriptionHookResult = ReturnType<
  typeof useWatchDeleteMessageSubscription
>;
export type WatchDeleteMessageSubscriptionResult =
  Apollo.SubscriptionResult<WatchDeleteMessageSubscription>;
export const WatchChannelsDocument = gql`
  subscription WatchChannels {
    watchChannels {
      channel {
        ...CoreChannelFields
      }
      method
    }
  }
  ${CoreChannelFieldsFragmentDoc}
`;

/**
 * __useWatchChannelsSubscription__
 *
 * To run a query within a React component, call `useWatchChannelsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useWatchChannelsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWatchChannelsSubscription({
 *   variables: {
 *   },
 * });
 */
export function useWatchChannelsSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    WatchChannelsSubscription,
    WatchChannelsSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    WatchChannelsSubscription,
    WatchChannelsSubscriptionVariables
  >(WatchChannelsDocument, options);
}
export type WatchChannelsSubscriptionHookResult = ReturnType<
  typeof useWatchChannelsSubscription
>;
export type WatchChannelsSubscriptionResult =
  Apollo.SubscriptionResult<WatchChannelsSubscription>;
export const WatchCommentsDocument = gql`
  subscription WatchComments {
    watchComments {
      method
      comment {
        ...CoreCommentsFields
      }
    }
  }
  ${CoreCommentsFieldsFragmentDoc}
`;

/**
 * __useWatchCommentsSubscription__
 *
 * To run a query within a React component, call `useWatchCommentsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useWatchCommentsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWatchCommentsSubscription({
 *   variables: {
 *   },
 * });
 */
export function useWatchCommentsSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    WatchCommentsSubscription,
    WatchCommentsSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    WatchCommentsSubscription,
    WatchCommentsSubscriptionVariables
  >(WatchCommentsDocument, options);
}
export type WatchCommentsSubscriptionHookResult = ReturnType<
  typeof useWatchCommentsSubscription
>;
export type WatchCommentsSubscriptionResult =
  Apollo.SubscriptionResult<WatchCommentsSubscription>;
export const WatchBotsDocument = gql`
  subscription WatchBots {
    watchBots {
      bots {
        ...ExtendedBotFields
      }
    }
  }
  ${ExtendedBotFieldsFragmentDoc}
`;

/**
 * __useWatchBotsSubscription__
 *
 * To run a query within a React component, call `useWatchBotsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useWatchBotsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWatchBotsSubscription({
 *   variables: {
 *   },
 * });
 */
export function useWatchBotsSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    WatchBotsSubscription,
    WatchBotsSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    WatchBotsSubscription,
    WatchBotsSubscriptionVariables
  >(WatchBotsDocument, options);
}
export type WatchBotsSubscriptionHookResult = ReturnType<
  typeof useWatchBotsSubscription
>;
export type WatchBotsSubscriptionResult =
  Apollo.SubscriptionResult<WatchBotsSubscription>;
export const WatchContactsAnalyticsDocument = gql`
  subscription WatchContactsAnalytics {
    watchContactsAnalytics {
      totalContacts
      newContactsToday
      contactsByChannels {
        ...CoreDataByChannelStatusFields
      }
      activeChats {
        ...CoreDataByRoomStatusFields
      }
      newContacts7d {
        ...CoreLineChartFields
      }
    }
  }
  ${CoreDataByChannelStatusFieldsFragmentDoc}
  ${CoreDataByRoomStatusFieldsFragmentDoc}
  ${CoreLineChartFieldsFragmentDoc}
`;

/**
 * __useWatchContactsAnalyticsSubscription__
 *
 * To run a query within a React component, call `useWatchContactsAnalyticsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useWatchContactsAnalyticsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWatchContactsAnalyticsSubscription({
 *   variables: {
 *   },
 * });
 */
export function useWatchContactsAnalyticsSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    WatchContactsAnalyticsSubscription,
    WatchContactsAnalyticsSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    WatchContactsAnalyticsSubscription,
    WatchContactsAnalyticsSubscriptionVariables
  >(WatchContactsAnalyticsDocument, options);
}
export type WatchContactsAnalyticsSubscriptionHookResult = ReturnType<
  typeof useWatchContactsAnalyticsSubscription
>;
export type WatchContactsAnalyticsSubscriptionResult =
  Apollo.SubscriptionResult<WatchContactsAnalyticsSubscription>;
export const WatchGeneralAnalyticsDocument = gql`
  subscription WatchGeneralAnalytics {
    watchGeneralAnalytics {
      totalChats
      activeChats
      totalContacts
      operatorsPerformance {
        ...CoreOperatorsPerformanceFields
      }
      chatsByChannels {
        ...CoreDataByChannelStatusFields
      }
      newChats7d {
        ...CoreLineChartFields
      }
    }
  }
  ${CoreOperatorsPerformanceFieldsFragmentDoc}
  ${CoreDataByChannelStatusFieldsFragmentDoc}
  ${CoreLineChartFieldsFragmentDoc}
`;

/**
 * __useWatchGeneralAnalyticsSubscription__
 *
 * To run a query within a React component, call `useWatchGeneralAnalyticsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useWatchGeneralAnalyticsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWatchGeneralAnalyticsSubscription({
 *   variables: {
 *   },
 * });
 */
export function useWatchGeneralAnalyticsSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    WatchGeneralAnalyticsSubscription,
    WatchGeneralAnalyticsSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    WatchGeneralAnalyticsSubscription,
    WatchGeneralAnalyticsSubscriptionVariables
  >(WatchGeneralAnalyticsDocument, options);
}
export type WatchGeneralAnalyticsSubscriptionHookResult = ReturnType<
  typeof useWatchGeneralAnalyticsSubscription
>;
export type WatchGeneralAnalyticsSubscriptionResult =
  Apollo.SubscriptionResult<WatchGeneralAnalyticsSubscription>;
export const WatchCalendarEventsDocument = gql`
  subscription WatchCalendarEvents($calendarId: String!) {
    watchCalendarEvents(calendarId: $calendarId) {
      actionType
      event {
        ...CoreCalendarEventFields
      }
    }
  }
  ${CoreCalendarEventFieldsFragmentDoc}
`;

/**
 * __useWatchCalendarEventsSubscription__
 *
 * To run a query within a React component, call `useWatchCalendarEventsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useWatchCalendarEventsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWatchCalendarEventsSubscription({
 *   variables: {
 *      calendarId: // value for 'calendarId'
 *   },
 * });
 */
export function useWatchCalendarEventsSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    WatchCalendarEventsSubscription,
    WatchCalendarEventsSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    WatchCalendarEventsSubscription,
    WatchCalendarEventsSubscriptionVariables
  >(WatchCalendarEventsDocument, options);
}
export type WatchCalendarEventsSubscriptionHookResult = ReturnType<
  typeof useWatchCalendarEventsSubscription
>;
export type WatchCalendarEventsSubscriptionResult =
  Apollo.SubscriptionResult<WatchCalendarEventsSubscription>;
