type ArrayMove = <T>(array: T[], fromIndex: number, toIndex: number) => T[];

const arrayMove: ArrayMove = (array, fromIndex, toIndex) => {
  const slicedArray = array.slice();

  slicedArray.splice(
    toIndex < 0 ? array.length + toIndex : toIndex,
    0,
    slicedArray.splice(fromIndex, 1)[0]
  );

  return slicedArray;
};

export default arrayMove;
