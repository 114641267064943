import { FC, PropsWithChildren } from "react";
import { useNavigate } from "react-router-dom";
import { Auth0Provider, Auth0ProviderOptions } from "@auth0/auth0-react";

const Auth0ProviderWithNavigate: FC<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate();

  const onRedirectCallback: Auth0ProviderOptions["onRedirectCallback"] = (
    appState
  ) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN as string}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID as string}
      onRedirectCallback={onRedirectCallback}
      cacheLocation={window.Cypress ? "localstorage" : "memory"}
      authorizationParams={{
        audience: process.env.REACT_APP_AUTH0_AUDIENCE as string,
        redirect_uri: window.location.origin,
      }}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithNavigate;
