import { useAuth0 } from "@auth0/auth0-react";
import filter from "lodash/filter";
import {
  GetUsersDocument,
  GetUsersQuery,
  MethodType,
  QueryGetUsersArgs,
  UserType,
  useWatchUsersSubscription,
} from "~@/graphql/codegen/generated";
import useInternalUser from "~@/hooks/useInternalUser";

const useWatchUsers = () => {
  const { logout } = useAuth0();

  const { internalUser } = useInternalUser();

  useWatchUsersSubscription({
    async onData({ data: { data: watchUsersData }, client: { cache } }) {
      const watchUsers = watchUsersData?.watchUsers;

      if (watchUsers) {
        const { user: watchUser, method } = watchUsers as Required<
          typeof watchUsers
        >;

        switch (method) {
          case MethodType.Delete: {
            if (internalUser?.id === watchUser?.id) {
              logout();
            } else {
              cache.updateQuery<GetUsersQuery, QueryGetUsersArgs>(
                {
                  query: GetUsersDocument,
                  variables: {
                    input: {
                      type: UserType.Internal,
                      isActive: true,
                    },
                  },
                },
                (data) => {
                  if (data?.getUsers) {
                    return {
                      getUsers: {
                        ...data.getUsers,
                        users: filter(
                          data.getUsers.users,
                          (user) => user?.id !== watchUser?.id
                        ),
                        pagination: {
                          ...data.getUsers.pagination,
                          totalItems:
                            (data.getUsers.pagination?.totalItems || 1) - 1,
                        },
                      },
                    };
                  }

                  return data;
                }
              );

              cache.updateQuery<GetUsersQuery, QueryGetUsersArgs>(
                {
                  query: GetUsersDocument,
                  variables: {
                    input: {
                      type: UserType.Internal,
                      isActive: false,
                    },
                  },
                },
                (data) => {
                  if (data?.getUsers) {
                    return {
                      getUsers: {
                        ...data.getUsers,
                        users: [watchUser, ...(data.getUsers.users || [])],
                        pagination: {
                          ...data.getUsers.pagination,
                          totalItems:
                            (data.getUsers.pagination?.totalItems || 0) + 1,
                        },
                      },
                    };
                  }

                  return data;
                }
              );
            }

            break;
          }
          case MethodType.Restore: {
            cache.updateQuery<GetUsersQuery, QueryGetUsersArgs>(
              {
                query: GetUsersDocument,
                variables: {
                  input: {
                    type: UserType.Internal,
                    isActive: false,
                  },
                },
              },
              (data) => {
                if (data?.getUsers) {
                  return {
                    getUsers: {
                      ...data.getUsers,
                      users: filter(
                        data.getUsers.users,
                        (user) => user?.id !== watchUser?.id
                      ),
                      pagination: {
                        ...data.getUsers.pagination,
                        totalItems:
                          (data.getUsers.pagination?.totalItems || 1) - 1,
                      },
                    },
                  };
                }

                return data;
              }
            );

            cache.updateQuery<GetUsersQuery, QueryGetUsersArgs>(
              {
                query: GetUsersDocument,
                variables: {
                  input: {
                    type: UserType.Internal,
                    isActive: true,
                  },
                },
              },
              (data) => {
                if (data?.getUsers) {
                  return {
                    getUsers: {
                      ...data.getUsers,
                      users: [watchUser, ...(data.getUsers.users || [])],
                      pagination: {
                        ...data.getUsers.pagination,
                        totalItems:
                          (data.getUsers.pagination?.totalItems || 0) + 1,
                      },
                    },
                  };
                }

                return data;
              }
            );

            break;
          }
          default:
            break;
        }
      }
    },
  });
};

export default useWatchUsers;
